import { Flex } from "assets/styles/common-styles";
import { ReactComponent as Envelope } from "assets/images/icons/envelope-2.svg";
import { SideContentWrapper } from "assets/styles/docs-styles";

const SideContent = () => {
  return (
    <SideContentWrapper>
      <p className="text-base-semibold headings mb-10">Contact</p>
      <div className={`${Flex} gap-2 mb-2`}>
        <Envelope />
        <p className="leading-[1.75rem]">Email</p>
      </div>
      <a
        href="mailto:techsupport@trymaxim.com"
        className="text-base-semibold accent-2"
      >
        techsupport@trymaxim.com
      </a>
      <p className="leading-[1.75rem] mt-8">
        Your feedback is invaluable as we continue improving and providing you
        with the best API experience. We look forward to hearing from you!
      </p>
    </SideContentWrapper>
  );
};

export default SideContent;
