import { styled } from "goober";
import { mobile, mobileSmallMax } from "globalStyles";

export const SidebarWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-width: 15rem;
  width: 100%;
  background-color: #f9fbfb;
  transition: width 0.3s;
  box-shadow: 0px 4px 7px var(--blue-100);
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  padding-bottom: 1rem;
  .bottom-nav {
    margin: 0 1rem;
    .item {
      padding: 0.5rem 1.25rem;
      border-radius: 0.25rem;
      background: var(--white);
      box-shadow: 0px 5px 20px 0px rgba(205, 213, 219, 0.50);
    }
  }
  ${mobile} {
    display: none;
  }
`;

export const NavItemsWrapper = styled("nav")`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 0;
  margin-top: 4.875rem;
  .nav-wrapper {
    position: relative;
    .coming-soon {
      position: absolute;
      top: -16px;
      right: 6px;
      background: var(--text-dark);
      padding: 0.25rem 0.5rem 0.375rem 0.5rem;
      border-radius: 0.25rem;
    }
    .active-bar {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1.75rem;
      width: 0.25rem;
      background: var(--headings);
      border-radius: 0 4px 4px 0;
    }
  }
  .nav-item {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    gap: 0.5rem;
    color: var(--text-neutral);
    font-size: 0.875rem;
    line-height: 16.94px;
    white-space: nowrap;
    min-height: 1.75rem;
    svg {
      fill: var(--text-neutral);
    }
    &:hover,
    &.active {
      color: var(--headings);
      font-weight: 600;
      svg {
        fill: var(--headings);
      }
    }
    .nav-item-title {
      opacity: 1;
      transform: translateX(0);
    }
    &.sub-item {
      .icon {
        fill: none;
        &:hover,
        &.active {
          fill: none;
        }
      }
    }
  }
`;

export const SubmenuItemsWrapper = styled("nav")`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 2rem;
  margin-top: 4.875rem;
  .nav-item {
    display: flex;
    align-items: center;
    padding: 0.25rem 0;
    gap: 0.5rem;
    color: var(--text-neutral);
    font-size: 0.875rem;
    line-height: 16.94px;
    white-space: nowrap;
    min-height: 1.75rem;
    svg {
      fill: var(--text-neutral);
    }
    &:hover,
    &.active {
      color: var(--headings);
      font-weight: 600;
    }
    .nav-item-title {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Wrapper = styled("div")`
  display: flex;
  position: relative;
`;

export const DocsContainer = styled("main")`
  width: calc(100vw - 15rem);
  min-height: 100vh;
  margin-left: auto;
  .main {
    margin-top: 4.875rem;
    padding: 2.375rem 5.125rem;
  }
  ${mobile} {
    width: 100%;
    .main {
      margin-top: 7.875rem;
      padding: 2rem;
    }
  }
`;

export const PageHeader = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--white);
  padding: 1.125rem 2rem 1.25rem 0;
  border-bottom: 0.5px solid var(--stroke);
  color: var(--headings);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  ${mobileSmallMax} {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 2rem;
  }
  .right {
    ${mobileSmallMax} {
      text-align: center;
    }
  }
`;

export const LogoWrapper = styled("div")`
  max-width: 15rem;
  width: 100%;
  padding-left: 2rem;
  ${mobile} {
    .mobile-none {
      display: none;
    }
  }
`;

export const Divider = styled("div")`
  border-right: 1px solid var(--stroke);
  height: 2rem;
`;

export const MainPageTitle = styled("section")`
  background: #f9fbfb;
  height: 11.25rem;
  width: 100%;
  padding: 0 3.125rem;
  border-radius: var(--br-md);
  margin-bottom: 3.25rem;
  ${mobile} {
    font-size: 1.25rem;
  }
`;

export const MainPageContent = styled("section")`
  padding-top: 3.25rem;
  border-top: 0.75px solid var(--stroke);
  display: flex;
  align-items: flex-start;
  gap: 3.75rem;
  ${mobile} {
    flex-direction: column;
  }
`;

export const SideContentWrapper = styled("section")`
  padding-left: 2rem;
  border-left: 0.5px solid var(--stroke);
  max-width: 16.875rem;
  width: 100%;
  ${mobile} {
    max-width: 100%;
  }
`;

export const PageTitle = styled("section")`
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 0.75px solid var(--stroke);
`;

export const InnerPageContent = styled("section")`
  width: 100%;
  margin-top: 2.5rem;
  .main-content {
    padding-right: 2rem;
    flex: 1;
  }
  .side-content {
    border-left: 0.75px solid var(--stroke);
    padding-left: 2.875rem;
    max-width: 507px;
    flex: 1;
  }
  .docs-table {
    width: 100%;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-spacing: 0;

    th {
      padding: 0.875rem 0;
      background-color: var(--white);
      color: var(--text-dark);
      text-align: left;
      white-space: nowrap;
      font-size: 1rem;
      font-weight: 500;
      line-height: normal;
      border-bottom: 0.75px solid var(--stroke);

      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 60%;
      }
    }

    td {
      color: var(--text-neutral);
      border: none;
    }

    tr {
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: var(--text-neutral);
        text-align: left;
        white-space: nowrap;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        border: none;
      }

      &:nth-child(even) {
        td {
          background-color: var(--white-alt);
        }
      }
      &:last-child {
        td {
          border-bottom: 0.75px solid var(--stroke);
        }
      }
    }
  }
`;

export const UrlBoxWrapper = styled("section")`
  border: 1px solid var(--stroke);
  padding: 0.8125rem 1rem 0.8125rem 1rem;
  border-radius: var(--br-sm);
`;

export const RequestType = styled("div")`
  padding: 0.125rem 0.75rem;
  border-radius: var(--br-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 3.375rem;
  height: 1.5rem;
`;
