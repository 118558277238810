import axiosInstance from 'axiosInstance';
import { proxy } from 'valtio';

const consent = proxy({
  verifyReferenceLoading: false,
  referenceData: null,
  validateIdLoading: false,
  validIdData: null,
  termsLoading: false,
  termsData: null,
  createConsentLoading: false,
  consentData: null,

  async verifyReference(body) {
    this.verifyReferenceLoading = true;
    return axiosInstance
      .post(`/assessments/consent`, body)
      .then(({ data }) => {
        this.referenceData = data.data;
        this.verifyReferenceLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.verifyReferenceLoading = false;
        return response;
      });
  },

  async validateId(body) {
    this.validateIdLoading = true;
    return axiosInstance
      .post(`/assessments/consent/validate-id`, body)
      .then(({ data }) => {
        this.validIdData = data.data;
        this.validateIdLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.validateIdLoading = false;
        return response;
      });
  },

  async fetchTerms(body) {
    this.termsLoading = true;
    return axiosInstance
      .post(`/assessments/consent/fetch-terms`, body)
      .then(({ data }) => {
        this.termsData = data.data;
        this.termsLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.termsLoading = false;
        return response;
      });
  },

  async createConsent(body) {
    this.createConsentLoading = true;
    return axiosInstance
      .post(`/assessments/consent/submit`, body)
      .then(({ data }) => {
        this.consentData = data.data;
        this.createConsentLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.createConsentLoading = false;
        return response;
      });
  },
});

export default consent;
