import React from "react";
import { styled } from "goober";
import Spinner from "components/common/Spinner";
import ArrowDown from "assets/images/icons/arrow-down-2.svg";

const Tab = ({
  tabs,
  activeTabLabel,
  handleTabClick,
  isactiveclass,
  className,
  tabclass,
  tabLoading,
  subtabs,
  disabled,
  disabledclass,
}) => {
  return (
    <Container className={className}>
      <div className="tabs">
        {tabLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : (
          tabs?.map((tab, idx) => (
            <React.Fragment key={`${tab?.urlParam}_${idx}`}>
              {!disabled ? (
                <>
                  <TabItem
                    onClick={() => {
                      handleTabClick(tab?.urlParam.toLowerCase());
                    }}
                    className={`${tabclass} ${
                      activeTabLabel &&
                      tab?.urlParam.toLowerCase() ===
                        activeTabLabel.toLowerCase()
                        ? isactiveclass
                        : ""
                    }`}
                  >
                    {tab?.label}{" "}
                    {activeTabLabel === tab?.urlParam.toLowerCase() &&
                      subtabs && <img src={ArrowDown} alt="arrow-down" />}
                  </TabItem>
                  {activeTabLabel === tab?.urlParam.toLowerCase() && subtabs}
                </>
              ) : (
                <TabItemDisabled className={disabledclass}>
                  {tab?.label}
                </TabItemDisabled>
              )}
            </React.Fragment>
          ))
        )}
      </div>
    </Container>
  );
};

export default Tab;

const Container = styled("section")`
  width: 100%;
  text-align: center;
  .tabs {
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
  &.webhook-tab {
    padding: 0.46875rem 0.325rem;
    background: var(--white-alt);
    border-radius: var(--br);
  }
  &.horizontal-tab {
    .tabs {
      display: flex;
      flex-direction: column;
    }
  }
`;

const TabItem = styled("div")`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const TabItemDisabled = styled("div")`
  cursor: not-allowed;
`;
