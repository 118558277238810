import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { Toaster, toast } from "sonner";
import store from "store";
import CustomModal from "components/modals/CustomModal";
import { DatapointsTableList } from "components/modals/modalStyles";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "assets/images/icons/delete.svg";
import Toast from "components/common/ToastNotification";
import Table from "components/table";
import { FlexRowBetween } from "assets/styles/common-styles";
import DeleteDatapointModal from "./DeleteDatapointModal";

const DatapointsList = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(store.settings);
  const webhookDatapoints = snap?.webhookDatapoints;
  const webhookDatapointsLoading = snap?.webhookDatapointsLoading;
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedReportTypeId, setSelectedReportTypeId] = useState(null);

  useEffect(() => {
    store?.settings.getWebhookDatapoints();
  }, []);

  const columns = [
    {
      Header: "Report type",
      accessor: "report_type",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Set Data",
      accessor: "datapoint_count",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  const deleteDatapointModal = async (id) => {
    setSelectedReportTypeId(id);
    setIsDeleteModal(true);
  };

  const groupedDatapoints =
    typeof webhookDatapoints === "object" &&
    webhookDatapoints?.reduce((acc, datapoint) => {
      const reportTypeId = datapoint?.report_type_id;
      if (!acc[reportTypeId]) {
        acc[reportTypeId] = [];
      }
      acc[reportTypeId].push(datapoint);
      return acc;
    }, {});

  // Creating a new array with grouped datapoints and total count
  const data = Object.keys(groupedDatapoints)?.map((reportTypeId) => {
    const datapoints = groupedDatapoints[reportTypeId];
    const totalCount = datapoints.length;
    const reportType = datapoints[0].report.name;
    const country = datapoints[0].country_code;

    const editPage = () => {
      const datapointsJSON = JSON.stringify(datapoints); // Serialize to JSON
      navigate("/select-datapoint", { state: { datapoints: datapointsJSON } });
    };

    return {
      report_type: reportType,
      country: country,
      datapoint_count: totalCount,
      actions: (
        <div className={FlexRowBetween}>
          <span className="cursor-pointer" onClick={() => editPage()}>
            <Edit />
          </span>
          <span
            className="cursor-pointer"
            onClick={() => deleteDatapointModal(reportTypeId)}
          >
            <Delete />
          </span>
        </div>
      ),
    };
  });

  const deleteDatapoint = async (id) => {
    try {
      const response = await store.settings.deleteWebhookDatapoint(id);
      if (response && response.message === "Datapoint deleted") {
        setTimeout(async () => {
          setIsDeleteModal(false);
          await store.settings.getWebhookDatapoints();
          toast.custom((t) => (
            <Toast toastType="success" text={response?.message} />
          ));
        }, 2000);
      } else if (response.errors || response.data.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className={DatapointsTableList}
    >
      <h3 className="heading-h2-type-2 headings">All set data points</h3>
      <Table
        columns={columns}
        data={data}
        showSearch={false}
        className="datapoints-table"
        isLoading={webhookDatapointsLoading}
      />
      {isDeleteModal && (
        <DeleteDatapointModal
          isOpen={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          isLoading={isLoading}
          deleteDatapoint={deleteDatapoint}
          selectedReportTypeId={selectedReportTypeId}
        />
      )}
      <Toaster position="top-center" visibleToasts={3} />
    </CustomModal>
  );
};

export default DatapointsList;
