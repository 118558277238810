import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { format } from "date-fns";
import { Pagination } from "antd";
import store from "store";
import {
  Card,
  MostRecent,
  MostRecentRequestHeader,
} from "assets/styles/customer-report-styles";
import { ReactComponent as Emptylist } from "assets/images/icons/list-empty.svg";
import { Flex, FlexCol, FlexColumnCenter } from "assets/styles/common-styles";
import Table from "components/table";
import { capitalizeFirstLetter } from "utils/common";
import Spinner from "components/common/Spinner";
import BackButton from "components/common/BackButton";
import { Link } from "react-router-dom";
import { getLocalStorageProperties } from "utils/user";

const RequestedReports = ({
  planDetails,
  onRowClick,
  fullPage,
  showSummaryPage,
}) => {
  const snap = useSnapshot(store.customerReport);
  const reportRequestsWithPagination = snap?.reportRequestsWithPagination;
  const reportRequestsWithPaginationLoading =
    snap?.reportRequestsWithPaginationLoading;
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;
  const [pagination, setPagination] = useState({
    perPage: 20,
    page: 1,
  });

  useEffect(() => {
    if (companyId) {
      if (fullPage) {
        store.customerReport.getReportRequestsWithPagination({ ...pagination });
      } else {
        store.customerReport.getReportRequestsWithPagination({
          perPage: 5,
          page: 1,
        });
      }
    }
  }, [pagination, companyId, fullPage]);

  const handleRowClick = (ref) => {
    onRowClick(ref?.reference);
  };

  const columns = [
    {
      Header: "FULL NAME",
      accessor: (row) =>
        `${capitalizeFirstLetter(
          row?.first_name + " " + row?.middle_name + " " + row?.last_name
        )}`,
    },
    {
      Header: "COUNTRY",
      accessor: (row) => row?.country.name,
    },
    {
      Header: "CONSENT",
      accessor: (row) =>
        row?.consent !== null ? (
          row?.consent?.granted_consent === true ? (
            <span className="status">
              <span className="status-ball active"></span>
              Consented
            </span>
          ) : (
            <span className="status">
              <span className="status-ball error"></span>
              Declined
            </span>
          )
        ) : (
          <span className="status">
            <span className="status-ball pending"></span>
            Pending
          </span>
        ),
    },
    {
      Header: "DATE",
      accessor: (row) =>
        format(new Date(row?.created_at), "MMM dd yyyy, hh:mm aa"),
    },
    {
      Header: "REPORT",
      accessor: (row) =>
        row?.status && (
          <span className="status">
            <span
              className={`status-ball ${
                row.consent === null ||
                row.status === "pending" ||
                row.status === "processing" ||
                row.status === "consented"
                  ? "pending"
                  : row.status === "completed"
                  ? "active"
                  : "error"
              }`}
            ></span>
            {row.consent === null ||
            row.status === "pending" ||
            row.status === "consented"
              ? "Pending"
              : row.status === "processing"
              ? "Processing"
              : row.status === "completed"
              ? "Completed"
              : "Failed"}
          </span>
        ),
    },
    {
      Header: "Action",
      accessor: "",
      Cell: () => (
        <span className="view-btn" onClick={() => handleRowClick()}>
          View
        </span>
      ),
    },
  ];

  return (
    <div className={MostRecent}>
      {fullPage ? (
        <section className={`${FlexCol} gap-5 mb-9`}>
          <BackButton onClick={showSummaryPage} />
          <div className={`${Flex} gap-1`}>
            <span className="text-md-semibold text-dark">Consumer check</span>
            <span className="text-md-semibold">/</span>
            <span className="text-md-medium">All consumer reports</span>
          </div>
        </section>
      ) : (
        <div className={MostRecentRequestHeader}>
          <p className="text-base-semibold card-header headings">
            Consumer reports
          </p>
          <Link
            className="text-base-semibold accent-2 cursor-pointer"
            to="/all-consumer-reports"
          >
            See all
          </Link>
        </div>
      )}
      {!fullPage &&
        (reportRequestsWithPaginationLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : reportRequestsWithPagination?.data?.length > 0 ? (
          <Table
            columns={columns}
            data={reportRequestsWithPagination?.data}
            showSearch={false}
            className="customer-request-table"
            onRowClick={handleRowClick}
          />
        ) : (
          <Card>
            <div className={`${FlexColumnCenter} empty-state`}>
              <Emptylist />
              <p className="text-base">
                It's quiet here! Keep yourself informed on the status of your
                data requests.
              </p>
              <span
                className={`text-md-spaced ${planDetails ? "" : "disabled"}`}
              >
                REQUEST REPORT
              </span>
            </div>
          </Card>
        ))}
      {fullPage &&
        (reportRequestsWithPaginationLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : reportRequestsWithPagination?.data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={reportRequestsWithPagination?.data}
              showSearch={false}
              className="customer-request-table"
              onRowClick={handleRowClick}
            />
            {reportRequestsWithPagination?.data?.length > 0 ? (
              <>
                <div className="mt-6" />
                <Pagination
                  current={pagination.page}
                  onChange={(page) => setPagination({ ...pagination, page })}
                  total={
                    reportRequestsWithPagination?.pagination?.total_pages *
                    pagination.perPage
                  }
                  pageSize={pagination.perPage}
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <Card>
            <div className={`${FlexColumnCenter} empty-state`}>
              <Emptylist />
              <p className="text-base">
                It's quiet here! Keep yourself informed on the status of your
                data requests.
              </p>
              <span
                className={`text-md-spaced ${planDetails ? "" : "disabled"}`}
              >
                REQUEST REPORT
              </span>
            </div>
          </Card>
        ))}
    </div>
  );
};

export default RequestedReports;
