import React from 'react';
import PropTypes from 'prop-types';
import {
  Check,
  CheckboxCustom,
  CheckboxInput,
  CheckboxWrapper,
  CheckmarkIcon,
} from 'components/formFields/formStyles';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';

const Checkbox = ({
  label,
  labelcolor,
  gap,
  onChange,
  className,
  labelclass,
  checked = false,
  disabled,
  muted,
  ...props
}) => {
  const customStyles = {
    '--label-color': labelcolor || '',
    '--gap': gap || '',
  };

  return (
    <CheckboxWrapper
      style={customStyles}
      className={className}
    >
      <Check
        type='checkbox'
        className={CheckboxInput}
        checked={checked}
        onChange={!muted ? onChange : undefined}
        disabled={disabled}
        {...props}
      />
      <span className={`${CheckboxCustom} ${muted ? "muted" : checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
        <span className={`${CheckmarkIcon} ${muted ? "muted" : checked ? 'checked' : ''}`}>
          <CheckIcon stroke='var(--white)' />
        </span>
      </span>
      <span className={labelclass}>{label}</span>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  label: PropTypes.any,
  labelcolor: PropTypes.string,
  checked: PropTypes.bool,
};

export default Checkbox;
