import React from "react";
import Modal from "components/modals";
import { MediumButton } from "components/button";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import {
  FlexCol,
  FlexColumnCenter,
  ShadowCircle,
} from "assets/styles/common-styles";
import { ReactComponent as BankIcon } from "assets/images/icons/bank.svg";
import { capitalizeFirstLetter } from "utils/common";

const ResultNotificationModal = ({
  isOpen,
  onClose,
  closeDrawer,
  companyData,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBodyWrapper
        className={`${FlexColumnCenter} text-center relative gap-[2.375rem]`}
      >
        <ShadowCircle className="w-[7.5rem] h-[7.5em] mt-[3rem] mx-auto absolute top-[-32%]">
          <BankIcon fill="#05A7E3" width="54px" height="54px" />
        </ShadowCircle>
        <div className={`${FlexCol} gap-[1.625rem]`}>
          <p className="text-base">
            RC{" "}
            <span className="text-dark text-base-medium">
              {companyData?.company_number}
            </span>
          </p>
          <h2 className="modal-title">{companyData?.company_name}</h2>
          <p className="text-base text-neutral">
            Company status{" "}
            <span className="text-base-medium text-dark">
              {capitalizeFirstLetter(companyData?.company_status)}
            </span>
          </p>
        </div>
        <p className="text-base">
          A report on{" "}
          <span className="text-base-medium dark">{companyData?.company_name}</span> has
          been requested. Result typically takes 1 - 24hrs.
        </p>
        <MediumButton
          bg="var(--accent-1)"
          textcolor="var(--primary)"
          onClick={() => {
            onClose();
            closeDrawer();
          }}
        >
          Okay, got it
        </MediumButton>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default ResultNotificationModal;
