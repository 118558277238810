import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Toaster, toast } from 'sonner';
import store from 'store';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { Form244H, InputsWrapper } from 'components/formFields/formStyles';
import { ModalBodyWrapper, } from 'components/modals/modalStyles';
import { FlexColumnBetween, FlexEndCenter, SpaceBetweenW100 } from 'assets/styles/common-styles';
import FloatingTextField from 'components/formFields/FloatingTextField';
import FloatingSelectField from 'components/formFields/FloatingSelectField';
import { validateFormData } from 'utils/validateFormData';
import Toast from 'components/common/ToastNotification';
import Spinner from 'components/common/Spinner';

const LaunchNotificationModal = ({ isOpen, onClose, setShowSubmissionModal }) => {
  const snap = useSnapshot(store.lookups);
  const countries = snap?.countries;
  const countriesLoading = snap?.countriesLoading;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    country: "Select a country",
    first_name: "",
    last_name: "",
    business_email: ""
  });

  useEffect(() => {
    store.lookups.getCountries();
  }, []);

  const handleChange = (name, e) => {
    setSelectedValue({...selectedValue, [name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data =  {
      country: selectedValue.country,
      first_name: selectedValue.first_name,
      last_name: selectedValue.last_name,
      business_email: selectedValue.business_email
    }

    const validationRules = {
      company: [{ required: true }],
      first_name: [{ required: true }],
      last_name: [{ required: true }],
      business_email: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }
    try {
      const response = await store.reminders.postReminder(data);
      if (response && response?.message === "Thank you for letting us know your business region. We will be in touch.") {
        setShowSubmissionModal(true);
        onClose();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBodyWrapper className="director-info">
        <div className="modal-header">
          <h2 className="modal-title">Be the first to know when we launch in your country</h2>
        </div>
        <form className={FlexColumnBetween} onSubmit={handleSubmit}>
          <Form244H>
            <FloatingSelectField
              id="country"
              name="country"
              options={countries
                .filter(country => country.active_market === false)
                .map(country => ({
                  value: country.name,
                  key: country.name + country.alpha_2_code
                }))
              }
              label="Business country"
              value={selectedValue.country}
              onChange={(e) => handleChange("country", e)}
              isLoading={countriesLoading}
              fullwidth="true"
              search
            />
            <InputsWrapper className={SpaceBetweenW100}>
              <FloatingTextField
                label="First name"
                type="text"
                name="first_name"
                id="first_name"
                labelfor="first_name"
                value={selectedValue.first_name}
                onChange={(e) => handleChange("first_name", e)}
              />
              <FloatingTextField
                label="Last name"
                type="text"
                name="last_name"
                id="last_name"
                labelfor="last_name"
                value={selectedValue.last_name}
                onChange={(e) => handleChange("last_name", e)}
              />
            </InputsWrapper>
            <FloatingTextField
              label="Business email"
              type="email"
              name="business_email"
              id="business_email"
              labelfor="business_email"
              value={selectedValue.business_email}
              onChange={(e) => handleChange("business_email", e)}
            />
          </Form244H>
          <div className={FlexEndCenter}>
            {isLoading ? (
              <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }}/>
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                type={selectedValue?.business_email === "" ? "button" : "submit"}
                disabled={selectedValue?.business_email === ""}
              >
                Submit
              </MediumButton>
            )}
          </div>
        </form>
      </ModalBodyWrapper>
      <Toaster position="top-center" visibleToasts={5} />
    </Modal>
  );
};

export default LaunchNotificationModal;
