import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const ShowAnAssessment = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          404 Error
        </div>
      ),
      key: "404",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      queryData: [
        {
          key: "with[]",
          value: "country",
          description: "-",
        },
        {
          key: "with[]",
          value: "consent",
          description: "-",
        },
        {
          key: "with[]",
          value: "report",
          description: "-",
        },
      ],
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Assessment returned",
        "data": {
          "id": 6,
          "user_country_code": "ng",
          "request_source": "api",
          "reference": "B0EgoXmd3ujcs_Q",
          "first_name": "ola",
          "last_name": "jide",
          "middle_name": "samuel",
          "decision_criteria_id": null,
          "email": "samuel+migrant@trymaxim.com",
          "phone_number": "2347065514172",
          "status": "pending",
          "id_type": "international_passport",
          "id_number": "APP23242323",
          "idcard": {
            "id": 2,
            "country_code": "ng",
            "name": "International Passport",
            "key": "international_passport",
            "status": true,
            "created_at": "2024-01-09T09:57:07.000000Z",
            "updated_at": "2024-01-09T09:57:07.000000Z"
          },
          "country": {
            "alpha_2_code": "ng",
            "name": "Nigeria",
            "calling_code": "234",
            "flag": "https://www.worldometers.info/img/flags/small/tn_ni-flag.gif"
          },
          "consent": null
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This
          indicates that the request to retrieve all assessments has been
          successful.
        </>
      ),
    },
    404: {
      queryData: [
        {
          key: "with[]",
          value: "country",
          description: "-",
        },
        {
          key: "with[]",
          value: "consent",
          description: "-",
        },
        {
          key: "with[]",
          value: "report",
          description: "-",
        },
      ],
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Assessment not found",
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This indicates that the requested assessment was not found.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Show an assessment"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/assessments/aNubIPODFHW4W2K?with[]=country&with[]=consent&with[]=report"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default ShowAnAssessment;
