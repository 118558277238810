import React, { useCallback, useEffect, useState } from "react";
import { SmallButton } from "components/button";
import Mastercard from "assets/images/mastercard.svg";
import { useSnapshot } from "valtio";
import store from "store";
import UpdateBillingInfoModal from "./UpdateBillingInfoModal";
import Spinner from "components/common/Spinner";
import AdditionalEmailsModal from "./AdditionalEmailsModal";

const BillingInfo = () => {
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const subscriptionData = subscriptionSnapshot?.subscriptionData;
  const updateCardLoading = subscriptionSnapshot?.updateCardLoading;
  const [billing, setBilling] = useState();
  const [openBillingInfoModal, setOpenBillingInfoModal] = useState(false);
  const [responseData, setResponseData] = useState({
    reference: "",
    amount: "",
    description: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const [openAddEmailsModal, setOpenAddEmailsModal] = useState(false);

  useEffect(() => {
    setBilling(subscriptionData?.billing_info);
  }, [subscriptionData]);

  const makePayment = useCallback(() => {
    // eslint-disable-next-line no-undef
    const modal = FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLUTTERWAVE_PUB_KEY,
      tx_ref: responseData.reference,
      amount: responseData.amount,
      currency: subscriptionData?.subscription_details?.plan?.price[0]?.currency,
      payment_options: "card",
      customer: {
        email: user?.email,
      },
      customizations: {
        description: responseData.description,
      },
      callback: function (response) {
        modal.close();
        setResponseData({
          reference: "",
          amount: "",
          description: "",
        });
        store?.subscription.getSubscription();
      },
    });
  }, [responseData, subscriptionData?.subscription_details?.plan?.price, user?.email]);

  useEffect(() => {
    if (
      responseData.amount.length > 0 &&
      responseData.description.length > 0 &&
      responseData.reference.length > 0
    ) {
      makePayment();
    }
  }, [responseData, makePayment]);

  const updateCardFn = async () => {
    const response = await store?.subscription.updateCard();

    if (response) {
      setResponseData({
        amount: response?.amount,
        reference: response?.reference,
        description: response?.description,
      });
    }
  };

  return (
    <div className="mt-[4.375rem]">
      <div className="flex items-center justify-between mb-9">
        <p className="text-dark text-base font-semibold">Billing information</p>
        <p className="text-neutral text-sm font-medium">
          Click here to{" "}
          <span
            className="text-accent-2 font-semibold cursor-pointer"
            onClick={() => setOpenAddEmailsModal(true)}
          >
            manage additional emails
          </span>{" "}
          for receipt
        </p>
      </div>

      <AdditionalEmailsModal
        isOpen={openAddEmailsModal}
        onClose={() => setOpenAddEmailsModal(false)}
      />

      <div className="flex space-x-10">
        <div className="px-6 py-7 border-stroke border-[0.5px] w-1/2 rounded-xl">
          <div className="flex items-center justify-between mb-6">
            <p className="text-dark font-semibold text-base">Billing address</p>
            <SmallButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              onClick={() => setOpenBillingInfoModal(true)}
            >
              Update
            </SmallButton>
          </div>

          <UpdateBillingInfoModal
            isOpen={openBillingInfoModal}
            onClose={() => setOpenBillingInfoModal(false)}
          />

          <div className="mb-5">
            <p className="text-sm text-neutral">Email</p>
            <p className="text-black text-sm font-medium">
              {billing?.address?.email || "Not Available"}
            </p>
          </div>

          <div>
            <p className="text-sm text-neutral">Address</p>
            <p className="text-black text-sm font-medium w-[70%]">
              {billing?.address?.street_address?.length
                ? `${billing?.address?.street_address.trim()}, 
              ${billing?.address?.state}`
                : "Not Available"}
            </p>
          </div>
        </div>

        <div className="px-6 py-7 border-stroke border-[0.5px] w-1/2 rounded-xl flex flex-col justify-between">
          <div className="flex items-center justify-between mb-5">
            <p className="text-dark font-semibold text-base">Payment details</p>
            {updateCardLoading ? (
              <div className="flex justify-end">
                <Spinner className="my-[0.5rem]" />
              </div>
            ) : (
              <SmallButton
                bg="var(--white-alt)"
                border="var(--white-alt)"
                textcolor="var(--primary)"
                hovercolor="var(--white-alt)"
                onClick={updateCardFn}
                disabled={!subscriptionData?.billing_info?.card}
              >
                Update
              </SmallButton>
            )}
          </div>

          <div className="flex flex-col justify-between">
            <div className="mb-8">
              <p className="text-sm text-neutral">Card number</p>
              <p className="text-dark text-sm font-medium">
                {!billing?.card ? (
                  <span className="text-dark">Not Available</span>
                ) : (
                  `Mastercard ending in **** ${billing?.card?.last4}`
                )}
              </p>
            </div>

            {billing?.card ? (
              <img
                src={Mastercard}
                alt="mastercard"
                draggable={false}
                className="w-[45px] h-[28px]"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInfo;
