import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const ShowACommercialCheck = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          404 Error
        </div>
      ),
      key: "404",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Report fetched successfully",
        "data": {
          "reference": "WWMbvlTg1TBsKBGNb6Tc",
          "company_name": "QUICKTEK DIGITALs INNOVATIONS",
          "company_number": "2739770",
          "company_status": "inactive",
          "status": "completed",
          "created_at": "2024-01-24T09:26:39.000000Z",
          "address": "23, ABBA STREET OMAGBA PHASE II, ONITSHA, ANAMBRA",
          "result": {
            "business_data": {
              "industry_sector": "",
              "no_of_directors": "0",
              "business_type": "",
              "date_of_incorporation": "30 Jul, 2018",
              "date_of_commencement": null,
              "tax_identification_number": "",
              "web_address": null,
              "commercial_email": null,
              "postal_address": "UBA HOUSE 57 MARINAADD",
              "telephone": null
            },
            "highest_delinquency_rating": {
              "rating": "10"
            },
            "facility_performance": {
              "monthly_instalment": "NGN0.00",
              "total_outstanding_debt": "NGN3,557,328.00",
              "total_account_arrear": "NGN1.00",
              "total_accounts": "1",
              "amount_arrear": "NGN3,557,328.00",
              "total_account_in_good_condition": "0",
              "total_account_in_bad_condition": "1",
              "total_number_of_judgement": "0",
              "total_judgement_amount": "NGN0",
              "total_number_of_dishonoured": "0",
              "total_dishonoured_amount": "NGN0.00"
            },
            "directors_information": [
              {
                "director_id": null,
                "date_of_birth": null,
                "first_name": null,
                "other_names": null,
                "surname": null,
                "identification": null,
                "director_appointment_date": null
              }
            ],
            "credit_agreement": [
              {
                "date_account_opened": "01 Jan, 2000",
                "subscriber_name": "United Bank for Africa Lagos",
                "indicator_description": "COMMERCIAL OVERDRAFT",
                "opening_balance_amount": "NGN0.00",
                "current_balance_amount": "NGN3,557,327.64",
                "instalment_amount": "NGN0.00",
                "amount_overdue": "NGN3,557,327.64",
                "closed_date": null,
                "last_updated": "08 Feb, 2023",
                "performance_status": "LOST",
                "account_status": "OPEN"
              }
            ]
          }
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to get the details of the commercial report was successful.
        </>
      ),
    },
    404: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Report not found",
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This indicates that the request to retrieve the detail of the commercial report was not found.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Commercial Report"
      pageDescription="Use this endpoint to check the CAC status and credit details of a registered Nigerian company or business organization."
      subtitle="Details"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/commercial-reports/WWMbvlTg1TBsKBGNb6Tc"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default ShowACommercialCheck;
