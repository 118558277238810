import CustomModal from "components/modals/CustomModal";
import {
  CommonDrawerStyle,
  PaymentHistoryModal,
} from "components/modals/modalStyles";
import Table from "components/table";

const PaymentHistory = ({ isOpen, onClose, subscriber, details }) => {
  const columns = [
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "AMOUNT PAID",
      accessor: "amount_paid",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
  ];

  const data = details?.map((detail) => {
    return {
      date: detail.date,
      amount_paid: detail.amount_paid || "Null",
      status: (
        <div className="status capitalize">
          <div
            className={`status-ball ${
              detail?.status === "Good"
                ? "active"
                : detail?.status === "N/A"
                ? ""
                : "error"
            }`}
          ></div>
          {detail?.status}
        </div>
      ),
    };
  });
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className={`${CommonDrawerStyle} ${PaymentHistoryModal}`}
    >
      <h3 className="heading-h2-type-2 headings mb-10">
        Monthly payment history
      </h3>
      <div className="mb-10">
        <p>Subscriber name</p>
        <p className="text-base-medium darker">{subscriber}</p>
      </div>
      <div className="px-1.5 flex flex-col gap-6">
        <Table
          columns={columns}
          data={data}
          showSearch={false}
          className="payment-history-table"
        />
      </div>
    </CustomModal>
  );
};

export default PaymentHistory;
