import { mobile, mobileSmall, slideInFromBottom } from "globalStyles";
import { styled } from "goober";

export const OnboardingComplianceWrapper = styled("section")`
  width: 100%;
  max-width: 26rem;
  border-radius: var(--br);
  padding: 2rem 1.9375rem 1.25rem 1.8125rem;
  background: var(--primary);
  position: fixed;
  bottom: 1.5625rem;
  right: 1.5rem;
  animation: ${slideInFromBottom} 0.5s ease-in-out;
  ${mobile} {
    position: sticky;
  }
  ${mobileSmall} {
    right: 0;
  }
  .title {
    color: var(--white);
    margin-top: 1.0625rem;
    margin-bottom: 0.625rem;
  }
  .subtitle {
    color: var(--white-alt);
    margin-bottom: 0.8125rem;
  }
  .progress-bar-wrapper {
    display: flex;
    align-items: center;
    gap: 23px;
  }
  .progress-bar {
    height: 0.375rem;
    width: 100%;
    border-radius: 0.25rem;
    background: rgba(94, 139, 67, 0.20);
    position: relative;
    
    .progress {
      background: var(--accent-2);
      border-radius: 0.25rem 0 0 0.25rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--progress-width, 20%);
    }
    span {
      color: var(--white);
    }
  }
  .add-teammates {
    gap: 0.5rem;
  }
  .skip {
    cursor: pointer;
  }
`;

export const OnboardingWrapper = styled("main")`
  padding: 1.8125rem 2.4375rem 1.8125rem 2.9375rem;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.875rem;
    h2 {
      color: var(--headings);
    }
    .close {
      gap: 0.5rem;
    }
  }
  ${mobileSmall} {
    .header {
      .close {
        p {
          display: none;
        }
      }
    }
  }
`;