import { Flex } from 'assets/styles/common-styles';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SubLayout = ({ element, pages }) => {
  const { pathname } = useLocation();

  return (
    <div className='bg-white py-6 rounded-lg min-h-[80%] border border-stroke w-full'>
      {pages && (
        <div className={`${Flex} border-b border-stroke gap-[5.625rem] px-11`}>
          {pages.map((page) => (
            <Link
              to={page.to}
              key={page.to}
            >
              <p
                className={`${
                  pathname === page.to
                    ? 'text-accent-2 text-md-semibold'
                    : 'text-md-medium'
                } cursor-pointer px-3 pb-3.5`}
              >
                {page.label}
              </p>

              <div
                className={`${
                  pathname === page.to ? 'bg-accent-2' : ''
                } w-full h-[3px] rounded-tr-[5px] rounded-tl-[5px]`}
              />
            </Link>
          ))}
        </div>
      )}

      <div>{element}</div>
    </div>
  );
};

export default SubLayout;
