import { Link } from 'react-router-dom';
import ProfileIcon from 'assets/images/icons/profile.svg';
import UserManagementIcon from 'assets/images/icons/user-management.svg';
import SecurityIcon from 'assets/images/icons/security.svg';
import AuditIcon from 'assets/images/icons/audit.svg';
import PreferenceIcon from 'assets/images/icons/preference.svg';

const settings = [
  {
    title: 'Profile settings',
    to: '/settings',
    desc: 'Update profile pic, email and contact details for dispute purposes',
    icon: ProfileIcon,
  },
  {
    title: 'User management',
    to: '/settings',
    desc: 'Manage your team and invite teammates to collaborate',
    icon: UserManagementIcon,
  },
  {
    title: 'Security',
    to: '/settings',
    desc: 'Secure your account with password and two-factor authentication',
    icon: SecurityIcon,
  },
  {
    title: 'Audit log',
    to: '/settings',
    desc: 'Be in the know, follow actions and user activities with a timestamp',
    icon: AuditIcon,
  },
  {
    title: 'Preferences',
    to: '/settings',
    desc: 'Define or customize your notifications and preferences',
    icon: PreferenceIcon,
  },
];

const SettingsSection = () => {
  return (
    <div className=''>
      <div className='grid grid-cols-3 gap-8'>
        {settings.map((setting, idx) => (
          <Link
            to={setting.to}
            key={idx}
            className='flex bg-white w-full h-[8rem] py-5 px-[18px] rounded-xl border-[0.5px] border-stroke gap-[10px] '
          >
            <div className='h-[28px] w-[28px] p-1 bg-alt rounded-sm flex items-center justify-center'>
              <img
                src={setting.icon}
                alt={setting.to}
              />
            </div>
            <div>
              <p className='mb-4 text-headings font-medium'>{setting.title}</p>
              <p className='text-neutral text-sm w-[80%]'>{setting.desc}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SettingsSection;
