import { MediumButton } from 'components/button';
import Spinner from 'components/common/Spinner';
import Toast from 'components/common/ToastNotification';
import FloatingTextField from 'components/formFields/FloatingTextField';
import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import React, { useEffect, useState } from 'react';
import store from 'store';
import { useSnapshot } from 'valtio';
import { toast } from 'sonner';

const UpdateBillingInfoModal = ({ isOpen, onClose }) => {
  const [billingInfo, setBillingInfo] = useState({
    email: '',
    street_address: '',
    state: '',
  });
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const isLoading = subscriptionSnapshot?.updateBillingInfoLoading;
  const subscriptionData = subscriptionSnapshot?.subscriptionData;

  useEffect(() => {
    if (subscriptionData) {
      setBillingInfo({
        email: subscriptionData?.billing_info?.address?.email,
        state: subscriptionData?.billing_info?.address?.state,
        street_address: subscriptionData?.billing_info?.address?.street_address,
      });
    }
  }, [subscriptionData]);

  const updateBillingInfoFn = async () => {
    const body = {
      ...billingInfo,
    };

    const response = await store?.subscription.updateBillingInfo(body);

    if (response?.errors) {
      const errorKeys = Object.keys(response?.errors);
      errorKeys?.forEach((key) => {
        (response?.errors?.[key]).forEach((errorMessage) => {
          toast.custom((t) => (
            <Toast
              key={`${key}-${errorMessage}`}
              toastType='error'
              text={errorMessage}
            />
          ));
        });
      });
    } else {
      await store?.subscription.getSubscription();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        <div className='flex flex-col items-center h-[27rem] w-full'>
          <p className='text-headings font-secondary text-[1.75rem] font-semibold mb-11'>
            Billing address
          </p>

          <div className='flex flex-col w-full space-y-8'>
            <FloatingTextField
              label='Email address'
              type='email'
              id='email-address'
              name='email-address'
              labelfor='email-address'
              value={billingInfo.email}
              onChange={(e) =>
                setBillingInfo({ ...billingInfo, email: e.target.value })
              }
            />

            <FloatingTextField
              label='Street address'
              type='text'
              id='street-address'
              name='street-address'
              labelfor='street-address'
              value={billingInfo.street_address}
              onChange={(e) =>
                setBillingInfo({
                  ...billingInfo,
                  street_address: e.target.value,
                })
              }
            />

            <FloatingTextField
              label='State'
              type='text'
              id='state'
              name='state'
              labelfor='state'
              value={billingInfo.state}
              onChange={(e) =>
                setBillingInfo({ ...billingInfo, state: e.target.value })
              }
            />
          </div>

          <div className='flex items-center justify-between w-full mt-12'>
            <MediumButton
              bg='var(--white-alt)'
              border='var(--white-alt)'
              textcolor='var(--primary)'
              hovercolor='var(--white-alt)'
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {isLoading ? (
              <Spinner style={{ margin: '0.5rem 0' }} />
            ) : (
              <MediumButton
                bg='var(--accent-1)'
                border='var(--accent-1)'
                textcolor='var(--primary)'
                hovercolor='var(--accent-1)'
                onClick={updateBillingInfoFn}
              >
                Save changes
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default UpdateBillingInfoModal;
