import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { BackBtn } from "assets/styles/common-styles";

const BackButton = ({ disabled, onClick }) => {

  const handleClick = (event) => {
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return (
    <BackBtn onClick={handleClick} className={`w-fit ${disabled ? "disabled" : ""}`}>
      <ArrowLeft /> 
      <p className="text-base-semibold accent-2">Back</p>
    </BackBtn>
  );
};

export default BackButton;