import { FlexColumnBetween, FlexRowBetween } from "assets/styles/common-styles";
import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";

const DeleteDatapointModal = ({ isOpen, onClose, isLoading, deleteDatapoint, selectedReportTypeId }) => {
  return (
    <Modal isOpen={isOpen} className="auto-height">
      <ModalBodyWrapper className={FlexColumnBetween}>
        <div className="modal-header">
          <h2 className="modal-title">Delete data point</h2>
        </div>
        <p className="text-base text-center">
          Are you sure you want to delete this particular data point? This
          action cannot be undone.
        </p>
        <div className={`${FlexRowBetween} button-group`}>
          <MediumButton
            bg="var(--white-alt)"
            textcolor="var(--text-dark)"
            type="button"
            onClick={onClose}
          >
            No, Cancel
          </MediumButton>
          {isLoading ? (
            <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
          ) : (
            <MediumButton
              bg="var(--complimentary-2)"
              border="var(--complimentary-2)"
              textcolor="var(--text-coloured)"
              type="button"
              onClick={() => deleteDatapoint(selectedReportTypeId)}
            >
              Yes, Delete
            </MediumButton>
          )}
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default DeleteDatapointModal;
