import { proxy } from "valtio";
import axiosInstance from "axiosInstance";

const auth = proxy({
  token: null,
  processToken: false,
  countryCode: null,
  processCountryCode: false,
  refreshToken: {},
  refreshTokenLoading: null,
  expiresAt: null,

  async storeToken(token) {
    if (token) {
      this.token = token;
      localStorage.setItem("token", token);
    }
  },
  async storeCountryCode(code) {
    if (code) {
      this.countryCode = code;
      localStorage.setItem("country-code", code);
    }
  },
  async login(data) {
    return axiosInstance
      .post(`/auth/login`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async logout() {
    return axiosInstance
      .post(`/auth/logout`)
      .then(({ data }) => {
        localStorage.clear();
        window.location.href = `/auth/login`;
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async getNewToken() {
    this.refreshTokenLoading = true;
    return axiosInstance
      .get("/auth/refresh-token")
      .then(({ data }) => {
        this.refreshToken = data.token;
        this.storeToken(this.refreshToken); // Update the token in the store
        localStorage.setItem("token", this.refreshToken);
        this.refreshTokenLoading = false;
        return this.refreshToken;
      })
      .catch(({ response }) => {
        this.refreshTokenLoading = false;
        return response;
      });
  },
  async postRegistration(data) {
    return axiosInstance
      .post(`/registrations`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postEmailVerification(data) {
    return axiosInstance
      .post(`/registrations/verifications/email`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postResendEmailVerification(data) {
    return axiosInstance
      .post(`/resend-email-verification`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postRequestPasswordReset(data) {
    return axiosInstance
      .post(`/auth/forgot-password`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postResetPassword(data) {
    return axiosInstance
      .post(`/auth/reset-password`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
});

export default auth;
