export const parseUserInfo = (localStorageDataKey) => {
  try {
    const localStorageData = localStorage.getItem(localStorageDataKey);
    if (!localStorageData) {
      return;
    }

    const jsonData = JSON.parse(localStorageData);
    // Extract the desired user information
    const { id, company_id: companyId, country_code: countryCode } = jsonData;

    return {
      id,
      companyId,
      countryCode,
    };
  } catch (error) {
    console.error('Error parsing values:', error);
    return null;
  }
};

export const getLocalStorageProperties = (localStorageData, propertyNames) => {
  try {
    if (!localStorageData || !propertyNames || !Array.isArray(propertyNames)) {
      return '';
    }

    const jsonData = JSON.parse(localStorageData);

    const result = {};
    propertyNames.forEach((prop) => {
      if (jsonData.hasOwnProperty(prop)) {
        result[prop] = jsonData[prop];
      }
    });

    return result;
  } catch (error) {
    console.error('Error getting values:', error);
    return null;
  }
};

export const updateLocalStorageProperty = (localStorageKey, propertyName, newValue) => {
  try {
    const existingData = localStorage.getItem(localStorageKey);
    if (!existingData) {
      return;
    }

    const jsonData = JSON.parse(existingData);
    jsonData[propertyName] = newValue;
    localStorage.setItem(localStorageKey, JSON.stringify(jsonData));
  } catch (error) {
    console.error('Error updating value:', error);
  }
};
