import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Toaster, toast } from "sonner";
import store from "store";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { MediumButton } from "components/button";
import FloatingTextField from "components/formFields/FloatingTextField";
import { FlexColumnBetween, FlexRowBetween } from "assets/styles/common-styles";
import FloatingSelectField from "components/formFields/FloatingSelectField";
import { validateFormData } from "utils/validateFormData";
import Toast from "components/common/ToastNotification";
import Spinner from "components/common/Spinner";
import { parseUserInfo } from "utils/user";

const VerifyCompanyModal = ({
  isOpen,
  onClose,
  handleVerifyCompanySuccess,
}) => {
  const snap = useSnapshot(store.lookups);
  const businessCategories = snap?.businessCategories;
  const businessCategoriesLoading = snap?.businessCategoriesLoading;
  const [selectedValue, setSelectedValue] = useState({
    company_id: "",
    business_category_id: "Select an industry",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const userValues = parseUserInfo("user");
  const countryCode =
    localStorage.getItem("country-code") || userValues?.countryCode;
  // const [cancelRequest, setCancelRequest] = useState(false);
  // const abortController = new AbortController();

  useEffect(() => {
    store.lookups.getBusinessCategories();
  }, []);

  const handleChange = (name, e) => {
    setSelectedValue({ ...selectedValue, [name]: e.target.value });
  };

  const handleSelectChange = (optionValue, optionKey) => {
    setCurrentValue(optionValue);
    setSelectedValue({ ...selectedValue, business_category_id: optionKey });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // setCancelRequest(false); // Reset cancelRequest flag

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data = {
      company_id: selectedValue?.company_id,
      business_category_id: selectedValue?.business_category_id?.target?.key,
    };

    const validationRules = {
      company_id: [{ required: true }],
      business_category_id: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }

    if (selectedValue?.business_category_id === "Select an industry") {
      toast.custom((t) => (
        <Toast toastType="error" text="Please select an industry" />
      ));
      setIsLoading(false);
      return;
    }
    try {
      const response = await store.lookups.getCompanyInfo(
        selectedValue?.company_id
      );
      // if (cancelRequest) {
      //   console.log('Request was canceled');
      //   return;
      // }
      if (response && response?.data?.id) {
        const newData = {
          business_category_id: data.business_category_id,
          companyInfo: response?.data,
        };
        handleVerifyCompanySuccess(newData);
        onClose();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  // const handleCancel = () => {
  //   setIsLoading(false);
  //   setCancelRequest(true); // Set cancelRequest flag when cancel is clicked
  //   abortController.abort(); // Abort the request
  //   onClose();
  // };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBodyWrapper className="verify-company">
          <section className="modal-header">
            <h2 className="modal-title">
              {countryCode === "gb"
                ? "Enter your company house number for verification"
                : "Enter your company RC number for verification"}
            </h2>
            <p className="text-base">
              {countryCode === "gb" ? (
                <>
                  Issued by <span>Companies House</span>, the agency responsible
                  for regulating, and incorporating companies in the United
                  Kingdom.
                </>
              ) : (
                <>
                  Assigned by the <span>Corporate Affairs Commission</span>, the
                  Nigerian Companies Registry responsible for regulating, and
                  incorporating companies.
                </>
              )}
            </p>
          </section>
          <form className={FlexColumnBetween} onSubmit={handleSubmit}>
            <FloatingTextField
              type="number"
              label={countryCode === "gb" ? "Company number" : "RC number"}
              name="company_id"
              id="company_id"
              value={selectedValue?.company_id}
              onChange={(e) => handleChange("company_id", e)}
              fullwidth="true"
              maxLength="7"
            />
            <FloatingSelectField
              id={currentValue}
              name="business_category_id"
              options={businessCategories?.map((categories) => ({
                value: categories.name,
                key: categories.id,
              }))}
              label="Company Industry"
              value={selectedValue?.business_category_id}
              onChange={(e) => handleSelectChange("business_category_id", e)}
              fullwidth="true"
              isLoading={businessCategoriesLoading}
              search
            />
            <div className={`${FlexRowBetween} button-group`}>
              <MediumButton
                bg="var(--white-alt)"
                textcolor="var(--text-dark)"
                type="button"
                onClick={onClose}
              >
                Cancel
              </MediumButton>
              {isLoading ? (
                <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
              ) : (
                <MediumButton
                  bg="var(--accent-1)"
                  border="var(--accent-1)"
                  textcolor="var(--primary)"
                  type="submit"
                >
                  Proceed to verify
                </MediumButton>
              )}
            </div>
          </form>
        </ModalBodyWrapper>
      </Modal>
      <Toaster position="top-center" visibleToasts={3} />
    </>
  );
};

export default VerifyCompanyModal;
