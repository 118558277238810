import { styled } from "goober";
import { mobile, mobileSmallMax } from "globalStyles";

export const SidebarWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 12.5rem;
  background-color: var(--primary);
  transition: width 0.3s;
  box-shadow: 0px 4px 7px var(--blue-100);
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  .bottom-nav {
    display: flex;
    flex-direction: column;
    gap: 0.4375rem;
    padding: 1.3125rem 1.5rem;
    width: 100%;
    .bottom-nax-text {
      color: var(--white);
      font-size: 0.5rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }
  }
  ${mobile} {
    display: none;
  }
`;

export const LogoWrapper = styled("div")`
  padding: 1rem 1rem 0.9375rem 0.75rem;
  .dummy-logo {
    width: 10.75rem;
    height: 3rem;
    border-radius: 12px;
    background: var(--text-dark);
  }
  .brand-logo {
    width: 100%;
    padding: 0.375rem 0.4375rem;
    border-radius: var(--br-md);
    background: var(--white);
    gap: 0.4375rem;
    .brand-icon {
      background: var(--text-muted);
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
      border-radius: var(--br-sm);
      color: var(--primary);
    }
    .text-md-semibold {
      color: var(--headings);
    }
  }
`;

export const NavItemsWrapper = styled("nav")`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 0;
  .nav-wrapper {
    position: relative;
    .coming-soon {
      position: absolute;
      top: -16px;
      right: 6px;
      background: var(--text-dark);
      padding: 0.25rem 0.5rem 0.375rem 0.5rem;
      border-radius: 0.25rem;
    }
    .active-bar {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1.75rem;
      width: 0.25rem;
      background: var(--accent-1);
      border-radius: 0 4px 4px 0;
    }
  }
  .nav-item {
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    gap: 0.5rem;
    color: var(--text-neutral);
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 140%;
    white-space: nowrap;
    min-height: 1.75rem;
    svg {
      fill: var(--text-neutral);
    }
    &:hover,
    &.active {
      color: var(--white);
      svg {
        fill: var(--accent-1);
      }
    }
    .nav-item-title {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .dummy-nav {
    gap: 1.9375rem;
    padding-left: 1.5rem;
    .dummy-menu {
      border-radius: var(--br-sm);
      background: var(--text-dark);
      width: 8.125rem;
      height: 1.75rem;
    }
  }
`;

export const MainWrapper = styled("div")`
  display: flex;
  position: relative;
`;

export const MainContainer = styled("main")`
  width: calc(100vw - 12.5rem);
  min-height: 100vh;
  margin-left: auto;
  background: var(--white-alt);
  .main {
    margin-top: 5rem;
  }
  ${mobile} {
    width: 100%;
  }
`;

export const PageHeader = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  padding: 1rem 2.5rem 0.9375rem 2.875rem;
  border-bottom: 1px solid var(--stroke);
  color: var(--headings);
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - 12.8rem);
  z-index: 999;
  ${mobileSmallMax} {
    flex-wrap: wrap;
  }
  .right {
    gap: 24px;
    ${mobileSmallMax} {
      width: 100%;
      justify-content: space-between;
    }
  }
`;

export const Divider = styled("div")`
  border-right: 1px solid var(--stroke);
  height: 2rem;
`;

export const UserMenu = styled("section")`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  user-select: none;
`;

export const UserImage = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background: var(--white-alt);
  min-width: 3rem;
  min-height: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: var(--br-md);
`;

export const DropdownContent = styled("div")`
  position: absolute;
  top: 5.375rem;
  right: 1rem;
  background: var(--white);
  border-radius: var(--br);
  padding: 0.875rem;
  min-width: 7.5rem;
  user-select: none;
  .dropdown-item {
    cursor: pointer;
    padding: 0.125rem 0.25rem;
    &:hover {
      background: var(--white-alt);
    }
  }
`;
