import { ReactComponent as LogoSmall } from "assets/images/logo-coloured-small.svg";
import { FlexColumnCenter } from "assets/styles/common-styles";

const PageLoader = () => {
  return (
    <section className={FlexColumnCenter} style={{minHeight: "100vh"}}>
      <LogoSmall />
    </section>
  );
}

export default PageLoader;