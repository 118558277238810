import { createGlobalStyles } from "goober/global";
import { keyframes } from "goober";
import ClashDisplayFont from "assets/fonts/ClashDisplay-Semibold.otf";

export const HD = `@media screen and (min-width: 1800px)`;
export const minDesktop = `@media screen and (min-width: 1440px)`;
export const desktop = `@media screen and (max-width: 1440px)`;
export const desktopMidi = `@media screen and (max-width: 1280px)`;
export const tablet = `@media screen and (max-width: 1023px)`;
export const tabletSmall = `@media screen and (max-width: 991px)`;
export const mobile = `@media screen and (max-width: 767px)`;
export const mobileMedium = `@media screen and (max-width: 640px)`;
export const mobileSmallMax = `@media screen and (max-width: 540px)`;
export const mobileSmall = `@media screen and (max-width: 480px)`;
export const mobileExtraSmall = `@media screen and (max-width: 340px)`;

export const GlobalStyles = createGlobalStyles`
  @font-face {
    font-family: 'Clash Display';
    src: url('${ClashDisplayFont}');
    font-weight: 600;
    font-style: normal;
  }

  :root {
    /* colors */
    --white: #ffffff;
    --white-alt: #F3F6F8;
    --black: #000000;
    --primary: #011A27;
    --accent-1: #BCE909;
    --accent-2: #5E8B43;
    --headings: #002D7C;
    --text-dark: #273C47;
    --text-neutral: #657486;
    --text-muted: #CDD5DB;
    --text-coloured: #E5F9E0;
    --stroke: #DCE3E9;
    --bg: #E8F7EE;
    --bg-alt: #F3FBF6;

    /* System alerts */
    --success: #2CCF66;
    --warning: #E2A939;
    --error: #E54B4B;
    --complimentary-1: #05A7E3;
    --complimentary-2: #E26D5A;

    /* fonts */
    font-family: 'Inter', sans-serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    --heading-font: 'Clash Display', sans-serif;
    
    /* border radius */
    --br-xs: 0.375rem;
    --br-sm: 0.5rem;
    --br-md: 0.75rem;
    --br: 1rem;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-size: 16px;
    letter-spacing: 0em;
    width: 100%;
    color: var(--text-neutral);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul,
    menu {
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
    }

    img {
      display: block;
      height: auto;
      user-select: none;
      -webkit-user-drag: none;
    }

    svg {
      display: block;
    }

    input,
    select,
    textarea {
      outline: none;
      box-shadow: none;
      width: 100%;
      margin: 0;
    }
  
    /* font types */
    .text-xs-medium {
      font-size: 0.625rem;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .text-xs-semibold {
      font-size: 0.625rem;
      font-weight: 600;
      line-height: normal;
    }
    .text-xs-semibold-spaced {
      font-size: 0.625rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .text-xs-semibold-spaced-2 {
      font-size: 0.625rem;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    .text-sm-medium {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .text-sm-semibold {
      font-size: 0.75rem;
      font-weight: 600;
    }
    .text-sm-bold-spaced {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.8px;
    }
    .text-md {
      font-size: 0.875rem;
      font-weight: 400;
    }
    .text-md-spaced {
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 1.4px;
    }
    .text-md-medium {
      font-size: 0.875rem;
      font-weight: 500;
    }
    .text-md-semibold {
      font-size: 0.875rem;
      font-weight: 600;
    }
    .text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .text-base-medium {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
    .text-base-semibold {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
    .text-base-bold {
      font-size: 1rem;
      font-weight: 700;
      line-height: normal;
    }
    .text-large-semibold {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: normal;
    }
    .heading-h2 {
      font-family: var(--heading-font);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }
    .heading-h2-type-2 {
      font-family: var(--heading-font);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }
    .heading-h2-mid {
      font-family: var(--heading-font);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .heading-h3 {
      font-family: inherit;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .heading-h3-type-2 {
      font-family: var(--heading-font);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;
    }

    .heading-h1 {
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 2rem;
    }
    .heading-h1-type-2 {
      font-family: var(--heading-font);
      font-size: 2.125rem;
      font-weight: 600;
      line-height: 38.4px;
    }
    
    .line-height-normal {
      line-height: normal;
    }
    .grow-1 {
      flex-grow: 1;
    }
    .modal-close-btn {
      min-width: 1.625rem;
      min-height: 1.625rem;
      width: 1.625rem;
      height: 1.625rem;
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
    
  }
  body.modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .main-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  /* color classes */
  .accent-1 {
    color: var(--accent-1);
  }
  .accent-2 {
    color: var(--accent-2);
  }
  .dark {
    color: var(--primary);
  }
  .text-muted {
    color: var(--text-muted) !important;
  }
  .darker {
    color: var(--text-dark) !important;
  }
  .headings {
    color: var(--headings) !important;
  }
  .neutral {
    color: var(--text-neutral);
  }
  .coloured {
    color: var(--text-coloured);
  }
  .error {
    color: var(--error);
  }
  .success {
    color: var(--success);
  }
  .warning {
    color: var(--warning);
  }
  .complimentary-2 {
    color: var(--complimentary-2);
  }
  
  .bg-white {
    background-color: var(--white);
  }
  .bg-maxim-white {
    background-color: var(--white-alt) !important;
  }
  .bg-accent-2 {
    background-color: var(--accent-2);
  }

  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #0a53e4;
  }
  
  .input-container .filled {
    transform: translate(0, 12px) scale(0.8);
  }
  
  .input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  
  .input-container input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  .input-container input:focus {
    box-shadow: 0 0 0 2px #79b1ff;
  }

  .view-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--stroke);
    border-radius: var(--br-sm);
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 2px;
    background: var(--stroke);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--stroke);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--accent-2);
    border-radius: 2px;
    height: 3rem;
  }
  .radial-chart {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in;
  }
  .radial-chart.no-progress .radial-chart-progress {
    opacity: 0;
  }
  .radial-chart-total {
    opacity: 0.3; 
  }
  .radial-chart-progress {
    transform: rotate(90deg);
    transform-origin: center;
    transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
    transition-delay: 0.3s; 
  }
  .status {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
  .status-ball {
    width: 0.5rem;
    height: 0.5rem;
    min-width: 0.5rem;
    min-height: 0.5rem;
    border-radius: 50%;
    &.pending {
      background: var(--warning);
    }
    &.active {
      background: var(--success);
    }
    &.complimentary {
      background: var(--complimentary-1);
    }
    &.error {
      background: var(--error);
    }
  }
`;

export const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
