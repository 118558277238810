import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import CloseIcon from "assets/images/icons/close-bg-white-alt.svg";
import CollapseIcon from "assets/images/icons/collapse.svg";
import ArrowDown from "assets/images/icons/arrow-down-2.svg";
import { MediumButton } from "components/button";
import Checkbox from "components/formFields/Checkbox";
import store from "store";
import { useSnapshot } from "valtio";
import Spinner from "components/common/Spinner";
import { formatNumberWithCommas } from "utils/common";
import SelectionModal from "./SelectionModal";

const CustomisePlanDrawer = ({ open, onClose }) => {
  const [units, setUnits] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [plans, setPlans] = useState([]);
  const lookupsSnapshot = useSnapshot(store?.lookups);
  const subscriptionSnapshot = useSnapshot(store?.subscription);
  const datapointsByClassLoading = lookupsSnapshot?.datapointsByClassLoading;
  const datapointsByClass = lookupsSnapshot?.datapointsByClass;
  const customPriceData = subscriptionSnapshot?.customPriceData;
  const customPriceLoading = subscriptionSnapshot?.customPriceLoading;
  const [classes, setClasses] = useState([]);
  const [datapointsForDB, setDatapointsForDB] = useState([]);
  const [selectionModal, setSelectionModal] = useState({
    open: false,
    plan: {
      label: "Custom",
      price: customPriceData?.cost,
      units,
      datapoints: [],
    },
  });

  useEffect(() => {
    store?.lookups?.getDataPointsByClass(store?.auth?.countryCode);
  }, []);

  useEffect(() => {
    if (!classes?.length) {
      return;
    }

    store?.subscription?.getCustomPrice(classes, units);
  }, [units, classes]);

  useEffect(() => {
    const selectedClasses = plans
      ?.filter((plan) =>
        plan.datapoints.some((datapoint) => datapoint.selected === true)
      )
      .map((plan) => plan.name.replace(/\s+/g, "_").toLowerCase());

    setClasses(selectedClasses);
  }, [plans]);

  useEffect(() => {
    const selectedClasses = plans
      ?.filter((plan) =>
        plan.datapoints.some((datapoint) => datapoint.selected === true)
      )
      .map((plan) => ({
        id: plan.name.replace(/\s+/g, "_").toLowerCase(),
        datapoints: plan.datapoints
          .filter((datapoint) => datapoint.selected)
          .map((datapoint) => datapoint.id),
      }));

    setDatapointsForDB(selectedClasses);
  }, [plans]);

  useEffect(() => {
    const formattedDataPoints = datapointsByClass?.map((data) => {
      return {
        name: data.name,
        visible: false,
        selected: false,
        datapoints: data?.datapoints?.map((datapoint) => {
          return { ...datapoint, selected: false };
        }),
      };
    });

    setPlans(formattedDataPoints);
  }, [datapointsByClass]);

  const handleToggle = (planName) => {
    setPlans((prevPlans) => {
      return prevPlans.map((plan) => {
        if (plan.name === planName) {
          return {
            ...plan,
            visible: !plan.visible,
          };
        } else {
          return { ...plan, visible: false };
        }
      });
    });
  };

  const handleSelect = (planName) => {
    setPlans((prevPlans) => {
      return prevPlans.map((plan) => {
        if (plan.name === planName) {
          return {
            ...plan,
            selected: !plan.selected,
            datapoints: plan.datapoints.map((datapoint) => {
              return {
                ...datapoint,
                selected: !plan.selected,
              };
            }),
          };
        }
        return plan;
      });
    });
  };

  const handleDataPointSelect = (datapointID) => {
    setPlans((prevDatapoints) => {
      return prevDatapoints?.map((data) => {
        return {
          ...data,
          datapoints: data?.datapoints?.map((datapoint) => {
            if (datapoint.id === datapointID) {
              return {
                ...datapoint,
                selected: !datapoint.selected,
              };
            }
            return datapoint;
          }),
        };
      });
    });
  };

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      open={open}
      height={"100%"}
      className="customise-plan-drawer"
      closeIcon={null}
    >
      <div className="sub-container">
        <img
          src={CloseIcon}
          alt="close"
          className="absolute top-10 right-10 cursor-pointer"
          onClick={onClose}
        />

        <div className="mb-[4rem]">
          <p className="text-headings font-secondary text-[1.75rem] mb-2">
            Customize plan
          </p>
          <p className="text-base text-neutral font-medium">
            Pay for only what you need. Select to create a <br /> custom list of
            data points suitable for your business.
          </p>
        </div>

        {datapointsByClassLoading ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-2 gap-x-[6rem] gap-y-12 mb-40 items-start">
            {plans?.map((plan, planIndex) => (
              <div
                className="border border-stroke rounded-lg py-2.5 pl-6 pr-8"
                key={planIndex}
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => handleToggle(plan.name)}
                >
                  <Checkbox
                    onChange={() => handleSelect(plan.name)}
                    checked={plan.selected}
                    label={plan.name}
                    labelclass="font-secondary text-dark text-base"
                  />

                  <img
                    src={ArrowDown}
                    alt="arrow-down"
                    className={`${
                      plan.visible ? "rotate-[180deg]" : ""
                    } p-2 transition-all duration-300`}
                  />
                </div>

                <div
                  className={`${
                    plan.visible ? "mt-[0.875rem]" : "h-0 overflow-hidden"
                  } transition-all duration-300 space-y-4`}
                >
                  {plan?.datapoints.map((datapoint, index) => (
                    <div key={index} className="">
                      <Checkbox
                        onChange={() => {
                          handleDataPointSelect(datapoint.id);
                        }}
                        checked={datapoint.selected}
                        label={datapoint.name}
                        labelclass="text-neutral text-sm font-medium"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="fixed bottom-0 left-0 w-full bg-alt px-[6rem] py-6">
          <div className="sub-container">
            <div className="flex items-center justify-between ">
              <p className="text-headings font-secondary text-xl">
                Selection-based pricing
              </p>

              <div
                className="flex items-center space-x-2 cursor-pointer"
                onClick={() => setCollapsed(!collapsed)}
              >
                <p>{collapsed ? "Expand" : "Collapse"}</p>
                <img
                  src={CollapseIcon}
                  alt="collapse"
                  className={`${
                    collapsed ? "rotate-[180deg]" : ""
                  } transition-all duration-300`}
                />
              </div>
            </div>

            <div
              className={`${
                collapsed ? "h-0 overflow-hidden" : "mt-7"
              } grid grid-cols-4 space-x-20 transition-all duration-300`}
            >
              <div>
                <p className="mb-4 text-neutral text-xs font-semibold tracking-[1.8px]">
                  TOTAL COST PER CHECK
                </p>
                {customPriceLoading ? (
                  <Spinner className="my-[0.5rem] mx-0" />
                ) : (
                  <p className="text-[1.75rem] text-dark font-semibold">
                    {formatNumberWithCommas(
                      customPriceData?.cost_per_check || ""
                    ) || 0}
                    <sub className="text-[0.75rem] font-bold text-accent-2">
                      {customPriceData?.currency}
                    </sub>
                  </p>
                )}
              </div>

              <div>
                <p className="mb-2 text-neutral text-xs font-semibold tracking-[1.8px]">
                  ENTER UNITS REQUIRED MONTLHY
                </p>
                <div className="flex items-end space-x-2">
                  <input
                    type="number"
                    value={units}
                    onChange={(e) => setUnits(e.target.value)}
                    disabled={!classes?.length}
                    className="bg-transparent border border-stroke rounded-lg py-1 px-2 w-[90px] text-[1.75rem] font-semibold text-dark disabled:cursor-not-allowed"
                  />
                  <p className="text-[0.75rem] font-bold text-accent-2">
                    UNITS/MONTH
                  </p>
                </div>
              </div>

              <div>
                <p className="mb-4 text-neutral text-xs font-semibold tracking-[1.8px]">
                  SUM TOTAL
                </p>
                {customPriceLoading ? (
                  <Spinner className="my-[0.5rem] mx-0" />
                ) : (
                  <p className="text-[1.75rem] text-dark font-semibold">
                    {formatNumberWithCommas(customPriceData?.cost || "") || 0}
                    <sub className="text-[0.75rem] font-bold text-accent-2">
                      {customPriceData?.currency}
                    </sub>
                  </p>
                )}
              </div>

              <SelectionModal
                isOpen={selectionModal.open}
                onClose={() =>
                  setSelectionModal({
                    open: false,
                    plan: {
                      label: "Custom",
                      units: "",
                      price: "",
                      datapoints: [],
                    },
                  })
                }
                plan={selectionModal.plan}
              />

              <MediumButton
                bg="var(--accent-1)"
                textcolor="var(--primary)"
                hovercolor="var(--white-alt)"
                className="place-self-end"
                onClick={() =>
                  setSelectionModal({
                    open: true,
                    plan: {
                      label: "Custom",
                      price: formatNumberWithCommas(
                        customPriceData?.cost || ""
                      ),
                      units,
                      datapoints: datapointsForDB,
                    },
                  })
                }
              >
                Activate plan
              </MediumButton>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomisePlanDrawer;
