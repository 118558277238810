import auth from 'store/auth';
import lookups from 'store/lookups';
import onboarding from 'store/onboarding';
import reminders from 'store/reminders';
import decisionCriteria from 'store/decisionCriteria';
import settings from 'store/settings';
import customerReport from 'store/customerReport';
import misc from 'store/misc';
import consent from 'store/consent';
import subscription from 'store/subscription';
import profileCheck from 'store/profileCheck';
import commercialCheck from './commercialCheck';

const store = {
  auth,
  lookups,
  onboarding,
  reminders,
  settings,
  decisionCriteria,
  customerReport,
  misc,
  consent,
  subscription,
  profileCheck,
  commercialCheck,
};

export default store;
