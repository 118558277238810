import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'goober';

const Toggle = ({
  id,
  onChange = () => {},
  isChecked,
  label1,
  label2,
  defaultChecked = false,
  disabled,
  ...props
}) => {
  return (
    <ToggleContainer className={disabled ? 'disabled' : ''}>
      <label className='toggle-label text-md-semibold neutral'>{label1}</label>
      <label
        className='toggle-switch'
        htmlFor={id}
      >
        <input
          type='checkbox'
          checked={isChecked}
          id={id}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <span className='toggle-slider'></span>
      </label>
      <label className='toggle-label text-md-semibold neutral'>{label2}</label>
    </ToggleContainer>
  );
};

Toggle.propTypes = {
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Toggle;

const ToggleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .toggle-label {
    line-height: 1.5rem;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 2.125rem;
    height: 1.25rem;
  }

  .toggle-switch input {
    display: none;
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--error);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .toggle-slider:before {
    position: absolute;
    content: '';
    height: 1rem;
    width: 1rem;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  .toggle-switch input:checked + .toggle-slider {
    background-color: var(--accent-2);
  }

  .toggle-switch input:focus + .toggle-slider {
    box-shadow: 0 0 1px var(--accent-2);
  }

  .toggle-switch input:checked + .toggle-slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
`;
