import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthPage from "pages/AuthPage";
import Onboarding from "pages/Onboarding";
import SelectCountry from "pages/SelectCountry";
import ConfirmVerification from "pages/ConfirmVerification";
import SettingsPage from "pages/Settings";
import WithAuth from "HOC/WithAuth";
import CustomerReports from "pages/CustomerReports";
import ConsentFlowPage from "pages/Consent";
import ConsentFlowThankYouPage from "components/consentFlow/ConsentThankYou";
import WebhooksPage from "pages/api-keys-webhooks/webhooks";
import APIKeysPage from "pages/api-keys-webhooks/apikeys";
import IPWhitelistsPage from "pages/api-keys-webhooks/ipwhitelists";
import WebhookServicePage from "pages/api-keys-webhooks/webhookservice";
import ApiLogsPage from "pages/api-keys-webhooks/apilogs";
import CustomerReport from "pages/CustomerReport";
import PlansAndBillingPage from "pages/plans-billing";
import SubscriptionPage from "pages/plans-billing/subscription";
import AllCustomerReports from "pages/AllCustomerReports";
import ProfileCheck from "pages/ProfileCheck";
import CommercialCheckPage from "pages/CommercialCheck";
import CommercialCheckReport from "pages/CommercialCheckReport";
import GetStarted from "pages/GetStarted";
import ApiDocsIntroduction from "pages/api-docs/Introduction";
import ApiDocsEnvironment from "pages/api-docs/Environment";
import ApiDocsDefinition from "pages/api-docs/Definition";
import ApiReferences from "pages/api-docs/ApiReferenceMain";
import ApiDocsApiKeysWebhook from "pages/api-docs/ApiKeysWebhooks";

function Main() {
  return (
    <Router>
      <Routes>
        <Route
          path="/select-country"
          element={<WithAuth element={<SelectCountry />} />}
        />
        <Route
          path="/auth/:state"
          element={<WithAuth element={<AuthPage />} />}
        />
        <Route
          path="/confirm"
          element={<WithAuth element={<ConfirmVerification />} />}
        />
        <Route
          path="/onboarding"
          element={<WithAuth element={<Onboarding />} />}
        />
        <Route
          path="/get-started"
          element={<WithAuth element={<GetStarted />} />}
        />
        <Route
          path="/consumer-reports"
          element={<WithAuth element={<CustomerReports />} />}
        />
        <Route
          path="/all-consumer-reports"
          element={<WithAuth element={<AllCustomerReports />} />}
        />
        <Route
          path="/consumer-reports/:reference_id"
          element={<WithAuth element={<CustomerReport />} />}
        />
        <Route
          path="/user-id-verification"
          element={<WithAuth element={<ProfileCheck />} />}
        />
        <Route
          path="/commercial-check"
          element={<WithAuth element={<CommercialCheckPage />} />}
        />
        <Route
          path="/commercial-check/:reference"
          element={<WithAuth element={<CommercialCheckReport />} />}
        />
        <Route
          path="/settings"
          element={<WithAuth element={<SettingsPage />} />}
        />
        <Route
          path="/api-keys"
          element={<WithAuth element={<APIKeysPage />} />}
        />
        <Route
          path="/webhooks"
          element={<WithAuth element={<WebhooksPage />} />}
        />
        <Route
          path="/webhooks/:service"
          element={<WithAuth element={<WebhookServicePage />} />}
        />
        <Route
          path="/api-logs"
          element={<WithAuth element={<ApiLogsPage />} />}
        />
        <Route
          path="/ip-whitelists"
          element={<WithAuth element={<IPWhitelistsPage />} />}
        />
        <Route
          path="/plans"
          element={<WithAuth element={<PlansAndBillingPage />} />}
        />
        <Route
          path="/plans/subscriptions"
          element={<WithAuth element={<SubscriptionPage />} />}
        />
        <Route path="/consent" element={<ConsentFlowPage />} />
        <Route
          path="/consent/thank-you"
          element={<ConsentFlowThankYouPage />}
        />
        <Route
          path="/docs"
          element={<Navigate to="/docs/introduction" replace />}
        />
        <Route
          path="/docs/introduction"
          element={<WithAuth element={<ApiDocsIntroduction />} />}
        />
        <Route
          path="/docs/definition"
          element={<WithAuth element={<ApiDocsDefinition />} />}
        />
        <Route
          path="/docs/environment"
          element={<WithAuth element={<ApiDocsEnvironment />} />}
        />
        <Route
          path="/docs/setup-api-keys-webhooks"
          element={<WithAuth element={<ApiDocsApiKeysWebhook />} />}
        />
        <Route
          path="/docs/api-reference"
          element={<WithAuth element={<ApiReferences />} />}
        />
        {/* <Route
          path="/decision-criteria"
          element={<WithAuth element={<DecisionCriteria />} />}
        /> */}
        <Route path="*" element={<Navigate to="/get-started" replace />} />
      </Routes>
    </Router>
  );
}

export default Main;
