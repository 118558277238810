import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import React, { useCallback, useEffect, useState } from "react";
import PlusIcon from "assets/images/icons/plus.svg";
import MinusIcon from "assets/images/icons/minus.svg";
import store from "store";
import { useSnapshot } from "valtio";
import { formatNumberWithCommas } from "utils/common";
import Toast from "components/common/ToastNotification";
import { toast } from "sonner";

const TopUpModal = ({ isOpen, onClose }) => {
  const [units, setUnits] = useState("");
  const [amountToPay, setAmountToPay] = useState(0);
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const topUpAmountLoading = subscriptionSnapshot?.topUpAmountLoading;
  const topUpAmountData = subscriptionSnapshot?.topUpAmountData;
  const topUpLoading = subscriptionSnapshot?.topUpLoading;
  const topUpData = subscriptionSnapshot?.topUpData;
  const user = JSON.parse(localStorage.getItem("user"));
  const [flwResponse, setflwResponse] = useState(null);
  const [reference, setReference] = useState("");
  const [pagination] = useState({
    perPage: 5,
    page: 1,
  });

  useEffect(() => {
    if (units > 0) {
      store?.subscription.getTopUpAmount(units);
    }
  }, [units]);

  useEffect(() => {
    setReference(topUpData?.reference);
  }, [topUpData?.reference]);

  useEffect(() => {
    const verifyPayment = async (id) => {
      try {
        const response = await store?.subscription.verifyPayment({
          transaction_id: id,
        });

        if (response?.data?.status === false) {
          alert(response?.data?.message);
          onClose();
        } else {
          alert("Payment Successful!");
          onClose();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (flwResponse?.status === "successful") {
      verifyPayment(flwResponse?.transaction_id.toString());
      setflwResponse(null);
    }
  }, [flwResponse, onClose]);

  useEffect(() => {
    if (topUpAmountData) {
      setAmountToPay(topUpAmountData?.price);
    }
  }, [topUpAmountData]);

  const makePayment = useCallback(() => {
    // eslint-disable-next-line no-undef
    const modal = FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLUTTERWAVE_PUB_KEY,
      tx_ref: reference,
      amount: topUpAmountData?.price,
      currency: topUpAmountData?.currency,
      payment_options: "card",
      customer: {
        email: user?.email,
      },
      customizations: {
        title: `${topUpData?.planType} plan.`,
        description: `You are about to make payment for the ${topUpData?.planType} plan.`,
      },
      callback: function (response) {
        setflwResponse(response);
        modal.close();
      },
    });
  }, [reference, topUpAmountData?.price, topUpAmountData?.currency, user?.email, topUpData?.planType]);

  useEffect(() => {
    if (reference?.length > 0) {
      if (topUpData?.inlineCharge) {
        makePayment();
      }
    }
  }, [makePayment, topUpData, onClose, pagination, reference]);

  const topUpFn = async () => {
    const response = await store?.subscription.createTopUp({ volume: +units });

    if (!response?.inlineCharge) {
      toast.custom((t) => (
        <Toast toastType="info" text="Transaction is processing" />
      ));
      store?.subscription.getSubscription();
      store?.subscription.getBillingHistory({ ...pagination });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        <div className="flex flex-col items-center justify-between h-[27rem] w-full">
          <div className="mb-[4.375rem] flex flex-col items-center">
            <p className="text-headings font-secondary text-[1.75rem] font-semibold mb-5">
              Top up additional units
            </p>

            <p className="text-base text-center w-[60%] ">
              Enter additional units needed and proceed to payment.
            </p>
          </div>

          <div className="mb-[2rem] w-full">
            <div className="flex justify-between items-center py-2 px-2 w-full border border-stroke rounded-2xl">
              <button
                className="p-2 bg-alt rounded-lg mr-5"
                onClick={() => {
                  if (units <= 0) return;
                  setUnits((prevVal) => prevVal - 1);
                }}
              >
                <img src={MinusIcon} alt="minus" />
              </button>
              <input
                className="flex-1 border-0 outline-0 text-base"
                placeholder="Enter units (E.g 100)"
                type="number"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              />
              <button
                className="p-2 bg-alt rounded-lg"
                onClick={() => setUnits((prevVal) => +prevVal + 1)}
              >
                <img src={PlusIcon} alt="plus" />
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between w-full">
            <p className="text-xs font-semibold text-neutral tracking-[1.8px]">
              AMOUNT TO PAY
            </p>

            {topUpAmountLoading ? (
              <Spinner style={{ margin: "0.5rem 0" }} />
            ) : (
              <p className="text-[1.75rem] text-dark font-semibold">
                {formatNumberWithCommas(amountToPay)}
                <span className="text-accent-2 text-[0.625rem] font-bold tracking-[1.5px] ml-1">
                  {topUpAmountData?.currency}
                </span>
              </p>
            )}
          </div>

          <div className="flex items-center justify-between w-full mt-12">
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {topUpLoading ? (
              <Spinner style={{ margin: "0.5rem 0" }} />
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                hovercolor="var(--accent-1)"
                onClick={topUpFn}
                disabled={units <= 0}
              >
                Proceed to payment
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default TopUpModal;
