import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const DEFAULT_COLOR = "var(--stroke)";

const RadialChart = ({
  radius,
  actualvalue,
  totalvalue,
  strokeWidth,
  dimension,
  text,
  title = "Credit Score",
}) => {
  const [setStrokeLength, setSetStrokeLength] = useState(false);

  useEffect(() => {
    // For initial animation
    const timeoutId = setTimeout(() => {
      setSetStrokeLength(true);
    }, 0); // Using a timeout of 0 to run it in the next tick of the event loop

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  const circleRadius = Math.min(radius, 85);
  const circumference = 2 * Math.PI * circleRadius;
  const progress = totalvalue !== 0 ? (actualvalue / totalvalue) * 100 : 0;
  const strokeLength = setStrokeLength ? (circumference / 100) * progress : 0;

  // Define the unique ID for the radial gradient
  const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div
      className={classNames("radial-chart", {
        "no-progress": strokeLength === 0,
      })}
    >
      <svg viewBox="0 0 180 180" width={dimension} height={dimension}>
        <defs>
          <radialGradient
            id={gradientId}
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            <stop
              offset="0%"
              style={{ stopColor: "var(--text-neutral)", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "var(--text-neutral)", stopOpacity: 1 }}
            />
          </radialGradient>
        </defs>
        <circle
          className="radial-chart-total"
          stroke={DEFAULT_COLOR}
          strokeWidth={strokeWidth}
          fill="none"
          cx="90"
          cy="90"
          r={circleRadius}
        />
        <circle
          className="radial-chart-progress"
          stroke={`url(#${gradientId})`}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeLength},${circumference}`}
          strokeLinecap="butt"
          fill="none"
          cx="90"
          cy="90"
          r={circleRadius}
          actualvalue={actualvalue}
          totalvalue={totalvalue}
        />
        <>
          {text && (
            <text
              x="50%"
              y="45%"
              textAnchor="middle"
              dy="0.3em"
              fontSize="33"
              fill="var(--text-dark)"
              fontWeight="600"
              fontFamily="var(--heading-font)"
            >
              {text}
            </text>
          )}
          {title && (
            <text
              x="50%"
              y="62%"
              textAnchor="middle"
              dy="0.3em"
              fontSize="16"
              fill="var(--text-neutral)"
            >
              {title}
            </text>
          )}
        </>
      </svg>
    </div>
  );
};

RadialChart.defaultProps = {
  radius: 80,
  actualvalue: 0,
  totalvalue: 100,
  strokeWidth: 10,
  dimension: 180,
  text: "",
};

RadialChart.propTypes = {
  radius: PropTypes.number,
  actualvalue: PropTypes.number,
  totalvalue: PropTypes.number,
  strokeWidth: PropTypes.number,
  dimension: PropTypes.number,
  text: PropTypes.string,
};

export default RadialChart;
