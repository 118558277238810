import React, { useState } from "react";
import { toast } from "sonner";
import store from "store";
import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import FloatingTextField from "components/formFields/FloatingTextField";
import CustomModal from "components/modals/CustomModal";
import { DrawerStyle } from "components/modals/modalStyles";
import Toast, { ToastWrapper } from "components/common/ToastNotification";
import {
  FlexColumnBetween,
  FlexEndCenter,
  ShadowCircle,
} from "assets/styles/common-styles";
import { ReactComponent as BankSearch } from "assets/images/icons/bank-search.svg";
import ResultNotificationModal from "components/commercialCheck/ResultNotificationModal";

const LookupDrawer = ({ isOpen, onClose }) => {
  const [RCNumber, setRCNumber] = useState("");
  const [openResultNotification, setOpenResultNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});

  const handleCheck = async () => {
    setIsLoading(true);
    try {
      const response = await store.commercialCheck.initiateCheck(RCNumber);
      if (response && response?.reference) {
        setCompanyData(response);
        await store.commercialCheck.getSubscription();
        setOpenResultNotification(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal isOpen={isOpen} onClose={onClose} className={DrawerStyle}>
        <p className="text-headings font-semibold font-secondary text-[1.75rem] mb-5">
          Begin Commercial Check
        </p>
        <div className={`${FlexColumnBetween} h-[90%]`}>
          <section className="w-full">
            <ShadowCircle className="w-[10rem] h-[10rem] mt-[3rem] mb-[2.5rem] lg:mt-[6.75rem] lg:mb-[5rem] mx-auto">
              <BankSearch />
            </ShadowCircle>
            <FloatingTextField
              label="Company name or RC Number"
              type="text"
              id="rc-number"
              name="rc-number"
              labelfor="rc-number"
              value={RCNumber}
              onChange={(e) => setRCNumber(e.target.value)}
            />
            <div className={`mt-12 ${FlexEndCenter}`}>
              {isLoading ? (
                <Spinner style={{ margin: "0.5rem 0" }} />
              ) : (
                <MediumButton
                  bg="var(--accent-1)"
                  border="var(--accent-1)"
                  textcolor="var(--primary)"
                  onClick={handleCheck}
                >
                  Request full check
                </MediumButton>
              )}
            </div>
          </section>
          <ToastWrapper className="warning !bg-[#FDFAF4] text-neutral mt-2">
            <p className="text-md-medium">
              The outcome of this check returns an immediate{" "}
              <span className="warning">CAC Result</span> and a subsequent{" "}
              <span className="warning">Credit Report</span> for companies who
              have a loan or transaction history
            </p>
          </ToastWrapper>
        </div>
      </CustomModal>

      {openResultNotification && (
        <ResultNotificationModal
          isOpen={openResultNotification}
          onClose={() => setOpenResultNotification(false)}
          closeDrawer={() => onClose()}
          companyData={companyData}
        />
      )}
    </>
  );
};

export default LookupDrawer;
