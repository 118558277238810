import { css } from "goober";
import React from "react";

const Spinner = ({ style, className }) => (
  <div
    className={`${className} ${css`
      border: 4px solid #bdbdbd;
      border-top: 4px solid var(--headings);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
      animation: spin 1s linear infinite;
      margin: 3.25rem auto 0;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}`}
    style={style}
  ></div>
);

export default Spinner;
