import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { format } from "date-fns";
import store from "store";
import {
  CodeBox,
  Flex,
  FlexCol,
  SpaceBetweenW100,
  StatusBall,
} from "assets/styles/common-styles";
import CustomModal from "components/modals/CustomModal";
import Spinner from "components/common/Spinner";
import Tab from "components/common/Tab";
import { ReactComponent as RedoIcon } from "assets/images/icons/redo.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";
import { capitalizeFirstLetter, handleCopy } from "utils/common";
import { getLocalStorageProperties } from "utils/user";
import { Toaster, toast } from "sonner";
import Toast from "components/common/ToastNotification";
import { DrawerStyle } from "components/modals/modalStyles";

const WebhookModal = ({ isOpen, onClose, rowId }) => {
  const { service } = useParams();
  const snap = useSnapshot(store.settings);
  const singleWebhook = snap?.singleWebhook;
  const singleWebhookLoading = snap?.singleWebhookLoading;
  const tabs = [
    { label: "request", urlParam: "request" },
    { label: "response", urlParam: "response" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const [isLoading, setIsLoading] = useState(false);
  const maximUser = localStorage.getItem("user");
  const toExtract = ["dashboard_state"];
  const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
  const { dashboard_state: dashboardState } = extractedUsernames;
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (rowId) {
      store.settings.getSingleWebhook(rowId);
    }
  }, [rowId]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const ResendHook = async (e) => {
    setIsLoading(true);

    const data = {
      env: dashboardState,
      service: singleWebhook?.service,
      url: singleWebhook?.url,
    };

    try {
      const response = await store.settings.refireWebhook(data, {
        id: singleWebhook?.id,
      });
      if (response && response?.message === "Notification Resend triggered") {
        toast.custom((t) => (
          <Toast toastType="success" text={response.message} />
        ));
        await store.settings.getSingleWebhook(rowId);
        await store?.settings?.getAllWebhooksByService(service);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className={`${DrawerStyle} webhook`}
    >
      <form className={`${FlexCol}`}>
        <h3 className="heading-h2-type-2 card-header break-words">
          {singleWebhook && singleWebhook?.id}
        </h3>
        <section className="header mb-5">
          <div className={`${Flex} ${SpaceBetweenW100} mb-[2.375rem]`}>
            <p className="text-md-medium">
              {singleWebhook &&
                format(
                  new Date(singleWebhook?.created_at),
                  "MMM dd yyyy, hh:mm a"
                )}
            </p>
            {isLoading ? (
              <Spinner className="mr-0 my-2" />
            ) : (
              <p
                className={`${Flex} gap-3 cursor-pointer accent-2 text-md-semibold`}
                onClick={ResendHook}
              >
                <RedoIcon fill="var(--accent-2)" /> Resend Hook
              </p>
            )}
          </div>
          <div className={`${Flex} ${SpaceBetweenW100}`}>
            <div className="detail-item">
              <p>Status</p>
              <p className="text-base-medium darker">
                {singleWebhook && capitalizeFirstLetter(singleWebhook?.status)}
              </p>
            </div>
            <div className="detail-item">
              <p>Code</p>
              <div className={`text-base-medium darker ${Flex}`}>
                {singleWebhook?.status_code && (
                  <StatusBall
                    className={`mr-[0.375rem] ${
                      singleWebhook?.status_code === "200"
                        ? "active"
                        : "pending"
                    }`}
                  ></StatusBall>
                )}
                {singleWebhook && singleWebhook?.status_code}
              </div>
            </div>
            <div className="detail-item">
              <p>Resent</p>
              <p className="text-base-medium darker">
                {singleWebhook?.retry_count}{" "}
                {singleWebhook?.retry_count === 0 ||
                singleWebhook?.retry_count === 1
                  ? "time"
                  : "times"}
              </p>
            </div>
          </div>
        </section>
        <Tab
          tabs={tabs.map((tab) => ({
            label: capitalizeFirstLetter(tab.label),
            urlParam: tab.urlParam,
          }))}
          activeTabLabel={activeTab}
          handleTabClick={handleTabClick}
          className="webhook-tab"
          tabclass="w-1/2 text-center text-md-medium py-2.5 justify-center"
          isactiveclass="bg-white text-md-semibold rounded-xl"
        />

        {activeTab === "request" && (
          <div className="h-full flex flex-col gap-4">
            <CodeBox>
              <div className={`${Flex} ${SpaceBetweenW100} header`}>
                <p className="coloured text-md-semibold">Header</p>
                <p
                  className={`${Flex} gap-[0.375rem] accent-2 text-md-semibold cursor-pointer`}
                  onClick={() => {
                    if (singleWebhook?.headers === undefined) {
                      return;
                    }
                    handleCopy(
                      JSON.stringify(
                        JSON.parse(singleWebhook?.headers),
                        null,
                        2
                      )
                    );
                    setIsCopied(true);
                  }}
                >
                  <CopyIcon fill="var(--accent-2)" />{" "}
                  {isCopied ? "Copied" : "Copy"}
                </p>
              </div>
              <div className="base">
                <code className="darker text-md whitespace-pre-wrap indent-0.5">
                  <samp>
                    {singleWebhookLoading ? (
                      <Spinner className="mx-auto my-2" />
                    ) : (
                      singleWebhook &&
                      JSON.stringify(
                        JSON.parse(singleWebhook?.headers),
                        null,
                        2
                      )
                    )}
                  </samp>
                </code>
              </div>
            </CodeBox>
            <CodeBox>
              <div className={`${Flex} ${SpaceBetweenW100} header`}>
                <p className="coloured text-md-semibold">Payload</p>
                <p
                  className={`${Flex} gap-[0.375rem] accent-2 text-md-semibold cursor-pointer`}
                  onClick={() => {
                    if (singleWebhook?.payload === undefined) {
                      return;
                    }
                    handleCopy(
                      JSON.stringify(
                        JSON.parse(singleWebhook?.payload),
                        null,
                        2
                      )
                    );
                    setIsCopied(true);
                  }}
                >
                  <CopyIcon fill="var(--accent-2)" />{" "}
                  {isCopied ? "Copied" : "Copy"}
                </p>
              </div>
              <div className="base">
                <code className="darker text-md whitespace-pre-wrap indent-0.5">
                  <samp>
                    {singleWebhookLoading ? (
                      <Spinner className="mx-auto my-2" />
                    ) : (
                      singleWebhook &&
                      JSON.stringify(
                        JSON.parse(singleWebhook?.payload),
                        null,
                        2
                      )
                    )}
                  </samp>
                </code>
              </div>
            </CodeBox>
          </div>
        )}
        {activeTab === "response" && (
          <CodeBox>
            <div className={`${Flex} ${SpaceBetweenW100} header`}>
              <p className="coloured text-md-semibold">Response</p>
              <p
                className={`${Flex} gap-[0.375rem] accent-2 text-md-semibold cursor-pointer`}
                onClick={() => {
                  if (singleWebhook?.response === undefined) {
                    return;
                  }
                  handleCopy(
                    JSON.stringify(JSON.parse(singleWebhook?.response), null, 2)
                  );
                  setIsCopied(true);
                }}
              >
                <CopyIcon fill="var(--accent-2)" />{" "}
                {isCopied ? "Copied" : "Copy"}
              </p>
            </div>
            <div className="base">
              <code className="darker text-md whitespace-pre-wrap indent-0.5">
                <samp>
                  {singleWebhookLoading ? (
                    <Spinner className="mx-auto my-2" />
                  ) : (
                    singleWebhook &&
                    JSON.stringify(JSON.parse(singleWebhook?.response), null, 2)
                  )}
                </samp>
              </code>
            </div>
          </CodeBox>
        )}
      </form>
      <Toaster position="top-center" />
    </CustomModal>
  );
};
export default WebhookModal;
