import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const WithAuth = ({ element }) => {
  const token = localStorage.getItem("token");
  const location = useLocation();

  if (
    !token &&
    !location.pathname.startsWith("/auth") &&
    !location.pathname.startsWith("/select-country") &&
    !location.pathname.startsWith("/confirm")
  ) {
    return <Navigate to="/auth/login" />;
  } else if (
    token &&
    (location.pathname.startsWith("/auth") ||
      location.pathname.startsWith("/select-country") ||
      location.pathname.startsWith("/confirm")) &&
    !location.pathname.startsWith("/get-started")
  ) {
    return <Navigate to="/get-started" />;
  } else {
    return element;
  }
};

export default WithAuth;
