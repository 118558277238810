import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  NavItemsWrapper,
  SidebarWrapper,
  SubmenuItemsWrapper,
} from "assets/styles/docs-styles";
import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import { ReactComponent as Intro } from "assets/images/icons/blocks.svg";
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/small-arrow-right.svg";
import { ReactComponent as Globe } from "assets/images/icons/globe.svg";
import { ReactComponent as Definition } from "assets/images/icons/definition.svg";
import { ReactComponent as EnvironmentIcon } from "assets/images/icons/toggle.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/spanner.svg";
import { ReactComponent as UndoIcon } from "assets/images/icons/undo.svg";

const globeIcon = <Globe />;
const introIcon = <Intro />;
const definitionIcon = <Definition />;
const envIcon = <EnvironmentIcon />;
const settingsIcon = <SettingsIcon />;

const DocsSidebar = ({
  handleSubmenuClick,
  selectedComponent,
  selectedSubmenuItem,
  setSelectedSubmenuItem,
  isSubmenuOpen,
  setIsSubmenuOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navItems = [
    {
      label: "General",
      items: [
        {
          to: ["/docs/introduction"],
          icon: introIcon,
          title: "Introduction",
        },
        {
          to: ["/docs/definition"],
          icon: definitionIcon,
          title: "Definition",
        },
        {
          to: ["/docs/environment"],
          icon: envIcon,
          title: "Environment",
        },
        {
          to: ["/docs/setup-api-keys-webhooks"],
          icon: settingsIcon,
          title: "Setting up API keys",
        },
      ],
    },
    {
      label: "API REFERENCES",
      items: [
        {
          icon: globeIcon,
          title: "Assessment",
          subItems: [
            { title: "Get all assessments" },
            { title: "Show an assessment" },
            { title: "Create an assessment" },
            { title: "Submit consent data" },
            { title: "Update an assessment" },
            { title: "Get ID card type" },
          ],
        },
        // {
        //   to: "",
        //   icon: globeIcon,
        //   title: "Reports",
        // },
        {
          icon: globeIcon,
          title: "User ID Verification",
          subItems: [
            { title: "Verify ID" },
            { title: "List all" },
            { title: "Get verification details" },
            { title: "Bulk verification" },
          ],
        },
        {
          icon: globeIcon,
          title: "Commercial Reports",
          subItems: [
            { title: "Initiate" },
            { title: "List" },
            { title: "Details" },
          ],
        },
        // {
        //   to: "",
        //   icon: globeIcon,
        //   title: "Miscellaneous",
        // },
        // {
        //   to: "",
        //   icon: globeIcon,
        //   title: "Webhooks",
        // },
      ],
    },
  ];

  const handleItemClick = (menuItem) => {
    setSelectedSubmenuItem({
      title: menuItem.title,
      subItems: menuItem.subItems,
    });
    localStorage.setItem('isSubmenuOpen', true);
    localStorage.setItem('selectedSubmenuItem', JSON.stringify({
      title: menuItem.title,
      subItems: menuItem.subItems,
    }));
    navigate("/docs/api-reference");
  };

  const handleBackClick = () => {
    setIsSubmenuOpen(false);
    setSelectedSubmenuItem(null);
    localStorage.removeItem('isSubmenuOpen');
  };

  return (
    <>
      <SidebarWrapper onClick={(e) => e.stopPropagation()}>
        {isSubmenuOpen ? (
          <SubmenuItemsWrapper>
            <div className="submenu">
              <div
                onClick={handleBackClick}
                className={`${Flex} text-md-semibold gap-2 back-button cursor-pointer mt-10`}
              >
                <ArrowLeft />
                Back to API References
              </div>
              <div>
                <p className="text-xs-semibold-spaced-2 mt-10">
                  {selectedSubmenuItem.title}
                </p>
                {selectedSubmenuItem.subItems.map((item) => {
                  return (
                    <div key={item.title} className="nav-wrapper">
                      <div
                        key={item.title}
                        className={`nav-item mt-5 cursor-pointer ${
                          selectedComponent === item.title ? "active" : ""
                        }`}
                        onClick={() => handleSubmenuClick(item)}
                      >
                        <span className={`nav-item-title`}>{item.title}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </SubmenuItemsWrapper>
        ) : (
          <>
          <div className="top-nav">
            <NavItemsWrapper>
              <>
                {navItems.map(
                  (menu) =>
                    menu.label && (
                      <div key={menu.label}>
                        <div>
                          <p className="text-xs-semibold-spaced-2 px-4 mt-[2.875rem]">
                            {menu.label}
                          </p>
                          {menu.items.map((item) =>
                            item.subItems ? (
                              <div
                                key={item.to + item.title}
                                className="nav-item sub-item mt-5 cursor-pointer"
                                onClick={() => {
                                  handleItemClick(item);
                                  setIsSubmenuOpen(true);
                                }}
                              >
                                <span className="icon">{item.icon}</span>
                                <span
                                  className={`nav-item-title ${FlexRowBetween} w-full`}
                                >
                                  {item.title}
                                  <ArrowRight
                                    stroke="var(--text-neutral)"
                                    className="icon"
                                  />
                                </span>
                              </div>
                            ) : (
                              <span
                                key={item.to + item.title}
                                className="nav-wrapper"
                              >
                                <span
                                  className={`${
                                    item.to.some((path) =>
                                      location.pathname.includes(path)
                                    ) || location.pathname === item.to
                                      ? "active-bar"
                                      : ""
                                  }`}
                                ></span>
                                <Link
                                  key={item.to}
                                  to={
                                    Array.isArray(item.to)
                                      ? item.to[0]
                                      : item.to
                                  }
                                  className={`nav-item mt-5 ${
                                    item.to.some((path) =>
                                      location.pathname.includes(path)
                                    ) || location.pathname === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="icon">{item.icon}</span>
                                  <span className={`nav-item-title`}>
                                    {item.title}
                                  </span>
                                </Link>
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    )
                )}
              </>
            </NavItemsWrapper>
          </div>
          <div className="bottom-nav">
            <Link className={`item ${Flex} gap-1`} to="/get-started">
              <UndoIcon />
              <p className="accent-2 text-md-semibold">Return to dashboard</p>
            </Link>
          </div>
          </>
        )}
      </SidebarWrapper>
    </>
  );
};

export default DocsSidebar;
