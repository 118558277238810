import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { ReactComponent as LogoSmall } from "assets/images/logo-coloured-small.svg";
import { FlexColumnCenter } from "assets/styles/common-styles";
import Toast from "components/common/ToastNotification";

const ConfirmVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      toast.custom((t) => (
        <Toast
          toastType="success"
          text={"Email verification successful"}
        />
      ));
      navigate("/get-started");
    }, 1500);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <section
      className={FlexColumnCenter}
      style={{ minHeight: "100vh" }}
    >
      <LogoSmall />
      <Toaster position="top-center" />
    </section>
  );
};

export default ConfirmVerification;
