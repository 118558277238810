import React from "react";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { ReactComponent as FailIcon } from "assets/images/fail.svg";

const BVNMismatchModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBodyWrapper className="h-full">
        <div className="flex flex-col items-center justify-center h-full">
          <FailIcon />
          <h2 className="text-headings font-semibold text-[1.75rem] my-6">
            Name and BVN mismatch
          </h2>
          <p className="text-neutral text-center">
            Please double-check the entered details or contact support for
            further assistance. Thank you for your anticipated understanding.
          </p>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default BVNMismatchModal;
