/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "assets/images/icons/close-bg-white-alt.svg";
import CheckIcon from "assets/images/icons/check-icon.svg";
import { SmallButton } from "components/button";
import CustomisePlanDrawer from "./CustomisePlanDrawer";
import SelectionModal from "./SelectionModal";
import { Toaster } from "sonner";
import store from "store";
import { useSnapshot } from "valtio";
import { formatNumberWithCommas } from "utils/common";
import ActivateFreePlanModal from "./ActivateFreePlanModal";
import { parseUserInfo } from "utils/user";

const SubscriptionSection = () => {
  const navigate = useNavigate();
  const [openCustomiseDrawer, setOpenCustomiseDrawer] = useState(false);
  const [selectionModal, setSelectionModal] = useState({
    open: false,
    plan: null,
  });
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const plansData = subscriptionSnapshot?.plansData;
  const [plans, setPlans] = useState([
    {
      type: "basic",
      label: "Basic",
      services: ["Credit summary", "Report customization"],
      units: "",
      price: "",
      currency: "",
    },
    {
      type: "standard",
      label: "Standard",
      services: [
        "Credit summary",
        "Report customization",
        "Financial data check",
      ],
      units: "",
      price: "",
      currency: "",
    },
    {
      type: "premium",
      label: "Premium",
      services: [
        "Credit summary",
        "Report customization",
        "Financial data check",
        "Performance classification",
      ],
      units: "",
      price: "",
      currency: "",
    },
    {
      type: "enterprise",
      label: "Enterprise",
      services: [
        "Credit summary",
        "Report customization",
        "Financial data check",
        "Performance classification",
        "Consumer full report",
        "24/7 dedicated support",
      ],
      units: "",
      price: "",
      currency: "",
    },
  ]);
  const [openActivatePlanModal, setOpenActivatePlanModal] = useState(false);
  const userValues = parseUserInfo("user");
  const countryCode =
    localStorage.getItem("country-code") || userValues?.countryCode;

  useEffect(() => {
    if (plansData) {
      const revisedPlans = plans?.map((plan) => {
        if (plan.type === plansData[plan.type].id) {
          const country = countryCode === "ng" ? "NGN" : "GBP";
          const selectedPrice = plansData[plan.type].price.find(
            (price) => price.currency === country
          );
          return {
            ...plan,
            price: formatNumberWithCommas(selectedPrice.price),
            units: plansData[plan.type].volume,
            currency: selectedPrice.currency,
          };
        }
        return false;
      });

      const filteredRevisedPlans = revisedPlans.filter((plan) => plan);

      setPlans(filteredRevisedPlans);
    }
  }, [plansData, countryCode]);

  useEffect(() => {
    store?.subscription.getPlans();
  }, []);

  return (
    <div className="bg-alt relative">
      <div className="min-h-screen py-11 px-12">
        <div className="flex items-end justify-between mb-12">
          <div>
            <p className="font-secondary text-headings text-[1.75rem] leading-8 mb-2">
              Subscription plan
            </p>
            <p className="text-neutral font-medium">
              Choose a plan tailored to your needs. Need a more <br /> suitable
              pricing?{" "}
              <span
                onClick={() => setOpenCustomiseDrawer(true)}
                className="text-accent-2 font-semibold cursor-pointer"
              >
                Customize your plan
              </span>
            </p>
          </div>

          <p className="text-neutral font-medium">
            Not sure where to get started?{" "}
            <span
              className="text-accent-2 font-semibold cursor-pointer"
              onClick={() => setOpenActivatePlanModal(true)}
            >
              Activate a free plan
            </span>
          </p>
        </div>

        <ActivateFreePlanModal
          isOpen={openActivatePlanModal}
          onClose={() => setOpenActivatePlanModal(false)}
        />

        <div className="grid grid-cols-4 space-x-[1.875rem]">
          {plans?.map((plan, index) => (
            <div
              className={`${
                plan.type === "premium" ? "bg-primary-darkest" : "bg-white"
              }  border-stroke border-[0.5px] rounded-xl py-10 px-9 flex flex-col justify-between space-y-11`}
              key={index}
            >
              <div>
                <div className="flex items-center space-x-2 mb-8">
                  <p
                    className={`${
                      plan.type === "premium"
                        ? "text-coloured"
                        : "text-headings"
                    } text-xl  font-semibold`}
                  >
                    {plan.label}
                  </p>
                  <span className="bg-complementary-1-opaque-20 px-3 py-1 rounded text-complementary-1 font-semibold text-[0.625rem] tracking-[1.5px]">
                    MONTHLY
                  </span>
                </div>

                {plan.services.map((service, index) => (
                  <div key={index} className="flex items-center space-x-2 mb-4">
                    <img src={CheckIcon} alt="check" />
                    <p
                      className={`${
                        plan.type === "premium" ? "text-coloured" : "text-dark"
                      } text-sm font-medium`}
                    >
                      {service}
                    </p>
                  </div>
                ))}
              </div>

              <div>
                <p className="text-neutral font-semibold text-[0.625rem] tracking-[1.5px] mb-1">
                  TOTAL CHECKS
                </p>
                <p
                  className={`${
                    plan.type === "premium" ? "text-coloured" : "text-dark"
                  } text-xl font-semibold mb-9`}
                >
                  {plan.units}{" "}
                  <span className="text-accent-2 text-[0.625rem] font-semibold tracking-[1.5px]">
                    UNITS/MONTH
                  </span>
                </p>
                <p
                  className={`${
                    plan.type === "premium" ? "text-coloured" : "text-dark"
                  } font-semibold text-[1.75rem] mb-1`}
                >
                  {plan.price}
                  <span className="text-accent-2 text-[0.625rem] font-bold">
                    {plan.currency}
                  </span>
                </p>
                <p className="text-neutral tracking-[1.5px] font-semibold text-[0.625rem] mb-9">
                  VAT INCLUSIVE
                </p>

                <SmallButton
                  bg={`${
                    plan.type === "premium"
                      ? "var(--accent-1)"
                      : "var(--white-alt)"
                  }`}
                  textcolor="var(--primary)"
                  hovercolor="var(--white-alt)"
                  onClick={() =>
                    setSelectionModal({
                      open: true,
                      plan,
                    })
                  }
                  width="100%"
                >
                  Continue with {plan.label}
                </SmallButton>
              </div>
            </div>
          ))}
        </div>

        <CustomisePlanDrawer
          open={openCustomiseDrawer}
          onClose={() => setOpenCustomiseDrawer(false)}
        />
      </div>

      <SelectionModal
        isOpen={selectionModal.open}
        onClose={() =>
          setSelectionModal({
            open: false,
            plan: null,
          })
        }
        plan={selectionModal.plan}
      />

      <div
        onClick={() => navigate("/plans")}
        className="absolute top-5 right-10 flex space-x-2 cursor-pointer"
      >
        <p className="text-neutral font-semibold">Cancel</p>
        <img src={CloseIcon} alt="close" />
      </div>

      <Toaster position="top-center" visibleToasts={5} />
    </div>
  );
};

export default SubscriptionSection;
