import {
  Flex,
  FlexColumnCenter,
  ShadowCircle2,
} from "assets/styles/common-styles";
import Spinner from "components/common/Spinner";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  removeUnderscores,
} from "utils/common";
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import { ReactComponent as NoData } from "assets/images/no-data.svg";
import { SmallButton } from "components/button";
import {
  Card,
  CreditScoreWrapper,
  CustomerReportOverview,
  ReportReviewWrapper,
} from "assets/styles/customer-report-styles";
import RequestDirectorReportModal from "./RequestDirectorReportModal";
import { useState } from "react";

const ReportContent = ({
  singleCommercialCheck,
  tabsWithSubTabs,
  activeTab,
  activeSubTab,
  renderTabContent,
  setIsOpenList,
  singleCommercialCheckLoading,
  subTabIndex,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Card className={`${ReportReviewWrapper} report`}>
      {singleCommercialCheckLoading ? (
        <Spinner style={{ margin: "0.5rem auto" }} />
      ) : singleCommercialCheck &&
        (Array.isArray(singleCommercialCheck?.result?.[activeTab])
          ? singleCommercialCheck?.result?.[activeTab]?.some(
              (director) =>
                director &&
                Object.values(director).some((value) => value !== null)
            )
          : (activeTab !== "facility_performance" ||
              singleCommercialCheck?.result?.facility_performance) &&
            (activeTab !== "credit_agreement" ||
              singleCommercialCheck?.result?.credit_agreement) &&
            Object.keys(singleCommercialCheck?.result?.[activeTab])?.length >
              0) ? (
        <CustomerReportOverview className="flex">
          <div className="reports-wrapper">
            <p className="text-base-semibold card-header">
              {capitalizeFirstLetter(removeUnderscores(activeSubTab)) ||
                capitalizeFirstLetter(removeUnderscores(activeTab))}
            </p>
            {singleCommercialCheck?.result && (
              <section className="details-wrapper">
                <div className="row half">
                  {activeTab && renderTabContent()}
                </div>
              </section>
            )}
          </div>
          <div className="reports-sidebar">
            {activeTab === "general_information" && (
              <>
                {singleCommercialCheck?.result?.general_information
                  ?.no_of_directors >= 0 && (
                  <CreditScoreWrapper className="mb-7">
                    <ShadowCircle2 className="w-[8.125rem] h-[8.125rem] mx-auto">
                      <h4 className="heading-h2-mid !text-[2.625rem] headings">
                        {
                          singleCommercialCheck?.result?.general_information
                            ?.no_of_directors
                        }
                      </h4>
                    </ShadowCircle2>

                    <div className="text-center">
                      <p className="text-base">Number of company directors</p>
                    </div>
                  </CreditScoreWrapper>
                )}
                {singleCommercialCheck?.result?.general_information
                  ?.web_address && (
                  <div className="bg-[#F3F6F8] py-[2.125rem] px-5 text-center rounded-[0.5rem]">
                    <p className="text-base">Web address</p>
                    <p className="text-base-medium">
                      {
                        singleCommercialCheck?.result?.general_information
                          ?.web_address
                      }
                    </p>
                  </div>
                )}
              </>
            )}
            {activeTab === "directors_information" &&
              tabsWithSubTabs?.directors_information?.includes(activeSubTab) &&
              subTabIndex !== -1 &&
              singleCommercialCheck?.result?.directors_information[subTabIndex]
                ?.first_name !== null && (
                <>
                  <CreditScoreWrapper className="mb-7">
                    <ShadowCircle2 className="w-[8.125rem] h-[8.125rem] mx-auto">
                      <UserIcon
                        fill="var(--text-neutral)"
                        width="5rem"
                        height="5rem"
                      />
                    </ShadowCircle2>

                    <div className="text-center">
                      <p className="text-base-medium text-dark">
                        {`
                                ${singleCommercialCheck?.result?.directors_information[subTabIndex]?.first_name} ${singleCommercialCheck?.result?.directors_information[subTabIndex]?.surname}
                              `}
                      </p>
                      <p className="text-sm-semibold uppercase tracking-[1.2px] mt-px">
                        Director
                      </p>
                    </div>
                  </CreditScoreWrapper>
                  <SmallButton
                    bg="var(--accent-1)"
                    border="var(--accent-1)"
                    textcolor="var(--primary)"
                    onClick={() => setOpenModal(true)}
                  >
                    Request director's report
                  </SmallButton>

                  <RequestDirectorReportModal
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    first_name={
                      singleCommercialCheck?.result?.directors_information[
                        subTabIndex
                      ]?.first_name
                    }
                    last_name={
                      singleCommercialCheck?.result?.directors_information[
                        subTabIndex
                      ]?.surname
                    }
                    email={
                      singleCommercialCheck?.result?.directors_information[
                        subTabIndex
                      ]?.email
                    }
                    commercialID={singleCommercialCheck?.reference}
                    directorID={
                      singleCommercialCheck?.result?.directors_information[
                        subTabIndex
                      ]?.id
                    }
                  />
                </>
              )}
            {activeTab === "credit_agreement" &&
              tabsWithSubTabs?.credit_agreement?.includes(activeSubTab) &&
              subTabIndex !== -1 &&
              singleCommercialCheck?.result?.credit_agreement && (
                <>
                  {(singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                    ?.last_updated !== null ||
                    singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                      ?.last_updated !== "") && (
                    <div className={`${Flex} gap-4 mb-16`}>
                      <span className="text-base">Last updated</span>
                      <span className="text-base-medium darker">
                        {
                          singleCommercialCheck?.result?.credit_agreement[
                            subTabIndex
                          ]?.last_updated
                        }
                      </span>
                    </div>
                  )}
                  {singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                    ?.performance_status !== null &&
                    singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                      ?.performance_status !== "" && (
                      <div className="bg-[#F3F6F8] py-[2.125rem] px-5 text-center rounded-[0.5rem]">
                        <p className="text-base">Performance Status</p>
                        <div className="text-base-medium darker flex items-center justify-center gap-2">
                          <div
                            className={`status-ball ${
                              singleCommercialCheck?.result?.credit_agreement[
                                subTabIndex
                              ]?.performance_status === "PERFORMING" ||
                              singleCommercialCheck?.result?.credit_agreement[
                                subTabIndex
                              ]?.performance_status === "Performing"
                                ? "active"
                                : singleCommercialCheck?.result
                                    ?.credit_agreement[subTabIndex]
                                    ?.performance_status === "SUBSTANDARD" ||
                                  singleCommercialCheck?.result
                                    ?.credit_agreement[subTabIndex]
                                    ?.performance_status === "Substandard"
                                ? "pending"
                                : singleCommercialCheck?.result
                                    ?.credit_agreement[subTabIndex]
                                    ?.performance_status === "DOUBTFUL" ||
                                  singleCommercialCheck?.result
                                    ?.credit_agreement[subTabIndex]
                                    ?.performance_status === "Doubtful"
                                ? "complimentary"
                                : "error"
                            }`}
                          ></div>
                          {capitalizeWords(
                            singleCommercialCheck?.result?.credit_agreement[
                              subTabIndex
                            ]?.performance_status
                          )}
                        </div>
                      </div>
                    )}
                  {singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                    ?.account_status !== null &&
                    singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                      ?.account_status !== "" && (
                      <div className="bg-[#F3F6F8] py-[2.125rem] px-5 text-center rounded-[0.5rem] mt-7">
                        <p className="text-base">Account Status</p>
                        <div className="text-base-medium darker flex items-center justify-center gap-2">
                          <div
                            className={`status-ball ${
                              singleCommercialCheck?.result?.credit_agreement[
                                subTabIndex
                              ]?.account_status === "OPEN"
                                ? "active"
                                : singleCommercialCheck?.result
                                    ?.credit_agreement[subTabIndex]
                                    ?.account_status === "CLOSED"
                                ? "pending"
                                : "error"
                            }`}
                          ></div>
                          {capitalizeWords(
                            singleCommercialCheck?.result?.credit_agreement[
                              subTabIndex
                            ]?.account_status
                          )}
                        </div>
                      </div>
                    )}
                  {singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                    ?.payment_history &&
                    singleCommercialCheck?.result?.credit_agreement[subTabIndex]
                      ?.payment_history?.length > 0 && (
                      <SmallButton
                        bg="var(--accent-1)"
                        border="var(--accent-1)"
                        textcolor="var(--primary)"
                        width="100%"
                        className="mt-[3.375rem]"
                        onClick={() => setIsOpenList(true)}
                      >
                        See payment history
                      </SmallButton>
                    )}
                </>
              )}
            {activeTab === "facility_performance" &&
              singleCommercialCheck?.result?.highest_delinquency_rating !==
                null &&
              singleCommercialCheck?.result?.highest_delinquency_rating
                ?.rating && (
                <CreditScoreWrapper>
                  <ShadowCircle2 className="w-[8.125rem] h-[8.125rem] mx-auto">
                    <h4 className="heading-h2-mid !text-[2rem] headings">
                      {
                        singleCommercialCheck?.result
                          ?.highest_delinquency_rating?.rating
                      }
                    </h4>
                    <p className="text-xs-medium mt-[-4px]">DAYS</p>
                  </ShadowCircle2>
                  <div className="text-center">
                    <p className="text-base">Highest Delinquency Rating</p>
                  </div>
                </CreditScoreWrapper>
              )}
          </div>
        </CustomerReportOverview>
      ) : (
        <div className={`${FlexColumnCenter} empty-state`}>
          <NoData />
          <p className="text-headings font-semibold my-3">No data returned</p>
          <div className="w-[40%]">
            <p className="text-neutral text-center">
              No available credit or payment history found for this company.
            </p>
          </div>
        </div>
      )}
    </Card>
  );
};

export default ReportContent;
