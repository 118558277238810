import { FlexRowBetween } from "assets/styles/common-styles";
import { Card, CustomerReport } from "assets/styles/customer-report-styles";
import { SmallButton } from "components/button";

const SmallCard = ({ cardTitle, cardDesc, btnText, btnClick, seeAll, seeAllLink, disabled }) => {
  return (
    <Card className={CustomerReport}>
      <div className={FlexRowBetween}>
        <p className="text-base-semibold card-header line-height-normal">{cardTitle}</p>
        {seeAll && (
          <p className="text-base-semibold accent-2 cursor-pointer" onClick={seeAllLink}>See all</p>
        )}
      </div>
      <p className="text-base grow-1">{cardDesc}</p>
      <SmallButton
        bg="var(--accent-1)"
        border="var(--accent-1)"
        textcolor="var(--primary)"
        onClick={btnClick}
        disabled={!disabled}
      >
        {btnText}
      </SmallButton>
    </Card>
  );
};

export default SmallCard;