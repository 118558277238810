import ConsentFlowSection from 'components/consentFlow/ConsentFlowSection';
import { useSearchParams } from 'react-router-dom';

const ConsentFlowPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <div>
      <ConsentFlowSection referenceHash={searchParams.get('hash')} />
    </div>
  );
};

export default ConsentFlowPage;
