import { minDesktop, mobile, tablet } from "globalStyles";
import { styled } from "goober";

export const CountrySelectWrapper = styled("main")`
  padding: 4.1875rem 7.5625rem 4.1875rem 7.5rem;
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  gap: 3rem;
  .header {
    gap: 1rem;
    h2 {
      color: var(--headings);
    }
  }
  .sub-heading {
    gap: 1rem;
    margin-bottom: 3.75rem;
    > :nth-child(1) {
      flex: 0.65;
    }
    > :nth-child(2) {
      flex: 1;
      text-align: right;
    }
  }
  .country-list-wrapper {
    .title {
      color: var(--text-dark);
      margin-bottom: 3.5rem;
    }
    .country-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4.25rem;
      max-height: 16.75rem;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    ${minDesktop} {
      width: 55%;
      margin: 0 auto;
    }
  }
  .country {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.5s;
    gap: 1rem;
    cursor: pointer;
    max-width: 6.875rem;
    width: 100%;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .country-card {
      border: 4px solid var(--white-alt);
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      min-height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.3s, height 0.3s, width 0.3s;
      &.selected {
        border: 4px solid var(--accent-2);
      }
    }
    img {
      width: 3.5rem;
      height: 3.5rem;
      min-width: 3.5rem;
      min-height: 3.5rem;
      border-radius: 50%;
      transition: transform 0.5s;
    }
  
    p {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: var(--text-neutral);
    }
    &.selected {
      p {
        font-weight: 600;
        color: var(--headings);
      }
    }
  }
  .set-reminder {
    cursor: pointer;
    color: var(--accent-2);
    line-height: normal;
    svg {
      fill: var(--accent-2);
      display: inline-block;
      vertical-align: middle;
    }
  }
  ${tablet} {
    padding: 3rem;
  }
  ${mobile} {
    padding: 2rem;
    .sub-heading {
      flex-direction: column;
      > :nth-child(1) {
        flex: 1;
      }
      > :nth-child(2) {
        flex: 1;
        text-align: left;
        width: 100%;
      }
    }
    
  }
`;