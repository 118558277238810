import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const SubmitConsentData = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK - credit
        </div>
      ),
      key: "200-credit",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK - Financial
        </div>
      ),
      key: "200-financial",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          404 Error
        </div>
      ),
      key: "404",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          400 Error
        </div>
      ),
      key: "400",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    "200-credit": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "reference": "qiLx7PkqGTtGhhU",
        "id_number": "1232490394",
        "account_number": "0131883461",
        "account_name": "john Doe",
        "bank_code": "058",
        "bvn": "2232323",
        "date_of_birth": "1990-01-02"
      },
      response: {
        "message": "Consent received successfully."
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK - Credit:</span> This indicates successful submission of consent data specifically for credit-related information.
        </>
      ),
    },
    "200-financial": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "reference": "qiLx7PkqGTtGhhU",
        "id_number": "1232490394",
        "account_number": "0131883461",
        "account_name": "john Doe",
        "bank_code": "058",
        "bvn": "2232323",
        "date_of_birth": "1990-01-02"
    },
      response: {
        "message": "Consent received successfully."
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK - Financial:</span> This signals successful submission of consent data for a comprehensive financial assessment.
        </>
      ),
    },
    "404": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "reference": "qiLx7PkqGTtGhhU",
        "id_number": "1232490394",
        "account_number": "0131883461",
        "account_name": "john Doe",
        "bank_code": "058",
        "bvn": "2232323",
        "date_of_birth": "1990-01-02"
      },
      response: {
        "message": "Assessment not found"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This indicates that the consent data endpoint being accessed was not found.
        </>
      ),
    },
    "400": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "reference": "qiLx7PkqGTtGhhU",
        "id_number": "1232490394",
        "account_number": "0131883461",
        "account_name": "john Doe",
        "bank_code": "058",
        "bvn": "2232323",
        "date_of_birth": "1990-01-02"
      },
      response: {
        "message": "Incorrect ID number entered, please check and try again"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">400 Error:</span> This suggests a bad request, indicating that the server cannot process the request due to inputted error.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Submit consent data"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/assessments/submit-consent-data"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default SubmitConsentData;
