import React, { useEffect, useState } from "react";
import PassportNumberModal from "./PassportNumberModal";
import FloatingTextField from "components/formFields/FloatingTextField";
import Checkbox from "components/formFields/Checkbox";
import { MediumButton } from "components/button";
import ReportAuthorizationFormModal from "./ReportAuthorizationFormModal";
import { useSnapshot } from "valtio";
import store from "store";
import Spinner from "components/common/Spinner";
import SelectField from "components/formFields/SelectField";
import { Toaster, toast } from "sonner";
import Toast from "components/common/ToastNotification";
import SuccessBadge from "assets/images/badge.svg";
import { useNavigate } from "react-router-dom";
import BVNMismatchModal from "./BVNMismatchModal";

const ConsentFlowSection = ({ referenceHash }) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [openCreditFormModal, setOpenCreditFormModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [decodedHash, setDecodedHash] = useState("");
  const [termsInfo, setTermsInfo] = useState({});
  const [consentAgree, setConsentAgree] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [referenceFailed, setReferenceFailed] = useState({
    status: "",
    message: "",
  });
  const [hasDirector, setHasDirector] = useState(null);
  const [id_data, setIdData] = useState();
  const [showBVNMismatchModal, setShowBVNMismatchModal] = useState(false);

  const consentSnapshot = useSnapshot(store.consent);
  const loading = consentSnapshot?.verifyReferenceLoading;
  const referenceData = consentSnapshot?.referenceData;
  const termsLoading = consentSnapshot?.termsLoading;
  const createConsentLoading = consentSnapshot?.createConsentLoading;
  const termsData = consentSnapshot?.termsData;

  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      localStorage.removeItem("id_data");
      return (ev.returnValue = "Are you sure you want to close?");
    });
  }, []);

  useEffect(() => {
    if (termsAccepted && consentAgree) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [termsAccepted, consentAgree]);

  useEffect(() => {
    if (Object.keys(termsInfo).length === 0) {
      const initialObject = {};

      id_data?.fields?.forEach((field) => {
        initialObject[field.field_name] = "";
      });

      setTermsInfo(initialObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIdData(JSON.parse(localStorage.getItem("id_data")));
    }, 1);
  }, [hasDirector]);

  useEffect(() => {
    const formattedHash = referenceHash.replaceAll("%3D", "=");
    const decodedString = atob(formattedHash);

    const values = decodedString.split("&");

    setDecodedHash(values);
  }, [referenceHash]);

  useEffect(() => {
    const verifyReference = async () => {
      const body = {
        reference: decodedHash && decodedHash[0]?.split("=")[1],
        env: decodedHash && decodedHash[1]?.split("=")[1],
      };

      const directorBody = {
        reference: decodedHash && decodedHash[0]?.split("=")[1],
        env: decodedHash && decodedHash[1]?.split("=")[1],
        director: decodedHash && decodedHash[2]?.split("=")[1],
      };

      const response = await store?.consent?.verifyReference(
        decodedHash[2]?.split("=")[1] === "yes" ? directorBody : body
      );
      if (response?.status >= 400) {
        setReferenceFailed({
          status: true,
          message: response?.data?.message,
        });
        return;
      } else {
        if (decodedHash[2]?.split("=")[1] === "yes") {
          setHasDirector(true);
          localStorage.setItem("id_data", JSON.stringify(response));
        } else {
          setOpenModal(true);
        }
      }
    };

    if (id_data === null && decodedHash.length) {
      verifyReference();
    }
  }, [decodedHash, id_data]);

  const renderErrorMessages = (errors) => {
    return Object.keys(errors)
      .map((fieldName) =>
        errors[fieldName].map((errorMessage, index) => ({
          field: fieldName,
          message: errorMessage,
          key: `${fieldName}-${index}`,
        }))
      )
      .flat();
  };

  const fetchTerms = async () => {
    const body = {
      user_country_code: id_data?.assessment?.user_country_code,
      reference: decodedHash && decodedHash[0]?.split("=")[1],
      env: decodedHash && decodedHash[1]?.split("=")[1],
      id_number: id_data?.assessment?.id_number,
      ...termsInfo,
    };

    const response = await store?.consent?.fetchTerms(body);

    if (response?.status >= 400) {
      const errors = renderErrorMessages(response?.data?.errors);

      toast.custom((t) => (
        <Toast
          toastType="error"
          text={errors[0].message}
        />
      ));

      return;
    } else {
      setOpenCreditFormModal(true);
    }
  };

  const createConsent = async () => {
    const body = {
      user_country_code: id_data?.assessment?.user_country_code,
      reference: decodedHash && decodedHash[0]?.split("=")[1],
      env: decodedHash && decodedHash[1]?.split("=")[1],
      id_number: id_data?.assessment?.id_number,
      ...termsInfo,
    };

    const response = await store?.consent?.createConsent(body);

    if (response?.data?.message === "Name and BVN mismatch") {
      setShowBVNMismatchModal(true);
    } else if (response?.status >= 400) {
      toast.custom((t) => (
        <Toast
          toastType="error"
          text={response?.data?.message}
        />
      ));

      return;
    } else {
      if (hasDirector && response?.connect_url) {
        navigate(
          `/consent/thank-you?has_director=true&connect_url=${response?.connect_url}`
        );
      } else if (hasDirector) {
        navigate(`/consent/thank-you?has_director=true`);
      } else if (response?.connect_url) {
        navigate(`/consent/thank-you?connect_url=${response?.connect_url}`);
      } else {
        navigate(`/consent/thank-you`);
      }
      localStorage.removeItem("id_data");
    }
  };

  if (loading || termsLoading)
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );

  if (referenceFailed.status)
    return (
      <div className="bg-alt min-h-screen flex items-center justify-center">
        <div className="bg-white p-10 rounded-xl w-[512px] h-[512px] flex flex-col items-center justify-center">
          <img
            src={SuccessBadge}
            alt="success"
            className="mb-12"
          />
          <p className="font-secondary text-headings font-semibold text-3xl mb-6">
            Thank you
          </p>
          <p className="text-center">{referenceFailed.message}</p>
        </div>
      </div>
    );

  return (
    <div className="relative bg-alt min-h-screen flex items-center justify-center sm:py-20">
      <div className="bg-white py-10 px-6 sm:px-20 rounded-xl">
        <div className="mb-8">
          <p className="mb-2">Full name</p>
          <p className="text-headings font-semibold text-xl sm:text-3xl font-secondary">
            {id_data?.assessment?.first_name} {id_data?.assessment?.middle_name}{" "}
            {id_data?.assessment?.last_name}
          </p>
        </div>

        <div className="sm:grid grid-cols-3 space-y-5 sm:space-y-0 sm:gap-10 mb-8">
          <div>
            <p className="mb-1">Country</p>
            <p className="text-dark">Nigeria</p>
          </div>
          <div>
            <p className="mb-1">{referenceData?.id_name} number</p>
            <p className="text-dark">{id_data?.assessment?.id_number}</p>
          </div>
          <div>
            <p className="mb-1">Phone</p>
            <p className="text-dark">{id_data?.assessment?.phone_number}</p>
          </div>
        </div>

        <div className="mb-9 space-y-7">
          {id_data?.fields?.map((field, idx) =>
            field.type === "text" ? (
              <div key={idx}>
                <FloatingTextField
                  key={idx}
                  label={field.placeholder}
                  type="text"
                  id={field.field_name}
                  name={field.field_name}
                  labelfor={field.field_name}
                  value={termsInfo[field.field_name]}
                  onChange={(e) =>
                    setTermsInfo({
                      ...termsInfo,
                      [field.field_name]: e.target.value,
                    })
                  }
                />
              </div>
            ) : field.type === "number" ? (
              <div key={idx}>
                <FloatingTextField
                  key={idx}
                  label={field.placeholder}
                  type="number"
                  id={field.field_name}
                  name={field.field_name}
                  labelfor={field.field_name}
                  value={termsInfo[field.field_name]}
                  onChange={(e) =>
                    setTermsInfo({
                      ...termsInfo,
                      [field.field_name]: e.target.value,
                    })
                  }
                />
              </div>
            ) : field.type === "select" ? (
              <div key={idx}>
                <SelectField
                  key={idx}
                  label={field.placeholder}
                  options={field.data.map((data) => {
                    return {
                      value: data.bank_name,
                      key: data.bank_code,
                    };
                  })}
                  value={termsInfo.bank_name}
                  onChange={(e) => {
                    setTermsInfo({
                      ...termsInfo,
                      [field.field_name]: e.target.key,
                      bank_name: e.target.value,
                    });
                  }}
                />
              </div>
            ) : field.type === "date" ? (
              <div key={idx}>
                <FloatingTextField
                  key={idx}
                  // label={field.placeholder}
                  type="date"
                  id={field.field_name}
                  name={field.field_name}
                  labelfor={field.field_name}
                  value={termsInfo[field.field_name]}
                  onChange={(e) =>
                    setTermsInfo({
                      ...termsInfo,
                      [field.field_name]: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>

        <div className="space-y-4">
          <Checkbox
            checked={consentAgree}
            disabled={termsLoading}
            onChange={fetchTerms}
            label={
              <span className="font-bold">
                I consent to sharing my report with a third party
              </span>
            }
          />

          <Checkbox
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            label={
              <span>
                I have read and agree with Maxim's{" "}
                <a
                  href="https://trymaxim.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  className="text-accent-2"
                >
                  Terms
                </a>
              </span>
            }
          />
        </div>

        <div className="flex justify-end mt-9">
          {createConsentLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              hovercolor="var(--accent-1)"
              onClick={createConsent}
              disabled={disabled}
            >
              Submit
            </MediumButton>
          )}
        </div>
      </div>
      <PassportNumberModal
        isOpen={openModal}
        IDName={referenceData?.id_name}
        reference={decodedHash && decodedHash[0].split("=")[1]}
        env={decodedHash && decodedHash[1]?.split("=")[1]}
        onClose={() => setOpenModal(false)}
        setHasDirector={setHasDirector}
      />
      <ReportAuthorizationFormModal
        isOpen={openCreditFormModal}
        onClose={() => setOpenCreditFormModal(false)}
        termsData={termsData}
        agreeFn={() => setConsentAgree(true)}
        disagreeFn={() => setConsentAgree(false)}
      />
      <BVNMismatchModal
        isOpen={showBVNMismatchModal}
        onClose={() => setShowBVNMismatchModal(false)}
      />
      <Toaster
        position="top-center"
        visibleToasts={5}
      />
    </div>
  );
};

export default ConsentFlowSection;
