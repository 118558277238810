import React, { useEffect, useState } from 'react';
import { FloatingLabelWrapper, InputLabel } from 'components/formFields/formStyles';

const withFloatingLabel = (WrappedComponent) => ({ label, labelfor, value, fullwidth, style, disabled, ...rest }) => {
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(Boolean(value));

  useEffect(() => {
    setHasValue(Boolean(value));
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleChange = (event) => {
    setHasValue(Boolean(event.target.value));
  };

  const inputProps = {
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    value: value,
    ...rest
  };

  return (
    <FloatingLabelWrapper
      className={`${focused || hasValue ? 'focused' : ''} ${fullwidth ? 'full-width' : ''} ${disabled ? 'disabled' : ''}`}
      style={style}
    >
      <WrappedComponent {...inputProps} />
      <InputLabel labelfor={labelfor} className={`${focused || hasValue ? 'active' : ''}`}>
        {label}
      </InputLabel>
    </FloatingLabelWrapper>
  );
};

export default withFloatingLabel;
