import { MediumButton } from 'components/button';
import Spinner from 'components/common/Spinner';
import Toast from 'components/common/ToastNotification';
import FloatingTextField from 'components/formFields/FloatingTextField';
import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import React, { useEffect, useState } from 'react';
import store from 'store';
import { useSnapshot } from 'valtio';
import { toast } from 'sonner';

const AdditionalEmailsModal = ({ isOpen, onClose }) => {
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [email3, setEmail3] = useState('');
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const isLoading = subscriptionSnapshot?.updateAdditionalEmailsLoading;
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (email1.length <= 0 && email2.length <= 0 && email3.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [email1, email2, email3]);

  const addEmailsFn = async () => {
    const body = {
      emails: [
        email1.length > 0 ? email1 : '',
        email2.length > 0 ? email2 : '',
        email3.length > 0 ? email3 : '',
      ],
    };

    const response = await store?.subscription.updateAdditionalEmails(body);

    if (response?.errors) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={response?.message}
        />
      ));
    } else {
      onClose();
      toast.custom((t) => (
        <Toast
          toastType='success'
          text='Email updated successfully'
        />
      ));
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        <div className='flex flex-col items-center h-[27rem] w-full'>
          <p className='text-headings font-secondary text-[1.75rem] font-semibold mb-11'>
            Manage additional emails
          </p>

          <div className='flex flex-col w-full space-y-8'>
            <FloatingTextField
              label='Email address 2'
              type='email'
              id='email-address2'
              name='email-address2'
              labelfor='email-address2'
              value={email1}
              onChange={(e) => setEmail1(e.target.value)}
            />

            <FloatingTextField
              label='Email address 3'
              type='email'
              id='email-address3'
              name='street-address3'
              labelfor='street-address3'
              value={email2}
              onChange={(e) => setEmail2(e.target.value)}
            />

            <FloatingTextField
              label='Email address 4'
              type='email'
              id='email-address4'
              name='email-address4'
              labelfor='email-address4'
              value={email3}
              onChange={(e) => setEmail3(e.target.value)}
            />
          </div>

          <div className='flex items-center justify-between w-full mt-12'>
            <MediumButton
              bg='var(--white-alt)'
              border='var(--white-alt)'
              textcolor='var(--primary)'
              hovercolor='var(--white-alt)'
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {isLoading ? (
              <Spinner style={{ margin: '0.5rem 0' }} />
            ) : (
              <MediumButton
                bg='var(--accent-1)'
                border='var(--accent-1)'
                textcolor='var(--primary)'
                hovercolor='var(--accent-1)'
                onClick={addEmailsFn}
                disabled={disabled || isLoading}
              >
                Save changes
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default AdditionalEmailsModal;
