import React from "react";
import { SmallButton } from "components/button";
import { toast } from "sonner";
import { FlexCol, FlexColumnCenter } from "assets/styles/common-styles";
import { Upload } from "antd";
import Toast from "components/common/ToastNotification";
const { Dragger } = Upload;

const csvFileType = ".csv";

const UploadCSV = ({ onFileUpload }) => {
  const props = {
    name: "file",
    multiple: false,
    onDrop(e) {
    },
    checkFile(file) {
      const isCSV = file.name.toLowerCase().endsWith(csvFileType);
      if (!isCSV) {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={"You can only upload a CSV file!"}
          />
        ));
      }
      return isCSV;
    },
    customRequest({ file, onSuccess, onError }) {
      try {
        onFileUpload(file);
        setTimeout(() => {
          toast.custom((t) => (
            <Toast
              toastType='success'
              text={"Upload successful!"}
            />
          ));
          onSuccess();
        }, 1000);
      } catch (error) {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={`"Error during file upload:" ${error}`}
          />
        ));
        onError();
      }
    },
    accept: csvFileType,
    maxCount: 1,
  };

  return (
    <Dragger {...props}>
      <div className={`text-center py-4 gap-2 ${FlexColumnCenter} ${FlexCol}`}>
        <p className="text-base-medium text-neutral">Upload filled template</p>
        <p className="text-md-medium text-muted">
          Your filled copy in CSV format, not more than 10MB
        </p>
        <div className="mx-auto mt-4">
          <SmallButton
            bg="transparent"
            border="var(--stroke)"
            textcolor="var(--text-neutral)"
            hovercolor="var(--stroke)"
          >
            Browse File
          </SmallButton>
        </div>
      </div>
    </Dragger>
  );
};
export default UploadCSV;
