import { DashboardWrapper } from 'assets/styles/dashboard-styles';
import MainLayout from 'components/mainLayout';
import PlansAndBillingSection from 'components/Plans-Billing/Plans/PlansAndBillingSection';
import SubLayout from 'components/subLayouts';

const PlansAndBillingPage = () => {
  return (
    <MainLayout pageHeader='Plans and Billing'>
      <DashboardWrapper>
        <SubLayout element={<PlansAndBillingSection />} />
      </DashboardWrapper>
    </MainLayout>
  );
};

export default PlansAndBillingPage;
