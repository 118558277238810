import React, { useCallback, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Toaster, toast } from "sonner";
import store from "store";
import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import PlusIcon from "assets/images/icons/plus.svg";
import MinusIcon from "assets/images/icons/minus.svg";
import { debounce, formatNumberWithCommas } from "utils/common";
import Toast from "components/common/ToastNotification";

const TopupUnitModal = ({ isOpen, onClose }) => {
  const [units, setUnits] = useState(1);
  const [pricePerUnit, setPricePerUnit] = useState(null);
  const [amountToPay, setAmountToPay] = useState(0);
  const snap = useSnapshot(store.profileCheck);
  const pricePerUnits = snap?.pricePerUnits;
  const pricePerUnitsLoading = snap?.pricePerUnitsLoading;
  const referenceDetails = snap?.referenceDetails;
  const referenceDetailsLoading = snap?.referenceDetailsLoading;
  const miscSnapshot = useSnapshot(store.misc);
  const environment = miscSnapshot?.environment;

  const user = JSON.parse(localStorage.getItem("user"));
  const [flwResponse, setflwResponse] = useState(null);
  const [reference, setReference] = useState("");

  const getPricePerUnitsDebounced = debounce(() => {
    if (units > 0) {
      store?.profileCheck?.getPricePerUnits(units);
    }
  }, 500);

  useEffect(() => {
    getPricePerUnitsDebounced();
    // Cleanup function to clear any pending debounce call when component unmounts
    return () => getPricePerUnitsDebounced.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  useEffect(() => {
    setReference(referenceDetails?.reference);
  }, [referenceDetails?.reference]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await store?.profileCheck?.getPricePerUnits(1);
      if (response?.amount) {
        setPricePerUnit(
          formatNumberWithCommas(response?.amount || 0) + response?.currency
        );
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (pricePerUnits) {
      setAmountToPay(pricePerUnits?.amount);
    }
  }, [pricePerUnits]);

  const makePayment = useCallback(() => {
    // eslint-disable-next-line no-undef
    const modal = FlutterwaveCheckout({
      public_key:
        environment?.environment === "sandbox"
          ? process.env.REACT_APP_FLUTTERWAVE_PUB_KEY_SANDBOX
          : process.env.REACT_APP_FLUTTERWAVE_PUB_KEY,
      tx_ref: reference,
      amount: pricePerUnits?.amount,
      currency: pricePerUnits?.currency,
      payment_options: "card",
      customer: {
        email: user?.email,
      },
      customizations: {
        title: `User ID Verification units`,
        description: `${pricePerUnits?.amount} per unit.`,
      },
      callback: function (response) {
        setflwResponse(response);
        modal.close();
      },
    });
  }, [
    environment?.environment,
    reference,
    pricePerUnits?.amount,
    pricePerUnits?.currency,
    user?.email,
  ]);

  useEffect(() => {
    if (reference?.length > 0) {
      makePayment();
    }
  }, [makePayment, onClose, reference]);

  useEffect(() => {
    if (flwResponse?.status === "successful") {
      setflwResponse(null);
      toast.custom((t) => (
        <Toast toastType="success" text="Payment successful!" />
      ));
      onClose();
      setTimeout(() => {
        window.location.href = "/user-id-verification";
      }, 2000);
    }
  }, [flwResponse?.status, onClose]);

  const generateReference = async (volumes) => {
    try {
      const response = await store.profileCheck.generateReference(volumes);
      if (response && !response?.reference) {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message} />
        ));
      } else if (response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType="error"
                text={errorMessage}
              />
            ));
          });
        });
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        <div className="flex flex-col items-center justify-between h-[27rem] w-full">
          <div className="bg-primary-darkest rounded-xl pt-[2.375rem] pb-[1.625rem] px-6 mb-[4.375rem] flex flex-col items-center w-full">
            <p className="coloured font-secondary text-[1.75rem] font-semibold mb-2.5">
              User ID Verification units
            </p>
            <p className="text-sm-bold-spaced text-center accent-1">
              {pricePerUnit} PER UNIT
            </p>
          </div>

          <div className="mb-[2rem] w-full">
            <div className="flex justify-between items-center py-2 px-2 w-full border border-stroke rounded-2xl">
              <button
                className="p-2 bg-alt rounded-lg mr-5"
                onClick={() => {
                  if (units <= 1) return;
                  setUnits((prevVal) => prevVal - 1);
                }}
              >
                <img src={MinusIcon} alt="minus" />
              </button>
              <input
                className="flex-1 border-0 outline-0 text-base"
                placeholder="Enter units (E.g 100)"
                type="number"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
              />
              <button
                className="p-2 bg-alt rounded-lg"
                onClick={() => setUnits((prevVal) => +prevVal + 1)}
              >
                <img src={PlusIcon} alt="plus" />
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between w-full">
            <p className="text-xs font-semibold text-neutral tracking-[1.8px]">
              AMOUNT TO PAY
            </p>

            {pricePerUnitsLoading ? (
              <Spinner style={{ margin: "0.5rem 0" }} />
            ) : (
              <p className="text-[1.75rem] text-dark font-semibold">
                {formatNumberWithCommas(amountToPay)}
                <span className="text-accent-2 text-[0.625rem] font-bold tracking-[1.5px] ml-1">
                  {pricePerUnits?.currency}
                </span>
              </p>
            )}
          </div>

          <div className="flex items-center justify-between w-full mt-12">
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {referenceDetailsLoading ? (
              <Spinner style={{ margin: "0.5rem 0" }} />
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                hovercolor="var(--accent-1)"
                onClick={() => generateReference(units)}
                disabled={units <= 0}
              >
                Proceed to payment
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
      <Toaster position="top-center" visibleToasts={1} />
    </Modal>
  );
};

export default TopupUnitModal;
