import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/modals";
import { MediumButton } from "components/button";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import {
  Flex,
  FlexCenter,
  FlexCol,
  FlexColumnCenter,
} from "assets/styles/common-styles";
import { ReactComponent as StarCheck } from "assets/images/icons/star-check.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user-outline.svg";

const RequestReportSuccessModal = ({ isOpen, onClose, count }) => {
  const navigate = useNavigate();

  const navigateToCustomerReports = () => {
    navigate("/consumer-reports");
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper
        className={`request-verification-success ${FlexColumnCenter}`}
      >
        <div className={`${FlexCol} header`}>
          <StarCheck />
          <h2 className="modal-title">Request sent successfully</h2>
        </div>
        <section className={`${FlexCol} info`}>
          <p className="text-base">
            A consumer report for{" "}
            <span className="text-base-medium dark">{count}</span> persons have
            been requested. Result typically takes 1 - 24hrs. You can track
            results via the{" "}
            <span className="text-base-medium dark">Consumer Check </span>
            page
          </p>
        </section>
        <MediumButton
          bg="var(--accent-1)"
          textcolor="var(--primary)"
          onClick={() => onClose()}
        >
          Okay, got it
        </MediumButton>
        <div
          className={`text-base-semibold accent-2 cursor-pointer ${Flex} gap-2`}
          onClick={() => navigateToCustomerReports()}
        >
          <div
            className={`bg-accent-2 w-6 h-6 rounded-[50%] p-x ${FlexCenter}`}
          >
            <UserIcon stroke="var(--white)" />
          </div>
          Go to Consumer Check
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default RequestReportSuccessModal;
