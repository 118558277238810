import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { FlexColumnBetween } from 'assets/styles/common-styles';
import { ReactComponent as Envelope } from "assets/images/icons/envelope.svg";

const LaunchNotificationSubmissionSuccess = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper className={`request-sent ${FlexColumnBetween}`}>
        <Envelope />
        <div>
          <h2 className="modal-title">Submission successful</h2>
          <p className="text-base">Thank you for letting us know your business region. We will be in touch.</p>
        </div>
        <Link to="/select-country">
          <MediumButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            onClick={onClose}
            style={{maxWidth: "initial"}}
          >
            Return to Maxim website
          </MediumButton>
        </Link>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default LaunchNotificationSubmissionSuccess;
