import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import Toast from "components/common/ToastNotification";
import store from "store";
import FloatingTextField from "components/formFields/FloatingTextField";
import { FullWidthButton } from "components/button";
import { AuthForm } from "assets/styles/authpage-styles";
import { validateFormData } from "utils/validateFormData";
import Spinner from "components/common/Spinner";
import { Flex } from "assets/styles/common-styles";

const ResetPassword = ({ isResetEmail, onResetPassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);
  const [newResetDetails, setNewResetDetails] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    email: isResetEmail.email,
    code: "",
    password: "",
    password_confirmation: "",
    reference: newResetDetails.reference || isResetEmail.reference
  });
  const [passwordValidation, setPasswordValidation] = useState({
    isLengthValid: false,
    hasUppercase: false,
    hasNumberAndSymbol: false,
  });

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  if (!selectedValue.email) {
    return <Navigate to="/auth/forgot-password" />;
  }

  const isPasswordValid = (password) => {
    // At least 8 characters
    const isLengthValid = password.length >= 8;

    // One uppercase letter
    const hasUppercase = /[A-Z]/.test(password);

    // One number and one symbol
    const hasNumberAndSymbol = /(?=.*\d)(?=.*\W)/.test(password);

    return { isLengthValid, hasUppercase, hasNumberAndSymbol };
  };

  const renderIndicator = (isValid) => (
    <div className={`password-indicator ${isValid ? 'bg-accent-2' : 'bg-pending'}`} />
  );

  const handlePasswordChange = (name, e) => {
    const value = e.target.value;
    const { isLengthValid, hasUppercase, hasNumberAndSymbol } = isPasswordValid(value);

    setSelectedValue({ ...selectedValue, [name]: value });
    setPasswordValidation({ isLengthValid, hasUppercase, hasNumberAndSymbol });
  };

  const handleResendResetCode = async () => {
    setIsResendCodeLoading(true);
    const data =  {
      email: selectedValue.email,
    };
    try {
      const response = await store.auth.postRequestPasswordReset(data);
      if (response && (response?.data?.reference || response?.reference)) {
        toast.custom((t) => (
          <Toast toastType="success" text={response?.message} />
        ));
        const newData = {
          reference: response?.data?.reference || response?.reference
        };
        setNewResetDetails(newData);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsResendCodeLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });    

    const data =  {
      email: selectedValue.email,
      code: selectedValue.code,
      password: selectedValue.password,
      password_confirmation: selectedValue.password_confirmation,
      reference: selectedValue.reference
    };
    const validationRules = {
      email: [{ required: true }],
      code: [{ required: true }],
      password: [{ required: true }],
      password_confirmation: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }
    try {
      const response = await store.auth.postResetPassword(data);
      if (response && response?.message === "Password Reset Successful.") {
        onResetPassword(response?.message);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className="container">
      <section className="header reset-password-header">
        <h2 className="heading-h2">Create a new password</h2>
        <p className="text-base">We sent a reset code to your email.{" "}
          <span className="text-base-semibold resend-code" onClick={()=> handleResendResetCode()}>
            {isResendCodeLoading ? "Resending" : "Resend code"}
          </span>
        </p>
      </section>
      <AuthForm onSubmit={handleSubmit}>
        <FloatingTextField
          label="Enter code"
          type="text"
          id="code"
          name="code"
          labelfor="code"
          value={selectedValue.code}
          onChange={(e) => handleChange("code", e)}
          maxLength="6"
        />
        <FloatingTextField
          label="Enter new password"
          type="password"
          id="password"
          name="password"
          labelfor="password"
          value={selectedValue.password}
          onChange={(e) => handlePasswordChange("password", e)}
        />
        <FloatingTextField
          label="Re-enter new password"
          type="password"
          id="password_confirmation"
          name="password_confirmation"
          labelfor="password_confirmation"
          value={selectedValue.password_confirmation}
          onChange={(e) => handleChange("password_confirmation", e)}
        />
        <section className="flex flex-col password-info">
          <div className={`${Flex} gap-2 text-md`}>{renderIndicator(passwordValidation.isLengthValid)} At least 8 characters</div>
          <div className={`${Flex} gap-2 text-md`}>{renderIndicator(passwordValidation.hasUppercase)} One uppercase letter</div>
          <div className={`${Flex} gap-2 text-md`}>{renderIndicator(passwordValidation.hasNumberAndSymbol)} One number and one symbol</div>
        </section>
        {isLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }}/>
        ) : (
          <FullWidthButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
            className="reset-password-btn"
            width="100%"
            type="submit"
          >
            Reset password
          </FullWidthButton>
        )}
      </AuthForm>
      <section className="base">
        <p className="text-base">Need help? <Link to="#">Contact Support</Link> or <Link to="/auth/login">Log in</Link></p>
      </section>
      <Toaster position="top-center" visibleToasts={5} />
    </section>
  );
}

export default ResetPassword;
