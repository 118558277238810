import { Flex, FlexCol } from "assets/styles/common-styles";
import {
  MainPageContent,
  MainPageTitle,
} from "assets/styles/docs-styles";
import DocsLayout from "components/api-docs/layout";
import SideContent from "components/api-docs/layout/SideContent";

const ApiDocsDefinition = () => {
  return (
    <DocsLayout>
      <MainPageTitle className={Flex}>
        <h2 className="heading-h1 headings">Definition</h2>
      </MainPageTitle>
      <MainPageContent>
        <section className={`${FlexCol} gap-8`}>
          <div>
            <p className="text-base-semibold text-dark mb-3">Countries</p>
            <p className="leading-[1.75rem]">This provides a list of countries Maxim is activated.</p>
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">
              Data Points List
            </p>
            <p className="leading-[1.75rem]">
              The Data Points list refers to pieces of information that can be
              used for analysis. It allows users to retrieve available
              information for decision-making.
            </p>
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">Consumer Check</p>
            <p className="leading-[1.75rem]">
              This check allows Service Providers to get reports (Credit &
              Financial) on their customers before granting them access to their
              services or products.
            </p>
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">
              Commercial Check
            </p>
            <p className="leading-[1.75rem]">
              This allows Service Providers to get reports (General & Credit)
              about the company they are about to do business with.
            </p>
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">
              User ID Verification
            </p>
            <p className="leading-[1.75rem]">
              This allows the Service Providers to get data insight on their
              customers or staff.{" "}
            </p>
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">Webhook</p>
            <p className="leading-[1.75rem]">
              This allows one system to send real-time data to another system as
              soon as an event occurs. It is a way for Maxim applications to
              communicate with other applications by triggering automated
              actions.
            </p>
          </div>
        </section>
        <SideContent />
      </MainPageContent>
    </DocsLayout>
  );
};

export default ApiDocsDefinition;
