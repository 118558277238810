import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Login from "components/auth/Login";
import CreateAccount from "components/auth/CreateAccount";
import VerifyAccount from "components/auth/VerifyAccount";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import Logo from "assets/images/logo-coloured.svg";
import SignupIllustration from "assets/images/signup-illustration.svg";
import {
  AuthFormContainer,
  AuthPageWrapper,
} from "assets/styles/authpage-styles";

const AuthPage = () => {
  const { state } = useParams();
  const navigate = useNavigate();
  const [isResetEmail, setIsResetEmail] = useState(false);
  const [country, setCountry] = useState("");
  const [referenceData, setReferenceData] = useState("");
  const [resetPasswordNotification, setResetPasswordNotification] =
    useState(false);

  const handleLogin = () => {
    navigate("/get-started");
  };

  const handleSendResetCode = (response) => {
    setIsResetEmail(response);
    navigate("/auth/reset-password");
  };

  const handleResetPassword = () => {
    setResetPasswordNotification(true);
    navigate("/auth/login");
  };

  const handleForgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const handleCreateAccount = (response) => {
    setReferenceData(response);
    navigate("/auth/verify-account");
  };

  const handleVerifyRedirect = (response) => {
    setReferenceData(response);
    setTimeout(() => {
      navigate("/auth/verify-account");
    }, 2000);
  };

  useEffect(() => {
    const storedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
    if (storedCountry) {
      setCountry(storedCountry);
    }
  }, []);

  useEffect(() => {
    const validStates = [
      "reset-password",
      "forgot-password",
      "login",
      "signup",
      "verify-account",
    ];
    if (!validStates.includes(state)) {
      navigate("/get-started"); // Redirect to the default page
    }
  }, [state, navigate]);

  return (
    <AuthPageWrapper>
      <AuthFormContainer>
        <Link
          to="https://trymaxim.com/"
          target="_blank"
        >
          <img
            src={Logo}
            width="140px"
            alt="Maxim Logo"
          />
        </Link>
        {state === "reset-password" ? (
          <ResetPassword
            onResetPassword={handleResetPassword}
            isResetEmail={isResetEmail}
          />
        ) : state === "forgot-password" ? (
          <ForgotPassword onSendResetCode={handleSendResetCode} />
        ) : state === "login" ? (
          <Login
            onLogin={handleLogin}
            onForgotPassword={handleForgotPassword}
            onResetPassword={resetPasswordNotification}
            onVerifyRedirect={handleVerifyRedirect}
          />
        ) : state === "signup" ? (
          <CreateAccount
            onCreateAccount={handleCreateAccount}
            selectedCountry={country}
          />
        ) : state === "verify-account" ? (
          <VerifyAccount referenceData={referenceData} />
        ) : (
          ""
        )}
      </AuthFormContainer>
      <img
        src={SignupIllustration}
        className="signup-illustration"
        alt="signup illustration"
      />
    </AuthPageWrapper>
  );
};

export default AuthPage;
