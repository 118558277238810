import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const ListAll = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Data fetched successfully",
        "data": [
          {
            "id": "UID-01hp22598xzp250q9hs65qjwjm",
            "first_name": "Joshua",
            "middle_name": "",
            "last_name": "Isaac",
            "email": "******ua@test.com",
            "phone_number": "*******6789",
            "id_type": "bvn",
            "id_number": "22*******22",
            "match": true,
            "created_at": "2024-02-07T15:14:39.000000Z",
            "nationality": "Nigeria"
          }
        ],
        "pagination": {
          "total": 1,
          "per_page": 20,
          "current_page": 1,
          "total_pages": 1,
          "first_page": 1,
          "last_page": 1,
          "first_page_url": "http://localhost:8000/public-api/v1/uid-verifications?page=1",
          "last_page_url": "http://localhost:8000/public-api/v1/uid-verifications?page=1",
          "next_page_url": null,
          "prev_page_url": null,
          "path": "http://localhost:8000/public-api/v1/uid-verifications",
          "from": 1,
          "to": 1
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to retrieve all lists of user ID was successful.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="User ID Verification"
      pageDescription="Use this endpoint to verify or know your customer using User ID  such as Bank Verification Number - BVN."
      subtitle="List All"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/uid-verifications"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default ListAll;
