let lastActivityTime = new Date().getTime();

export const startAutoLogout = () => {
  const activityCheckInterval = setInterval(() => {
    // Check if the current route is an auth page
    const isOtherPages =
      window.location.pathname.includes("/auth") ||
      window.location.pathname.includes("/select-country") ||
      window.location.pathname.includes("/consent");

    // If on an auth page, reset the last activity time and return
    if (isOtherPages) {
      lastActivityTime = new Date().getTime();
      return;
    }

    const currentTime = new Date().getTime();
    const timeSinceLastActivity = currentTime - lastActivityTime;
    const logoutTimeLimit = 30 * 60 * 1000; // 30 minutes in milliseconds

    if (timeSinceLastActivity > logoutTimeLimit) {
      localStorage.clear();
      window.location.reload();
    }
  }, 60 * 1000);

  document.addEventListener("mousemove", () => {
    lastActivityTime = new Date().getTime();
  });

  return activityCheckInterval;
};

export const stopAutoLogout = (intervalId) => {
  clearInterval(intervalId);
};
