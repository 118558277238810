import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { setup } from 'goober';
import Main from 'Main';
import 'index.css';
import { GlobalStyles } from 'globalStyles';
import { useToken } from 'utils/hooks/useToken';
import { useCountryCode } from 'utils/hooks/useUserValues';
import { startAutoLogout, stopAutoLogout } from 'autoLogout';

setup(React.createElement);

const App = () => {
  useToken();
  useCountryCode();

  useEffect(() => {
    const autoLogoutInterval = startAutoLogout();

    // Clean up the interval when the component unmounts
    return () => {
      stopAutoLogout(autoLogoutInterval);
    };
  }, []);

  return (
    <React.StrictMode>
      <GlobalStyles />
      <Main />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
