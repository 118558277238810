import axiosInstance from 'axiosInstance';
import { proxy } from 'valtio';

const subscription = proxy({
  subscriptionData: null,
  subscriptionLoading: false,
  generateReferenceData: null,
  generateReferenceLoading: false,
  verifyPaymentData: null,
  verifyPaymentLoading: false,
  updateBillingInfoData: null,
  updateBillingInfoLoading: false,
  billingHistoryData: null,
  billingHistoryLoading: false,
  plansData: null,
  plansLoading: false,
  topUpData: null,
  topUpLoading: false,
  topUpAmountData: null,
  topUpAmountLoading: false,
  updateCardData: null,
  updateCardLoading: false,
  updateAdditionalEmailsData: null,
  updateAdditionalEmailsLoading: false,
  activateFreePlanData: null,
  activateFreePlanLoading: false,
  customPriceData: null,
  customPriceLoading: false,
  generateReceipt: null,
  generateReceiptLoading: false,

  async getPlans() {
    this.plansLoading = true;

    return axiosInstance
      .get(`/plans`)
      .then(({ data }) => {
        this.plansData = data.data;
        this.plansLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.plansLoading = false;
        return response;
      });
  },

  async getSubscription() {
    this.subscriptionLoading = true;

    return axiosInstance
      .get(`/subscriptions`)
      .then(({ data }) => {
        this.subscriptionData = data.data;
        this.subscriptionLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.subscriptionLoading = false;
        return response;
      });
  },

  async generateReference(body) {
    this.generateReferenceLoading = true;

    return axiosInstance
      .post(`/transactions/reference`, body)
      .then(({ data }) => {
        this.generateReferenceData = data.data;
        this.generateReferenceLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.generateReferenceLoading = false;
        return response;
      });
  },

  async verifyPayment(body) {
    this.verifyPaymentLoading = true;

    return axiosInstance
      .post(`/transactions/verify-payment`, body)
      .then(({ data }) => {
        this.verifyPaymentData = data.data;
        this.verifyPaymentLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.verifyPaymentLoading = false;
        return response;
      });
  },

  async updateBillingInfo(body) {
    this.updateBillingInfoLoading = true;

    return axiosInstance
      .patch(`/subscriptions/billing-info`, body)
      .then(({ data }) => {
        this.updateBillingInfoData = data.data;
        this.updateBillingInfoLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.updateBillingInfoLoading = false;
        return response;
      });
  },

  async getBillingHistory({ perPage, page }) {
    this.billingHistoryLoading = true;

    return axiosInstance
      .get(`/transactions?per_page=${perPage}&page=${page}`)
      .then(({ data }) => {
        this.billingHistoryData = data;
        this.billingHistoryLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.billingHistoryLoading = false;
        return response;
      });
  },

  async createTopUp(body) {
    this.topUpLoading = true;

    return axiosInstance
      .post(`/subscriptions/top-up`, body)
      .then(({ data }) => {
        this.topUpData = data.data;
        this.topUpLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.topUpLoading = false;
        return response;
      });
  },

  async getTopUpAmount(volume) {
    this.topUpAmountLoading = true;

    return axiosInstance
      .get(`/subscriptions/top-up/amount?volume=${volume}`)
      .then(({ data }) => {
        this.topUpAmountData = data.data;
        this.topUpAmountLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.topUpAmountLoading = false;
        return response;
      });
  },

  async updateCard() {
    this.updateCardLoading = true;

    return axiosInstance
      .post(`/subscriptions/update-card`)
      .then(({ data }) => {
        this.updateCardData = data.data;
        this.updateCardLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.updateCardLoading = false;
        return response;
      });
  },

  async updateAdditionalEmails(body) {
    this.updateAdditionalEmailsLoading = true;

    return axiosInstance
      .patch(`/subscriptions/additional-emails`, body)
      .then(({ data }) => {
        this.updateAdditionalEmailsData = data.data;
        this.updateAdditionalEmailsLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.updateAdditionalEmailsLoading = false;
        return response;
      });
  },

  async activateFreePlan(body) {
    this.activateFreePlanLoading = true;

    return axiosInstance
      .post(`/subscriptions/activate-plan`, body)
      .then(({ data }) => {
        this.activateFreePlanData = data.data;
        this.activateFreePlanLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.activateFreePlanLoading = false;
        return response;
      });
  },

  async getCustomPrice(classes, units) {
    this.customPriceLoading = true;

    let classesParams = '';

    classes.forEach((cl) => {
      classesParams += `&classes[]=${cl}`;
    });

    return axiosInstance
      .get(`/plans/custom-price?units=${units}${classesParams}`)
      .then(({ data }) => {
        this.customPriceData = data.data;
        this.customPriceLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.customPriceLoading = false;
        return response;
      });
  },

  async getGenerateReceipt(ref) {
    this.generateReceiptLoading = true;

    return axiosInstance
      .get(`/transactions/${ref}/receipt`)
      .then(({ data }) => {
        this.generateReceipt = data;
        this.generateReceiptLoading = false;
        return this.generateReceipt;
      })
      .catch(({ response }) => {
        this.generateReceiptLoading = false;
        return response;
      });
  },
});

export default subscription;
