import { MediumButton } from "components/button";
import React, { useCallback, useEffect, useState } from "react";
import { Progress } from "antd";
import { useSnapshot } from "valtio";
import store from "store";
import { formatNumberWithCommas } from "utils/common";
import { format } from "date-fns";
import TopUpModal from "./TopUpModal";
import Toast from "components/common/ToastNotification";
import { toast } from "sonner";
import Spinner from "components/common/Spinner";
import ConfirmRenewalModal from "components/Plans-Billing/Plans/ConfirmRenewalModal";

const PlanInfo = () => {
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const subscriptionData = subscriptionSnapshot?.subscriptionData;
  const [openTopUpModal, setOpenTopUpModal] = useState(false);
  const [plan, setPlan] = useState();
  const generateReferenceLoading =
    subscriptionSnapshot?.generateReferenceLoading;
  const generateReferenceData = subscriptionSnapshot?.generateReferenceData;
  const user = JSON.parse(localStorage.getItem("user"));
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");
  const [hasExecuted, setHasExecuted] = useState(false);
  const [flwResponse, setflwResponse] = useState(null);
  const [isOpenRenewalModal, setIsOpenRenewalModal] = useState(false);

  useEffect(() => {
    setPlan(subscriptionData?.subscription_details);
  }, [subscriptionData]);

  useEffect(() => {
    setAmount(generateReferenceData?.amount);
    setReference(generateReferenceData?.reference);
  }, [generateReferenceData]);

  useEffect(() => {
    const verifyPayment = async (id) => {
      try {
        const response = await store?.subscription.verifyPayment({
          transaction_id: id,
        });

        if (response?.data?.status === false) {
          alert(response?.data?.message);
          // onClose();
        } else {
          alert("Payment Successful!");
          // onClose();
          setTimeout(() => {
            window.location.href = "/plans";
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (flwResponse?.status === "successful") {
      verifyPayment(flwResponse?.transaction_id.toString());
      setflwResponse(null);
    }
  }, [flwResponse]);

  const makePayment = useCallback(() => {
    // eslint-disable-next-line no-undef
    const modal = FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLUTTERWAVE_PUB_KEY,
      tx_ref: reference,
      amount: amount,
      currency: plan?.plan?.price[0]?.currency,
      payment_options: "card",
      customer: {
        email: user?.email,
      },
      customizations: {
        description: `You are about to make payment for a plan.`,
      },
      callback: function (response) {
        setflwResponse(response);
        modal.close();
      },
    });
  }, [reference, amount, plan?.plan?.price, user?.email]);

  useEffect(() => {
    if (reference?.length > 0 && hasExecuted === false) {
      setHasExecuted(true);

      if (!generateReferenceData?.inlineCharge) {
        toast.custom((t) => (
          <Toast
            toastType="info"
            text="Transaction is processing"
          />
        ));
        setTimeout(() => {
          window.location.href = "/plans";
        }, 1000);
      } else {
        makePayment();
      }
    }
  }, [reference, hasExecuted, makePayment, generateReferenceData]);

  const generateReference = async () => {
    try {
      const body = {
        planId: plan?.plan?.id.toLowerCase(),
      };

      const response = await store?.subscription.generateReference(body);
      if (response?.data?.status === false) {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message}
          />
        ));
      }
      setIsOpenRenewalModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (!plan?.startDate) return;

  return (
    <div className="px-[1.625rem] py-9 border-[0.5px] border-stroke rounded-xl flex space-x-[4rem]">
      <div>
        <div className="flex items-center justify-between gap-2 mb-8">
          <p className="text-headings text-xl font-semibold capitalize">
            {plan?.plan?.id}
          </p>
          <span
            className={`${
              plan?.active
                ? "bg-complementary-1-opaque-20 text-complementary-1"
                : "bg-error-opaque-20 text-error"
            }  px-3 py-1 rounded  font-semibold text-[0.625rem] tracking-[1.5px] uppercase`}
          >
            {plan?.active ? plan?.frequency : "EXPIRED"}
          </span>
        </div>

        <p className="text-dark font-semibold text-2xl">
          {formatNumberWithCommas(Number(plan?.plan?.price[0]?.price))}
          <span className="text-neutral text-[0.625rem] font-bold">{plan?.plan?.price[0]?.currency}</span>
        </p>
      </div>
      <div className="flex-1 flex flex-col justify-between">
        <div className="flex items-center space-x-[1.125rem]">
          <p className="text-neutral text-[0.625rem] tracking-[1.5px] font-bold whitespace-nowrap">
            {plan?.active
              ? `${plan?.unitUsed} OF ${plan?.totalUnit} UNITS USED`
              : `0 UNITS AVAILABLE`}
          </p>
          <Progress
            percent={
              plan?.active ? (plan?.unitUsed / plan?.totalUnit) * 100 : 100
            }
            showInfo={false}
            trailColor="#E6F6FC"
            strokeColor={plan?.active ? "#05A7E3" : "#E54B4B"}
            className="plan-progress"
          />
        </div>

        <div className="grid grid-cols-3">
          <div>
            <p className="text-neutral text-sm">
              {plan?.active ? "Active from" : "Expired on"}
            </p>
            <p className="text-sm text-dark font-medium">
              {plan?.active
                ? plan?.startDate &&
                  format(new Date(plan?.startDate), "MMM dd, yyyy")
                : plan?.endDate &&
                  format(new Date(plan?.endDate), "MMM dd, yyyy")}
            </p>
          </div>

          {plan?.active ? (
            <div>
              <p className="text-neutral text-sm">Next payment date</p>
              <p className="text-sm text-dark font-medium">
                {plan?.endDate &&
                  format(new Date(plan?.endDate), "MMM dd, yyyy")}
              </p>
            </div>
          ) : (
            <div />
          )}

          {plan?.active ? (
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              className="place-self-end"
              disabled={plan?.plan?.id === "free"}
              onClick={() => setOpenTopUpModal(true)}
            >
              Top up unit
            </MediumButton>
          ) : generateReferenceLoading ? (
            <div className="flex justify-end">
              <Spinner className="my-[0.5rem]" />
            </div>
          ) : (
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              className="place-self-end"
              onClick={() => setIsOpenRenewalModal(true)}
            >
              Renew plan
            </MediumButton>
          )}

          <TopUpModal
            isOpen={openTopUpModal}
            onClose={() => setOpenTopUpModal(false)}
          />
          {isOpenRenewalModal && (
            <ConfirmRenewalModal
              isOpen={isOpenRenewalModal}
              onClose={() => setIsOpenRenewalModal(false)}
              isLoading={generateReferenceLoading}
              generateReference={generateReference}
              planDetails={plan}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanInfo;
