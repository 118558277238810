import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import NetworkSvg from "assets/images/network.svg";
// import { useSnapshot } from "valtio";
import store from "store";
import Table from "components/table";
import { TableFilter } from "assets/styles/common-styles";
import TextField from "components/formFields/TextField";
import SelectField from "components/formFields/SelectField";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
// import { format } from "date-fns";
// import Spinner from "components/common/Spinner";

const APILogs = () => {
  // const navigate = useNavigate();
  // const [openModal, setOpenModal] = useState(false);
  // const settingsSnapshot = useSnapshot(store.settings);
  // const webhooks = settingsSnapshot?.webhooks;
  // const webhookLoading = settingsSnapshot?.webhookLoading;
  // const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    store?.settings.getWebhooks();
    store?.onboarding.getOnboardingStage();
  }, []);

  // useEffect(() => {
  //   if (webhooks?.length > 0) {
  //     const refinedData = webhooks?.map((webhook) => {
  //       return {
  //         createdAt: format(
  //           new Date(webhook.created_at),
  //           "MMM dd yyyy, hh:mm a"
  //         ),
  //         service: webhook.service,
  //         url: webhook.url,
  //         notifications: webhook.notification_counts,
  //         status: (
  //           <Toggle
  //             label1="Enabled"
  //             label2=""
  //             name="status"
  //             isChecked={webhook.status}
  //           />
  //         ),
  //       };
  //     });
  //     setData(refinedData);
  //   }
  // }, [webhooks]);

  const columns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "METHOD",
      accessor: "method",
    },
    {
      Header: "CODE",
      accessor: "code",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "REQUEST ID",
      accessor: "request_id",
    },
    {
      Header: "DATE",
      accessor: "createdAt",
    },
  ];

  const data = [
    {
      name: 'Get Assessment',
      method: "patch",
      code: "200",
      status: "active",
      request_id: "MXM4SMCKQO56S2",
      createdAt: "22 OCT 23, 11:45 AM",
    },
    {
      name: 'Assessment',
      method: "get",
      code: "404",
      status: "pending",
      request_id: "AXM4SMCKQO56S2",
      createdAt: "22 OCT 23, 11:45 AM",
    },
  ];

  const handleSearchInputChange = e => {
    setSearchText(e.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sorting direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorting column in ascending order
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = [...data];

  if (sortColumn) {
    sortedData.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  // if (webhookLoading)
  //   return (
  //     <div className="flex items-center justify-center">
  //       <Spinner />
  //     </div>
  //   );

  // const handleRowClick = (rowData) => {
  //   const serviceName = rowData?.service;
  //   navigate(`/settings/webhooks/${serviceName}`);
  // };

  return (
    <div className="p-11">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="font-semibold text-base text-maximblack-10 mb-2">
            API logs
          </p>
          <p className="text-maximgray-10 text-sm">
            Oversee the interaction between your integration.
          </p>
        </div>
      </div>
      <TableFilter className="mb-8">
        <TextField
          type="text"
          name="search"
          placeholder="Search"
          value={searchText}
          onChange={handleSearchInputChange}
          iconLeft={<SearchIcon className="icon left-icon" />}
          className="text-indent"
          width="21rem"
          wrapperclass="input-sm"
        />
        <div className="filter-row-right">
          <SelectField
            name="filter"
            value="All Code"
            onChange={(selectedColumn) => {
              const { key } = selectedColumn.target;
              handleSort(key);
            }}
            options={columns
              .filter(option => option.accessor !== 'name' && option.accessor !== 'actions')
              .map(option => ({ value: option.Header, key: option.accessor }))
            }
            minwidth="7.5rem"
            className="select-sm bg-maxim-white"
          />
          <SelectField
            name="filter"
            value="All Status"
            onChange={(selectedColumn) => {
              const { key } = selectedColumn.target;
              handleSort(key);
            }}
            options={columns
              .filter(option => option.accessor !== 'name' && option.accessor !== 'actions')
              .map(option => ({ value: option.Header, key: option.accessor }))
            }
            minwidth="8.125rem"
            className="select-sm bg-maxim-white"
          />
          <SelectField
            name="filter"
            value="All Time"
            onChange={(selectedColumn) => {
              const { key } = selectedColumn.target;
              handleSort(key);
            }}
            options={columns
              .filter(option => option.accessor !== 'name' && option.accessor !== 'actions')
              .map(option => ({ value: option.Header, key: option.accessor }))
            }
            minwidth="7.5rem"
            className="select-sm bg-maxim-white"
          />
        </div>
      </TableFilter>

      {/* {webhooks && webhooks?.length > 0 ? ( */}
        <Table
          columns={columns}
          data={data}
          className="webhook-table"
          // onRowClick={handleRowClick}
        />
      {/* ) : ( */}
        {/* <div className="flex flex-col items-center justify-center h-[40vh]">
          <img src={NetworkSvg} alt="network" draggable={false} />
          <p className="text-base mt-6 text-center">
            There are no API logs <br /> at the moment
          </p>
        </div> */}
      {/* )} */}
    </div>
  );
};

export default APILogs;
