import { FlexCol } from "assets/styles/common-styles";
import { PageTitle } from "assets/styles/docs-styles";

const InnerPageTitle = ({ title, description }) => {
  return (
    <PageTitle className={`${FlexCol} gap-8`}>
      <h1 className="heading-h1 headings">{title}</h1>
      <p className="text-base leading-[28px] w-[57%]">{description}</p>
    </PageTitle>
  );
};

export default InnerPageTitle;
