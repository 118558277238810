import { css, styled } from "goober";
import { mobile, mobileMedium, mobileSmall, mobileSmallMax } from "globalStyles";

export const OnboardingChecklistWrapper = styled("div")`
  width: 100%;
  max-width: 35.625rem;
  margin: 7.5rem auto;
  strong {
    color: var(--text-dark);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  .add-teammates-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5625rem;
    .view-verified {
      color: var(--accent-2);
    }
    .add-teammates {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      p {
        color: var(--accent-2);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
    }
    .not-now {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.4px;
      color: var(--text-neutral);
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;

export const ReportWrapper = styled("section")`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 3rem 3.75rem 2rem 3.75rem;
  min-height: 91vh;
  .card-row {
    display: flex;
    gap: 1.25rem;
    ${mobileMedium} {
      flex-wrap: wrap;
    }
  }
  ${mobile} {
    padding: 1rem;
    flex-wrap: wrap;
  }
`;

export const Card = styled("section")`
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--br-md);
  .card-header {
    color: var(--headings);
  }
`;

export const CustomerReportOverview = styled("section")`
  height: 100%;
  .reports-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
    padding: 2.375rem 1.9375rem;
    height: max-content;
  }
  .free-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
    padding: 2.375rem 1.9375rem;
    height: max-content;
    
  }
  .reports-sidebar {
    min-height: 100%;
    border-left: 0.5px solid var(--stroke);
    padding: 2.375rem 1.5rem;
    width: 100%;
    max-width: 18rem;
  }
  .details-wrapper {
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    ${mobile} {
      width: 100%;
    }
  }
  .detail-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .row {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
    &.no-space {
      justify-content: unset;
      gap: 2rem;
    }
    &.half {
      & > * {
        flex: 1;
        flex-basis: calc(50% - 2rem);
      }
    }
  }
  .indepth-summary {
    background-color: var(--white-alt);
    border-radius: var(--br);
    padding: 1.75rem 2.375rem;
    .title {
      margin-bottom: 1.375rem;
    }
  }
`;

export const CustomerReport = css`
  padding: 1.5rem 1.625rem 1.375rem 1.625rem;
  gap: 0.71875rem;
  width: 100%;
`;

export const MostRecent = css`
  .empty-state {
    gap: 1.1875rem;
    text-align: center;
    width: 69%;
    margin: 0 auto;
    min-height: 47vh;
    padding: 3rem 0;
    p {
      margin-top: 0.625rem;
    }
    span {
      color: var(--accent-2);
    }
  }
  .customer-request-table {
    width: 95vw;
    overflow: auto;
    max-width: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    td, th {
      padding: 8px;
      text-align: left;
    }
    th {
      padding-top: 1.375rem;
      padding-bottom: 1.375rem;
      background-color: var(--white);
      color: var(--text-dark);
      text-align: left;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.2px;
      border-bottom: 0.25px solid var(--stroke);
      &:nth-child(1) {
        padding-left: 2.25rem;
        border-radius: var(--br-md) 0 0 0;
      }
      &:last-child {
        border-radius: 0 var(--br-md) 0 0;
      }
    }
    td {
      color: var(--text-neutral);
    }
    tr {
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--white);
        color: var(--text-neutral);
        text-align: left;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
        border-bottom: 0.25px solid var(--stroke);
       
        &:nth-child(1) {
          padding-left: 2.25rem;
          color: var(--text-dark);
          font-weight: 500;
          line-height: normal;
          white-space: nowrap;
        }
      }
      &:last-child {
        td {
          &:nth-child(1) {
            border-radius: 0 0 0 var(--br-md);
          }
          &:last-child {
            border-radius: 0 0 var(--br-md) 0;
          }
        }
      }
    }
  }
  .view-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--stroke);
    border-radius: var(--br-sm);
  }
`;

export const MostRecentRequestHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.75rem 2rem 1rem 1.625rem;
  ${mobileSmallMax} {
    flex-wrap: wrap;
  }
`;

export const ReportOverviewWrapper = styled("section")`
  display: flex;
  gap: 1.25rem;
`;

export const ReportOverviewSidebar = css`
  width: 100%;
  max-width: 16.375rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
  .header {
    padding: 2.375rem 2rem 0.375rem;
  }
  .title {
    margin-bottom: 0.25rem;
  }
  .report-tab {
    padding: 0.875rem 2rem 0.9375rem 2rem;
    text-align: left;
    &.active {
      border-right: 6px solid var(--headings);
    }
  }
`;

export const ReportReviewWrapper = css`
  padding: 2.375rem 1.9375rem;
  flex: 1;
  .empty-state {
    gap: 1.25rem;
    text-align: center;
  }
  &.report {
    padding: 0;
    gap: 3.125rem;
  }
`;

export const SelectCountryWrapper = styled("section")`
  width: 100%;
  min-height: 100vh;
  background-color: var(--white-alt);
  position: relative;
  padding-top: 3rem;
  .container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    max-width: 62.375rem;
    padding: 1.5rem 0 2.25rem 0;
    margin: 0 auto;
    ${mobile} {
      width: 100%;
    }
  }
  .details-wrapper {
    display: flex;
    gap: 3rem;
    ${mobile} {
      flex-wrap: wrap;
    }
  }
  .request-types {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const SelectCountryHeader = styled("section")`
  background-color: var(--white);
  border-radius: var(--br-md);
  border: 0.5px solid var(--stroke);
  padding: 0.875rem 2.625rem 0.875rem 2.25rem;
  h2 {
    color: var(--headings);
  }
  .left-header {
    gap: 2rem;
  }
  ${mobileSmall} {
    h2 {
      font-size: 1.25rem;
    }
  }
`;

export const CreditScoreWrapper = styled("section")`
  width: 100%;
  max-width: 15rem;
  background: var(--white-alt);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: fit-content;
  padding: 2rem;
  border-radius: var(--br-sm);
`;

export const CreditAccountsDetails = styled("section")`
  .body {
    gap: 1.5rem;
    border-radius: var(--br-sm) var(--br-sm) 0 0;
    background: var(--white-alt);
    padding: 1.25rem 1.75rem 1rem 1.25rem;
    .item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .footer {
    border-radius: 0 0 var(--br-sm) var(--br-sm);
    background: var(--text-dark);
    padding: 0.875rem 1.75rem 0.875rem 1.25rem;
  }
`;
