import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from "sonner";
import store from "store";
import FloatingTextField from "components/formFields/FloatingTextField";
import { FullWidthButton } from "components/button";
import Checkbox from "components/formFields/Checkbox";
import { AuthForm } from "assets/styles/authpage-styles";
import { Flex } from "assets/styles/common-styles";
import { ReactComponent as Marker } from "assets/images/icons/marker.svg";
import { validateFormData } from "utils/validateFormData";
import Toast from "components/common/ToastNotification";
import Spinner from "components/common/Spinner";

const CreateAccount = ({ onCreateAccount, selectedCountry }) => {
  const [selectedValue, setSelectedValue] = useState({
    email: "",
    password: "",
  });
  const [country, setCountry] = useState({ alpha_2_code: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    isLengthValid: false,
    hasUppercase: false,
    hasNumberAndSymbol: false,
  });

  useEffect(() => {
    const storedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
    if (storedCountry) {
      setCountry(storedCountry);
    }
  }, []);

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const isPasswordValid = (password) => {
    // At least 8 characters
    const isLengthValid = password.length >= 8;

    // One uppercase letter
    const hasUppercase = /[A-Z]/.test(password);

    // One number and one symbol
    const hasNumberAndSymbol = /(?=.*\d)(?=.*\W)/.test(password);

    return { isLengthValid, hasUppercase, hasNumberAndSymbol };
  };

  const renderIndicator = (isValid) => (
    <div
      className={`password-indicator ${isValid ? "bg-accent-2" : "bg-pending"}`}
    />
  );

  const handlePasswordChange = (name, e) => {
    const value = e.target.value;
    const { isLengthValid, hasUppercase, hasNumberAndSymbol } =
      isPasswordValid(value);

    setSelectedValue({ ...selectedValue, [name]: value });
    setPasswordValidation({ isLengthValid, hasUppercase, hasNumberAndSymbol });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data = {
      country_code: country.alpha_2_code,
      email: selectedValue?.email,
      password: selectedValue?.password,
    };
    const validationRules = {
      country_code: [{ required: true }],
      email: [{ required: true }],
      password: [{ required: true }],
    };

    if (!data.country_code) {
      toast.custom((t) => (
        <Toast toastType="error" text="Please select a country" />
      ));
      setIsLoading(false);
      return;
    }

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }
    if (!isChecked) {
      toast.custom((t) => (
        <Toast
          toastType="error"
          text="Please accept the terms and privacy policy"
        />
      ));
      setIsLoading(false);
      return;
    }

    try {
      const response = await store.auth.postRegistration(data);
      if (response && (response?.data?.reference || response?.reference)) {
        const newData = {
          email: data.email,
          reference: response?.data?.reference || response?.reference,
        };
        onCreateAccount(newData);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="container">
        <section className="header">
          <h2 className="heading-h2">Create your account</h2>
          <p className="text-base">
            One minute is all it takes to capture new market
          </p>
        </section>
        <section className="country-header">
          <div className={`${Flex} country`}>
            <Marker />
            <p className="text-large-semibold">
              {selectedCountry ? selectedCountry.name : "Select a country"}
            </p>
          </div>
          <Link to="/select-country">
            <p className="text-md-semibold select">Select new</p>
          </Link>
        </section>
        <AuthForm onSubmit={handleSubmit}>
          <FloatingTextField
            label="Company webmail"
            id="email"
            name="email"
            type="email"
            labelfor="email"
            value={selectedValue.email}
            onChange={(e) => handleChange("email", e)}
          />
          <FloatingTextField
            label="Enter password"
            id="password"
            name="password"
            type="password"
            labelfor="password"
            value={selectedValue.password}
            onChange={(e) => handlePasswordChange("password", e)}
          />
          <section className="flex flex-col password-info">
            <div className={`${Flex} gap-2 text-md`}>
              {renderIndicator(passwordValidation.isLengthValid)} At least 8
              characters
            </div>
            <div className={`${Flex} gap-2 text-md`}>
              {renderIndicator(passwordValidation.hasUppercase)} One uppercase
              letter
            </div>
            <div className={`${Flex} gap-2 text-md`}>
              {renderIndicator(passwordValidation.hasNumberAndSymbol)} One
              number and one symbol
            </div>
          </section>
          <Checkbox
            label={
              <>
                By creating an account, you agree with Maxim's{" "}
                <Link
                  to="https://v2.trymaxim.com/terms-of-service"
                  target="_blank"
                  className="text-base-medium terms-policy"
                >
                  Terms
                </Link>{" "}
                and{" "}
                <Link
                  to="https://v2.trymaxim.com/privacy-policy"
                  target="_blank"
                  className="text-base-medium terms-policy"
                >
                  Privacy Policy
                </Link>
              </>
            }
            name="confirm_create_account"
            className="create-account-checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            labelclass="text-base"
          />
          {isLoading ? (
            <Spinner style={{ margin: "0.5rem auto" }} />
          ) : (
            <FullWidthButton
              bg="var(--accent-1)"
              textcolor="var(--primary)"
              width="100%"
              type="submit"
            >
              Create account
            </FullWidthButton>
          )}
        </AuthForm>
        <section className="base">
          <p className="text-base">
            Already registered? <Link to="/auth/login">Log in</Link>
          </p>
        </section>
      </section>
      <Toaster position="top-center" visibleToasts={1} />
    </>
  );
};

export default CreateAccount;
