import { proxy } from "valtio";
import axiosInstance from "axiosInstance";

const misc = proxy({
  environment: null,
  environmentLoading: false,

  async getEnvironment() {
    this.environmentLoading = true;
    return axiosInstance
      .get(`/misc/environment`)
      .then(({ data }) => {
        this.environment = data.data;
        this.environmentLoading = false;
        return this.environment;
      })
      .catch(({ response }) => {
        this.environmentLoading = false;
        return response;
      });
  },
  async switchEnvironment() {
    return axiosInstance
      .patch(`/misc/environment`)
      .then(({ data }) => {
        if (data.message === "Environment switched.") {
          return true;
        } else {
          return data;
        }
      })
      .catch(({ response }) => {
        return false;
      });
  },
});

export default misc;
