import CustomModal from "components/modals/CustomModal";
import {
  CommonDrawerStyle,
  IndepthAnalysisModal,
} from "components/modals/modalStyles";
import { FlexCol, FlexRowBetween } from "assets/styles/common-styles";
import { CreditAccountsDetails } from "assets/styles/customer-report-styles";

const IndepthAnalysisList = ({ isOpen, onClose, reportDetails }) => {
  const data =
    reportDetails?.result?.event_data?.meta?.credit
      ?.indepth_analysis_of_credit_accounts;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className={`${CommonDrawerStyle} ${IndepthAnalysisModal}`}
    >
      <h3 className="heading-h2-type-2 headings mb-10">
        In-depth analysis of credit accounts
      </h3>
      <div className="px-1.5 flex flex-col gap-6">
        {data?.map((item, index) => (
          <CreditAccountsDetails key={index}>
            <div className={`body ${FlexCol}`}>
              <div className="item">
                <p className="text-base neutral">Currency:</p>
                <div className="bg-white py-1.5 px-3 rounded-lg">
                  <p className="text-md-medium dark">{item?.currency}</p>
                </div>
              </div>
              <div className="item">
                <p className="text-base neutral">Provider:</p>
                <p className="text-base-medium dark">{item?.provider}</p>
              </div>
              <div className="item">
                <p className="text-base neutral">Performing status:</p>
                <p className="text-base-medium dark">
                  {item?.performance_status}
                </p>
              </div>
              <div className="item">
                <p className="text-base neutral">Account opening date:</p>
                <p className="text-base-medium dark">
                  {item?.account_opening_date}
                </p>
              </div>
              <div className="item">
                <p className="text-base neutral">Account status:</p>
                <p className="text-base-medium dark">{item?.account_status}</p>
              </div>
            </div>
            <div className={`footer ${FlexRowBetween}`}>
              <p className="text-base coloured">Account opening balance</p>
              <p className="text-base-medium success">
                {item?.account_opening_balance}
              </p>
            </div>
          </CreditAccountsDetails>
        ))}
      </div>
    </CustomModal>
  );
};

export default IndepthAnalysisList;
