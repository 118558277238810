// import { useEffect } from 'react';

export const getFirstLetter = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }
  return str[0].toUpperCase();
};

export const truncateString = (str, maxLength) => {
  if (str && str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleCopy = (value) => {
  const textArea = document.createElement('textarea');
  textArea.value = value;

  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const removeUnderscores = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }
  return str.replace(/_/g, ' ');
};

export const addCountryCode = (phoneNumber, countryCode) => {
  // Remove any existing country code or plus sign from the phone number
  phoneNumber = phoneNumber.replace(/^(\+|0+)/, '');

  // Add the provided country code to the phone number
  return `${countryCode}${phoneNumber}`;
};

export const formatNumberWithCommas = (number) => {
  // Convert the number to a string
  const numberString = number.toString();

  // Use regex to add commas after every three digits from the right
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const debounce = (func, delay) => {
  let timeoutId;

  const debounced = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debounced.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debounced;
};

export const capitalizeWords = (inputString) => {
  let words = inputString?.split(' ');

  let capitalizedWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  let resultString = capitalizedWords?.join(' ');

  return resultString;
};

// export const useFetchData = (callback, dependencies) => {
//   useEffect(() => {
//     let ignore = false;

//     const fetchData = async () => {
//       try {
//         // Your data fetching logic
//         callback();
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchData();

//     return () => {
//       ignore = true;
//     };
//   }, dependencies); // Make sure dependencies are only what's necessary
// };
