import {
  Card,
  ReportOverviewSidebar,
} from "assets/styles/customer-report-styles";
import Spinner from "components/common/Spinner";
import Tab from "components/common/Tab";
import { capitalizeFirstLetter, removeUnderscores } from "utils/common";

const ReportSidebar = ({
  tabsWithSubTabs,
  activeTab,
  handleTabClick,
  handleSubTabClick,
  singleCommercialCheckLoading,
  activeSubTab,
  singleCommercialCheck,
}) => {
  return (
    <Card className={ReportOverviewSidebar}>
      <div className="header">
        <p className="text-base-semibold headings title">Company report</p>
      </div>
      {singleCommercialCheckLoading ? (
        <Spinner style={{ margin: "0 auto" }} />
      ) : (
        tabsWithSubTabs?.mainTabs?.length > 0 && (
          <Tab
            tabs={tabsWithSubTabs?.mainTabs?.map((mainTab) => ({
              label: capitalizeFirstLetter(removeUnderscores(mainTab)),
              urlParam: mainTab,
            }))}
            activeTabLabel={activeTab}
            handleTabClick={handleTabClick}
            className="horizontal-tab"
            tabclass="report-tab text-base-semibold px-[2.0625rem] text-neutral !leading-none mb-4 justify-between"
            isactiveclass="active bg-maxim-white !text-dark text-base-semibold"
            disabled={
              singleCommercialCheck?.status === "pending" ||
              singleCommercialCheck?.status === "processing"
                ? true
                : false
            }
            disabledclass="report-tab text-base-semibold px-[2.0625rem] text-muted !leading-none mb-4"
            subtabs={
              singleCommercialCheckLoading ? (
                <Spinner style={{ margin: "0 auto" }} />
              ) : (
                activeTab &&
                tabsWithSubTabs[activeTab] &&
                tabsWithSubTabs[activeTab] &&
                !tabsWithSubTabs[activeTab].includes("undefined undefined") && (
                  <Tab
                    tabs={tabsWithSubTabs[activeTab]?.map((subTab) => ({
                      label: capitalizeFirstLetter(removeUnderscores(subTab)),
                      urlParam: subTab,
                    }))}
                    activeTabLabel={activeSubTab}
                    handleTabClick={handleSubTabClick}
                    className="horizontal-tab sub-tab mt-[-8px] mb-4"
                    tabclass="report-tab text-md-semibold text-muted px-[2.0625rem]"
                    isactiveclass="headings"
                  />
                )
              )
            }
          />
        )
      )}
    </Card>
  );
};

export default ReportSidebar;
