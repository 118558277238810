import React, { useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  FlexCol,
  FlexColumnCenter,
  FlexRowBetween,
  SpaceBetweenW100,
} from "assets/styles/common-styles";
import {
  Card,
  ReportReviewWrapper,
  CustomerReportOverview,
  ReportOverviewSidebar,
  ReportOverviewWrapper,
  CreditScoreWrapper,
} from "assets/styles/customer-report-styles";
import { useSnapshot } from "valtio";
import store from "store";
import { useEffect } from "react";
import Spinner from "components/common/Spinner";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  removeUnderscores,
} from "utils/common";
import BackButton from "components/common/BackButton";
import Tab from "components/common/Tab";
import RadialChart from "components/common/RadialChart";
import IndepthAnalysisList from "components/customerReports/IndepthAnalysisList";
import { ReactComponent as NoData } from "assets/images/no-data.svg";
import { ReactComponent as Pending } from "assets/images/pending.svg";
import { getLocalStorageProperties } from "utils/user";

const ReportOverview = ({ reference }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(store.customerReport);
  const singleReportRequest = snap?.singleReportRequest;
  const singleReportRequestLoading = snap?.singleReportRequestLoading;
  const datapointDetails = snap?.datapointDetails;
  // const editDatapoints = snap?.editDatapoints;
  const [isOpenList, setIsOpenList] = useState(false);
  const maximUser = localStorage.getItem("user");
  const toExtract = ["company_id"];
  const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
  const { company_id: companyId } = extractedUsernames;

  useEffect(() => {
    if (companyId) {
      store?.customerReport.getDatapointsDetails();
      // store?.customerReport?.editDatapointsDetails();
    }
  }, [companyId]);

  const goBack = () => {
    navigate(-1);
  };

  const hasResultKey =
    singleReportRequest && Object.keys(singleReportRequest)?.includes("result");

  const mainTabs = ["financial", "credit"];
  const subTabs = {
    credit: [
      "credit_summary",
      "performance_classification",
      "delinquency_information",
      "indepth_analysis_of_credit_accounts",
    ],
  };

  const [activeTab, setActiveTab] = useState("financial");
  const [activeSubTab, setActiveSubTab] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);

    if (mainTabs?.includes(tab) && subTabs[tab]?.length > 0) {
      setActiveSubTab(subTabs[tab][0]);
    } else {
      setActiveSubTab(null);
    }
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const hasData = (tab, subTab = null) => {
    if (subTab) {
      const data =
        singleReportRequest?.result?.event_data?.meta?.[tab]?.[subTab];
      return (
        data && (Array.isArray(data) ? data.length : Object.keys(data).length)
      );
    } else {
      const data = singleReportRequest?.result?.event_data?.meta?.[tab];
      return (
        data && (Array.isArray(data) ? data.length : Object.keys(data).length)
      );
    }
  };

  // Render information based on the active tab
  const renderTabContent = () => {
    if (singleReportRequest?.result?.event_data?.meta) {
      const activeTabData =
        singleReportRequest?.result?.event_data?.meta?.[activeTab];
      if (activeTab === "financial" && activeTabData) {
        return (
          <>
            {Object.entries(activeTabData).map(([key, value]) => (
              <React.Fragment key={key}>
                {typeof value !== "object" &&
                (value !== null || value !== "") ? (
                  <div className="detail-item" key={key}>
                    <p className="text-base">
                      {capitalizeFirstLetter(removeUnderscores(key))}
                    </p>
                    <p className="text-base-medium darker">
                      {key === "updated_at" || key === "created_at"
                        ? format(new Date(value), "MMM dd, yyyy")
                        : capitalizeFirstLetter(removeUnderscores(value)) ||
                          value}
                    </p>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </>
        );
      } else if (activeTab === "credit" && activeTabData) {
        const otherSubTabs = subTabs[activeTab].filter(
          (tab) => tab !== "indepth_analysis_of_credit_accounts"
        );

        if (otherSubTabs.includes(activeSubTab)) {
          return (
            <>
              {activeTabData[activeSubTab] &&
                Object.entries(activeTabData[activeSubTab]).map(
                  ([key, value]) => (
                    <React.Fragment key={key}>
                      {key === "credit_score" ? null : key ===
                        "risk_level" ? null : value !== null && value !== "" ? (
                        <div className="detail-item" key={key}>
                          <p className="text-base">
                            {capitalizeFirstLetter(removeUnderscores(key))}
                          </p>
                          <p className="text-base-medium darker">
                            {key === "updated_at" || key === "created_at"
                              ? format(new Date(value), "MMM dd, yyyy")
                              : capitalizeFirstLetter(
                                  removeUnderscores(value)
                                ) || value}
                          </p>
                        </div>
                      ) : (
                        <div className="detail-item" key={key}>
                          <p className="text-base">
                            {capitalizeFirstLetter(removeUnderscores(key))}
                          </p>
                          <p className="text-base-medium darker">Nil</p>
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
            </>
          );
        } else {
          return (
            <section className="indepth-summary">
              <p className="title">In-depth analysis of credit accounts</p>
              <h2 className="heading-h1-type-2 dark mb-4">
                {
                  singleReportRequest?.result?.event_data?.meta?.credit
                    ?.indepth_analysis_of_credit_accounts?.length
                }
              </h2>
              {singleReportRequest?.result?.event_data?.meta?.credit
                ?.indepth_analysis_of_credit_accounts?.length > 0 && (
                <span
                  className="cursor-pointer accent-2 text-base-semibold"
                  onClick={() => setIsOpenList(true)}
                >
                  View all
                </span>
              )}
            </section>
          );
        }
      }
      return null;
    }
  };

  return (
    <>
      <section className={`${FlexCol} gap-5`}>
        <BackButton onClick={goBack} />
        <section className={`${Flex} ${SpaceBetweenW100} mb-4`}>
          <div className={`${Flex} gap-1`}>
            <span className="text-md-semibold text-dark">Consumer check</span>
            <span className="text-md-semibold">/</span>
            <span className="text-md-semibold text-dark">
              All consumer reports
            </span>
            <span className="text-md-semibold">/</span>
            {singleReportRequest && (
              <span className="text-md-medium">{`${capitalizeWords(
                singleReportRequest?.first_name
              )} ${
                singleReportRequest?.middle_name !== ""
                  ? `${capitalizeWords(singleReportRequest?.middle_name)} `
                  : ""
              }${capitalizeWords(singleReportRequest?.last_name)}`}</span>
            )}
          </div>
          {singleReportRequest?.consent && (
            <p className="text-md">
              Reference ID:{" "}
              <span className="text-md-medium darker">
                {singleReportRequest?.reference}
              </span>
            </p>
          )}
        </section>
      </section>
      <Card className="w-100 pl-[2.0625rem] pr-8 pt-[2.0625rem] pb-[3.75rem]">
        {singleReportRequestLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : (
          <>
            <section className={`${FlexRowBetween} mb-8`}>
              <div className={`${FlexRowBetween} w-[41%] gap-8`}>
                <p className="text-base-semibold headings">Basic information</p>
                {singleReportRequest?.consent && (
                  <p className="text-md-medium">
                    {format(
                      new Date(singleReportRequest?.consent.created_at),
                      "MMM dd, yyyy hh:mm aa"
                    )}
                  </p>
                )}
              </div>
              <div className={`${Flex} gap-2 text-base`}>
                <span>Status</span>
                <span className="text-base-medium text-dark">
                  {capitalizeFirstLetter(singleReportRequest?.status)}
                </span>
              </div>
            </section>
            <CustomerReportOverview
              className={`flex items-end gap-12 ${SpaceBetweenW100}`}
            >
              <div className="detail-item">
                <p className="text-base">Full Name</p>
                <p className="heading-h2-mid headings">
                  {singleReportRequest?.first_name &&
                    `${capitalizeWords(singleReportRequest?.first_name)} ${
                      singleReportRequest?.middle_name !== ""
                        ? `${capitalizeWords(
                            singleReportRequest?.middle_name
                          )} `
                        : ""
                    }${capitalizeWords(singleReportRequest?.last_name)}`}
                </p>
              </div>
              <div className="detail-item">
                <p>Country</p>
                <p className="text-base-medium darker">
                  {singleReportRequest?.country?.name}
                </p>
              </div>
              <div className="detail-item">
                <p>Phone</p>
                <p className="text-base-medium darker">
                  {singleReportRequest?.phone_number}
                </p>
              </div>
              <div className="detail-item">
                <p>ID Type</p>
                <p className="text-base-medium darker whitespace-nowrap">
                  {capitalizeFirstLetter(
                    removeUnderscores(singleReportRequest?.id_type)
                  )}
                </p>
              </div>
              <div className={`${Flex} gap-2 text-base`}>
                <p>Email</p>
                <p className="text-base-medium darker">
                  {singleReportRequest?.email}
                </p>
              </div>
            </CustomerReportOverview>
          </>
        )}
      </Card>
      {singleReportRequestLoading ? (
        <Spinner style={{ margin: "0.5rem auto" }} />
      ) : (
        <ReportOverviewWrapper>
          <Card className={ReportOverviewSidebar}>
            <div className="header">
              <p className="text-base-semibold headings title">
                Customer report
              </p>
              <p className="text-base">Based on your plan</p>
            </div>
            <Tab
              tabs={mainTabs?.map((mainTab) => ({
                label: capitalizeFirstLetter(mainTab) + " report",
                urlParam: mainTab,
              }))}
              activeTabLabel={activeTab}
              handleTabClick={handleTabClick}
              className="horizontal-tab"
              tabclass="report-tab text-base-semibold px-[2.0625rem] darker !leading-none justify-between"
              isactiveclass="active bg-maxim-white text-base-semibold"
              disabled={
                singleReportRequest?.status === "pending" ||
                singleReportRequest?.status === "processing"
                  ? true
                  : false
              }
              disabledclass="report-tab text-base-semibold px-[2.0625rem] text-muted !leading-none mb-4"
              subtabs={
                singleReportRequestLoading ? (
                  <Spinner style={{ margin: "0 auto" }} />
                ) : (
                  activeTab &&
                  subTabs[activeTab] && (
                    <Tab
                      tabs={subTabs[activeTab]?.map((subTab) => ({
                        label: capitalizeFirstLetter(removeUnderscores(subTab)),
                        urlParam: subTab,
                      }))}
                      activeTabLabel={activeSubTab}
                      handleTabClick={handleSubTabClick}
                      className="horizontal-tab sub-tab"
                      tabclass="report-tab text-md-semibold text-muted px-[2.0625rem]"
                      isactiveclass="darker"
                    />
                  )
                )
              }
            />
          </Card>
          {singleReportRequest?.status === "pending" ? (
            <Card
              className={`${ReportReviewWrapper} ${FlexColumnCenter} empty-state min-h-[47vh]`}
            >
              <Pending />
              <p className="text-headings font-semibold my-3">Report Pending</p>
              <div className="w-[40%]">
                <p className="text-neutral text-center">
                  Stay tuned while we fetch the details of your data requests.
                </p>
              </div>
            </Card>
          ) : hasResultKey &&
            singleReportRequest?.result !== null &&
            activeTab &&
            hasData(activeTab, activeSubTab) ? (
            <Card className={`${ReportReviewWrapper} report`}>
              {singleReportRequestLoading ? (
                <Spinner style={{ margin: "0.5rem auto" }} />
              ) : (
                singleReportRequest &&
                (datapointDetails?.plan !== "Free" ? (
                  <CustomerReportOverview className="flex">
                    <div className="reports-wrapper">
                      <p className="text-base-semibold card-header">
                        {capitalizeFirstLetter(
                          removeUnderscores(activeSubTab)
                        ) || capitalizeFirstLetter(activeTab) + " report"}
                      </p>
                      {singleReportRequest?.result && (
                        <section className="details-wrapper">
                          <div className="row half">
                            {activeTab && renderTabContent()}
                          </div>
                        </section>
                      )}
                    </div>
                    <div className="reports-sidebar">
                      {(singleReportRequest?.result?.event_data?.meta?.credit
                        ?.credit_summary?.credit_score ||
                        singleReportRequest?.result?.event_data?.meta?.credit
                          ?.credit_summary?.risk_level) && (
                        <CreditScoreWrapper>
                          {singleReportRequest?.result?.event_data?.meta?.credit
                            ?.credit_summary?.credit_score && (
                            <RadialChart
                              actualvalue={parseInt(
                                singleReportRequest?.result?.event_data?.meta
                                  ?.credit?.credit_summary?.credit_score
                              )}
                              totalvalue={800}
                              strokeWidth={15}
                              className=""
                              text={
                                singleReportRequest?.result?.event_data?.meta
                                  ?.credit?.credit_summary?.credit_score
                              }
                            />
                          )}
                          {singleReportRequest?.result?.event_data?.meta?.credit
                            ?.credit_summary?.risk_level && (
                            <div className="text-center">
                              <p className="text-base">Credit risk level</p>
                              <p className="text-base-medium">
                                {
                                  singleReportRequest?.result?.event_data?.meta
                                    ?.credit?.credit_summary?.risk_level
                                }
                              </p>
                            </div>
                          )}
                        </CreditScoreWrapper>
                      )}
                    </div>
                  </CustomerReportOverview>
                ) : (
                  <CustomerReportOverview>
                    <div className="free-wrapper">
                      <p className="text-base-semibold card-header">
                        {capitalizeFirstLetter(
                          removeUnderscores(activeSubTab)
                        ) || capitalizeFirstLetter(activeTab) + " report"}
                      </p>
                      <div className="mx-auto">
                        <CreditScoreWrapper className="w-[25rem] max-w-full">
                          {singleReportRequest?.result?.event_data?.meta?.credit
                            ?.credit_summary?.credit_score && (
                            <RadialChart
                              actualvalue={parseInt(
                                singleReportRequest?.result?.event_data?.meta
                                  ?.credit?.credit_summary?.credit_score
                              )}
                              totalvalue={800}
                              strokeWidth={15}
                              className=""
                              text={
                                singleReportRequest?.result?.event_data?.meta
                                  ?.credit?.credit_summary?.credit_score
                              }
                            />
                          )}
                          {singleReportRequest?.result?.event_data?.meta?.credit
                            ?.credit_summary?.risk_level && (
                            <div className="text-center">
                              <p className="text-base">Credit risk level</p>
                              <p className="text-base-medium">
                                {
                                  singleReportRequest?.result?.event_data?.meta
                                    ?.credit?.credit_summary?.risk_level
                                }
                              </p>
                            </div>
                          )}
                        </CreditScoreWrapper>
                      </div>
                    </div>
                  </CustomerReportOverview>
                ))
              )}
            </Card>
          ) : (
            <Card className={`${ReportReviewWrapper} report`}>
              <div className={`${FlexColumnCenter} empty-state`}>
                <NoData />
                <p className="text-headings font-semibold my-3">
                  No data returned
                </p>
                <div className="w-[40%]">
                  <p className="text-neutral text-center">
                    No available data found for this consumer.
                  </p>
                </div>
              </div>
            </Card>
          )}
          {isOpenList && (
            <IndepthAnalysisList
              isOpen={isOpenList}
              onClose={() => setIsOpenList(false)}
              reportDetails={singleReportRequest}
            />
          )}
        </ReportOverviewWrapper>
      )}
    </>
  );
};

export default ReportOverview;
