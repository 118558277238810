import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";

import { MediumButton } from "components/button";
import store from "store";
import { useSnapshot } from "valtio";
import Spinner from "components/common/Spinner";
import FloatingTextField from "components/formFields/FloatingTextField";
import SelectField from "components/formFields/SelectField";
import { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import Toast from "components/common/ToastNotification";
import { getLocalStorageProperties } from "utils/user";

const AddWebhookModal = ({ isOpen, onClose }) => {
  const settingsSnapshot = useSnapshot(store.settings);
  const webhookTypes = settingsSnapshot?.webhookTypes;
  const webhookTypesLoading = settingsSnapshot?.webhookTypesLoading;
  const [isLoading, setIsLoading] = useState(false);
  const [webhookData, setWebhookData] = useState({
    url: "",
    event: "",
  });
  const maximUser = localStorage.getItem("user");
  const toExtract = ["dashboard_state", "company_id"];
  const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
  const { dashboard_state: dashboardState, company_id: companyId } =
    extractedUsernames;

  useEffect(() => {
    if (companyId) {
      store.settings.getWebhookTypes();
    }
  }, [companyId]);

  const createWebhook = async () => {
    setIsLoading(true);
    const data = {
      env: dashboardState,
      service: webhookData.event,
      url: webhookData.url,
    };

    try {
      const response = await store?.settings?.subWebhook(data);
      if (response && response?.message === "Webhook saved") {
        onClose();
        setWebhookData({
          url: "",
          event: "",
        });
        toast.custom((t) => (
          <Toast toastType="success" text={response?.message} />
        ));
        await store?.settings?.getWebhooks();
      } else if (response?.errors) {
        const errorKeys = Object.keys(response?.errors);
        errorKeys?.forEach((key) => {
          (response?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType="error"
                text={errorMessage}
              />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <Modal isOpen={isOpen}>
        <ModalBodyWrapper className="h-full">
          <p className="font-secondary text-headings text-center text-3xl mb-10">
            Add a webhook
          </p>

          <div className="space-y-6 flex flex-col justify-between h-full">
            <div className="space-y-6">
              <SelectField
                label="Event Type"
                options={webhookTypes?.map((webhookType) => {
                  return {
                    value: webhookType.name,
                    key: webhookType.slug,
                  };
                })}
                value={webhookData.event}
                onChange={(e) =>
                  setWebhookData({ ...webhookData, event: e.target.key })
                }
                isLoading={webhookTypesLoading}
              />
              <FloatingTextField
                label="Webhook URL"
                type="text"
                id="webhook-url"
                name="webhook-url"
                labelfor="webhook-url"
                value={webhookData.url}
                onChange={(e) =>
                  setWebhookData({ ...webhookData, url: e.target.value })
                }
              />
            </div>

            <div className="flex items-center justify-between">
              <MediumButton
                bg="var(--white-alt)"
                border="var(--white-alt)"
                textcolor="var(--primary)"
                hovercolor="var(--white-alt)"
                onClick={() => onClose()}
              >
                Cancel
              </MediumButton>
              {isLoading ? (
                <Spinner style={{ margin: "0.5rem 0" }} />
              ) : (
                <MediumButton
                  bg="var(--accent-1)"
                  border="var(--accent-1)"
                  textcolor="var(--primary)"
                  hovercolor="var(--accent-1)"
                  onClick={createWebhook}
                >
                  Save changes
                </MediumButton>
              )}
            </div>
          </div>
        </ModalBodyWrapper>
      </Modal>
      <Toaster position="top-center" visibleToasts={5} />
    </div>
  );
};

export default AddWebhookModal;
