import { MediumButton } from 'components/button';
import Spinner from 'components/common/Spinner';
import Toast from 'components/common/ToastNotification';
import { toast } from 'sonner';
import FloatingTextField from 'components/formFields/FloatingTextField';
import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { useState } from 'react';
import store from 'store';
import { useSnapshot } from 'valtio';

const PassportNumberModal = ({ isOpen, onClose, IDName, reference, env, setHasDirector }) => {
  const [passportNumber, setPassportNumber] = useState('');
  const consentSnapshot = useSnapshot(store.consent);
  const loading = consentSnapshot?.validateIdLoading;

  const validateID = async () => {
    const body = {
      reference,
      env,
      id_number: passportNumber,
    };

    const response = await store?.consent?.validateId(body);

    if (response?.status >= 400) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={response?.data?.message}
        />
      ));
      return;
    } else {
      localStorage.setItem('id_data', JSON.stringify(response));
      setHasDirector(false);
      onClose();
      setPassportNumber('');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className='sm:min-w-[663px] h-fit sm:px-[6rem]'
    >
      <ModalBodyWrapper>
        <div className='flex flex-col gap-7 sm:gap-10'>
          <p className='font-secondary text-headings font-semibold text-xl sm:text-3xl text-center'>
            Enter your {IDName} number
          </p>

          <FloatingTextField
            label={`${IDName} Number`}
            type='text'
            id='passport-number'
            name='passport-number'
            labelfor='passport-number'
            value={passportNumber}
            onChange={(e) => setPassportNumber(e.target.value)}
          />

          <div className='flex justify-end'>
            {loading ? (
              <div className='flex flex-end'>
                <Spinner />
              </div>
            ) : (
              <MediumButton
                bg='var(--accent-1)'
                border='var(--accent-1)'
                textcolor='var(--primary)'
                hovercolor='var(--accent-1)'
                onClick={validateID}
              >
                Submit
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default PassportNumberModal;
