import React, { useState } from 'react';
import withFloatingLabel from 'HOC/FloatingLabelHoc';
import { FloatingInput, TextFieldWrapperStyles } from 'components/formFields/formStyles';
import { styled } from 'goober';

const TextFieldWrapper = styled("div")`
  ${TextFieldWrapperStyles}
`;

const FloatingTextField = ({ type, className, width, disabled, ...props }) => {
  const isPasswordInput = type === "password";
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    setValues({ ...values, password: event.target.value });
  };

  const customStyles = {
    "--textfield-width": width,
  };

  return (
    <TextFieldWrapper style={customStyles}>
      {!isPasswordInput &&
        <FloatingInput
          type={type}
          className={className}
          {...props}
        />
      }
      {isPasswordInput && (
        <>
          <FloatingInput
            type={values.showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            className={className}
            {...props}
          />
          <span
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            className="show-password"
          >
            {values.showPassword ? "Hide" : "Show"}
          </span>
        </>
      )}
    </TextFieldWrapper>
  );
};

export default withFloatingLabel(FloatingTextField);
