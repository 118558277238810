import { Link, useNavigate } from "react-router-dom";
import Checkbox from "components/formFields/Checkbox";
import { SmallButton } from "components/button";
import { OnboardingComplianceWrapper } from "assets/styles/onboarding-styles";
import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import { ReactComponent as PlusBg } from "assets/images/icons/plus-bg.svg";

const OnboardingCompliance = ({
  progressWidth,
  onlyProgress,
  isLive,
  isNotSubscribed,
}) => {
  const navigate = useNavigate();

  const handleContinueSetup = () => {
    navigate("/onboarding");
  };

  const handlePlansPage = () => {
    navigate("/plans");
  };

  return (
    <OnboardingComplianceWrapper>
      {onlyProgress ? (
        <>
          <section className="progress-bar-wrapper">
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width:
                    progressWidth !== undefined + "%" ? progressWidth : "0%",
                }}
              ></div>
            </div>
            <span className="text-base-semibold">{progressWidth}</span>
          </section>
          <p className="text-large-semibold title">Onboarding and compliance</p>
        </>
      ) : progressWidth === "100%" ? (
        <>
          <section className={FlexRowBetween}>
            <Checkbox
              label="Onboarding complete"
              name="onboarding-complete"
              checked
              readOnly
            />
            <span>100%</span>
          </section>
          <p className="text-large-semibold title">Activate a plan</p>
          {/* <p className="text-base subtitle">Next, to use the platform .</p> */}
          <section className={FlexRowBetween}>
            <Link to="/team-mates">
              <div className={`${Flex} add-teammates`}>
                <PlusBg />
                <p className="text-base-semibold">Activate plan</p>
              </div>
            </Link>
            <p className="text-md-spaced skip">SKIP</p>
          </section>
        </>
      ) : isLive ? (
        <>
          <p className="text-large-semibold text-white mb-2.5">
            Live environment
          </p>
          <p className="text-base text-muted mb-5">
            Welcome to the live environment. You need a subscription plan to get
            active.
          </p>
          <SmallButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            onClick={handlePlansPage}
          >
            Subscribe now
          </SmallButton>
        </>
      ) : isNotSubscribed ? (
        <>
          <p className="text-large-semibold text-white mb-2.5">
            No active subscription
          </p>
          <p className="text-base text-muted mb-5">
            Your subscription has expired. Kindly renew for an uninterrupted
            experience.
          </p>
          <SmallButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            onClick={handlePlansPage}
          >
            Subscribe now
          </SmallButton>
        </>
      ) : (
        <>
          <section className="progress-bar-wrapper">
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width:
                    progressWidth !== undefined + "%" ? progressWidth : "0%",
                }}
              ></div>
            </div>
            <span className="text-base-semibold">
              {progressWidth !== undefined + "%" ? progressWidth : "0%"}
            </span>
          </section>
          <p className="text-large-semibold title">Onboarding and compliance</p>
          <p className="text-base subtitle">
            You are required to provide your business information to get fully
            activated.
          </p>
          <SmallButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            onClick={handleContinueSetup}
          >
            Activate business
          </SmallButton>
        </>
      )}
    </OnboardingComplianceWrapper>
  );
};

export default OnboardingCompliance;
