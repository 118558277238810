import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/modals";
import { MediumButton } from "components/button";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { FlexCol, FlexColumnCenter } from "assets/styles/common-styles";
import { ReactComponent as Warning } from "assets/images/warning.svg";

const InsufficientUnitModal = ({ isOpen, onClose, text = "Consumer" }) => {
  const navigate = useNavigate();

  const navigateToPlans = () => {
    navigate("/plans");
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper
        className={`request-verification-success ${FlexColumnCenter}`}
      >
        <div className={`${FlexCol} header`}>
          <Warning />
          <h2 className="modal-title mb-[-1rem]">Insufficient plan unit</h2>
        </div>
        <section className={`${FlexCol} info`}>
          <p className="text-base">
            Based on your current subscription, you do not have sufficient units
            to run this{" "}
            <span className="text-base-medium dark">{text} Report Check.</span>{" "}
            Top up additional units for an uninterrupted experience
          </p>
        </section>
        <MediumButton
          bg="var(--accent-1)"
          textcolor="var(--primary)"
          onClick={() => {
            navigateToPlans();
            onClose();
          }}
        >
          Top up unit
        </MediumButton>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default InsufficientUnitModal;
