import { Link } from "react-router-dom";
import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import { Divider, RequestType, UrlBoxWrapper } from "assets/styles/docs-styles";
import { ReactComponent as ArrowRight } from "assets/images/icons/small-arrow-right.svg";
import { useState } from "react";

const UrlBox = ({ type, bg, color, endpoint, link }) => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  return (
    <UrlBoxWrapper className={`${FlexRowBetween} gap-4 relative`}>
      <div className={`${Flex} gap-4`}>
        <div className={`${Flex} gap-[1.1875rem]`}>
          <RequestType className={`text-md-medium ${bg} ${color}`}>
            {type}
          </RequestType>
          <Divider />
        </div>
        <p className="text-base break-all">
          <span className="complimentary-2">{"{{baseUrl}}"}</span>
          {endpoint}
        </p>
      </div>
      <Link
        to={link}
        className="flex items-center gap-2"
        onMouseEnter={() => setShowComingSoon(true)}
        onMouseLeave={() => setShowComingSoon(false)}
      >
        <span className="font-semibold text-muted ">Try</span>
        <ArrowRight stroke="var(--text-muted)" />
        {showComingSoon && (
          <span className="absolute top-[-15%] right-0 bg-[var(--stroke)] text-dark py-1 px-1 rounded text-xs-semibold">
            Coming soon
          </span>
        )}
      </Link>
    </UrlBoxWrapper>
  );
};

export default UrlBox;
