import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmallButton } from "components/button";
import NetworkSvg from "assets/images/network.svg";
import AddWebhookModal from "components/API-Webhooks/Webhook/AddWebhookModal";
import { useSnapshot } from "valtio";
import store from "store";
import Table from "components/table";
import { format } from "date-fns";
import Spinner from "components/common/Spinner";
import Toggle from "components/common/Toggle";
import { Toaster, toast } from "sonner";
import Toast from "components/common/ToastNotification";
import { getLocalStorageProperties } from "utils/user";

const WebhookSection = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const settingsSnapshot = useSnapshot(store.settings);
  const webhooks = settingsSnapshot?.webhooks;
  const webhookLoading = settingsSnapshot?.webhookLoading;
  const [data, setData] = useState([]);
  const onboardingSnapshot = useSnapshot(store.onboarding);
  const onboardingStage = onboardingSnapshot?.onboardingStage;
  const completionPercentage =
    onboardingStage && (onboardingStage?.percentage + "%" || "0%");
  const completionPercentageConverted = parseInt(completionPercentage, 10);
  const fiftyPercent = parseInt("50%", 10);
  // const AppEnv = process.env.REACT_APP_ENV?.toLowerCase() || "";
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  useEffect(() => {
    if (companyId) {
      store?.settings.getWebhooks();
    }
  }, [companyId]);

  const toggleWebhookStatus = async (ref) => {
    const toggle = await store?.settings.toggleWebhookStatus(ref);
    if (toggle) {
      await store?.settings.getWebhooks();
    } else {
      toast.custom((t) => <Toast toastType="error" text="Unable to switch" />);
    }
  };

  const columns = [
    {
      Header: "SERVICE",
      accessor: "service",
    },
    {
      Header: "TARGET URL",
      accessor: "url",
    },
    {
      Header: "NOTIFICATIONS",
      accessor: "notifications",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "createdAt",
    },
    {
      Header: "ACTION",
      accessor: "action",
    },
  ];

  const handleRowClick = (service) => {
    navigate(`/webhooks/${service}`);
  };

  useEffect(() => {
    if (webhooks && webhooks?.length > 0) {
      const refinedData = webhooks?.map((webhook) => {
        return {
          createdAt: format(
            new Date(webhook.created_at),
            "MMM dd yyyy, hh:mm a"
          ),
          service: webhook.service,
          url: webhook.url,
          notifications: webhook.notifications_count,
          status: (
            <Toggle
              label1={webhook.status ? "Enabled" : "Disabled"}
              label2=""
              name="status"
              isChecked={webhook.status}
              onChange={() => toggleWebhookStatus(webhook.id)}
            />
          ),
          action: (
            <span
              className="view-btn cursor-pointer"
              onClick={() => handleRowClick(webhook.service)}
            >
              View
            </span>
          ),
        };
      });
      setData(refinedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhooks]);

  if (webhookLoading)
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="p-11">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="font-semibold text-base text-maximblack-10 mb-2">
            Webhook configuration
          </p>
          <p className="text-maximgray-10 text-sm">
            Manage webhook settings for receiving notifications
          </p>
        </div>

        <SmallButton
          bg="var(--accent-1)"
          border="var(--accent-1)"
          textcolor="var(--primary)"
          onClick={() => setOpenModal(true)}
          disabled={!(completionPercentageConverted >= fiftyPercent)}
        >
          Add a webhook
        </SmallButton>
      </div>

      {webhooks && webhooks?.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          className="webhook-table"
          // onRowClick={handleRowClick}
        />
      ) : (
        <div className="flex flex-col items-center justify-center h-[40vh]">
          <img src={NetworkSvg} alt="network" draggable={false} />
          <p className="text-base mt-6 text-center">
            There are no configured <br /> webhooks at the moment
          </p>
        </div>
      )}
      {openModal && (
        <AddWebhookModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      <Toaster position="top-center" visibleToasts={1} />
    </div>
  );
};

export default WebhookSection;
