import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { useSnapshot } from "valtio";
import store from "store";
import Sidebar from "components/mainLayout/Sidebar";
import {
  Divider,
  DropdownContent,
  MainContainer,
  MainWrapper,
  PageHeader,
  UserImage,
  UserMenu,
} from "components/mainLayout/layoutStyles";
import Toast from "components/common/ToastNotification";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import { Flex } from "assets/styles/common-styles";
import Toggle from "components/common/Toggle";
import {
  getLocalStorageProperties,
  updateLocalStorageProperty,
} from "utils/user";
import { getFirstLetter } from "utils/common";

const MainLayout = ({ children, isOnboarding, pageHeader }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const maximUser = localStorage.getItem("user");
  const toExtract = ["first_name", "last_name", "is_live", "company_id"];
  const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
  const {
    first_name: FirstName,
    last_name: LastName,
    is_live: isLive,
    company_id: companyId,
  } = extractedUsernames;

  const snap = useSnapshot(store.misc);
  const environment = snap?.environment;

  const [isChecked, setIsChecked] = useState(
    environment?.environment === "live" ? true : false
  );
  const AppEnv = process.env.REACT_APP_ENV?.toLowerCase() || "";

  useEffect(() => {
    if (environment?.environment === "sandbox") {
      setIsChecked(false);
    } else if (environment?.environment === "live") {
      setIsChecked(true);
    }
  }, [environment?.environment]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSwitchEnvironment = async () => {
    if (!companyId) {
      return navigate("/onboarding");
    }
    if (!isLive) {
      const response = await store.settings.goLive();
      if (response) {
        updateLocalStorageProperty("user", "is_live", response);
        navigate("/get-started");
      } else {
        return toast.custom((t) => (
          <Toast
            toastType="error"
            text="Your director's verification has not been completed. Requirements are available in the company success email."
          />
        ));
      }
    }
    const switchEnv = await store.misc.switchEnvironment();
    if (switchEnv) {
      const response = await store.misc.getEnvironment();
      if (response?.environment) {
        updateLocalStorageProperty(
          "user",
          "dashboard_state",
          response.environment
        );
        navigate("/get-started");
        window.location.reload();
      }
      // } else if (switchEnv && switchEnv?.success === false) {
      //   toast.custom((t) => (
      //     <Toast toastType="error" text={switchEnv?.message} />
      //   ));
    } else {
      toast.custom((t) => (
        <Toast
          toastType="error"
          text="Unable to switch"
        />
      ));
    }
  };

  async function logout() {
    try {
      const response = await store.auth.logout();
      if (
        response &&
        (response?.data?.message === "Logout Successful" ||
          response?.message === "Logout Successful")
      ) {
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast
          toastType="error"
          text={"Something went wrong"}
        />
      ));
    }
  }

  return (
    <MainWrapper>
      <Sidebar isOnboarding={isOnboarding} />
      <MainContainer>
        <PageHeader>
          <h3 className="heading-h3">{pageHeader}</h3>
          <div className={`${Flex} right`}>
            {AppEnv === "sandbox" ? (
              <Toggle
                label1="Sandbox"
                label2="Live"
                name="switch_env"
                isChecked={false}
                disabled={true}
              />
            ) : (
              <Toggle
                label1="Sandbox"
                label2="Live"
                name="switch_env"
                onChange={() => handleSwitchEnvironment()}
                isChecked={isChecked}
              />
            )}
            <Divider />
            <UserMenu onClick={() => toggleDropdown()}>
              <UserImage>
                <span className={`brand-icon text-base-bold`}>
                  {`${
                    FirstName !== null
                      ? getFirstLetter(FirstName) + getFirstLetter(LastName)
                      : "U"
                  }`}
                </span>
              </UserImage>
              <ArrowDown stroke="var(--primary)" />
            </UserMenu>
            {isDropdownOpen && (
              <DropdownContent>
                <div
                  className="text-base-semibold dropdown-item"
                  onClick={() => logout()}
                >
                  Logout
                </div>
              </DropdownContent>
            )}
          </div>
        </PageHeader>
        <div className="main">{children}</div>
      </MainContainer>
      <Toaster
        position="top-center"
        visibleToasts={3}
      />
    </MainWrapper>
  );
};

export default MainLayout;
