import React from 'react';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { ModalBodyWrapper, } from 'components/modals/modalStyles';
import { FlexColumnBetween } from 'assets/styles/common-styles';
import { ReactComponent as StarCheck } from 'assets/images/icons/star-check.svg';

const UserVerificationSuccessModal = ({ isOpen, handleCloseUserVerificationSuccess }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper className={`request-sent ${FlexColumnBetween}`}>
        <StarCheck />
        <div>
          <h2 className="modal-title">Welcome to Maxim</h2>
          <p className="text-base">You completed your company and individual verification successfully. Your account is now fully activated.</p>
        </div>
        <MediumButton bg="var(--accent-1)" textcolor="var(--primary)" onClick={handleCloseUserVerificationSuccess}>
          Explore Dashboard
        </MediumButton>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default UserVerificationSuccessModal;
