import { Flex, SpaceBetweenW100 } from "assets/styles/common-styles";
import { Card } from "assets/styles/customer-report-styles";
import Spinner from "components/common/Spinner";
import { format } from "date-fns";
import { capitalizeFirstLetter, capitalizeWords } from "utils/common";

const ReportSummary = ({
  singleCommercialCheck,
  singleCommercialCheckLoading,
}) => {
  return (
    <Card className="w-100 pl-[2.0625rem] pr-8 pt-[2.0625rem] pb-[3.75rem]">
      {singleCommercialCheckLoading ? (
        <Spinner style={{ margin: "0.5rem auto" }} />
      ) : (
        <>
          <section
            className={`mb-8 grid`}
            style={{ gridTemplateColumns: "37% 63%" }}
          >
            <p className="text-base-semibold headings">CAC information</p>
            <div className={`${Flex} ${SpaceBetweenW100}`}>
              <p className="text-md-medium text-dark">
                RC No: {singleCommercialCheck?.company_number}
              </p>
              <div className="text-base-medium text-dark flex items-center gap-2">
                <span className="text-base neutral font-normal">
                  Report Status:
                </span>
                <div
                  className={`status-ball ${
                    singleCommercialCheck?.status === "completed" ||
                    singleCommercialCheck?.status === "active"
                      ? "active"
                      : singleCommercialCheck?.status === "processing"
                      ? "pending"
                      : "error"
                  }`}
                ></div>
                {capitalizeFirstLetter(singleCommercialCheck?.status)}
              </div>
              <span className="text-base">
                {singleCommercialCheck?.created_at &&
                  format(
                    new Date(singleCommercialCheck?.created_at),
                    "MMM dd yyyy, hh:mm aa"
                  )}
              </span>
            </div>
          </section>
          <section className="grid" style={{ gridTemplateColumns: "37% 63%" }}>
            <div className="detail-item pr-2">
              <p className="text-base">Company Name</p>
              <p className="heading-h2-mid headings">
                {capitalizeWords(singleCommercialCheck?.company_name)}
              </p>
            </div>
            <div className="flex items-end gap-[4.25rem]">
              <div className="detail-item">
                <p>Company Status</p>
                <div className={`${Flex} gap-1.5 text-base-medium darker`}>
                  <div
                    className={`status-ball ${
                      singleCommercialCheck?.company_status === "active"
                        ? "active"
                        : "error"
                    }`}
                  ></div>
                  {capitalizeFirstLetter(singleCommercialCheck?.company_status)}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Card>
  );
};
export default ReportSummary;
