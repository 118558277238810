import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import Warning from 'assets/images/warning.svg';
import {
  FlexCol,
  FlexColumnCenter,
  FlexRowBetween,
} from 'assets/styles/common-styles';
import { MediumButton } from 'components/button';
import store from 'store';
import { useSnapshot } from 'valtio';
import Spinner from 'components/common/Spinner';

const ConfirmRegenerateKeys = ({ isOpen, onClose }) => {
  const settingsSnapshot = useSnapshot(store.settings);
  const loading = settingsSnapshot?.regenerateLoading;

  const regenerateFn = async () => {
    const response = await store?.settings?.regenerateApiKey();

    if (response?.key?.length) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        <div
          className={FlexCol}
          style={{ height: '100%', marginTop: '70px' }}
        >
          <div className={FlexColumnCenter}>
            <img
              src={Warning}
              alt='warning'
              style={{ width: '72px', height: '72px', marginBottom: '20px' }}
            />
            <h1
              style={{ fontSize: '28px', marginBottom: '22px' }}
              className='headings'
            >
              Regenerate API keys
            </h1>
            <p
              style={{ textAlign: 'center' }}
              className='text-base'
            >
              Generating new API keys will cause your test secret key to expire.
              This action cannot be undone.
            </p>
          </div>

          <div
            className={FlexRowBetween}
            style={{ marginTop: '100px' }}
          >
            <MediumButton
              bg='var(--white-alt)'
              border='var(--white-alt)'
              textcolor='var(--primary)'
              hovercolor='var(--white-alt)'
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {loading ? (
              <Spinner style={{ margin: '0.5rem auto' }} />
            ) : (
              <MediumButton
                bg='var(--complimentary-2)'
                border='var(--complimentary-2)'
                textcolor='var(--white-alt)'
                hovercolor='var(--complimentary-2)'
                onClick={regenerateFn}
              >
                Regenerate API Keys
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default ConfirmRegenerateKeys;
