import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalOverlay, ModalWrapper } from "components/modals/modalStyles";
import { ReactComponent as Close } from "assets/images/icons/close.svg";

const Modal = ({ isOpen, onClose, className, children }) => {
  useEffect(() => {
    const modalRoot = document.getElementById("modal-root");

    if (!modalRoot) {
      const newModalRoot = document.createElement("div");
      newModalRoot.id = "modal-root";
      document.body.appendChild(newModalRoot);
    }

    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <ModalOverlay>
      <ModalWrapper className={className}>
        {onClose && (
          <div className="modal-close-btn" onClick={onClose}>
            <Close />
          </div>
        )}
        {children}
      </ModalWrapper>
    </ModalOverlay>,
    document.getElementById("modal-root")
  );
};

export default Modal;
