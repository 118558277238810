import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const GetVerificationDetails = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          404 Error
        </div>
      ),
      key: "404",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      headerData: [
        {
          key: "country-code",
          value: "ng",
          description: "-",
        },
        {
          key: "company-id",
          value: "NG-C01hkfwenr1mqbp2y5359nc2jdr",
          description: "-",
        },
      ],
      response: {
        "message": "Data fetched successfully",
        "data": {
          "id": "UID-01hp22598xzp250q9hs65qjwjm",
          "first_name": "Joshua",
          "middle_name": "",
          "last_name": "Isaac",
          "email": "******ua@test.com",
          "phone_number": "*******6789",
          "id_type": "bvn",
          "id_number": "22*******22",
          "match": true,
          "created_at": "2024-02-07T15:14:39.000000Z",
          "nationality": "Nigeria"
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to retrieve detailed information about a specific verification was successful.
        </>
      ),
    },
    400: {
      headerData: [
        {
          key: "country-code",
          value: "ng",
          description: "-",
        },
        {
          key: "company-id",
          value: "NG-C01hkfwenr1mqbp2y5359nc2jdr",
          description: "-",
        },
      ],
      response: {
        "message": "Data not found"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This indicates that the requested verification was not found.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="User ID Verification"
      pageDescription="Use this endpoint to verify or know your customer using User ID  such as Bank Verification Number - BVN."
      subtitle="Get verification details"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/uid-verifications/UID-01hp22598xzp250q9hs65qjwjm"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default GetVerificationDetails;
