import { Flex } from "assets/styles/common-styles";
import {
  MainPageContent,
  MainPageTitle,
} from "assets/styles/docs-styles";
import DocsLayout from "components/api-docs/layout";
import SideContent from "components/api-docs/layout/SideContent";

const ApiDocsApiKeysWebhook = () => {
  return (
    <DocsLayout>
      <MainPageTitle className={Flex}>
        <h2 className="heading-h1 headings">Setting up API keys and webhook</h2>
      </MainPageTitle>
      <MainPageContent>
        <section>
          <p className="mb-6 leading-[1.75rem]">
            Setting up API keys and webhooks is a crucial part of integrating
            Maxim API into your applications. This guide will walk you through
            generating API keys, using them for authentication, configuring
            webhooks, ensuring security, testing, and troubleshooting.
          </p>
          <p className="mb-6 leading-[1.75rem]">
            Maxim employs webhooks to stay informed about updates related to
            transaction objects initiated through the API. These webhooks serve
            as triggers for launching additional workflows based on these
            events. Upon the occurrence of an event being monitored, Maxim
            receives a POST request from Mono at the specified Webhook URL,
            containing pertinent information about the transaction event.
          </p>
          <p className="leading-[1.75rem]">
            In order to ensure timely communication, Maxim's endpoint is
            expected to promptly respond to these webhooks. To acknowledge the
            receipt of a webhook, the endpoint must return a 200 HTTP status
            code. It is essential to note that this status code is indicative of
            message reception and not necessarily confirmation that Maxim's
            system has successfully processed the message. Any additional
            information provided in the response headers or body is disregarded.
          </p>
        </section>
        <SideContent />
      </MainPageContent>
    </DocsLayout>
  );
};

export default ApiDocsApiKeysWebhook;
