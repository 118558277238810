import React from 'react';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { FlexCenter, FlexColumnBetween } from 'assets/styles/common-styles';
import { ReactComponent as StarCheck } from "assets/images/icons/star-check.svg";
import { ReactComponent as Save } from "assets/images/icons/save.svg";
import { Link } from 'react-router-dom';

const DirectorInfoVerificationSuccessModal = ({
  isOpen,
  onClose,
  handleUserVerificationSuccess,
  directorKycLink,
  handleSaveAndContinue,
}) => {
  const kycLinkStorage = JSON.parse(localStorage.getItem('directorKycLink'));
  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper className={`verification-success ${FlexColumnBetween}`}>
        <StarCheck />
        <h2 className="modal-title">Date of birth submitted successfully</h2>
        <Link 
          to={kycLinkStorage?.url || directorKycLink?.url} 
          target="_blank" 
          onClick={() => {
            handleUserVerificationSuccess();
            onClose();
          }}
        >
          <MediumButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
          >
            Begin verification
          </MediumButton>
        </Link>
        <section>
          <p>For a smooth procedure, ensure that you have good lighting and a clear background. Not ready?</p>
          <div className={`${FlexCenter} save-continue-later`} onClick={()=> handleSaveAndContinue()}>
            <Save />
            <p className="text-base-semibold">Save and continue later</p>
          </div>
        </section>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default DirectorInfoVerificationSuccessModal;
