import React, { useState } from "react";
import withFloatingLabel from "HOC/FloatingLabelHoc";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { CustomDropdown, CustomDropdownIcon, CustomOption, FloatingSelect, SelectFieldWrapper } from "components/formFields/formStyles";
import TextField from "components/formFields/TextField";
import Spinner from "components/common/Spinner";

const FloatingSelectField = ({
  label,
  options,
  value,
  onChange,
  className="",
  width,
  search,
  isDisabledSelect,
  isLoading,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || "");
  const [searchText, setSearchText] = useState("");
  const customStyles = {
    "--selectfield-width": width,
  };

  const handleOpen = () => {
    if (!isDisabledSelect) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (optionValue, optionKey) => {
    setCurrentValue(optionValue);
    setIsOpen(false);
    onChange({ target: { value: optionValue, key: optionKey } });
  };

  const filteredOptions = options?.filter(option =>
    typeof option.value === "string" ? option.value.toLowerCase().includes(searchText.toLowerCase())
    : option.value
  );

  // const unfilteredOptions = options?.filter(option =>
  //   option.value.includes(searchText)
  // );

  const handleSearchInputChange = event => {
    setSearchText(event.target.value);
  };
  
  return (
    <SelectFieldWrapper style={customStyles} disabled={isDisabledSelect}>
      <FloatingSelect
        className={`${className} ${isDisabledSelect ? "disabled" : ""}`}
        onClick={() => handleOpen()}
        {...props}
      >
        {currentValue}
        <CustomDropdownIcon><ArrowDown stroke="var(--text-neutral)" /></CustomDropdownIcon>
      </FloatingSelect>
      
      {isOpen && (
        <CustomDropdown id="custom-dropdown" className="custom-height">
          {isLoading ? (
            <Spinner style={{margin: "1rem auto"}}/>
          ) : (
            <>
              {search && (
                <div className="search">
                  <TextField
                    type="search"
                    name="search"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    iconLeft={<SearchIcon className="icon left-icon" />}
                    className="text-indent"
                    width="420px"
                    wrapperclass="input-sm"
                  />
                </div>
              )}
              {filteredOptions?.length > 0 ?
                filteredOptions?.map((option) => {
                  return (
                  <CustomOption
                    key={option.key}
                    selected={option.value === currentValue}
                    onClick={() => handleSelect(option.value, option.key)}
                    className={option.value === currentValue ? "selected" : ""}
                  >
                    {option.value}
                  </CustomOption>
                )})
                : (
                  <p className="text-base-medium not-found">No data found</p>
                )
              }
            </>
          )}
        </CustomDropdown>
      )}
    </SelectFieldWrapper>
  );
};

export default withFloatingLabel(FloatingSelectField);
