import { css, styled } from "goober";

export const FloatingLabelWrapper = styled("div")`
  position: relative;
  &:focus-within label {
    transform: translate(0, -6px) scale(0.875);
    font-size: 0.875rem;
  }
  &.full-width {
    width: 100%;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const InputLabel = styled("label")`
  position: absolute;
  pointer-events: none;
  top: 19.5px;
  left: 1.25rem;
  transform: translate(0, 0) scale(1);
  font-size: 1rem;
  color: var(--text-neutral);
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  transform-origin: top left;

  &.active {
    transform: translate(0, -6px) scale(0.875);
    font-size: 0.875rem;
  }
`;

export const TextFieldWrapperStyles = `
  width: 100%;
  max-width: var(--textfield-width);
  position: relative;
  .show-password {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: var(--accent-2);
    cursor: pointer;
    position: absolute;
    top: 19.5px;
    right: 23px;
  }
  .icon {
    position: absolute;
    top: var(--icon-top-spacing, 1.125rem);
    &.left-icon {
      left: var(--icon-left-spacing, 1.25rem);
    }
  }
  &.input-sm {
    input {
      padding: 0.4375rem 1.25rem;
      border-radius: var(--br-sm);
      font-size: 0.875rem;
    }
    .icon {
      position: absolute;
      top: var(--icon-top-spacing, 0.5625rem);
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
      &.left-icon {
        left: var(--icon-left-spacing, 1.25rem);
      }
    }
  }
`;

export const FloatingInput = styled("input")`
  position: relative;
  padding: 1.625rem 1.25rem 0.5rem;
  border-radius: var(--br);
  border: 1px solid var(--stroke);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--text-dark);

  &:focus {
    border-color: var(--accent-2);
    box-shadow: none;
  }
`;

export const Input = styled("input")`
  position: relative;
  padding: var(--input-padding, 0.75rem 1.25rem);
  border-radius: var(--br);
  border: 1px solid var(--stroke);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--text-neutral);

  &.big-input {
    padding: 1.0625rem 1.25rem;
  }
  &:focus {
    border-color: var(--accent-2);
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.text-indent {
    text-indent: 1.75rem;
  }
`;

export const SelectFieldWrapper = styled("div")`
  width: 100%;
  max-width: var(--selectfield-width);
  min-width: var(--selectfield-minwidth);
  position: relative;
  &.dropdown-md {
    .filter {
      padding: 0.4375rem 1.25rem;
      border-radius: var(--br-sm);
      font-size: 0.875rem;
      svg {
        width: 0.625rem;
        height: 0.375rem;
      }
    }
    .icon {
      position: absolute;
      top: var(--icon-top-spacing, 0.5625rem);
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
      &.left-icon {
        left: var(--icon-left-spacing, 1.25rem);
      }
    }
    .filter-sm {
      padding: 0.4375rem 0.75rem;
      border-radius: var(--br-sm);
      font-size: 0.875rem;
      svg {
        width: 0.625rem;
        height: 0.375rem;
      }
    }
  }
`;

export const FloatingSelect = styled("div")`
  appearance: none;
  padding: 1.625rem 1.125rem 0.5rem;
  border-radius: var(--br);
  border: 1px solid var(--stroke);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  height: 3.75rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--text-dark);
  cursor: pointer;
  width: 100%;
  &.disabled {
    cursor: not-allowed;
    opacity: unset;
  }

  &:focus {
    border-color: var(--accent-2);
    box-shadow: none;
  }
`;

export const CustomDropdownIcon = styled('div')`
  position: absolute;
  top: 50%;
  right: 1.4375rem;
  transform: translateY(-50%);
`;

export const CustomDropdown = styled('div')`
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 20px 10px 0;
  border-radius: var(--br-md);
  border: 1px solid var(--stroke);
  background: var(--white);
  z-index: 1;
  &.above {
    top: -12%;
    height: 177px;
    transform: translateY(-100%);
  }
  &.custom-height {
    max-height: 200px;
  }
  .search {
    padding: 1rem 0 1rem 1rem;
  }
  .not-found {
    color: var(--text-dark);
    padding: 0 1.375rem;
  }
`;

export const CustomOption = styled('div')`
  font-size: 1rem;
  color: var(--text-dark);
  padding: 8px 20px 10px 20px;
  cursor: pointer;
  &:hover, 
  &.selected {
    background: var(--bg);
  }
`;

export const Select = styled("div")`
  padding: 0.875rem 1.25rem;
  border-radius: var(--br);
  background: var(--white);
  border: 1px solid var(--stroke);
  color: var(--text-neutral);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--text-neutral);
  cursor: pointer;
  white-space: nowrap;
  width: 100%;

  &:focus {
    border-color: var(--accent-2);
    box-shadow: none;
  }

  &.select-sm {
    padding: 0.625rem 1.1875rem;
    border-radius: var(--br-sm);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const TextAreaWrapper = styled("div")`
  min-width: 15rem;
  width: var(--textareafield-width);
`;

export const FloatingTextArea = styled("textarea")`
  padding: 1.625rem 1.25rem 0.5rem;
  border-radius: var(--br);
  border: 1px solid var(--stroke);
  font-size: 1rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.5rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--text-dark);

  &:focus {
    border-color: var(--accent-2);
    box-shadow: none;
  }
`;

export const Check = styled("input")`
`;

export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Form244H = styled("section")`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &.h-274 {
    height: 100%;
    max-height: 24.985rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1.5rem;
  }
`;

export const InputsWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const OtpFields = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 5.75rem;
  input {
    min-width: 3.75rem;
    min-height: 3.75rem;
    width: 3.75rem;
    height: 3.75rem;
    font-size: 2rem;
  }
`;

export const checkboxWrapperStyle = `
  display: flex;
  align-items: center;
  gap: var(--gap, 1rem);
  cursor: pointer;
  color: var(--label-color);
`;

export const CheckboxWrapper = styled("label")`
  ${checkboxWrapperStyle}
  ${(props) => props.customstyles}
`;

export const CheckboxInput = css`
  opacity: 0;
  position: absolute;
  width: 0;
`;

export const CheckboxCustom = css`
  position: relative;
  min-width: 1.125rem;
  min-height: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  border: 2px solid var(--stroke);
  border-radius: 0.25rem;
  background-color: var(--white);
  font-family: inherit;
  transition: background-color 0.3s, border-color 0.3s;
  &.checked {
    background-color: var(--accent-2);
    border: none;
  }
  &.muted {
    background-color: var(--stroke);
    border: none;
  }
`;

export const CheckmarkIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0;
  &.checked {
    opacity: 1;
  }
  &.muted {
    opacity: 1;
  }
`;

export const RadioButtonWrapper = styled('label')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
`;

export const RadioButtonInput = styled('input')`
  appearance: none;
  background-color: var(--white);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  min-width: 1.15em;
  min-height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;

  &:checked::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    background: var(--accent-2);
    border-radius: 50%;
    transform: scale(1);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }
`;
