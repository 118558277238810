import React from "react";
import { useTable, useGlobalFilter } from "react-table";
import TextField from "components/formFields/FloatingTextField";
import Spinner from "components/common/Spinner";
import { SmallButton } from "components/button";

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <TextField
        placeholder="search"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};

const Table = ({
  columns,
  data,
  showSearch = false,
  className,
  onRowClick,
  isLoading,
  onLoadMore,
  showLoadMoreButton = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data, // Use the provided data directly
      initialState: {
        pageSize: 5,
      },
      manualPagination: true,
      pageCount: Math.ceil(data.length / 5), // Adjust page size as needed
      useControlledState: (state) => ({
        ...state,
        pageIndex: state.pageIndex, // Use controlled state for pagination
      }),
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  return (
    <div className={className}>
      {showSearch && (
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      )}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...otherProps } = headerGroup.getHeaderGroupProps();
            return (
            <tr {...otherProps} key={key}>
              {headerGroup.headers.map((column) => {
                const { key, ...otherProps } = column.getHeaderProps();
                return (
                <th {...otherProps} key={key}>{column.render("Header")}</th>
              )})}
            </tr>
          )})}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr align="center">
              <td align="center">
                <Spinner style={{ margin: "0" }} />
              </td>
            </tr>
          ) : rows?.length > 0 ? (
            rows?.map((row) => {
              prepareRow(row);
              const { key, ...otherProps } = row.getRowProps();
              return (
                <tr 
                  key={key} {...otherProps}
                  onClick={() => {
                    if (onRowClick) {
                      if (
                        onRowClick &&
                        row.original.reference &&
                        !row.original.id
                      ) {
                        onRowClick(row.original.reference);
                      } else {
                        onRowClick(row.original);
                      }
                    }
                  }}
                  className={onRowClick ? "cursor-pointer" : ""}
                >
                  {row.cells.map((cell) => {
                    const { key, ...otherProps } = cell.getCellProps();
                    return (
                    <td {...otherProps} key={key}>{cell.render("Cell")}</td>
                  )})}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      {onLoadMore && showLoadMoreButton && (
        <SmallButton
          bg="var(--stroke)"
          border="var(--stroke)"
          textcolor="var(--primary)"
          className="mt-8 mx-auto"
          onClick={onLoadMore}
        >
          Load More
        </SmallButton>
      )}
    </div>
  );
};

export default Table;
