import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import store from "store";
import {
  Card,
  CustomerReport,
  ReportWrapper,
} from "assets/styles/customer-report-styles";
import MainLayout from "components/mainLayout";
import { SmallButton } from "components/button";
import Results from "components/lookup/Results";
import SmallCard from "components/common/SmallCard";
import Spinner from "components/common/Spinner";
import { getLocalStorageProperties } from "utils/user";
import { Progress } from "antd";
import { FlexCol } from "assets/styles/common-styles";
import InitiateLookup from "components/lookup/InitiateLookup";
import TopupUnitModal from "components/lookup/TopupUnitModal";
import OnboardingCompliance from "components/dashboard/OnboardingCompliance";

const ProfileCheck = () => {
  const snap = useSnapshot(store.profileCheck);
  const miscSnapshot = useSnapshot(store.misc);
  const onboardingSnapshot = useSnapshot(store.onboarding);
  const commercialCheckSnapshot = useSnapshot(store.commercialCheck);
  const units = snap?.units;
  const unitsLoading = snap?.unitsLoading;
  const [isProfileCheckFormOpen, setIsProfileCheckFormOpen] = useState(false);
  const [isTopUpUnitsOpen, setIsTopUpUnitsOpen] = useState(false);
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;
  const environment = miscSnapshot?.environment;
  const environmentLoading = miscSnapshot?.environmentLoading;
  const onboardingStage = onboardingSnapshot?.onboardingStage;
  const onboardingStageLoading = onboardingSnapshot?.onboardingStageLoading;
  const completionPercentage = onboardingStage?.percentage + "%" || "0%";
  const subscriptionData = commercialCheckSnapshot?.subscriptionData;
  const subscriptionLoading = commercialCheckSnapshot?.subscriptionLoading;

  useEffect(() => {
    if (companyId) {
      store.profileCheck.getUnits();
    }
  }, [companyId]);

  useEffect(() => {
    store?.onboarding.getOnboardingStage();
  }, []);

  return (
    <MainLayout pageHeader="User ID Verification">
      <ReportWrapper>
        <div className="card-row">
          <SmallCard
            cardTitle="Initiate User ID Verification"
            cardDesc="Crafting an Intuitive BVN search experience: begin your seamless identification journey now!"
            btnText="Start lookup"
            btnClick={() => setIsProfileCheckFormOpen(true)}
            disabled={!!companyId}
          />
          <Card className={`${CustomerReport} justify-between`}>
            <p className="text-base-semibold card-header line-height-normal">
              Available units
            </p>
            {unitsLoading ? (
              <Spinner style={{ margin: "0.5rem auto" }} />
            ) : (
              <>
                <div className={`${FlexCol} gap-3`}>
                  <Progress
                    percent={(units?.units_used / units?.total_units) * 100}
                    showInfo={false}
                    trailColor="#E6F6FC"
                    strokeColor={"var(--complimentary-1"}
                    className="plan-progress"
                  />
                  <p className="text-neutral text-[0.625rem] tracking-[1.5px] font-bold whitespace-nowrap">
                    {units?.units_used || 0} OF {units?.total_units || 0} UNITS
                    USED
                  </p>
                </div>
                <SmallButton
                  bg="var(--white-alt)"
                  border="var(--white-alt)"
                  textcolor="var(--text-dark)"
                  onClick={() => setIsTopUpUnitsOpen(true)}
                  disabled={
                    environment?.environment === "sandbox" || !companyId
                      ? true
                      : false
                  }
                >
                  Get units
                </SmallButton>
              </>
            )}
          </Card>
        </div>
        <Results setIsProfileCheckFormOpen={setIsProfileCheckFormOpen} />
      </ReportWrapper>
      {isProfileCheckFormOpen && (
        <InitiateLookup
          isOpen={isProfileCheckFormOpen}
          onClose={() => setIsProfileCheckFormOpen(false)}
          setIsTopUpUnitsOpen={setIsTopUpUnitsOpen}
        />
      )}
      {isTopUpUnitsOpen && (
        <TopupUnitModal
          isOpen={isTopUpUnitsOpen}
          onClose={() => setIsTopUpUnitsOpen(false)}
          setIsTopUpUnitsOpen={setIsTopUpUnitsOpen}
        />
      )}
      {onboardingStageLoading ? (
        ""
      ) : completionPercentage !== "50%" && completionPercentage !== "100%" ? (
        <OnboardingCompliance progressWidth={completionPercentage} />
      ) : subscriptionLoading || environmentLoading ? (
        ""
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length === 0 ? (
        <OnboardingCompliance isLive />
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length > 0 &&
        !subscriptionData?.subscription_details?.active ? (
        <OnboardingCompliance isNotSubscribed />
      ) : (
        ""
      )}
    </MainLayout>
  );
};

export default ProfileCheck;
