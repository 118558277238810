import React from 'react';
import MainLayout from 'components/mainLayout';
import CommercialCheckSection from 'components/commercialCheck/CommercialCheckSection';

const CommercialCheckPage = () => {
  return (
    <MainLayout pageHeader='Commercial Check'>
      <CommercialCheckSection />
    </MainLayout>
  );
};

export default CommercialCheckPage;
