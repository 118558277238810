import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmallButton } from "components/button";
import { Progress } from "antd";
import LookupDrawer from "./LookupDrawer";
import store from "store";
import { useSnapshot } from "valtio";
import Spinner from "components/common/Spinner";
import GetUnitsModal from "components/commercialCheck/GetUnitsModal";
import Results from "components/commercialCheck/Results";
import { getLocalStorageProperties } from "utils/user";
import OnboardingCompliance from "components/dashboard/OnboardingCompliance";

const CommercialCheckSection = () => {
  const navigate = useNavigate();
  const [openLookupDrawer, setOpenLookupDrawer] = useState(false);
  const commercialCheckSnapshot = useSnapshot(store.commercialCheck);
  const miscSnapshot = useSnapshot(store.misc);
  const onboardingSnapshot = useSnapshot(store.onboarding);
  const onboardingStage = onboardingSnapshot?.onboardingStage;
  const onboardingStageLoading = onboardingSnapshot?.onboardingStageLoading;
  const completionPercentage = onboardingStage?.percentage + "%" || "0%";
  const subscriptionData = commercialCheckSnapshot?.subscriptionData;
  const subscriptionLoading = commercialCheckSnapshot?.subscriptionLoading;
  const environment = miscSnapshot?.environment;
  const environmentLoading = miscSnapshot?.environmentLoading;
  const [openGetUnitModal, setOpenGetUnitModal] = useState(false);
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  useEffect(() => {
    if (companyId) {
      store?.commercialCheck?.getSubscription();
    }
  }, [companyId]);

  useEffect(() => {
    store?.onboarding.getOnboardingStage();
  }, []);

  const handleViewClick = (reference) => {
    if (reference) {
      navigate(`/commercial-check/${reference}`);
    }
  };
  const units_available =
    subscriptionData?.total_units - subscriptionData?.units_used;

  return (
    <div className="pt-12 pb-8 px-[3.75rem]">
      <div className="flex justify-between space-x-5 mb-11">
        <div className="bg-white py-6 px-[1.625rem] rounded-xl border-[0.5px] border-stroke w-1/2">
          <p className="text-headings font-semibold mb-3">
            Initiate commercial check
          </p>
          <p className="mb-5">
            Unlock comprehensive CAC status and credit details with our
            intuitive company registration search tool.
          </p>
          <SmallButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            onClick={() => setOpenLookupDrawer(true)}
            disabled={!(companyId && units_available > 0)}
          >
            Lookup company
          </SmallButton>
        </div>
        <div className="bg-white py-6 px-[1.625rem] rounded-xl border-[0.5px] border-stroke w-1/2">
          <p className="text-headings font-semibold mb-3">Available units</p>
          {subscriptionLoading ? (
            <div className="flex items-center justify-center w-full">
              <Spinner style={{ margin: "0.5rem 0" }} />
            </div>
          ) : (
            <>
              <Progress
                percent={
                  (subscriptionData?.units_used /
                    subscriptionData?.total_units) *
                  100
                }
                showInfo={false}
                trailColor="#E6F6FC"
                strokeColor={"#05A7E3"}
              />
              <p className="text-neutral text-[0.625rem] tracking-[1.5px] font-bold whitespace-nowrap mb-6">
                {subscriptionData?.units_used || 0} OF{" "}
                {subscriptionData?.total_units || 0} UNITS USED
              </p>
              <SmallButton
                bg="var(--white-alt)"
                border="var(--white-alt)"
                textcolor="var(--primary)"
                onClick={() => setOpenGetUnitModal(true)}
                disabled={(environment?.environment === "sandbox" || !companyId) ? true : false}
              >
                Get Units
              </SmallButton>
            </>
          )}
        </div>
      </div>

      <Results
        onRowClick={(reference) => {
          handleViewClick(reference);
        }}
        setOpenLookupDrawer={setOpenLookupDrawer}
      />
      {openLookupDrawer && (
        <LookupDrawer
          isOpen={openLookupDrawer}
          onClose={() => setOpenLookupDrawer(false)}
        />
      )}
      {openGetUnitModal && (
        <GetUnitsModal
          isOpen={openGetUnitModal}
          onClose={() => setOpenGetUnitModal(false)}
        />
      )}
      {onboardingStageLoading ? (
        ""
      ) : completionPercentage !== "50%" && completionPercentage !== "100%" ? (
        <OnboardingCompliance progressWidth={completionPercentage} />
      ) : subscriptionLoading || environmentLoading ? (
        ""
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length === 0 ? (
        <OnboardingCompliance isLive />
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length > 0 &&
        !subscriptionData?.subscription_details?.active ? (
        <OnboardingCompliance isNotSubscribed />
      ) : (
        ""
      )}
    </div>
  );
};

export default CommercialCheckSection;
