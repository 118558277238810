import { styled, css, keyframes } from "goober";
import { mobile, mobileMedium } from "globalStyles";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const ModalOverlay = styled("div")`
  background: rgba(1, 26, 39, 0.5);
  backdrop-filter: blur(4px);
  z-index: 99999;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  opacity: 20;
  height: 100vh;
  width: 100vw;
`;

export const ModalWrapper = styled("div")`
  border-radius: 20px;
  background: var(--white);
  width: 100%;
  height: 100%;
  max-width: 32rem;
  max-height: 32rem;
  padding: 2.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.6875rem;
  animation: ${fadeIn} 0.5s ease;
  &.longer {
    max-height: 40rem;
  }
  &.auto-height {
    max-height: 16.25rem;
  }
  ${mobileMedium} {
    max-height: 100%;
    height: auto;
    padding: 1rem;
  }
  .modal-title {
    color: var(--headings);
    font-family: var(--heading-font);
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2rem;
    ${mobileMedium} {
      font-size: 1.25rem;
      line-height: normal;
      width: 80%;
      margin: 0 auto;
    }
  }
  form {
    gap: 3rem;
  }
  .back-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    left: 2.5rem;
    p {
      color: var(--accent-2);
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

export const AddTeamModalHeader = styled("section")`
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
`;

export const AddTeamMatesList = styled("section")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const AddTeamMateInfo = styled("section")`
  display: flex;
  align-items: center;
  gap: 1.125rem;
  .remove-teammate-info {
    cursor: pointer;
  }
`;

export const AddTeamMatesFooter = styled("section")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .add-multiple {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    p {
      color: var(--accent-2);
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

export const TeammateBulkUpload = styled("section")`
  p {
    margin-top: 2rem;
    color: var(--text-neutral);
    text-align: center;
    span {
      color: var(--accent-2);
    }
  }
`;

export const ModalBodyWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  &.verify-company {
    gap: 2.13rem;
    height: 100%;
    form {
      gap: 1.5rem;
      width: 100%;
    }
  }
  &.director-info {
    gap: 2.875rem;
    height: 100%;
    .modal-header {
      gap: 0.4375rem;
    }
    form {
      gap: 2rem;
    }
    .director-summary {
      padding: 0 1.25rem;
      gap: 1.625rem;
      .icon-bg {
        width: 3.25rem;
        height: 3.25rem;
        min-width: 3.25rem;
        min-height: 3.25rem;
        border-radius: 50%;
        background: var(--white-alt);
      }
    }
  }
  &.request-verification {
    gap: 1rem;
    height: 100%;
    .modal-header {
      gap: 0.4375rem;
    }
    form {
      gap: 1.985rem;
    }
  }
  &.director-phone {
    .modal-header {
      gap: 0.875rem;
    }
  }
  &.country-select {
    gap: 2.1875rem;
    text-align: center;
  }
  &.verification-success {
    justify-content: unset;
    align-items: center;
    text-align: center;
    gap: 2.375rem;
    h2 {
      width: 75%;
    }
    .save-continue-later {
      gap: 0.5rem;
      margin-top: 1.5rem;
      cursor: pointer;
      p {
        color: var(--accent-2);
      }
    }
  }
  &.request-verification-success {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
    .header {
      gap: 1.375rem;
      svg {
        margin: 0 auto;
      }
    }
    .info {
      gap: 1.375rem;
    }
  }
  &.request-sent {
    justify-content: unset;
    align-items: center;
    text-align: center;
    margin: 4.0625rem 0 2.625rem 0;
    svg {
      margin-bottom: 1.6875rem;
    }
    h2 {
      margin-bottom: 22px;
    }
    p {
      margin-bottom: 3.5rem;
    }
    strong {
      color: var(--text-dark);
      font-weight: 500;
    }
  }
  &.director-notification {
    text-align: center;
    gap: 0.75rem;
  }
  &.user-verification {
    margin: 0 auto;
    text-align: center;
    justify-content: unset;
    .modal-header {
      gap: 0.875rem;
      margin-bottom: 0.875rem;
    }
    .subtext {
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 1.4px;
      color: var(--accent-2);
      cursor: pointer;
      &.mobile-phone {
        margin-bottom: 1.5625rem;
        cursor: auto;
      }
    }
    .other-text {
      margin: 1.5125rem 0 1.1875rem 0 ;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      color: var(--text-neutral);
    }
    .center-barcode {
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 9.375rem;
      height: 9.375rem;
    }
    .powered-by {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3125rem;
      font-size: 0.5rem;
      font-weight: 500;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }
  }
  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 1.4375rem;
    text-align: center;
    .text-base {
      color: var(--text-neutral);
      span {
        color: var(--text-dark);
        font-weight: 500;
      }
    }
  }
  .company-verification-summary {
    background-color: var(--white-alt);
    padding: 1.0625rem 2rem 2.375rem 2rem;
    border-radius: var(--br-sm);
    margin-top: 1.5625rem;
    margin-bottom: 1.675rem;
    height: 16.5rem;
    overflow-y: auto;
    .company-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--stroke);
      margin-bottom: 1.75rem;
      h3 {
        font-family: 'Inter';
        color: var(--headings);
        ${mobileMedium} {
          font-size: 1.125rem;
        }
      }
    }
    .company-address {
      margin-bottom: 1.75rem;
    }
    .company-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;
      .item {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      ${mobileMedium} {
        flex-wrap: wrap;
      }
    }
    .title {
      color: var(--text-neutral);
    }
    .info {
      color: var(--text-dark);
      font-weight 500;
    }
  }
  .button-group {
    width: 100%;
  }
`;

export const CommonDrawerStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 32rem;
  overflow-y: auto;
  animation: ${slideIn} 0.5s ease-in-out;
`;

export const DrawerStyle = css`
  position: absolute;
  background: var(--white);
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 32rem;
  padding: 1.8125rem 1.75rem 2.0625rem 2.5rem;
  overflow-y: auto;
  animation: ${slideIn} 0.5s ease-in-out;
  form {
    gap: 1.75rem;
    .card-header {
      color: var(--headings);
    }
    .request-types {
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
  &.webhook {
    padding: 1.8125rem 2.375rem 0.5rem 2.4375rem;
    form {
      gap: 1rem;
    }
    .header {
      padding-right: 1.5rem;
    }
  }
  .upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    border-radius: 1rem;
    padding: 1.125rem 1.4375rem 1.125rem 1rem;
    box-shadow: 0px 15px 50px 0px rgba(205, 213, 219, 0.35);
    margin-bottom: 1.5rem;
    .country {
      gap: 5px;
      p {
        color: var(--headings);
      }
    }
    .select {
      color: var(--accent-2);
    }
  }
  .ant-upload-wrapper {
    font-family: "Inter", sans-serif !important;
    .ant-upload-drag {
      border-radius: 1rem;
      border: 1px dashed var(--stroke);
      background: transparent;
      &:hover {
        border: 1px dashed var(--text-neutral);
      }
    }
    .ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
      display: none;
    }
  }
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    background: #fef2f2;
    .anticon svg {
      fill: #e54b4b;
    }
  }
  ${mobile} {
    padding: 1rem;
  }
`;

export const DatapointsTableList = css`
  position: absolute;
  background: var(--white);
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 32rem;
  padding: 1.75rem 1.5rem 0.875rem 1.375rem;
  overflow-y: auto;
  animation: ${slideIn} 0.5s ease-in-out;
  h3 {
    margin-bottom: 1.5rem;
  }
  .datapoints-table {
    width: 100%;
    height: 80vh;
    overflow: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    th {
      padding: 0.875rem 0;
      background-color: var(--white-alt);
      color: var(--text-dark);
      text-align: left;
      white-space: nowrap;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.2px;
      border: none;
      text-transform: uppercase;
      &:nth-child(1) {
        padding-left: 1rem;
        width: 35%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
    }

    td {
      color: var(--text-neutral);
      border: none;
    }
    tr {
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--white);
        color: var(--text-neutral);
        text-align: left;
        white-space: nowrap;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        border: none;

        &:nth-child(1) {
          font-weight: 500;
          padding-left: 1rem;
        }
      }
    }
  }
`;

export const IndepthAnalysisModal = css`
  background: var(--white);
  padding: 1.75rem 2.5rem 0.875rem 2.5rem;
`;

export const PaymentHistoryModal = css`
  background: var(--white);
  padding: 1.75rem 2.5rem;

  .payment-history-table {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-spacing: 0;

    th {
      padding: 0.875rem 0;
      background-color: var(--white-alt);
      color: var(--text-dark);
      text-align: left;
      white-space: nowrap;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.2px;
      border: none;
      text-transform: uppercase;

      &:nth-child(1) {
        padding-left: 1rem;
        width: 30%;
      }

      &:nth-child(2) {
        width: 35%;
      }

      &:nth-child(3) {
        width: 25%;
      }
    }

    td {
      color: var(--text-neutral);
      border: none;
    }

    tr {
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: var(--text-neutral);
        text-align: left;
        white-space: nowrap;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        border: none;

        &:nth-child(1) {
          font-weight: 500;
          padding-left: 1rem;
        }
      }

      &:nth-child(even) {
        td {
          background-color: var(--white-alt);
        }
      }
    }
  }
`;

