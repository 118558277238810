import { styled } from "goober";
import { tabletSmall } from "globalStyles";

export const AuthPageWrapper = styled("section")`
  display: flex;
  justify-content: center;
  background: var(--white);
  min-height: 100vh;
  .signup-illustration {
    min-height: 100vh;
  }
  ${tabletSmall} {
    .signup-illustration {
      display: none;
    }
  }
`;

export const AuthFormContainer = styled("section")`
  width: 100%;
  padding: 2.5rem 2.375rem;
  .container {
    margin: 0 auto;
    max-width: 26.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .header {
      margin-bottom: 1.625rem;
      display: flex;
      flex-direction: column;
      gap: 5px;
      &.login-header,
      &.reset-password-header {
        margin-bottom: 3.375rem;
      }
      h2 {
        color: var(--headings);
      }
      p {
        color: var(--text-neutral);
      }
      .verify-header {
        text-align: center;
      }
      .sent-to-email {
        color: var(--text-dark);
        font-weight: 500;
      }
    }
    .country-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--white);
      border-radius: 1rem;
      padding: 1.125rem 1.4375rem 1.125rem 1rem;
      box-shadow: 0px 15px 50px 0px rgba(205, 213, 219, 0.35);
      margin-bottom: 3.375rem;
      .country {
        gap: 5px;
        p {
          color: var(--headings);
        }
      }
      .select {
        color: var(--accent-2);
      }
    }
    .verification-header {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 3.375rem;
      h2 {
        color: var(--headings);
        letter-spacing: normal;
      }
      p {
        color: var(--text-neutral);
      }
      .sent-to-email {
        color: var(--text-dark);
        font-weight: 500;
      }
    }
    .base {
      margin-top: 2.625rem;
      color: var(--text-neutral);
      text-align: center;
      font-weight: 500;
      a {
        color: var(--accent-2);
        font-weight: 700;
      }
    }
    @media screen and (max-height: 700px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .resend-code {
    cursor: pointer;
    color: var(--accent-2);
  }
`;

export const AuthForm = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .terms-policy {
    color: var(--accent-2);
  }
  p {
    text-align: center;
  }
  .reset-password-btn {
    margin-top: 2.375rem;
  }
  .forgot-password {
    color: var(--accent-2);
    cursor: pointer;
    margin-top: -0.75rem;
    margin-bottom: 5rem;
    align-self: flex-end;
  }
  .create-account-checkbox {
    margin-top: 0.625rem;
    margin-bottom: 1.625rem;
  }
  .password-info {
    padding-left: 1.25rem;
    margin-top: -0.5rem;
  }
  .password-indicator {
    min-width: 0.625rem;
    min-height: 0.625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    &.bg-pending {
      background-color: #D9D9D9; 
    }
  }
`;

export const VerifyEmailForm = styled("form")`
  display: flex;
  flex-direction: column;
  .resend {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 6.125rem;
  }
`;
