import SuccessBadge from "assets/images/badge.svg";
import { MediumButton } from "components/button";
import { useSearchParams } from "react-router-dom";

const ConsentFlowThankYouPage = () => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("connect_url");
  const hasDirector = searchParams.get("has_director");

  const authorize = () => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="bg-alt min-h-screen flex items-center justify-center">
      <div className="bg-white p-10 rounded-xl w-[512px] h-[512px] flex flex-col items-center justify-center">
        <img
          src={SuccessBadge}
          alt="success"
          className="mb-12"
        />
        <p className="font-secondary text-headings font-semibold text-3xl mb-6">
          Thank you
        </p>
        <p className="text-center mb-5">
          The authorization to obtain a {hasDirector ? "director" : "consumer"}{" "}
          report on you have been received successfully.{" "}
          {url
            ? "You will be redirected to grant financial authorization on your account."
            : null}
        </p>

        {url ? (
          <MediumButton
            bg="var(--accent-1)"
            border="var(--accent-1)"
            textcolor="var(--primary)"
            hovercolor="var(--accent-1)"
            onClick={authorize}
          >
            Authorize
          </MediumButton>
        ) : null}
      </div>
    </div>
  );
};

export default ConsentFlowThankYouPage;
