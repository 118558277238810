import { useNavigate } from "react-router-dom";
import { ReportWrapper } from "assets/styles/customer-report-styles";
import MainLayout from "components/mainLayout";
import RequestedReports from "components/customerReports/RequestedReports";

const AllCustomerReports = () => {
  const navigate = useNavigate();

  const showSummaryPage = () => {
    navigate(`/consumer-reports`);
  };
  const handleViewClick = (reference) => {
    if (reference) {
      navigate(`/consumer-reports/${reference}`);
    }
  };

  return (
    <MainLayout pageHeader="All Consumer Reports">
      <ReportWrapper>
        <RequestedReports
          showSummaryPage={showSummaryPage}
          fullPage
          onRowClick={(reference) => {
            handleViewClick(reference);
          }}
        />
      </ReportWrapper>
    </MainLayout>
  );
};

export default AllCustomerReports;
