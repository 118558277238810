import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { FlexCol, FlexColumnCenter } from 'assets/styles/common-styles';
import { ReactComponent as StarCheck } from "assets/images/icons/star-check.svg";

const RequestVerificationSuccessModal = ({ isOpen, onClose, handleCompleteProcess, requestVerificationEmail }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper className={`request-verification-success ${FlexColumnCenter}`}>
        <div className={`${FlexCol} header`}>
          <StarCheck />
          <h2 className="modal-title">Request sent successfully</h2>
        </div>
        <section className={`${FlexCol} info`}>
          <p className="text-base">Email sent to <span className="text-base-medium dark">{requestVerificationEmail}</span></p>
          <p className="text-base">Your director will be promoted to add you as a team member after completing the director's verification process. In the mean time, explore Maxim sandbox (Dashboard Demo)</p>
        </section>
        <Link 
          to="/dashboard"
          onClick={() => {
            handleCompleteProcess();
            onClose();
          }}
        >
          <MediumButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
          >
            See Dashboard Demo
          </MediumButton>
        </Link>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default RequestVerificationSuccessModal;
