import React, { useEffect, useState } from "react";
import { SmallButton } from "components/button";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/images/icons/small-arrow-right.svg";
import { ReactComponent as Check } from "assets/images/icons/check-alt.svg";
import { ReactComponent as Warning } from "assets/images/icons/warning.svg";
import { ReactComponent as Avatar } from "assets/images/circle-green-avatar.svg";
import { ReactComponent as Commercial } from "assets/images/circle-commercial.svg";
import { ReactComponent as Document } from "assets/images/document.svg";
import { ReactComponent as Search } from "assets/images/circle-search.svg";
import { getLocalStorageProperties } from "utils/user";
import { useSnapshot } from "valtio";
import store from "store";
import Spinner from "components/common/Spinner";

const GetStartedSection = () => {
  const userDetails = localStorage.getItem("user");
  const companyInfo = localStorage.getItem("companyInfo");
  const idToExtract = ["company_id", "maxim_id", "company"];
  const nameToExtract = ["id", "name"];
  const extractedUserDetails = getLocalStorageProperties(
    userDetails,
    idToExtract
  );

  const extractedCompanyName = getLocalStorageProperties(
    companyInfo,
    nameToExtract
  );
  const { name: companyName } = extractedCompanyName;
  const { maxim_id: userMaximId, company } = extractedUserDetails;

  const snap = useSnapshot(store.misc);
  const environment = snap?.environment;
  const onboardingSnap = useSnapshot(store.onboarding);
  const onboardingStage = onboardingSnap?.onboardingStage;
  const completionPercentage = onboardingStage?.percentage + "%" || 0;
  const completionPercentageConverted = parseInt(completionPercentage, 10);
  const [completionText, setCompletionText] = useState("");
  const [showAIComingSoon, setShowAIComingSoon] = useState(false);
  const [showReportingStack, setShowReportingStack] = useState(false);
  const [showRepaymentStack, setShowRepaymentStack] = useState(false);

  useEffect(() => {
    store?.onboarding.getOnboardingStage();
  }, []);

  useEffect(() => {
    if (completionPercentageConverted >= 100) {
      setCompletionText("Completed");
    } else {
      setCompletionText(`${completionPercentage} Complete`);
    }
  }, [completionPercentage, completionPercentageConverted]);

  return (
    <div className="py-8 px-11">
      <div className="flex items-start justify-between mb-7">
        <p className="uppercase text-xs text-neutral mb-1 tracking-wider font-semibold">
          Hello {company?.name || companyName || userMaximId}
        </p>
        {environment?.environment === "sandbox" ? (
          <p className="text-sm text-neutral">
            You are within the test environment
          </p>
        ) : (
          ""
        )}
      </div>

      <div className="mb-11">
        <h2 className="text-headings font-secondary font-semibold text-2xl mb-4">
          All you need to build your credit solution
        </h2>
        <div className="flex justify-between space-x-10">
          <div className="w-1/2">
            <p className="text-neutral mb-2">
              Build real-time consumer data from Credit Bureaus. Make appraisals
              and underwriting decisions with our customizable API.
            </p>
            <Link className="flex items-center gap-3 mt-3" to="/docs">
              <span className="text-accent-2 font-semibold">
                See API documentation
              </span>
              <ArrowRight stroke="var(--accent-2)" />
            </Link>
          </div>
          <div className="w-1/2 flex justify-between">
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <Check />
                <p className="text-neutral font-medium">
                  Financial and Credit Data Stack
                </p>
              </div>
              <div className="flex relative items-center gap-3">
                {showAIComingSoon && (
                  <span className="absolute top-[-60%] right-0 bg-dark py-1 px-1 rounded accent-1 text-xs-semibold">
                    Coming soon
                  </span>
                )}
                <Warning />
                <p
                  onMouseEnter={() => setShowAIComingSoon(true)}
                  onMouseLeave={() => setShowAIComingSoon(false)}
                  className="text-muted font-medium"
                >
                  AI-Powered Decision Stack
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <div className="relative flex items-center gap-3">
                {showReportingStack && (
                  <span className="absolute top-[-60%] right-0 bg-dark py-1 px-1 rounded accent-1 text-xs-semibold">
                    Coming soon
                  </span>
                )}
                <Warning />
                <p
                  onMouseEnter={() => setShowReportingStack(true)}
                  onMouseLeave={() => setShowReportingStack(false)}
                  className="text-muted font-medium"
                >
                  Reporting Stack
                </p>
              </div>
              <div className="relative flex items-center gap-3">
                {showRepaymentStack && (
                  <span className="absolute top-[-60%] right-0 bg-dark py-1 px-1 rounded accent-1 text-xs-semibold">
                    Coming soon
                  </span>
                )}
                <Warning />
                <p
                  onMouseEnter={() => setShowRepaymentStack(true)}
                  onMouseLeave={() => setShowRepaymentStack(false)}
                  className="text-muted font-medium"
                >
                  Repayment Stack
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECOND SECTION */}

      <div className="flex space-x-10 mb-12">
        <div className="relative bg-white w-[65%] py-4 pl-6 flex items-center border-[0.5px] border-stroke rounded-xl">
          <div>
            <p className="text-headings font-semibold mb-1.5">
              Onboarding and compliance
            </p>
            <p className="text-neutral w-[70%] mb-4">
              Your CAC and company director verification is required to activate
              your business.
            </p>
            {onboardingStage?.percentage >= 0 ? (
              <Link
                to={
                  completionPercentageConverted >= 100
                    ? "/get-started"
                    : "/onboarding"
                }
              >
                <SmallButton
                  bg="var(--accent-1)"
                  border="var(--white-alt)"
                  textcolor="var(--primary)"
                  hovercolor="var(--white-alt)"
                >
                  {completionText}
                </SmallButton>
              </Link>
            ) : (
              <Spinner style={{ margin: "0" }} />
            )}
          </div>

          <div className="absolute right-0">
            <Document />
          </div>
        </div>

        <div className="w-[35%]">
          <div className="flex items-center gap-3 mb-6">
            <p className="text-headings font-semibold whitespace-nowrap">
              Quick links
            </p>
            <div className="h-[1px] bg-stroke w-full" />
          </div>

          <div className="mb-3">
            <div className="flex items-center justify-between mb-2">
              <p className="text-dark font-medium">Billing information</p>
              <Link
                to={environment ? environment?.environment === "sandbox" ? "" : "/plans" : ""}
                className={`text-accent-2 font-semibold flex items-center gap-3 ${
                  environment ? environment?.environment === "sandbox" ? "disabled" : "" : "disabled"
                }`}
              >
                <span>Learn more</span> <ArrowRight />
              </Link>
            </div>
            <p className="text-neutral">
              Stay informed about your subscription plan.
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <p className="text-dark font-medium">Maxim API</p>
              <Link
                className="text-accent-2 font-semibold flex items-center gap-3"
                to="/docs"
              >
                <span>See documentation</span> <ArrowRight />
              </Link>
            </div>
            <p className="text-neutral">
              Integrate Maxim API with a developer guide.
            </p>
          </div>
        </div>
      </div>

      {/* THIRD SECTION */}

      <div>
        <p className="text-headings font-semibold mb-5 ml-8">
          Features at your finger tips
        </p>

        <div className="grid grid-cols-3 gap-5">
          <div className="bg-white p-8 border-[0.5px] border-stroke rounded-xl">
            <Avatar />
            <p className="text-headings font-semibold mt-5 mb-2">
              Credit report - Individual
            </p>
            <p className="text-neutral mb-3">
              Get the credit worthiness and financial status of individuals,
              existing or potential customers.
            </p>
            <Link to="/consumer-reports" className="flex items-center gap-3">
              <span className="text-accent-2 font-semibold">Get started</span>{" "}
              <ArrowRight />
            </Link>
          </div>
          <div className="bg-white p-8 border-[0.5px] border-stroke rounded-xl">
            <Commercial />
            <p className="text-headings font-semibold mt-5 mb-2">
              Credit report - Commercial
            </p>
            <p className="text-neutral mb-3">
              Run a comprehensive CAC status and credit details check on
              institutions and businesses.
            </p>
            <Link to="/commercial-check" className="flex items-center gap-3">
              <span className="text-accent-2 font-semibold">Get started</span>{" "}
              <ArrowRight />
            </Link>
          </div>
          <div className="bg-white p-8 border-[0.5px] border-stroke rounded-xl">
            <Search />
            <p className="text-headings font-semibold mt-5 mb-2">
              User verification - KYC
            </p>
            <p className="text-neutral mb-3">
              Understand your customers better with our seamless BVN
              verification system.
            </p>
            <Link
              to="/user-id-verification"
              className="flex items-center gap-3"
            >
              <span className="text-accent-2 font-semibold">Get started</span>{" "}
              <ArrowRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedSection;
