import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const VerifyUserID = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          400 Error
        </div>
      ),
      key: "400",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "bvn": "22222222222",
        "first_name": "Joshua",
        "last_name": "Isaac",
        "email": "joshua@test.com",
        "phone_number": "08123456789"
      },
      response: {
        "message": "Lookup successful",
        "data": {
          "id": "UID-01hp22598xzp250q9hs65qjwjm",
          "company_id": "NG-C01hkfwenr1mqbp2y5359nc2jdr",
          "first_name": "Joshua",
          "last_name": "Isaac",
          "email": "joshua@test.com",
          "middle_name": "",
          "phone_number": "08123456789",
          "match": true,
          "id_type": "bvn",
          "id_number": "22222222222",
          "created_at": "2024-02-07T15:14:39.000000Z",
          "nationality": "Nigeria"
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to verify the provided ID was successful.
        </>
      ),
    },
    400: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "bvn": "44444444444",
        "first_name": "Olayinka",
        "last_name": "Azeez",
        "middle_name": "Bamidele",
        "email": "olayinka@yahoo.com",
        "phone_number": "08034343434"
      },
      response: {
        "message": "You dont have enough unit to process this request"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">400 Error:</span> This suggests a bad request, indicating that the server cannot process the request due to client error, such as an invalid or improperly formatted request payload.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="User ID Verification"
      pageDescription="Use this endpoint to verify or know your customer using User ID  such as Bank Verification Number - BVN."
      subtitle="Verify ID"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/uid-verifications"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default VerifyUserID;
