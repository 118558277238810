import { FlexColumnBetween, FlexRowBetween } from "assets/styles/common-styles";
import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { formatNumberWithCommas } from "utils/common";

const ConfirmRenewalModal = ({
  isOpen,
  onClose,
  isLoading,
  generateReference,
  planDetails,
}) => {
  return (
    <Modal isOpen={isOpen} className="auto-height">
      <ModalBodyWrapper className={FlexColumnBetween}>
        <div className="modal-header">
          <h2 className="modal-title">Renew your plan</h2>
        </div>
        <p className="text-base text-center">
          You are about to pay for{" "}
          <span className="capitalize text-base-medium text-dark">
            {planDetails?.plan?.id}
          </span>{" "}
          at{" "}
          <span className="text-base-medium text-dark">
            {formatNumberWithCommas(Number(planDetails?.plan?.price[0]?.price))}{" "}
            {planDetails?.plan?.price[0]?.currency}.
          </span>{" "}
          <br />
          Are you sure you want to proceed?
        </p>
        <div className={`${FlexRowBetween} button-group`}>
          <MediumButton
            bg="var(--white-alt)"
            textcolor="var(--text-dark)"
            type="button"
            onClick={onClose}
          >
            No, Cancel
          </MediumButton>
          {isLoading ? (
            <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
          ) : (
            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              type="button"
              onClick={generateReference}
            >
              Yes, Proceed
            </MediumButton>
          )}
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default ConfirmRenewalModal;
