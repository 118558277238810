import axiosInstance from 'axiosInstance';
import { getLocalStorageProperties, parseUserInfo } from 'utils/user';
import { proxy } from 'valtio';
const maximUser = localStorage.getItem('user');
const toExtract = ['dashboard_state'];
const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
const { dashboard_state: dashboardState } = extractedUsernames;

const userValues = parseUserInfo('user');

const settings = proxy({
  apiKey: null,
  apiKeyLoading: false,
  regenerateLoading: false,
  webhooks: [],
  webhookLoading: false,
  allWebhooksByService: [],
  allWebhooksByServiceLoading: false,
  subWebhookLoading: false,
  singleWebhook: null,
  singleWebhookLoading: false,
  webhookDatapoints: [],
  webhookDatapointsLoading: false,
  webhookTypes: [],
  webhookTypesLoading: false,
  ipWhitelists: null,
  ipWhitelistLoading: false,
  setWhitelistLoading: false,
  deleteWhitelistLoading: false,

  async getApiKey() {
    this.apiKeyLoading = true;
    return axiosInstance
      .get(`/settings/api-keys?env=${dashboardState}`)
      .then(({ data }) => {
        this.apiKey = data.data;
        this.apiKeyLoading = false;
      })
      .catch(({ response }) => {
        this.apiKeyLoading = false;
        return response;
      });
  },

  async regenerateApiKey() {
    this.regenerateLoading = true;
    return axiosInstance
      .post('/settings/api-keys', { env: dashboardState })
      .then(({ data }) => {
        this.apiKey = data.data;
        this.regenerateLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.regenerateLoading = false;
        return response;
      });
  },

  async getWebhooks() {
    this.webhookLoading = true;
    return axiosInstance
      .get(`/settings/webhooks?env=${dashboardState}`)
      .then(({ data }) => {
        this.webhooks = data.data;
        this.webhookLoading = false;
      })
      .catch(({ response }) => {
        this.webhookLoading = false;
        return response;
      });
  },

  async subWebhook(body) {
    this.subWebhookLoading = true;
    return axiosInstance
      .post('/settings/webhooks', body)
      .then(({ data }) => {
        this.webhooks = data.data;
        this.subWebhookLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.subWebhookLoading = false;
        return response;
      });
  },

  async getAllWebhooksByService(service) {
    this.allWebhooksByServiceLoading = true;
    return axiosInstance
      .get(`/settings/webhook-notifications?service=${service}`)
      .then(({ data }) => {
        this.allWebhooksByService = data.data;
        this.allWebhooksByServiceLoading = false;
        return this.allWebhooksByService;
      })
      .catch(({ response }) => {
        this.allWebhooksByServiceLoading = false;
        return response;
      });
  },

  async getSingleWebhook(id) {
    this.singleWebhookLoading = true;
    return axiosInstance
      .get(`/settings/webhook-notifications/${id}`)
      .then(({ data }) => {
        this.singleWebhook = data.data;
        this.singleWebhookLoading = false;
        return this.singleWebhook;
      })
      .catch(({ response }) => {
        this.singleWebhookLoading = false;
        return response;
      });
  },

  async refireWebhook(data, {id}) {
    return axiosInstance
      .post(`/settings/webhook-notifications/${id}/refire`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },

  async getWebhookTypes() {
    this.webhookTypesLoading = true;
    return axiosInstance
      .get(`/settings/webhooks/types`)
      .then(({ data }) => {
        this.webhookTypes = data.data;
        this.webhookTypesLoading = false;
        return this.webhookTypes;
      })
      .catch(({ response }) => {
        this.webhookTypesLoading = false;
        return response;
      });
  },

  async toggleWebhookStatus(id) {
    return axiosInstance.patch(`/settings/webhooks/${id}`)
      .then(({data}) => {
        return true;
      })
      .catch(({response}) => {
        return false;
      });
  },

  async getWhitelists() {
    this.ipWhitelistLoading = true;
    return axiosInstance
      .get(`/settings/whitelists?env=${dashboardState}`)
      .then(({ data }) => {
        this.ipWhitelists = data.data;
        this.ipWhitelistLoading = false;
        return data.data;
      })
      .catch(({ response }) => {
        this.ipWhitelistLoading = false;
        return response;
      });
  },

  async setWhitelist(ip) {
    this.setWhitelistLoading = true;
    return axiosInstance
      .post('/settings/whitelists', { env: dashboardState, ips: [ip] })
      .then(({ data }) => {
        this.ipWhitelists = data.data;
        this.setWhitelistLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.setWhitelistLoading = false;
        return response;
      });
  },

  async deleteWhitelist(ip) {
    this.deleteWhitelistLoading = true;
    return axiosInstance
      .delete(`/settings/whitelists/${ip}`)
      .then(({ data }) => {
        this.deleteWhitelistLoading = false;
        return data;
      })
      .catch(({ response }) => {
        this.deleteWhitelistLoading = false;
        return response;
      });
  },

  async goLive() {
    return axiosInstance
      .post('/settings/go-live')
      .then(({ data }) => {
        return true;
      })
      .catch(({ response }) => {
        return false;
      });
  },

  async getWebhookDatapoints() {
    this.webhookDatapointsLoading = true;
    return axiosInstance
      .get(`/settings/assessments/datapoints`)
      .then(({ data }) => {
        this.webhookDatapoints = data.data;
        this.webhookDatapointsLoading = false;
        return this.webhookDatapoints;
      })
      .catch(({ response }) => {
        this.webhookDatapointsLoading = false;
        return response;
      });
  },

  async postWebhookDatapoints(data) {
    return axiosInstance
      .post(`/settings/assessments/datapoints`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },

  async deleteWebhookDatapoint(id) {
    return axiosInstance
      .delete(
        `/settings/assessments/datapoints?country_code=${
          localStorage.getItem('country-code') || userValues?.countryCode
        }&report_id=${id}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
});

export default settings;
