import { proxy } from 'valtio';
import axiosInstance from 'axiosInstance';

const decisionCriteria = proxy({
  allDecisionCriteria: null,
  allDecisionCriteriaLoading: false,
  decisionCriteria: null,
  decisionCriteriaLoading: false,

  async getAllDecisionCriteria() {
    this.allDecisionCriteriaLoading = true;
    return axiosInstance.get('/decisions/criteria')
      .then(({data}) => {
        this.allDecisionCriteria = data.data;
        this.allDecisionCriteriaLoading = false;
        return this.allDecisionCriteria;
      })
      .catch(({response}) => {
        this.allDecisionCriteriaLoading = false;
        return response;
      });
  },
  async getDecisionCriteria(id) {
    this.decisionCriteriaLoading = true;
    return axiosInstance.get(`/decisions/criteria/${id}`)
      .then(({data}) => {
        this.decisionCriteria = data.data;
        this.decisionCriteriaLoading = false;
        return this.decisionCriteria;
      })
      .catch(({response}) => {
        this.decisionCriteriaLoading = false;
        return response;
      });
  },
  async createDecisionCriteria(data) {
    return axiosInstance.post(`/decisions/criteria/`, data)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response;
      });
  },
  async deleteDecisionCriteria(id) {
    return axiosInstance.delete(`/decisions/criteria/${id}`)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response;
      });
  },
})

export default decisionCriteria;