import React from "react";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { ReactComponent as StarCheck } from "assets/images/icons/star-check.svg";
import { ReactComponent as GreenAvatar } from "assets/images/green-avatar.svg";
import { capitalizeWords } from "utils/common";
import { MediumButton } from "components/button";
import { Link } from "react-router-dom";

const RequestDirectorReportSuccessModal = ({
  isOpen,
  first_name,
  last_name,
  onClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBodyWrapper className="h-full">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="mb-7">
            <StarCheck />
          </div>
          <div className="mb-7">
            <h1 className="text-headings font-secondary font-semibold text-[1.75rem] text-center mb-3">
              Request sent successfully
            </h1>
            <p className="text-neutral text-center">
              A consumer report on{" "}
              <span className="text-dark font-medium">
                {capitalizeWords(first_name)} {capitalizeWords(last_name)}
              </span>{" "}
              has been requested. Result typically takes 1 - 24hrs.
            </p>
          </div>
          <div className="mb-7">
            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              hovercolor="var(--accent-1)"
              onClick={() => onClose()}
            >
              Okay, got it
            </MediumButton>
          </div>

          <div className="flex items-center space-x-3">
            <GreenAvatar />
            <Link
              to="/consumer-reports"
              className="text-accent-2 font-semibold"
            >
              Go to Consumer Report
            </Link>
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default RequestDirectorReportSuccessModal;
