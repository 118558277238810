import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { toast } from "sonner";
import { format } from "date-fns";
import store from "store";
import {
  Card,
  MostRecent,
  MostRecentRequestHeader,
} from "assets/styles/customer-report-styles";
import {
  Flex,
  FlexCenter,
  FlexColumnCenter,
} from "assets/styles/common-styles";
import Table from "components/table";
import Spinner from "components/common/Spinner";
import TextField from "components/formFields/TextField";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user-outline.svg";
import Checkbox from "components/formFields/Checkbox";
import Toast from "components/common/ToastNotification";
import RequestReportSuccessModal from "components/lookup/RequestReportSuccessModal";
import InsufficientUnitModal from "components/lookup/InsufficientUnitModal";
import InactiveSubscriptionModal from "components/lookup/InactiveSubscriptionModal";
import { getLocalStorageProperties } from "utils/user";

const Results = ({ setIsProfileCheckFormOpen }) => {
  const snap = useSnapshot(store.profileCheck);
  const profileChecks = snap?.profileChecks;
  const profileChecksLoading = snap?.profileChecksLoading;
  const [searchText, setSearchText] = useState("");
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const initialCheckedState =
    Object.keys(checkboxStates).length > 0 &&
    Object.values(checkboxStates).every((val) => val === false);
  const [isChecked, setIsChecked] = useState(initialCheckedState);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [isSuccessfulRequestOpen, setIsSuccessfulRequestOpen] = useState(false);
  const [reportCount, setReportCount] = useState(null);
  const [isInsufficientUnitOpen, setIsInsufficientUnitOpen] = useState(false);
  const [isInactiveModalOpen, setIsInactiveModalOpen] = useState(false);

  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  useEffect(() => {
    if (companyId) {
      store.profileCheck.getProfileChecks();
    }
  }, [companyId]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "select_all") {
      const newCheckboxStates = {};
      const newSelectedProfiles = [];

      filteredData.forEach((item, index) => {
        // Check if the item has a "No Match" status before adding to the selection
        if (item.status.props.children[1] !== "No Match") {
          newCheckboxStates[`select-${index}`] = checked;

          if (checked) {
            newSelectedProfiles.push(profileChecks[index].id);
          }
        }
      });

      setCheckboxStates(newCheckboxStates);

      // Check if all individual checkboxes are selected
      const allChecked = Object.values(newCheckboxStates).every(
        (val) => val === true
      );

      setIsChecked(allChecked);
      setSelectedProfiles(newSelectedProfiles);
    } else {
      // If any other checkbox is clicked
      const newCheckboxStates = {
        ...checkboxStates,
        [name]: checked,
      };

      const newSelectedProfiles = Object.keys(newCheckboxStates)
        .filter((key) => newCheckboxStates[key])
        .map((key) => profileChecks[parseInt(key.split("-")[1])].id);

      setCheckboxStates(newCheckboxStates);
      setSelectedProfiles(newSelectedProfiles);

      // Check if the number of selected items is equal to the total number of items
      const allChecked = newSelectedProfiles?.length === profileChecks?.length;

      setIsChecked(allChecked);
    }
  };

  const columns = [
    {
      Header: (
        <div className={Flex}>
          <Checkbox
            name="select_all"
            id="select_all"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span style={{ marginLeft: "1rem" }}>FULL NAME</span>
        </div>
      ),
      accessor: "full_name",
      checkbox: true,
    },
    {
      Header: "SEARCH TYPE",
      accessor: "search_type",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "PHONE",
      accessor: "phone_number",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "DATE",
      accessor: "created_at",
    },
  ];

  const data = profileChecks?.map((profileCheck) => {
    return {
      full_name:
        profileCheck.first_name +
        " " +
        profileCheck.middle_name +
        " " +
        profileCheck.last_name,
      search_type: (
        <span className="uppercase">
          {profileCheck.id_type} - {profileCheck.id_number}
        </span>
      ),
      status: (
        <span className="status">
          <span
            className={`status-ball ${profileCheck.match ? "active" : "error"}`}
          ></span>
          {profileCheck?.match ? "Match" : "No Match"}
        </span>
      ),
      phone_number: profileCheck.phone_number,
      email: profileCheck.email,
      created_at: format(
        new Date(profileCheck?.created_at),
        "MMM dd yyyy, hh:mm aa"
      ),
    };
  });

  const filteredData = data
    ? data
        .filter(
          (item) =>
            searchText === "" ||
            item.full_name.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((item, index) => {
          const isChecked = checkboxStates[`select-${index}`] || false;
          return {
            ...item,
            full_name: columns[0].checkbox ? (
              <div className={Flex}>
                <Checkbox
                  name={`select-${index}`}
                  id={`select-${index}`}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled={item.status.props.children[1] === "No Match"}
                  muted={item.status.props.children[1] === "No Match"}
                />
                <span style={{ marginLeft: "1rem" }}>{item.full_name}</span>
              </div>
            ) : (
              item.full_name
            ),
          };
        })
    : [];

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleRequestReport = async () => {
    setIsLoadingRequest(true);

    const data = {
      ids: selectedProfiles,
    };

    try {
      const response = await store.profileCheck.requestReport(data);
      if (response && response?.data?.count) {
        setReportCount(response?.data?.count);
        setIsSuccessfulRequestOpen(true);
      } else if (response && response?.data?.data?.key === "no-subscription") {
        setIsInactiveModalOpen(true);
      } else if (
        response &&
        response?.data?.data?.key === "insufficient-unit"
      ) {
        setIsInsufficientUnitOpen(true);
      } else if (response?.data?.message === "The ids field is required.") {
        toast.custom((t) => (
          <Toast toastType="error" text="Please select one or more profiles." />
        ));
      } else if (response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType="error"
                text={errorMessage}
              />
            ));
          });
        });
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoadingRequest(false);
    }
  };

  return (
    <>
      <section className={MostRecent}>
        <div className={MostRecentRequestHeader}>
          <div className={`${Flex} gap-6 w-[60%]`}>
            <p className="text-base-semibold card-header headings whitespace-nowrap">
              Results
            </p>
            <TextField
              type="text"
              name="search_names"
              placeholder="Search names"
              value={searchText}
              onChange={handleSearchInputChange}
              iconLeft={<SearchIcon className="icon left-icon" />}
              className="text-indent"
              width="15.875rem"
              wrapperclass="input-sm"
            />
            {/* <SelectField
            name="filter"
            value="Sort by date"
            // onChange={selectedColumn => handleSort(selectedColumn)}
            onChange={(selectedColumn) => {
              const { key } = selectedColumn.target;
              handleSort(key);
            }}
            options={columns
              .filter((option) => option.accessor === "created_at")
              .map((option) => ({
                value: option.Header,
                key: option.accessor,
              }))}
            width="8rem"
            className="filter-sm"
            wrapperclass="dropdown-md"
          /> */}
          </div>
          {isLoadingRequest ? (
            <Spinner style={{ margin: "0 0 0 auto" }} />
          ) : (
            <div
              className={`text-base-semibold accent-2 ${Flex} gap-2 ${
                filteredData?.length === 0 ? "disabled" : "cursor-pointer"
              }`}
              onClick={
                filteredData?.length !== 0
                  ? () => handleRequestReport()
                  : undefined
              }
            >
              <div
                className={`bg-accent-2 w-6 h-6 rounded-[50%] p-x ${FlexCenter}`}
              >
                <UserIcon stroke="var(--white)" />
              </div>
              Request report
            </div>
          )}
        </div>
        {profileChecksLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : profileChecks?.length > 0 ? (
          <Table
            columns={columns}
            data={filteredData}
            showSearch={false}
            className="customer-request-table"
          />
        ) : (
          <Card>
            <div className={`${FlexColumnCenter} empty-state`}>
              <p className="text-base">
                Looks like a blank canvas! Get ready to fill this space with
                meaningful insights
              </p>
              <span
                className={`text-md-spaced ${!!companyId ? "cursor-pointer" : "disabled"}`}
                onClick={
                  !!companyId
                    ? () => setIsProfileCheckFormOpen(true)
                    : undefined
                }
              >
                START LOOKUP
              </span>
            </div>
          </Card>
        )}
      </section>
      {isSuccessfulRequestOpen && (
        <RequestReportSuccessModal
          isOpen={isSuccessfulRequestOpen}
          onClose={() => setIsSuccessfulRequestOpen(false)}
          count={reportCount}
        />
      )}
      {isInsufficientUnitOpen && (
        <InsufficientUnitModal
          isOpen={isInsufficientUnitOpen}
          onClose={() => setIsInsufficientUnitOpen(false)}
        />
      )}
      {isInactiveModalOpen && (
        <InactiveSubscriptionModal
          isOpen={isInactiveModalOpen}
          onClose={() => setIsInactiveModalOpen(false)}
        />
      )}
    </>
  );
};

export default Results;
