import { useEffect, useState } from 'react';
import { Toaster, toast } from 'sonner';
import store from 'store';
import FloatingTextField from 'components/formFields/FloatingTextField';
import { FullWidthButton } from 'components/button';
import { AuthForm } from 'assets/styles/authpage-styles';
import { validateFormData } from 'utils/validateFormData';
import Toast from 'components/common/ToastNotification';
import Spinner from 'components/common/Spinner';

const Login = ({
  onLogin,
  onForgotPassword,
  onResetPassword,
  onVerifyRedirect,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (onResetPassword) {
      toast.custom((t) => (
        <Toast
          toastType='success'
          text={'Password reset successful'}
        />
      ));
    }
  }, [onResetPassword]);

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data = {
      email: selectedValue.email,
      password: selectedValue.password,
    };
    const validationRules = {
      email: [{ required: true }],
      password: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={firstError.replace('_', ' ')}
        />
      ));
      setIsLoading(false);
      return;
    }
    try {
      const response = await store.auth.login(data);
      if (response && response?.data?.token) {
        const token = response?.data?.token;
        store.auth.storeToken(token);
        const code = response?.data?.country_code;
        store.auth.storeCountryCode(code);
        localStorage.setItem('user', JSON.stringify(response?.data));

        // Check if the plan is present in the URL
        const urlSearchParams = new URLSearchParams(window.location.search);
        const plan = urlSearchParams.get('plan');

        // Save the plan to local storage
        if (plan) {
          localStorage.setItem('plan', plan);
        } else {
          localStorage.setItem('plan', 'free');
        }

        onLogin();
        window.location.reload();
      } else if (response?.data?.data?.redirect_to) {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={response?.data?.message}
          />
        ));
        const newData = {
          country_code: response?.data?.data?.country_code,
          email: data.email,
        };
        try {
          const response = await store.auth.postResendEmailVerification(
            newData
          );
          if (response && response?.data?.reference) {
            const referenceData = {
              email: data.email,
              reference: response?.data?.reference,
            };
            onVerifyRedirect(referenceData);
          } else if (response?.errors || response?.data?.errors) {
            const errorKeys = Object.keys(
              response?.errors || response?.data?.errors
            );
            errorKeys.forEach((key) => {
              (
                response?.errors?.[key] || response?.data?.errors?.[key]
              ).forEach((errorMessage) => {
                toast.custom((t) => (
                  <Toast
                    key={`${key}-${errorMessage}`}
                    toastType='error'
                    text={errorMessage}
                  />
                ));
              });
            });
          } else {
            toast.custom((t) => (
              <Toast
                toastType='error'
                text={response?.data?.message || response?.message}
              />
            ));
          }
        } catch (error) {
          toast.custom((t) => (
            <Toast
              toastType='error'
              text={'Something went wrong'}
            />
          ));
        }
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType='error'
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={'Something went wrong'}
        />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className='container'>
      <section className='header login-header'>
        <h2 className='heading-h2'>Log in to Maxim</h2>
        <p className='text-base'>A smarter way to evaluate creditworthiness.</p>
      </section>
      <AuthForm onSubmit={handleSubmit}>
        <FloatingTextField
          label='Enter email'
          type='email'
          id='email'
          name='email'
          labelfor='email'
          value={selectedValue.email}
          onChange={(e) => handleChange('email', e)}
        />
        <FloatingTextField
          label='Enter password'
          type='password'
          id='password'
          name='password'
          labelfor='password'
          value={selectedValue.password}
          onChange={(e) => handleChange('password', e)}
        />
        <p
          className='text-base-semibold forgot-password'
          onClick={() => onForgotPassword()}
        >
          Forgot Password?
        </p>
        {isLoading ? (
          <Spinner style={{ margin: '0.5rem auto' }} />
        ) : (
          <FullWidthButton
            bg='var(--accent-1)'
            textcolor='var(--primary)'
            width='100%'
            type='submit'
          >
            Log in
          </FullWidthButton>
        )}
      </AuthForm>
      <section className='base'>
        <p className='text-base'>
          New to Maxim?{' '}
          <a
            href='https://calendly.com/maxim-support/30min'
            target='_blank'
            rel='noreferrer'
          >
            Book a demo
          </a>
        </p>
      </section>
      <Toaster
        position='top-center'
        visibleToasts={5}
      />
    </section>
  );
};

export default Login;
