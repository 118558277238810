import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallButton } from 'components/button';
import PlanInfo from './PlanInfo';
import BillingInfo from './BillingInfo';
import BillingHistory from './BillingHistory';
import store from 'store';
import { useSnapshot } from 'valtio';
import Spinner from 'components/common/Spinner';
import { capitalizeFirstLetter } from 'utils/common';
import { getLocalStorageProperties } from 'utils/user';

const PlansAndBillingSection = () => {
  const navigate = useNavigate();
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const subscriptionLoading = subscriptionSnapshot?.subscriptionLoading;
  const subscriptionData = subscriptionSnapshot?.subscriptionData;
  const maximUser = localStorage.getItem('user');
  const toExtract = ['company_id'];
  const extractedCompanyId = getLocalStorageProperties(maximUser, toExtract);
  const { company_id } = extractedCompanyId;

  useEffect(() => {
    if (company_id) {
      store?.subscription.getSubscription();
    }
  }, [company_id]);

  if (subscriptionLoading)
    return (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    );

  return (
    <div className='py-8 px-11'>
      <div className='w-[80%]'>
        <div className='flex justify-between mb-9'>
          <div>
            <p className='text-base text-dark font-semibold mb-2'>
              Plan overview
            </p>
            {subscriptionData?.subscription_details?.plan?.id ? (
              <p className='text-sm text-neutral'>
                You are currently on{' '}
                {capitalizeFirstLetter(
                  subscriptionData?.subscription_details?.plan?.id
                )}{' '}
                plan
              </p>
            ) : (
              <p className='text-sm text-neutral'>
                You currently have no active plan
              </p>
            )}
          </div>
          {!subscriptionData?.subscription_details?.hasUpcomingSubscription ||
          !subscriptionData?.subscription_details?.active ? (
            <SmallButton
              bg='var(--accent-1)'
              border='var(--white-alt)'
              textcolor='var(--primary)'
              hovercolor='var(--white-alt)'
              onClick={() => navigate('/plans/subscriptions')}
              disabled={!company_id}
            >
              {!subscriptionData?.subscription_details?.startDate ||
              !subscriptionData?.subscription_details?.active
                ? 'Subscribe now'
                : 'Change plan'}
            </SmallButton>
          ) : null}
        </div>

        <PlanInfo />

        <BillingInfo />
      </div>

      <BillingHistory />
    </div>
  );
};

export default PlansAndBillingSection;
