import { Flex } from "assets/styles/common-styles";
import { MainPageContent, MainPageTitle } from "assets/styles/docs-styles";
import DocsLayout from "components/api-docs/layout";
import SideContent from "components/api-docs/layout/SideContent";

const ApiDocsIntroduction = () => {
  return (
    <DocsLayout>
      <MainPageTitle className={Flex}>
        <h2 className="heading-h1 headings">Introduction</h2>
      </MainPageTitle>
      <MainPageContent>
        <section>
          <p className="text-base-semibold text-dark mb-6">
            Welcome to the Maxim API documentation
          </p>
          <p className="leading-[1.75rem] mb-8">
            Welcome to the Maxim API documentation. This documentation provides
            detailed information on interacting with the Maxim API, enabling you
            to integrate Maxim's services into your applications. Maxim is a
            comprehensive platform for assessment reports on consumers and
            commercial entities.
          </p>
          <p className="leading-[1.75rem] mb-8">
            Maxim is currently available in the{" "}
            <span className="text-base-medium text-dark">UK</span> and{" "}
            <span className="text-base-medium text-dark">Nigeria</span>.
          </p>

          <p className="leading-[1.75rem] mb-8">
            We're here to help! Thank you for choosing Maxim APIs to enhance your
            applications. If you have any questions, need assistance, want to
            share feature suggestions, or encounter any issues, please don't
            hesitate to contact us.
          </p>
        </section>
        <SideContent />
      </MainPageContent>
    </DocsLayout>
  );
};

export default ApiDocsIntroduction;
