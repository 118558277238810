import { InnerPageContent } from "assets/styles/docs-styles";
import InnerPageTitle from "components/api-docs/InnerPageTitle";
import { DarkCodeBox, Flex, FlexRowBetween } from "assets/styles/common-styles";
import UrlBox from "components/api-docs/UrlBox";
import SelectField from "components/formFields/SelectField";
import Table from "components/table";
import { useState } from "react";

const ApiReference = ({
  pageTitle,
  pageDescription,
  subtitle,
  urlType,
  urlBg,
  urlColor,
  endpoint,
  statusOptions,
  contentMap,
}) => {
  const [selectedValue, setSelectedValue] = useState(statusOptions[0].key);

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.key);
  };

  return (
    <>
      <InnerPageTitle title={pageTitle} description={pageDescription} />
      <InnerPageContent>
        <section>
          <h3 className="heading-h2-mid headings mb-[1.1875rem]">{subtitle}</h3>
          <UrlBox
            type={urlType}
            bg={urlBg}
            color={urlColor}
            endpoint={endpoint}
          />
          <div className={`${FlexRowBetween} gap-4 my-[2.75rem] flex-wrap`}>
            <div className={`${Flex} gap-10 flex-1`}>
              <p className="text-base-semibold text-dark">Select status code</p>
              <SelectField
                name="filter"
                value={
                  statusOptions.find((option) => option.key === selectedValue)
                    ?.value
                }
                width="18rem"
                className="select-sm"
                options={statusOptions}
                onChange={handleSelectChange}
              />
            </div>
            <p>Last updated 29 Feb, 2024</p>
          </div>
        </section>

        {selectedValue && contentMap[selectedValue] && (
          <section className="flex w-full">
            <div className="main-content">
              {contentMap[selectedValue].queryData && (
                <>
                  <p className="text-base-semibold text-dark mb-4">
                    Query parameters
                  </p>
                  <Table
                    columns={contentMap.columns}
                    data={contentMap[selectedValue].queryData}
                    showSearch={false}
                    className="docs-table mb-[4rem]"
                  />
                </>
              )}

              {contentMap[selectedValue].headerData && (
                <>
                  <p className="text-base-semibold text-dark mb-4">Header</p>
                  <Table
                    columns={contentMap.columns}
                    data={contentMap[selectedValue].headerData}
                    showSearch={false}
                    className="docs-table"
                  />
                </>
              )}
            </div>

            <div className="side-content">
              {contentMap[selectedValue].request && (
                <DarkCodeBox className="mb-[2.75rem]">
                  <div className="header">
                    <p className="coloured text-md-semibold">JSON Request</p>
                  </div>
                  <div className="base">
                    <code className="text-md whitespace-pre-wrap indent-0.5">
                      <samp className="text-muted">
                        {JSON.stringify(
                          contentMap[selectedValue].request,
                          null,
                          2
                        )}
                      </samp>
                    </code>
                  </div>
                </DarkCodeBox>
              )}
              <DarkCodeBox>
                <div className="header">
                  <p className="coloured text-md-semibold">Response</p>
                </div>
                <div className="base">
                  <code className="text-md whitespace-pre-wrap indent-0.5">
                    <samp className="text-muted">
                      {JSON.stringify(
                        contentMap[selectedValue].response,
                        null,
                        2
                      )}
                    </samp>
                  </code>
                </div>
              </DarkCodeBox>
              <p className="mt-10">{contentMap[selectedValue].description}</p>
            </div>
          </section>
        )}
      </InnerPageContent>
    </>
  );
};

export default ApiReference;
