import React, { useEffect, useState } from 'react';
import { css } from 'goober';
import { ReactComponent as ArrowUp } from "assets/images/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";

const AccordionItem = ({ title, content, leftIcon, isOpen, toggle }) => (
  <div>
    <div className={`${itemStyle} ${isOpen ? "is-open" : "" }`} onClick={toggle}>
      <div className={titleStyle}>
        <h3 className="heading-h3-type-2">
          {leftIcon && leftIcon}
          {title}
        </h3>
        {isOpen ? <ArrowUp stroke="var(--headings)" /> : <ArrowDown stroke="var(--stroke)" /> }
      </div>
    </div>
    {isOpen && <div className={contentStyle}>{content}</div>}
  </div>
);

const Accordion = ({ items, leftIcon, blockedItems }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [nextAvailableAccordion, setNextAvailableAccordion] = useState(null);

  const toggleItem = (index) => {
    // Check if the clicked item is in the list of blocked items
    if (blockedItems && blockedItems?.includes(index)) {
      return; // Do nothing if the item is blocked
    }
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    // Function to find the next available unblocked accordion
    for (let i = 0; i < items?.length; i++) {
      if (!blockedItems || !blockedItems?.includes(i)) {
        setNextAvailableAccordion(i);
        break;
      }
    }
  }, [items, blockedItems]);

  useEffect(() => {
    if (nextAvailableAccordion !== null) {
      setOpenIndex(nextAvailableAccordion);
    }
  }, [nextAvailableAccordion]);

  return (
    <div className={accordionStyle}>
      {items?.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          toggle={() => toggleItem(index)}
          leftIcon={leftIcon(item)}
        />
      ))}
    </div>
  );
};

const accordionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const itemStyle = css`
  cursor: pointer;
  border-radius: var(--br-md);
  border: 0.5px solid var(--stroke);
  background-color: var(--white);
  padding: 1.25rem 2.0625rem 1.25rem 1.375rem;
  transition: background-color 0.5s ease;
  &.is-open {
    border-radius: var(--br-md) var(--br-md) 0 0;
    padding: 1.25rem 2.0625rem 0.625rem 1.375rem;
    border-bottom: 0;
  }
`;

const titleStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--headings);
  user-select: none;
  h3 {
    display: flex;
    align-items: center;
    gap: 0.9375rem;
  }
`;

const contentStyle = css`
  max-height: 1000px;
  overflow: hidden;
  background: var(--white);
  padding: 0 4.125rem 2.3125rem 3.5rem;
  border: 0.5px solid var(--stroke);
  border-top: 0;
  border-radius: 0 0 var(--br-md) var(--br-md);
  transition: max-height 0.5s ease-in-out;
`;

export default Accordion;
