import React from 'react';
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { FlexColumnBetween } from 'assets/styles/common-styles';
import { ReactComponent as StarCheck } from "assets/images/icons/star-check.svg";

const CustomerRequestSuccessModal = ({ isOpen, onClose, requestResponse, setIsCustomerReportRequestFormOpen }) => {
  const handleClose = () => {
    setIsCustomerReportRequestFormOpen(false);
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBodyWrapper className={`request-sent ${FlexColumnBetween}`}>
        <StarCheck />
        <div>
          <h2 className="modal-title">Request sent successfully</h2>
          
          <p className="text-base" dangerouslySetInnerHTML={{ __html: requestResponse }} />
        </div>
        <MediumButton bg="var(--accent-1)" textcolor="var(--primary)" onClick={handleClose} type="button">
          Okay, got it
        </MediumButton>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default CustomerRequestSuccessModal;
