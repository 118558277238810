export const validateFormData = (formData, validationRules) => {
  const errors = {};

  for (const [fieldName, fieldValue] of formData.entries()) {
    const fieldRules = validationRules[fieldName];

    if (fieldRules) {
      for (const rule of fieldRules) {
        if (rule.required && !fieldValue) {
          errors[fieldName] = `${fieldName} is required`;
        }

        if (rule.maxLength && fieldValue.length > rule.maxLength) {
          errors[fieldName] = `${fieldName} should be at most ${rule.maxLength} characters long`;
        }

        if (rule.minLength && fieldValue.length < rule.minLength) {
          errors[fieldName] = `${fieldName} should be at least ${rule.minLength} characters long`;
        }

        if (rule.pattern && fieldValue && !rule.pattern.test(fieldValue)) {
          errors[fieldName] = `${fieldName} is invalid`;
        }
      }
    }
  }

  return errors;
};
