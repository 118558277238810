import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const InitiateCheck = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          400 Error
        </div>
      ),
      key: "400",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "company_name_number": "qUICKTEk DIGITALs INNOVATIONS"
      },
      response: {
        "message": "A report on <strong>QUICKTEK DIGITAL INNOVATIONS</strong> has been requested. You will be notified once it is ready.",
        "data": {
          "reference": "Pl5PDAzPVfWegmAfOzhh",
          "company_name": "QUICKTEK DIGITAL INNOVATIONS",
          "company_number": "2739770",
          "status": "pending",
          "created_at": "2024-01-22T14:24:56.000000Z"
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the initiation of a commercial report was successful.
        </>
      ),
    },
    400: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "company_name_number": "qUICKTEk DIGITALs INNOVATIONS"
      },
      response: {
        "message": "You dont have enough unit to process this request"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This suggests a bad request, indicating that the server cannot process the request due to client error, such as an invalid or improperly formatted request payload.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Commercial Report"
      pageDescription="Use this endpoint to check the CAC status and credit details of a registered Nigerian company or business organization."
      subtitle="Initiate"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/commercial-reports"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default InitiateCheck;
