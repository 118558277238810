import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const GetAllAssessments = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
  ];

  const contentMap = {
    columns: [
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      queryData: [
        {
          key: "with[]",
          value: "country",
          description: "-",
        },
        {
          key: "with[]",
          value: "consent",
          description: "-",
        },
      ],
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Assessment fetched",
        "data": [
          {
            "user_country_code": "ng",
            "request_source": "api",
            "type": "Credit",
            "reference": "qiLx7PkqGTtGhhU",
            "first_name": "ola",
            "last_name": "jide",
            "middle_name": "samuel",
            "decision_criteria_id": "",
            "id_type": "National ID Card",
            "id_number": "1232490394",
            "email": "samuel+migrant@trymaxim.com",
            "phone_number": "2347065514172",
            "status": "pending",
            "created_at": "2023-10-19T22:30:00.000000Z",
            "updated_at": "2023-10-19T22:30:00.000000Z",
            "country": {
              "alpha_2_code": "ng",
              "name": "Nigeria",
              "calling_code": "234",
              "flag": "https://www.worldometers.info/img/flags/small/tn_ni-flag.gif"
            },
            "consent": null
          },
          {
            "user_country_code": "ng",
            "request_source": "dashboard",
            "type": "Credit",
            "reference": "iwL6aLA5A15vS7S",
            "first_name": "ola",
            "last_name": "jide",
            "middle_name": "samuel",
            "decision_criteria_id": "",
            "id_type": "National ID Card",
            "id_number": "1232490394",
            "email": "samuel+migrant@trymaxim.com",
            "phone_number": "2347065514172",
            "status": "pending",
            "created_at": "2023-10-19T22:15:20.000000Z",
            "updated_at": "2023-10-19T22:15:20.000000Z",
            "country": {
              "alpha_2_code": "ng",
              "name": "Nigeria",
              "calling_code": "234",
              "flag": "https://www.worldometers.info/img/flags/small/tn_ni-flag.gif"
            },
            "consent": null
          },
          {
            "user_country_code": "gb",
            "request_source": "dashboard",
            "type": "Credit",
            "reference": "aH9p_rH06K9LiFK",
            "first_name": "ola",
            "last_name": "jide",
            "middle_name": "samuel",
            "decision_criteria_id": "",
            "id_type": "National ID Card",
            "id_number": "1232490394",
            "email": "samuel+migrant@trymaxim.com",
            "phone_number": "2347065514172",
            "status": "pending",
            "created_at": "2023-10-19T22:15:19.000000Z",
            "updated_at": "2023-10-19T22:15:19.000000Z",
            "country": {
              "alpha_2_code": "gb",
              "name": "United Kingdom",
              "calling_code": "44",
              "flag": "https://www.worldometers.info/img/flags/small/tn_uk-flag.gif"
            },
            "consent": null
          },
          {
            "user_country_code": "ng",
            "request_source": "dashboard",
            "type": "Credit",
            "reference": "4Rs6037dzA85Sjq",
            "first_name": "ola",
            "last_name": "jide",
            "middle_name": "samuel",
            "decision_criteria_id": "",
            "id_type": "National ID Card",
            "id_number": "1232490394",
            "email": "samuel+migrant@trymaxim.com",
            "phone_number": "2347065514172",
            "status": "pending",
            "created_at": "2023-10-19T22:15:18.000000Z",
            "updated_at": "2023-10-19T22:15:18.000000Z",
            "country": {
              "alpha_2_code": "ng",
              "name": "Nigeria",
              "calling_code": "234",
              "flag": "https://www.worldometers.info/img/flags/small/tn_ni-flag.gif"
            },
            "consent": null
          }
        ]
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This
          indicates that the request to retrieve all assessments has been
          successful.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Get all assessments"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/assessments?with[]=country&with[]=consent"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default GetAllAssessments;
