import { proxy } from "valtio";
import axiosInstance from "axiosInstance";

const commercialCheck = proxy({
  initiateCheckData: null,
  subscriptionData: null,
  subscriptionLoading: false,
  commercialChecks: [],
  commercialChecksLoading: false,
  searchCommercialChecks: [],
  searchCommercialChecksLoading: false,
  singleCommercialCheck: null,
  singleCommercialCheckLoading: false,
  pricePerUnitData: null,
  pricePerUnitLoading: false,
  generateReferenceData: null,
  generateReferenceLoading: false,
  requestDirectorReportData: null,
  requestDirectorReportLoading: false,

  async initiateCheck(company_name_number) {
    this.initiateCheckLoading = true;

    return axiosInstance
      .post(`/commercial-reports`, { company_name_number })
      .then(({ data }) => {
        this.initiateCheckData = data.data;
        return this.initiateCheckData;
      })
      .catch(({ response }) => {
        return response;
      });
  },

  async getCommercialChecks({ page, perPage }) {
    this.commercialChecksLoading = true;
    return axiosInstance
      .get(`/commercial-reports?page=${page}&perPage=${perPage}`)
      .then(({ data }) => {
        this.commercialChecks = data;
        this.commercialChecksLoading = false;
        return this.commercialChecks;
      })
      .catch(({ response }) => {
        this.commercialChecksLoading = false;
        return response;
      });
  },

  async searchCommercialChecksFull(query) {
    this.searchCommercialChecksLoading = true;
    return axiosInstance
      .get(`/commercial-reports?q=${query}`)
      .then(({ data }) => {
        this.searchCommercialChecks = data;
        this.searchCommercialChecksLoading = false;
        return this.searchCommercialChecks;
      })
      .catch(({ response }) => {
        this.searchCommercialChecksLoading = false;
        return response;
      });
  },

  async getSingleCheck(ref) {
    this.singleCommercialCheckLoading = true;
    return axiosInstance
      .get(`/commercial-reports/${ref}`)
      .then(({ data }) => {
        this.singleCommercialCheck = data;
        this.singleCommercialCheckLoading = false;
        return this.singleCommercialCheck;
      })
      .catch(({ response }) => {
        this.singleCommercialCheckLoading = false;
        return response;
      });
  },

  async getSubscription() {
    this.subscriptionLoading = true;

    return axiosInstance
      .get(`/commercial-reports/subscription`)
      .then(({ data }) => {
        this.subscriptionData = data.data;
        this.subscriptionLoading = false;
        return this.subscriptionData;
      })
      .catch(({ response }) => {
        this.subscriptionLoading = false;
        return response;
      });
  },

  async getPricePerUnit(volume) {
    this.pricePerUnitLoading = true;

    return axiosInstance
      .get(`/commercial-reports/subscription/price-per-unit?volume=${volume}&type=${"commercial"}`)
      .then(({ data }) => {
        this.pricePerUnitData = data.data;
        this.pricePerUnitLoading = false;
        return this.pricePerUnitData;
      })
      .catch(({ response }) => {
        this.pricePerUnitLoading = false;
        return response;
      });
  },

  async generateReference(volume) {
    this.generateReferenceLoading = true;

    return axiosInstance
      .get(`/commercial-reports/subscription/reference?volume=${volume}`)
      .then(({ data }) => {
        this.generateReferenceData = data.data;
        this.generateReferenceLoading = false;
        return this.generateReferenceData;
      })
      .catch(({ response }) => {
        this.generateReferenceLoading = false;
        return response;
      });
  },

  async requestDirectorReport(body) {
    this.requestDirectorReportLoading = true;

    return axiosInstance
      .post(
        `/commercial-reports/${body.commercialID}/director/${body.directorID}/report`,
        { email: body.email }
      )
      .then(({ data }) => {
        this.requestDirectorReportData = data;
        this.requestDirectorReportLoading = false;
        return this.requestDirectorReportData;
      })
      .catch(({ response }) => {
        this.requestDirectorReportLoading = false;
        return response;
      });
  },
});

export default commercialCheck;
