import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { MediumButton } from "components/button";
import store from "store";
import { useSnapshot } from "valtio";
import Spinner from "components/common/Spinner";
import { useCallback, useEffect, useState } from "react";
import Toast from "components/common/ToastNotification";
import { toast } from "sonner";

const SelectionModal = ({ isOpen, onClose, plan }) => {
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const generateReferenceLoading =
    subscriptionSnapshot?.generateReferenceLoading;
  const generateReferenceData = subscriptionSnapshot?.generateReferenceData;
  const verifyPaymentLoading = subscriptionSnapshot?.verifyPaymentLoading;
  const user = JSON.parse(localStorage.getItem("user"));
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");
  const [hasExecuted, setHasExecuted] = useState(false);
  const [flwResponse, setflwResponse] = useState(null);

  useEffect(() => {
    setAmount(generateReferenceData?.amount);
    setReference(generateReferenceData?.reference);
  }, [generateReferenceData]);

  useEffect(() => {
    const verifyPayment = async (id) => {
      try {
        const response = await store?.subscription.verifyPayment({
          transaction_id: id,
        });

        if (response?.data?.status === false) {
          alert(response?.data?.message);
          onClose();
        } else {
          alert("Payment Successful!");
          onClose();
          setTimeout(() => {
            window.location.href = "/plans";
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (flwResponse?.status === "successful") {
      verifyPayment(flwResponse?.transaction_id.toString());
      setflwResponse(null);
    }
  }, [flwResponse, onClose]);

  const makePayment = useCallback(() => {
    // eslint-disable-next-line no-undef
    const modal = FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLUTTERWAVE_PUB_KEY,
      tx_ref: reference,
      amount: amount,
      currency: plan?.currency,
      payment_options: "card",
      customer: {
        email: user?.email,
      },
      customizations: {
        description: `You are about to make payment for a plan.`,
      },
      callback: function (response) {
        setflwResponse(response);
        modal.close();
      },
    });
  }, [reference, amount, plan?.currency, user?.email]);

  useEffect(() => {
    if (reference?.length > 0 && hasExecuted === false) {
      setHasExecuted(true);

      if (!generateReferenceData?.inlineCharge) {
        toast.custom((t) => (
          <Toast toastType="info" text="Transaction is processing" />
        ));
        setTimeout(() => {
          window.location.href = "/plans";
        }, 1000);
      } else {
        makePayment();
      }
    }
  }, [reference, hasExecuted, makePayment, generateReferenceData]);

  const generateReference = async () => {
    try {
      const customBody = {
        planId: plan?.label.toLowerCase(),
        volume: plan?.units,
        datapoints: plan.datapoints,
      };

      const plansBody = {
        planId: plan?.label.toLowerCase(),
      };

      const response = await store?.subscription.generateReference(
        plan?.label === "Custom" ? customBody : plansBody
      );

      if (response?.data?.status === false) {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message} />
        ));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper>
        {verifyPaymentLoading ? (
          <div className="flex flex-col justify-center items-center h-[27rem]">
            <p className="text-headings font-secondary text-[1.75rem] text-center">
              Verifying Payment
            </p>
            <Spinner className="my-[0.5rem]" />
          </div>
        ) : (
          <div className="flex flex-col justify-between h-[27rem]">
            <div className="bg-primary-darkest rounded-xl py-4 px-6">
              <div className="flex items-center space-x-2 mb-8">
                <p className="text-xl text-coloured font-semibold">
                  {plan?.label}
                </p>
                <span className="bg-complementary-1-opaque-20 px-3 py-1 rounded text-complementary-1 font-semibold text-[0.625rem] tracking-[1.5px]">
                  MONTHLY
                </span>
              </div>

              <div className="flex justify-between">
                <div>
                  <p className="text-neutral font-semibold text-[0.625rem] tracking-[1.5px] mb-1">
                    TOTAL CHECKS
                  </p>
                  <p className="text-xl text-coloured font-semibold">
                    {plan?.units}{" "}
                    <span className="text-accent-2 text-[0.625rem] font-semibold tracking-[1.5px]">
                      UNITS/MONTH
                    </span>
                  </p>
                </div>
                <div>
                  <p className="text-neutral font-semibold text-[0.625rem] tracking-[1.5px] mb-1">
                    AMOUNT TO PAY
                  </p>
                  <p className="text-xl text-coloured font-semibold">
                    {plan?.price}{" "}
                    <span className="text-neutral text-[0.625rem] font-semibold tracking-[1.5px]">
                      {plan?.currency}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center">
              <p className="text-headings font-secondary text-[1.75rem] text-center">
                You made a selection
              </p>
              <p className="text-center text-base text-neutral w-[65%]">
                Are you sure you want to proceed with this plan?
              </p>
            </div>

            <div className="flex items-center justify-between">
              <MediumButton
                bg="var(--white-alt)"
                border="var(--white-alt)"
                textcolor="var(--text-dark)"
                hovercolor="var(--white-alt)"
                onClick={() => onClose()}
              >
                Cancel
              </MediumButton>
              {generateReferenceLoading ? (
                <div className="flex justify-end">
                  <Spinner className="my-[0.5rem]" />
                </div>
              ) : (
                <MediumButton
                  bg="var(--accent-1)"
                  border="var(--accent-1)"
                  textcolor="var(--text-dark)"
                  hovercolor="var(--complimentary-2)"
                  onClick={() => generateReference()}
                >
                  Proceed to payment
                </MediumButton>
              )}
            </div>
          </div>
        )}
      </ModalBodyWrapper>
    </Modal>
  );
};

export default SelectionModal;
