import React from 'react';
import { styled } from 'goober';
import { Input, TextFieldWrapperStyles } from 'components/formFields/formStyles';

const TextFieldWrapper = styled("div")`
  ${TextFieldWrapperStyles}
`;

const TextField = ({ type, wrapperclass, className, iconLeft, iconRight, width, iconTopSpacing, iconLeftSpacing, padding, disabled, ...props }) => {
  const customStyles = {
    "--textfield-width": width,
    "--icon-top-spacing": iconTopSpacing,
    "--icon-left-spacing": iconLeftSpacing,
    "--input-padding": padding,
  };

  return (
    <TextFieldWrapper className={wrapperclass} style={customStyles}>
      <Input type={type} className={className} disabled={disabled} {...props} />
      {iconLeft && iconLeft}
      {iconRight && iconRight}
    </TextFieldWrapper>
  );
};

export default TextField;
