import { mobile, mobileMedium, tabletSmall } from "globalStyles";
import { css, styled } from "goober";

export const Flex = css`
  display: flex;
  align-items: center;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnBetween = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FlexRowBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const SpaceBetween = css`
  justify-content: space-between;
`;

export const FlexEndCenter = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SpaceBetweenW100 = css`
  justify-content: space-between;
  width: 100%;
`;

export const FlexColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexJustifyCenter100 = css`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const TagWrapper = css`
  padding: 0.125rem 0.75rem;
  background: var(--tag-bg);
  color: var(--tag-color);
  border: 1px solid var(--tag-border);
  width: fit-content;
  border-radius: 6px;
`;

export const BoxTagStyle = styled("span")`
  padding: 0.25rem 0.75rem;
  background: rgba(5, 167, 227, 0.2);
  color: var(--complimentary-1);
  width: fit-content;
  border-radius: 4px;
`;

export const CloseToastIcon = styled("span")`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  cursor: pointer;
`;

export const TableFilter = styled("section")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  .filter-row-right {
    display: flex;
    align-items: center;
    gap: 1.625rem;
    ${mobileMedium} {
      flex-wrap: wrap;
      gap: 0.75rem;
    }
  }
`;

export const EmptyStateWrapper = styled("section")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 42%;
  min-height: 90vh;
  margin: 0 auto;
  text-align: center;
  padding: 3rem 3.75rem 2rem 3.75rem;
  ${tabletSmall} {
    width: 80%;
  }
  ${mobile} {
    padding: 1rem;
    flex-wrap: wrap;
  }
`;

export const Fieldset = styled("fieldset")`
  gap: 1.5rem;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-color: var(--stroke);
  border-width: 0.5px;
`;

export const Legend = styled("legend")`
  padding-right: 1rem;
  margin-bottom: 1.5rem;
`;

export const BackBtn = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  p {
    line-height: normal;
  }
  svg {
    width: 10px;
  }
`;

export const CodeBox = styled("section")`
  .header {
    background: var(--primary);
    border-radius: var(--br) var(--br) 0 0;
    padding: 1.0625rem 1.75rem 1.0625rem 1.6875rem;
  }
  .base {
    background: var(--white-alt);
    padding: 1.4375rem 1.5625rem 1.4375rem 1.625rem;
    border-radius: 0 0 var(--br) var(--br);
  }
`;

export const DarkCodeBox = styled(CodeBox)`
  max-width: 28.75rem;
  width: 100%;
  .base {
    background: #193147;
    overflow: auto;
    max-height: 24.375rem;
    height: 100%;
  }
`;

export const StatusBall = styled("div")`
  width: 0.5rem;
  height: 0.5rem;
  min-width: 0.5rem;
  min-height: 0.5rem;
  border-radius: 50%;
  &.pending {
    background: var(--warning);
  }
  &.active {
    background: var(--success);
  }
  &.error {
    background: var(--error);
  }
`;

export const ShadowCard = styled("section")`
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--br);
  box-shadow: 0px 15px 50px 0px rgba(205, 213, 219, 0.35);
`;

export const ShadowCircle = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 100%;
  filter: drop-shadow(0px 15px 50px rgba(205, 213, 219, 0.35));
`;

export const ShadowCircle2 = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 100%;
  box-shadow: 0px 12px 24px 0px rgba(1, 26, 39, 0.05);
`;
