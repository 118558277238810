import React, { useState } from "react";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { ReactComponent as Avatar } from "assets/images/avatar.svg";
import { capitalizeWords } from "utils/common";
import FloatingTextField from "components/formFields/FloatingTextField";
import { MediumButton } from "components/button";
import Spinner from "components/common/Spinner";
import store from "store";
import { useSnapshot } from "valtio";
import RequestDirectorReportSuccessModal from "./RequestDirectorReportSuccessModal";
import InsufficientUnitModal from "components/lookup/InsufficientUnitModal";
import InactiveSubscriptionModal from "components/lookup/InactiveSubscriptionModal";

const RequestDirectorReportModal = ({
  isOpen,
  onClose,
  first_name,
  last_name,
  email,
  commercialID,
  directorID,
}) => {
  const [directorEmail, setDirectorEmail] = useState("");
  const commercialCheckSnapshot = useSnapshot(store.commercialCheck);
  const isLoading = commercialCheckSnapshot?.requestDirectorReportLoading;
  const [openModal, setOpenModal] = useState(false);
  const [openNoSubscriptionModal, setOpenNoSubscriptionModal] = useState(false);
  const [openInsufficientUnitModal, setOpenInsufficientUnitModal] =
    useState(false);

  const requestReport = async () => {
    const body = {
      commercialID,
      directorID,
      email: directorEmail || email,
    };
    const response = await store?.commercialCheck?.requestDirectorReport(body);

    if (response?.data?.data?.key === "no-subscription") {
      setOpenNoSubscriptionModal(true);
      setDirectorEmail("");
      onClose();
    } else if (response?.data?.data?.key === "insufficient-unit") {
      setOpenInsufficientUnitModal(true);
      setDirectorEmail("");
      onClose();
    } else {
      setOpenModal(true);
      setDirectorEmail("");
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBodyWrapper>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <h1 className="text-headings font-secondary text-[1.75rem] font-semibold text-center mb-3">
                Request director's report
              </h1>
              <p className="text-neutral text-center">
                Make a consumer check on{" "}
                <span className="font-medium text-dark">
                  {capitalizeWords(first_name)} {capitalizeWords(last_name)}
                </span>{" "}
                to see their credit and financial report.
              </p>
            </div>

            <div className="flex items-start space-x-3 w-full shadow-20 p-5 rounded-2xl mb-6">
              <Avatar />
              <div>
                <p className="text-headings text-lg font-semibold">
                  {capitalizeWords(first_name)} {capitalizeWords(last_name)}
                </p>
                <p className="text-neutral">{email}</p>
              </div>
            </div>

            <div className="w-full flex items-center space-x-5 mb-6">
              <p className="text-dark font-medium">Optional</p>
              <div className="h-[1px] w-full bg-stroke" />
            </div>

            <div className="w-full mb-8">
              <FloatingTextField
                label="Enter director's email"
                type="text"
                id="director-email"
                name="director-email"
                labelfor="director-email"
                value={directorEmail}
                onChange={(e) => setDirectorEmail(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-between w-full">
              <MediumButton
                bg="var(--white-alt)"
                border="var(--white-alt)"
                textcolor="var(--primary)"
                hovercolor="var(--white-alt)"
                onClick={() => onClose()}
              >
                Cancel
              </MediumButton>
              {isLoading ? (
                <Spinner style={{ margin: "0.5rem 0" }} />
              ) : (
                <MediumButton
                  bg="var(--accent-1)"
                  border="var(--accent-1)"
                  textcolor="var(--primary)"
                  hovercolor="var(--accent-1)"
                  onClick={requestReport}
                  disabled={isLoading}
                >
                  Yes, send request
                </MediumButton>
              )}
            </div>
          </div>
        </ModalBodyWrapper>
      </Modal>

      <RequestDirectorReportSuccessModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        first_name={first_name}
        last_name={last_name}
      />

      <InsufficientUnitModal
        isOpen={openInsufficientUnitModal}
        onClose={() => setOpenInsufficientUnitModal(false)}
        text="Director"
      />

      <InactiveSubscriptionModal
        isOpen={openNoSubscriptionModal}
        onClose={() => setOpenNoSubscriptionModal(false)}
        text="Director"
      />
    </>
  );
};

export default RequestDirectorReportModal;
