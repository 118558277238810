import { MediumButton } from "components/button";
import TextField from "components/formFields/TextField";
import Table from "components/table";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import store from "store";
import Spinner from "components/common/Spinner";
import Close from "assets/images/icons/close.svg";
import { toast } from "sonner";
import Toast from "components/common/ToastNotification";
import DeleteWhiteListModal from "components/API-Webhooks/IPWhitelisting/DeleteIPModal";
import { getLocalStorageProperties } from "utils/user";

const IPWhiteListingSection = () => {
  const [whitelistIP, setWhitelistIP] = useState("");
  const settingsSnapshot = useSnapshot(store.settings);
  const [whitelists, setWhitelists] = useState([]);
  const ipWhitelistLoading = settingsSnapshot?.ipWhitelistLoading;
  const setWhitelistLoading = settingsSnapshot?.setWhitelistLoading;
  const deleteWhitelistLoading = settingsSnapshot?.deleteWhitelistLoading;
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: "",
  });
  const [data, setData] = useState([]);
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  useEffect(() => {
    if (companyId) {
      getWhitelists();
    }
  }, [companyId]);

  useEffect(() => {
    if (whitelists) {
      const refinedData = whitelists?.map((whitelist) => {
        return {
          ip_address: whitelist["ip_address "],
          action: (
            <img
              src={Close}
              alt="close"
              className="cursor-pointer"
              onClick={() =>
                setDeleteModal({
                  open: true,
                  id: whitelist.id,
                })
              }
            />
          ),
        };
      });

      setData(refinedData);
    } else {
      setData([]);
    }
  }, [whitelists]);

  const getWhitelists = async () => {
    const response = await store?.settings.getWhitelists();

    setWhitelists(response);
  };

  const createWhitelist = async () => {
    const response = await store?.settings?.setWhitelist(whitelistIP);

    if (response?.data?.errors) {
      toast.custom((t) => (
        <Toast toastType="error" text={response?.data?.message} />
      ));
      return;
    } else {
      setWhitelistIP("");
      toast.custom((t) => (
        <Toast toastType="success" text={response?.message} />
      ));
      getWhitelists();
    }
  };

  const deleteWhitelist = async (id) => {
    const response = await store?.settings.deleteWhitelist(id);

    if (response) {
      toast.custom((t) => (
        <Toast toastType="success" text={response?.message} />
      ));

      setDeleteModal({
        open: false,
        id: "",
      });

      getWhitelists();
    }
  };

  const columns = [
    {
      Header: "IP ADDRESSES",
      accessor: "ip_address",
    },

    {
      Header: "ACTION",
      accessor: "action",
    },
  ];

  if (ipWhitelistLoading)
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="p-11">
      <div className="mb-14">
        <p className="font-semibold text-base text-text-dark">
          IP whitelisting
        </p>
        <p className="text-neutral text-sm mb-2">
          Add and manage whitelisted IP addresses.
        </p>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-10 w-[800px] mb-10">
          <label className="whitespace-nowrap text-text-dark text-sm font-semibold">
            Enter IP address
          </label>
          <TextField
            placeholder="123.45.67.89"
            value={whitelistIP}
            onChange={(e) => setWhitelistIP(e.target.value)}
          />
          {setWhitelistLoading ? (
            <div className="-mt-12">
              <Spinner />
            </div>
          ) : (
            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              hovercolor="var(--accent-1)"
              disabled={!(companyId && whitelistIP.length)}
              onClick={createWhitelist}
            >
              Add new
            </MediumButton>
          )}
        </div>

        {data.length ? (
          <div className="w-[500px]">
            <Table columns={columns} data={data} className="whitelist-table" />
          </div>
        ) : (
          ""
        )}
      </div>

      <DeleteWhiteListModal
        isOpen={deleteModal.open}
        deleteWhitelist={() => deleteWhitelist(deleteModal.id)}
        onClose={() =>
          setDeleteModal({
            open: false,
            id: "",
          })
        }
        isLoading={deleteWhitelistLoading}
      />
    </div>
  );
};

export default IPWhiteListingSection;
