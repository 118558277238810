import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const BulkVerification = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      queryData: [
        {
          key: "file",
          value: "",
          description: "formData",
        },
      ],
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Bulk upload successful!",
        "data": {
          "count": 4
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request for bulk verification was successful.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="User ID Verification"
      pageDescription="Use this endpoint to verify or know your customer using User ID  such as Bank Verification Number - BVN."
      subtitle="BulkVerification"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/uid-lookups/bulk"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default BulkVerification;
