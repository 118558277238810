import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import { SmallButton } from "components/button";
import { useEffect, useState } from "react";
import CopyIcon from "assets/images/icons/copy.svg";
import ConfirmRegenerateKeys from "components/API-Webhooks/ApiKeys/ConfirmRegenerateKeys";
import { useSnapshot } from "valtio";
import store from "store";
import { handleCopy } from "utils/common";
import { getLocalStorageProperties } from "utils/user";
import Spinner from "components/common/Spinner";

const ApiKeysSection = () => {
  const [showApiKeys, setShowApiKeys] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const settingsSnapshot = useSnapshot(store.settings);
  const onboardingSnapshot = useSnapshot(store.onboarding);
  const onboardingStage = onboardingSnapshot?.onboardingStage;
  const apiKey = settingsSnapshot?.apiKey;
  const apiKeyLoading = settingsSnapshot?.apiKeyLoading;
  const maximUser = localStorage.getItem("user");
  const toExtract = ["dashboard_state", "company_id"];
  const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
  const { dashboard_state: dashboardState, company_id: companyId } = extractedUsernames;
  const completionPercentage = onboardingStage?.percentage + "%" || "0%";
  const completionPercentageConverted = parseInt(completionPercentage, 10);
  const fiftyPercent = parseInt("50%", 10);

  useEffect(() => {
    if (companyId) {
      store?.settings.getApiKey();
    }
  }, [companyId]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  const toggleApiKeys = () => {
    setShowApiKeys(!showApiKeys);
  };

  if (apiKeyLoading)
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div style={{ padding: "44px" }}>
      <div className={FlexRowBetween} style={{ marginBottom: "60px" }}>
        <div>
          <p
            className="text-base-semibold dark"
            style={{ marginBottom: "10px" }}
          >
            API configuration
          </p>
          {dashboardState !== "live" && (
            <p
              className="text-md-medium text-error"
              style={{ marginBottom: "10px" }}
            >
              Configurations are for testing only. Do not use them in
              production.
            </p>
          )}
        </div>

        <SmallButton
          bg="var(--accent-1)"
          border="var(--accent-1)"
          textcolor="var(--primary)"
          onClick={() => setOpenConfirmModal(true)}
          disabled={!(completionPercentageConverted >= fiftyPercent)}
          // disabled={AppEnv === 'sandbox' ? true : false}
        >
          Regenerate API Keys
        </SmallButton>
      </div>

      <div className={Flex} style={{ gap: "56px" }}>
        <p className="dark text-md-semibold">
          {dashboardState !== "live" ? "Test secret key" : "Live secret key"}
        </p>

        <div
          className={`${FlexRowBetween} neutral border border-stroke px-5 py-[1.125rem] rounded-2xl gap-9 min-w-[28.5rem]`}
        >
          <p>
            {showApiKeys
              ? apiKey?.key
              : "***************************************"}
          </p>

          <p
            className="accent-2 text-md-semibold"
            style={{ cursor: "pointer" }}
            onClick={toggleApiKeys}
          >
            {showApiKeys ? "Hide" : "Show"}
          </p>
        </div>

        <div
          className={`${Flex} ${
            apiKey?.key === undefined ? "cursor-not-allowed" : "cursor-pointer"
          }  bg-maxim-white`}
          style={{
            gap: "6px",
            padding: "10px 16px",
            borderRadius: "8px",
          }}
          onClick={() => {
            if (apiKey?.key === undefined) {
              return;
            }
            handleCopy(apiKey?.key);
            setIsCopied(true);
          }}
        >
          <p className="accent-2 text-md-semibold">
            {isCopied ? "Copied" : "Copy"}
          </p>
          <img src={CopyIcon} alt="copy" />
        </div>
      </div>

      <ConfirmRegenerateKeys
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
      />
    </div>
  );
};

export default ApiKeysSection;
