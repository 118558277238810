import { proxy } from "valtio";
import axiosInstance from "axiosInstance";

const customerReport = proxy({
  reportRequests: [],
  reportRequestsLoading: false,
  reportRequestsCountryLimit: [],
  reportRequestsCountryLimitLoading: false,
  singleReportRequest: null,
  singleReportRequestLoading: false,
  datapointDetails: null,
  datapointDetailsLoading: false,
  editDatapoints: null,
  editDatapointsLoading: false,
  reportRequestsWithPagination: [],
  reportRequestsWithPaginationLoading: false,

  async getReportRequests() {
    this.reportRequestsLoading = true;
    return axiosInstance
      .get(`/assessments?with[]=country&with[]=consent&show_date=true`)
      .then(({ data }) => {
        this.reportRequests = data.data;
        this.reportRequestsLoading = false;
        return this.reportRequests;
      })
      .catch(({ response }) => {
        this.reportRequestsLoading = false;
        return response;
      });
  },
  async getReportRequestsWithPagination({ perPage, page }) {
    this.reportRequestsWithPaginationLoading = true;
    return axiosInstance
      .get(
        `/assessments?with[]=country&with[]=consent&show_date=true&per_page=${perPage}&page=${page}`
      )
      .then(({ data }) => {
        this.reportRequestsWithPagination = data;
        this.reportRequestsWithPaginationLoading = false;
        return this.reportRequestsWithPagination;
      })
      .catch(({ response }) => {
        this.reportRequestsWithPaginationLoading = false;
        return response;
      });
  },
  async getReportRequestsWithCountryLimit() {
    this.reportRequestsCountryLimitLoading = true;
    return axiosInstance
      .get(
        `/assessments?with[]=country&with[]=consent&per_page=5&show_date=true`
      )
      .then(({ data }) => {
        this.reportRequestsCountryLimit = data.data;
        this.reportRequestsCountryLimitLoading = false;
        return this.reportRequestsCountryLimit;
      })
      .catch(({ response }) => {
        this.reportRequestsCountryLimitLoading = false;
        return response;
      });
  },
  async getSingleReportRequest(ref) {
    this.singleReportRequestLoading = true;
    return axiosInstance
      .get(
        `/assessments/${ref}?with[]=country&with[]=report&with[]=consent&show_date=true`
      )
      .then(({ data }) => {
        this.singleReportRequest = data.data;
        this.singleReportRequestLoading = false;
        return this.singleReportRequest;
      })
      .catch(({ response }) => {
        this.singleReportRequestLoading = false;
        return response;
      });
  },
  async createReport(data) {
    return axiosInstance
      .post(`/assessments`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async createBulkReport(data) {
    return axiosInstance
      .post(`/assessments/bulk`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async getDatapointsDetails() {
    this.datapointDetailsLoading = true;
    return axiosInstance
      .get(`/company-data-points`)
      .then(({ data }) => {
        this.datapointDetails = data.data;
        this.datapointDetailsLoading = false;
        return this.datapointDetails;
      })
      .catch(({ response }) => {
        this.datapointDetailsLoading = false;
        return response;
      });
  },
  async editDatapointsDetails() {
    this.editDatapointsLoading = true;
    return axiosInstance
      .get(`/company-data-points/edit`)
      .then(({ data }) => {
        this.editDatapoints = data.data;
        this.editDatapointsLoading = false;
        return this.editDatapoints;
      })
      .catch(({ response }) => {
        this.editDatapointsLoading = false;
        return response;
      });
  },
});

export default customerReport;
