import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { useSnapshot } from "valtio";
import store from "store";
import BottomDrawer from "components/common/BottomDrawer";
import ArrowDown from "assets/images/icons/arrow-down-2.svg";
import { ReactComponent as LockIcon } from "assets/images/icons/lock.svg";
import { MediumButton } from "components/button";
import Checkbox from "components/formFields/Checkbox";
import Spinner from "components/common/Spinner";
import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import Toast from "components/common/ToastNotification";

const EditDatapointsDrawer = ({ open, onClose }) => {
  const [plans, setPlans] = useState([]);
  const customerSnap = useSnapshot(store.customerReport);
  const editDatapoints = customerSnap?.editDatapoints;
  const editDatapointsLoading = customerSnap?.editDatapointsLoading;
  const [datapointSelected, setDatapointSelected] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    store?.customerReport?.editDatapointsDetails();
  }, []);

  useEffect(() => {
    const formattedDataPoints = editDatapoints?.map((data) => {
      const allDatapointsSelected = data?.datapoints?.every(
        (datapoint) => datapoint.isSelected
      );
      return {
        name: data.name,
        canChoose: data.canChoose,
        selected: allDatapointsSelected,
        datapoints: data?.datapoints?.map((datapoint) => {
          // Use the state variable to manage isSelected
          const initialSelected = datapoint.isSelected;
          setDatapointSelected((prevState) => ({
            ...prevState,
            [datapoint.id]: initialSelected,
          }));

          return {
            ...datapoint,
            selected: initialSelected,
          };
        }),
      };
    });

    setPlans(formattedDataPoints);
  }, [editDatapoints]);

  const handleToggle = (planName) => {
    setPlans((prevPlans) => {
      return prevPlans.map((plan) => {
        if (plan?.name === planName) {
          return {
            ...plan,
            visible: !plan?.visible,
          };
        } else {
          return { ...plan, visible: false };
        }
      });
    });
  };

  const handleSelect = (planName) => {
    setPlans((prevPlans) => {
      return prevPlans?.map((plan) => {
        if (plan?.name === planName) {
          const updatedDatapointSelected = { ...datapointSelected };

          plan?.datapoints?.forEach((datapoint) => {
            updatedDatapointSelected[datapoint.id] = !plan?.selected;
          });

          setDatapointSelected(updatedDatapointSelected);

          return {
            ...plan,
            selected: !plan?.selected,
            datapoints: plan?.datapoints?.map((datapoint) => {
              return {
                ...datapoint,
                selected: !plan?.selected,
              };
            }),
          };
        }
        return plan;
      });
    });
  };

  const handleDataPointSelect = (datapointID) => {
    setDatapointSelected((prevState) => ({
      ...prevState,
      [datapointID]: !prevState[datapointID], // Toggle isSelected state
    }));

    setPlans((prevDatapoints) => {
      return prevDatapoints?.map((data) => {
        const updatedDatapoints = data?.datapoints?.map((datapoint) => {
          if (datapoint?.id === datapointID) {
            return {
              ...datapoint,
              selected: !datapointSelected[datapointID], // Use the current selected state from datapointSelected
            };
          }
          return datapoint;
        });
  
        const allDatapointsSelected = updatedDatapoints?.every(
          (datapoint) => datapoint?.selected
        );
  
        return {
          ...data,
          selected: allDatapointsSelected,
          datapoints: updatedDatapoints,
        };
      });
    });
  };
  

  const handleActivate = async () => {
    setIsLoading(true);

    const selectedDatapoints = Object.keys(datapointSelected).filter(
      (datapointID) => datapointSelected[datapointID]
    );

    const data = {
      data_point_ids: selectedDatapoints,
    };

    try {
      const response = await store.settings.postWebhookDatapoints(data);
      if (response && response?.message === "Datapoint selection saved") {
        toast.custom((t) => (
          <Toast toastType="success" text={response?.message} />
        ));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BottomDrawer onClose={onClose} open={open}>
      <div className="sub-container">
        <div className={`${FlexRowBetween} mb-[3.75rem]`}>
          <div className="">
            <p className="text-headings font-secondary text-[1.75rem] mb-2">
              Edit active data points
            </p>
            <p className="text-base text-neutral font-medium">
              Select the data points suitable for your business.
            </p>
          </div>
          <div className={`${Flex} gap-8`}>
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--text-dark)"
              onClick={onClose}
            >
              Cancel
            </MediumButton>
            {isLoading ? (
              <Spinner style={{ margin: "0 auto" }} />
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                onClick={() => handleActivate()}
              >
                Save Changes
              </MediumButton>
            )}
          </div>
        </div>

        {editDatapointsLoading ? (
          <Spinner style={{ margin: "1rem auto" }} />
        ) : (
          <div className="grid grid-cols-2 gap-x-[6rem] gap-y-12 mb-40 items-start">
            {plans?.map((plan, planIndex) => {
              return (
                <div
                  className="border border-[#DCE3E9] rounded-lg py-2.5 pl-6 pr-8"
                  key={planIndex}
                >
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => handleToggle(plan?.name)}
                  >
                    <Checkbox
                      onChange={() => handleSelect(plan?.name)}
                      checked={plan?.selected}
                      label={plan?.name}
                      labelclass="font-secondary text-dark text-base"
                      disabled={!plan?.canChoose ? "disabled" : false} // Disable checkbox if canChoose is false
                    />
                    <div className={`${Flex} gap-px`}>
                      {!plan?.canChoose && (
                        <div className={`${Flex} gap-0.5`}>
                          <p className="text-md-medium">
                            Upgrade to select data points
                          </p>
                          <LockIcon fill="var(--complimentary-1)" />
                        </div>
                      )}
                      <img
                        src={ArrowDown}
                        alt="arrow-down"
                        className={`${
                          plan?.visible ? "rotate-[180deg]" : ""
                        } p-2 transition-all duration-300`}
                      />
                    </div>
                  </div>

                  <div
                    className={`${
                      plan?.visible ? "mt-[0.875rem]" : "h-0 overflow-hidden"
                    } transition-all duration-300 space-y-4`}
                  >
                    {plan?.datapoints?.map((datapoint, index) => (
                      <div key={index} className="">
                        <Checkbox
                          onChange={() => {
                            handleDataPointSelect(datapoint?.id);
                          }}
                          checked={datapoint?.selected}
                          label={datapoint?.name}
                          labelclass="text-neutral text-sm font-medium"
                          disabled={!plan?.canChoose ? "disabled" : false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </BottomDrawer>
  );
};

export default EditDatapointsDrawer;
