import { useEffect, useState } from "react";
import DocsLayout from "components/api-docs/layout";
import GetAllAssessments from "pages/api-docs/assessment/GetAllAssessments";
import ShowAnAssessment from "pages/api-docs/assessment/ShowAnAssessment";
import VerifyUserID from "pages/api-docs/userIdVerification/VerifyUserID";
import ListAll from "pages/api-docs/userIdVerification/ListAll";
import GetVerificationDetails from "pages/api-docs/userIdVerification/GetVerificationDetails";
import BulkVerification from "pages/api-docs/userIdVerification/BulkVerification";
import InitiateCheck from "pages/api-docs/commercialReport/InitiateCheck";
import ListCommercialChecks from "pages/api-docs/commercialReport/ListCommercialChecks";
import ShowACommercialCheck from "pages/api-docs/commercialReport/ShowACommercialCheck";
import CreateAssessment from "pages/api-docs/assessment/CreateAssessment";
import SubmitConsentData from "pages/api-docs/assessment/SubmitConsentData";
import UpdateConsentData from "pages/api-docs/assessment/UpdateConsentData";
import GetIdType from "pages/api-docs/assessment/GetIdType";
import { useNavigate } from "react-router-dom";

const ApiReferences = () => {
  const navigate = useNavigate();
  const menuItems = JSON.parse(localStorage.getItem("selectedSubmenuItem"));
  const [lastVisitedSubmenuItem, setLastVisitedSubmenuItem] = useState(null);

  useEffect(() => {
    if (!menuItems && !lastVisitedSubmenuItem) {
      navigate("/docs/introduction");
    }
  }, [lastVisitedSubmenuItem, menuItems, navigate]);

  useEffect(() => {
    const lastVisitedItem = localStorage.getItem("lastVisitedSubmenuItem");
    if (lastVisitedItem) {
      setLastVisitedSubmenuItem(lastVisitedItem);
    }
  }, []);

  const renderComponent = () => {
    const isItemInSubmenu =
      menuItems?.subItems &&
      menuItems?.subItems.some((item) => item.title === lastVisitedSubmenuItem);
    if (isItemInSubmenu) {
      switch (lastVisitedSubmenuItem) {
        case "Get all assessments":
          return <GetAllAssessments />;
        case "Show an assessment":
          return <ShowAnAssessment />;
        case "Create an assessment":
          return <CreateAssessment />;
        case "Submit consent data":
          return <SubmitConsentData />;
        case "Update an assessment":
          return <UpdateConsentData />;
        case "Get ID card type":
          return <GetIdType />;
        case "Verify ID":
          return <VerifyUserID />;
        case "List all":
          return <ListAll />;
        case "Get verification details":
          return <GetVerificationDetails />;
        case "Bulk verification":
          return <BulkVerification />;
        case "Initiate":
          return <InitiateCheck />;
        case "List":
          return <ListCommercialChecks />;
        case "Details":
          return <ShowACommercialCheck />;
        default:
          return null;
      }
    } else {
      switch (menuItems?.subItems[0].title) {
        case "Get all assessments":
          return <GetAllAssessments />;
        case "Show an assessment":
          return <ShowAnAssessment />;
        case "Create an assessment":
          return <CreateAssessment />;
        case "Submit consent data":
          return <SubmitConsentData />;
        case "Update an assessment":
          return <UpdateConsentData />;
        case "Get ID card type":
          return <GetIdType />;
        case "Verify ID":
          return <VerifyUserID />;
        case "List all":
          return <ListAll />;
        case "Get verification details":
          return <GetVerificationDetails />;
        case "Bulk verification":
          return <BulkVerification />;
        case "Initiate":
          return <InitiateCheck />;
        case "List":
          return <ListCommercialChecks />;
        case "Details":
          return <ShowACommercialCheck />;
        default:
          return null;
      }
    }
  };

  return <DocsLayout>{renderComponent()}</DocsLayout>;
};

export default ApiReferences;
