import { proxy } from "valtio";
import axiosInstance from "axiosInstance";

const profileCheck = proxy({
  profileChecks: [],
  profileChecksLoading: false,
  units: null,
  unitsLoading: false,
  pricePerUnits: null,
  pricePerUnitsLoading: false,
  referenceDetails: null,
  referenceDetailsLoading: false,

  async getProfileChecks() {
    this.profileChecksLoading = true;
    return axiosInstance
      .get(`/uid-lookups`)
      .then(({ data }) => {
        this.profileChecks = data.data;
        this.profileChecksLoading = false;
        return this.profileChecks;
      })
      .catch(({ response }) => {
        this.profileChecksLoading = false;
        return response;
      });
  },
  async initiateProfileCheck(data) {
    return axiosInstance
      .post(`/uid-lookups`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async initiateBulkProfileChecks(data) {
    return axiosInstance
      .post(`/uid-lookups/bulk`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async getUnits() {
    this.unitsLoading = true;
    return axiosInstance
      .get(`/uid-lookups/subscription`)
      .then(({ data }) => {
        this.units = data.data;
        this.unitsLoading = false;
        return this.units;
      })
      .catch(({ response }) => {
        this.unitsLoading = false;
        return response;
      });
  },
  async getPricePerUnits(units) {
    this.pricePerUnitsLoading = true;
    return axiosInstance
      .get(`/uid-lookups/subscription/price-per-unit?volume=${units}`)
      .then(({ data }) => {
        this.pricePerUnits = data.data;
        this.pricePerUnitsLoading = false;
        return this.pricePerUnits;
      })
      .catch(({ response }) => {
        this.pricePerUnitsLoading = false;
        return response;
      });
  },
  async generateReference(units) {
    this.referenceDetailsLoading = true;
    return axiosInstance
      .get(`/uid-lookups/subscription/reference?volume=${units}`)
      .then(({ data }) => {
        this.referenceDetails = data.data;
        this.referenceDetailsLoading = false;
        return this.referenceDetails;
      })
      .catch(({ response }) => {
        this.referenceDetailsLoading = false;
        return response;
      });
  },
  async requestReport(data) {
    return axiosInstance
      .post(`/uid-lookups/request-report`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
});

export default profileCheck;
