import { DashboardWrapper } from 'assets/styles/dashboard-styles';
import MainLayout from 'components/mainLayout';
import WebhookService from 'components/API-Webhooks/Webhook/WebhookService';

const WebhookServicePage = () => {
  return (
    <MainLayout pageHeader='API and Webhooks'>
      <DashboardWrapper>
        <WebhookService />
      </DashboardWrapper>
    </MainLayout>
  );
};

export default WebhookServicePage;
