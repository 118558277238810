import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { MediumButton } from 'components/button';
import store from 'store';
import { useSnapshot } from 'valtio';
import Spinner from 'components/common/Spinner';
import { useState } from 'react';
import Toast from 'components/common/ToastNotification';
import { toast } from 'sonner';
import FloatingTextField from 'components/formFields/FloatingTextField';
import { useNavigate } from 'react-router-dom';

const ActivateFreePlanModal = ({ isOpen, onClose, plan }) => {
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const activateFreePlanLoading = subscriptionSnapshot?.activateFreePlanLoading;
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const activatePlanFn = async () => {
    const body = {
      code,
    };

    const response = await store?.subscription.activateFreePlan(body);

    if (response?.status >= 400) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={response?.data?.message}
        />
      ));
    } else {
      onClose();
      toast.custom((t) => (
        <Toast
          toastType='success'
          text='Free plan was successfully activated'
        />
      ));
      navigate('/plans');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className='!max-h-[20rem]'
      onClose={onClose}
    >
      <ModalBodyWrapper>
        <div className='flex flex-col justify-between h-[15rem]'>
          <p className='text-headings font-secondary text-[1.75rem] font-semibold'>
            Enter key to activate trial
          </p>

          <FloatingTextField
            label='Enter activation key'
            type='text'
            id='key'
            name='key'
            labelfor='key'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <div className='flex items-center justify-between'>
            <MediumButton
              bg='var(--white-alt)'
              border='var(--white-alt)'
              textcolor='var(--text-dark)'
              hovercolor='var(--white-alt)'
              onClick={() => onClose()}
            >
              Cancel
            </MediumButton>
            {activateFreePlanLoading ? (
              <div className='flex justify-end'>
                <Spinner className='my-[0.5rem]' />
              </div>
            ) : (
              <MediumButton
                bg='var(--accent-1)'
                border='var(--accent-1)'
                textcolor='var(--text-dark)'
                hovercolor='var(--complimentary-2)'
                onClick={() => activatePlanFn()}
              >
                Proceed to validate
              </MediumButton>
            )}
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default ActivateFreePlanModal;
