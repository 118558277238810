import { useState } from "react";
import { Link } from "react-router-dom";
import DocsSidebar from "components/api-docs/layout/Sidebar";
import { Flex, FlexRowBetween } from "assets/styles/common-styles";
import { SmallButton } from "components/button";
import {
  Divider,
  DocsContainer,
  LogoWrapper,
  PageHeader,
  Wrapper,
} from "assets/styles/docs-styles";
import { ReactComponent as Logo } from "assets/images/logo-short.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import TextField from "components/formFields/TextField";

import GetAllAssessments from "pages/api-docs/assessment/GetAllAssessments";
import ShowAnAssessment from "pages/api-docs/assessment/ShowAnAssessment";
import VerifyUserID from "pages/api-docs/userIdVerification/VerifyUserID";
import ListAll from "pages/api-docs/userIdVerification/ListAll";
import GetVerificationDetails from "pages/api-docs/userIdVerification/GetVerificationDetails";
import BulkVerification from "pages/api-docs/userIdVerification/BulkVerification";
import InitiateCheck from "pages/api-docs/commercialReport/InitiateCheck";
import ListCommercialChecks from "pages/api-docs/commercialReport/ListCommercialChecks";
import ShowACommercialCheck from "pages/api-docs/commercialReport/ShowACommercialCheck";
import CreateAssessment from "pages/api-docs/assessment/CreateAssessment";
import SubmitConsentData from "pages/api-docs/assessment/SubmitConsentData";
import UpdateConsentData from "pages/api-docs/assessment/UpdateConsentData";
import GetIdType from "pages/api-docs/assessment/GetIdType";

const DocsLayout = ({ children }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(
    localStorage.getItem("isSubmenuOpen") || false
  );
  const [selectedSubmenuItem, setSelectedSubmenuItem] = useState(
    JSON.parse(localStorage.getItem("selectedSubmenuItem")) || null
  );

  const renderSelectedPage = () => {
    switch (selectedComponent) {
      case "Get all assessments":
        return <GetAllAssessments />;
      case "Show an assessment":
        return <ShowAnAssessment />;
      case "Create an assessment":
        return <CreateAssessment />;
      case "Submit consent data":
        return <SubmitConsentData />;
      case "Update an assessment":
        return <UpdateConsentData />;
      case "Get ID card type":
        return <GetIdType />;
      case "Verify ID":
        return <VerifyUserID />;
      case "List all":
        return <ListAll />;
      case "Get verification details":
        return <GetVerificationDetails />;
      case "Bulk verification":
        return <BulkVerification />;
      case "Initiate":
        return <InitiateCheck />;
      case "List":
        return <ListCommercialChecks />;
      case "Details":
        return <ShowACommercialCheck />;
      default:
        return null;
    }
  };

  const handleSubmenuClick = (menuItem) => {
    setSelectedComponent(menuItem.title);
    localStorage.setItem("lastVisitedSubmenuItem", menuItem.title);
  };

  return (
    <Wrapper>
      <PageHeader>
        <div className={`${Flex} w-full gap-[5.125rem]`}>
          <LogoWrapper className={`${FlexRowBetween}`}>
            <Logo width="136px" />
            <Divider className="mobile-none" />
          </LogoWrapper>
          <TextField
            type="text"
            name="search"
            placeholder="Search"
            iconLeft={<SearchIcon className="icon left-icon" />}
            className="text-indent"
            width="26.25rem"
            wrapperclass="input-sm"
          />
        </div>
        <div className={`right`}>
          <Link to="/api-keys">
            <SmallButton bg="var(--accent-1)" textcolor="var(--primary)">
              Get API Keys
            </SmallButton>
          </Link>
        </div>
      </PageHeader>
      <DocsSidebar
        handleSubmenuClick={handleSubmenuClick}
        selectedComponent={selectedComponent}
        isSubmenuOpen={isSubmenuOpen}
        setIsSubmenuOpen={setIsSubmenuOpen}
        selectedSubmenuItem={selectedSubmenuItem}
        setSelectedSubmenuItem={setSelectedSubmenuItem}
      />
      <DocsContainer>
        <div className="main">
          {selectedComponent ? renderSelectedPage() : children}
        </div>
      </DocsContainer>
    </Wrapper>
  );
};

export default DocsLayout;
