import { DashboardWrapper } from 'assets/styles/dashboard-styles';
import MainLayout from 'components/mainLayout';
import IPWhiteListingSection from 'components/API-Webhooks/IPWhitelisting/IPWhitelistingSection';
import SubLayout from 'components/subLayouts';

const pages = [
  {
    to: '/api-keys',
    label: 'API keys',
  },
  {
    to: '/webhooks',
    label: 'Webhooks',
  },
  {
    to: '/ip-whitelists',
    label: 'IP whitelisting',
  },
  // {
  //   to: '/api-logs',
  //   label: 'API logs',
  // },
];

const IPWhitelistsPage = () => {
  return (
    <MainLayout pageHeader='API and Webhooks'>
      <DashboardWrapper>
        <SubLayout
          element={<IPWhiteListingSection />}
          pages={pages}
        />
      </DashboardWrapper>
    </MainLayout>
  );
};

export default IPWhitelistsPage;
