import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import store from "store";
import { OnboardingChecklistWrapper } from "assets/styles/dashboard-styles";
import Accordion from "components/common/Accordion";
import Checkbox from "components/formFields/Checkbox";
import { MediumButton } from "components/button";
import { Flex } from "assets/styles/common-styles";
import { OnboardingWrapper } from "assets/styles/onboarding-styles";
import { ReactComponent as Close } from "assets/images/icons/close.svg";
import VerifyCompanyModal from "components/modals/VerifyCompanyModal";
import VerifyCompanySuccessModal from "components/modals/VerifyCompanySuccessModal";
import OnboardingCompliance from "components/dashboard/OnboardingCompliance";
import SelectDirectorModal from "components/modals/SelectDirectorModal";
import DirectorInfoVerificationModal from "components/modals/DirectorInfoVerificationModal";
import DirectorInfoVerificationSuccessModal from "components/modals/DirectorInfoVerificationSuccessModal";
import UserVerificationSuccessModal from "components/modals/UserVerificationSuccessModal";
import Spinner from "components/common/Spinner";
import RequestVerificationModal from "components/modals/RequestVerificationModal";
import RequestVerificationSuccessModal from "components/modals/RequestVerificationSuccessModal";
import PageLoader from "components/common/PageLoader";
import DirectorNotificationModal from "components/modals/DirectorNotificationModal";

const Onboarding = () => {
  const navigate = useNavigate();
  const snap = useSnapshot(store.onboarding);
  const checklist = snap?.checklist;
  const checklistLoading = snap?.checklistLoading;
  const onboardingStage = snap?.onboardingStage;
  const currentStep = snap?.currentStep;
  // const onboardingStageLoading = snap?.onboardingStageLoading;
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSelectDirectorModalOpen, setIsSelectDirectorModalOpen] =
    useState(false);
  const [isDirectorInfoModalOpen, setIsDirectorInfoModalOpen] = useState(false);
  const [isDirectorInfoVerifiedModalOpen, setIsDirectorInfoVerifiedModalOpen] =
    useState(false);
  const [
    isUserVerificationSuccessModalOpen,
    setIsUserVerificationSuccessModalOpen,
  ] = useState(false);
  const [isRequestVerification, setIsRequestVerification] = useState(false);
  const [isRequestVerificationSuccessful, setIsRequestVerificationSuccessful] =
    useState(false);
  const [isDirectorNotificationModal, setisDirectorNotificationModal] =
    useState(false);
  const [showOnboardingCompliance, setShowOnboardingCompliance] =
    useState(false);
  const [companyInfoDetails, setCompanyInfoDetails] = useState("");
  const [directorInfoDetails, setDirectorInfoDetails] = useState("");
  const [directorKycLink, setDirectorKycLink] = useState("");
  const [requestVerificationEmail, setRequestVerificationEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentStep, setIsCurrentStep] = useState(
    localStorage.getItem("currentStep") || currentStep
  );
  // const AppEnv = process.env.REACT_APP_ENV?.toLowerCase() || "";

  useEffect(() => {
    store?.onboarding.getChecklist();
    store?.onboarding.getOnboardingStage();
  }, []);

  const completionPercentage = onboardingStage?.percentage + "%" || 0;
  const completionPercentageConverted = parseInt(completionPercentage, 10);
  const fiftyPercent = parseInt("50%", 10);
  // const hundredPercent = parseInt("100%", 10);

  useEffect(() => {
    if (isCurrentStep) {
      if (isCurrentStep === "2") {
        setIsDirectorInfoVerifiedModalOpen(true);
      } else if (onboardingStage?.percentage === 100) {
        setIsUserVerificationSuccessModalOpen(true);
      }
    }
  }, [isCurrentStep, onboardingStage?.percentage]);

  const functionMap = {
    setIsVerifyModalOpen,
    setIsSelectDirectorModalOpen,
    setIsDirectorInfoModalOpen,
  };

  useEffect(() => {
    if (showOnboardingCompliance) {
      const timer = setTimeout(() => {
        setShowOnboardingCompliance(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [showOnboardingCompliance]);

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false); // Deactivate loading state after 2 seconds
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const handleVerifyCompanySuccess = (response) => {
    setIsSuccessModalOpen(!isSuccessModalOpen);
    setCompanyInfoDetails(response);
  };

  const handleCloseVerifyCompanySuccess = () => {
    setIsSuccessModalOpen(false);
    setIsLoading(true);
    window.location.reload();
  };

  const handleDirectorInfoVerification = (data) => {
    setIsDirectorInfoModalOpen(!isDirectorInfoModalOpen);
    setDirectorInfoDetails(data);
    if (currentStep === 0) {
      store.onboarding.currentStep++;
      localStorage.setItem("currentStep", store.onboarding.currentStep);
    }
  };

  const handleSwitchDirectorInfoVerification = () => {
    setIsSelectDirectorModalOpen(true);
    setIsDirectorInfoModalOpen(false);
  };

  const handleDirectorInfoVerificationSuccessModal = (data) => {
    setIsDirectorInfoVerifiedModalOpen(!isDirectorInfoVerifiedModalOpen);
    setDirectorKycLink(data);
    if (currentStep === 1) {
      store.onboarding.currentStep++;
      localStorage.setItem("directorKycLink", JSON.stringify(data));
      localStorage.setItem("currentStep", store.onboarding.currentStep);
    }
  };

  const handleSaveAndContinue = () => {
    setIsDirectorInfoVerifiedModalOpen(false);
    setIsCurrentStep(localStorage.getItem("currentStep") || currentStep);
  };

  const handleUserVerificationSuccess = () => {
    localStorage.setItem("currentStep", "3");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleCloseUserVerificationSuccess = () => {
    setIsUserVerificationSuccessModalOpen(false);
  };

  const handleRequestVerificationSuccessfulModal = (data) => {
    setIsRequestVerificationSuccessful(!isRequestVerificationSuccessful);
    setRequestVerificationEmail(data);
  };

  const handleCompleteProcess = () => {
    navigate("/get-started");
  };

  useEffect(() => {
    if (completionPercentageConverted >= fiftyPercent) {
      navigate("/get-started");
    }
  }, [navigate, completionPercentageConverted, fiftyPercent]);

  const accordionItems =
    checklist && checklist[0]
      ? [
          {
            title: checklist[0]?.title,
            content: (
              <>
                <p
                  className="text-base"
                  dangerouslySetInnerHTML={{
                    __html: checklist[0]?.description,
                  }}
                />
                <div className="add-teammates-wrapper">
                  {checklist[0]?.completed ? (
                    <p className={`text-base-semibold view-verified ${Flex}`}>
                      {checklist[0]?.verified_cta_text}
                    </p>
                  ) : (
                    <MediumButton
                      bg="var(--accent-1)"
                      border="var(--accent-1)"
                      textcolor="var(--primary)"
                      onClick={() => {
                        isCurrentStep === "2" || isCurrentStep === "3"
                          ? setIsDirectorInfoVerifiedModalOpen(true)
                          : functionMap[checklist[0]?.function_name](true);
                      }}
                      disabled={
                        checklist[0]?.title === "Director Verification" &&
                        !checklist.find(
                          (i) =>
                            i.title === "Company Verification" && i.completed
                        )
                      }
                    >
                      {checklist[0]?.cta_text}
                    </MediumButton>
                  )}
                </div>
              </>
            ),
            checked: checklist[0]?.completed,
          },
        ]
      : [];

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <OnboardingWrapper>
          <section className="header">
            <h2 className="heading-h2">Onboarding and compliance</h2>
            <Link
              to="/get-started"
              className={`${Flex} close`}
            >
              <p className="text-base-medium">I'll do this later</p>
              <div className="min-w-[1.625rem] min-h-[1.625rem] h-[1.625rem] w-[1.625rem] cursor-pointer">
                <Close />
              </div>
            </Link>
          </section>
          <section className="sub-heading">
            <p className="text-base-medium">
              The information you provide will determine your eligibility to get
              fully activated on Maxim.
            </p>
          </section>
          <OnboardingChecklistWrapper>
            {checklistLoading ? (
              <Spinner />
            ) : (
              <Accordion
                items={accordionItems}
                leftIcon={(item) => (
                  <Checkbox
                    checked={item?.checked}
                    name={item?.title}
                    readOnly
                  />
                )}
                blockedItems={[completionPercentage === "50%" ? 0 : ""]}
              />
            )}
          </OnboardingChecklistWrapper>
          <VerifyCompanyModal
            isOpen={isVerifyModalOpen}
            onClose={() => setIsVerifyModalOpen(false)}
            handleVerifyCompanySuccess={handleVerifyCompanySuccess}
          />
          <VerifyCompanySuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            handleCloseVerifyCompanySuccess={handleCloseVerifyCompanySuccess}
            companyInfoDetails={companyInfoDetails}
          />
          <SelectDirectorModal
            isOpen={isSelectDirectorModalOpen}
            onClose={() => setIsSelectDirectorModalOpen(false)}
            handleDirectorInfoVerification={handleDirectorInfoVerification}
          />
          <DirectorInfoVerificationModal
            isOpen={isDirectorInfoModalOpen}
            onClose={() => setIsDirectorInfoModalOpen(false)}
            handleDirectorInfoVerificationSuccessModal={
              handleDirectorInfoVerificationSuccessModal
            }
            handleSwitchDirectorInfoVerification={
              handleSwitchDirectorInfoVerification
            }
            directorInfoDetails={directorInfoDetails}
          />
          <DirectorInfoVerificationSuccessModal
            isOpen={isDirectorInfoVerifiedModalOpen}
            onClose={() => setIsDirectorInfoVerifiedModalOpen(false)}
            handleUserVerificationSuccess={handleUserVerificationSuccess}
            directorKycLink={directorKycLink}
            handleSaveAndContinue={handleSaveAndContinue}
          />
          <DirectorNotificationModal
            isOpen={isDirectorNotificationModal}
            onClose={() => setisDirectorNotificationModal(false)}
          />
          <UserVerificationSuccessModal
            isOpen={isUserVerificationSuccessModalOpen}
            handleCloseUserVerificationSuccess={
              handleCloseUserVerificationSuccess
            }
          />
          <RequestVerificationModal
            isOpen={isRequestVerification}
            onClose={() => setIsRequestVerification(false)}
            handleRequestVerificationSuccessfulModal={
              handleRequestVerificationSuccessfulModal
            }
          />
          <RequestVerificationSuccessModal
            isOpen={isRequestVerificationSuccessful}
            onClose={() => setIsRequestVerificationSuccessful(false)}
            requestVerificationEmail={requestVerificationEmail}
            handleCompleteProcess={handleCompleteProcess}
          />
          {showOnboardingCompliance && (
            <OnboardingCompliance
              progressWidth={completionPercentage}
              onlyProgress
            />
          )}
        </OnboardingWrapper>
      )}
    </>
  );
};

export default Onboarding;
