import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Toaster, toast } from 'sonner';
import store from 'store';
import Toast from "components/common/ToastNotification";
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { Form244H, InputsWrapper } from 'components/formFields/formStyles';
import { ModalBodyWrapper, } from 'components/modals/modalStyles';
import FloatingSelectField from 'components/formFields/FloatingSelectField';
import { Fieldset, FlexCol, FlexColumnBetween, FlexRowBetween, Legend, SpaceBetweenW100 } from 'assets/styles/common-styles';
import { validateFormData } from 'utils/validateFormData';
import { getLocalStorageProperties, parseUserInfo } from 'utils/user';
import FloatingTextField from 'components/formFields/FloatingTextField';
import Spinner from 'components/common/Spinner';

const RequestVerificationModal = ({
    isOpen,
    onClose,
    handleRequestVerificationSuccessfulModal,
  }) => {
  const snap = useSnapshot(store.lookups);
  const companyDirectors = snap?.companyDirectors;
  const companyDirectorsLoading = snap?.companyDirectorsLoading;
  const userValues = parseUserInfo("user");
  const companyInfo = localStorage.getItem('companyInfo');
  const userEmail = localStorage.getItem('user');
  const propertiesToExtract = ['id'];
  const emailProperty = ['email'];
  const extractedProperties = getLocalStorageProperties(companyInfo, propertiesToExtract);
  const extractedEmail = getLocalStorageProperties(userEmail, emailProperty);
  const { id: companyId } = extractedProperties;
  const { email: authEmail } = extractedEmail;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    full_name: "",
    first_name: "",
    last_name: "",
    alt_email: "",
    director_id: ""
  });

  useEffect(() => {
    if (companyId || userValues?.companyId) {
      store.lookups.getCompanyDirectors(companyId || userValues?.companyId);
    }
  }, [companyId, userValues?.companyId]);

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });

    const director = companyDirectors?.directors?.find(director => director.first_name + " " + director.last_name === value);
    if (director) {
      setSelectedValue(prevValue => ({
        ...prevValue,
        director_id: director.id
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });    

    const data =  {
      first_name: selectedValue.first_name,
      last_name: selectedValue.last_name,
      alt_email: selectedValue.alt_email,
      auth_email: authEmail,
      director_id: selectedValue.director_id,
    };
    const validationRules = {
      first_name: [{ required: true }],
      last_name: [{ required: true }],
      alt_email: [{ required: true }],
      director_id: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }

    try {
      const response = await store.onboarding.postMemberKycToDirector(data);
      if (response && response?.data?.id) {
        const email = data?.alt_email;
        handleRequestVerificationSuccessfulModal(email);
        onClose();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className="longer">
      <ModalBodyWrapper className="request-verification">
        <div className="modal-header">
          <p className="text-base">For non-directors and company representatives</p>
          <h2 className="modal-title">Request director verification</h2>
        </div>
        <form className={FlexColumnBetween} id="request_verification" onSubmit={handleSubmit}>
          <Form244H className="h-274">
            <p className="text-base">Send a request via email to an official company director to complete the director verification process.</p>
            <Fieldset className={FlexCol}>
              <Legend className="text-base-semibold">Director info</Legend>
              <FloatingSelectField
                id="full_name"
                name="full_name"
                labelfor="full_name"
                options={companyDirectors?.directors?.map((director, index) => ({
                      value: `${director?.first_name} ${director?.last_name}`,
                      key: `${director?.first_name}${director?.last_name}${index}`,
                    }))
                  }
                label="Select company director"
                value={selectedValue.full_name}
                onChange={(e) => {
                  handleChange("full_name", e);
                }}
                isLoading={companyDirectorsLoading}
              />
              <FloatingTextField
                type="email"
                label="Official email of selected director"
                id="alt_email"
                name="alt_email"
                labelfor="alt_email"
                value={selectedValue.alt_email}
                onChange={(e) => handleChange("alt_email", e)}
                fullwidth="true"
              />
            </Fieldset>
            <Fieldset className={FlexCol}>
              <Legend className="text-base-semibold">Personal info</Legend>
              <InputsWrapper className={SpaceBetweenW100}>
                <FloatingTextField
                  label="First name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  labelfor="first_name"
                  value={selectedValue.first_name}
                  onChange={(e) => handleChange("first_name", e)}
                />
                <FloatingTextField
                  label="Last name"
                  type="text"
                  name="last_name"
                  id="last_name"
                  labelfor="last_name"
                  value={selectedValue.last_name}
                  onChange={(e) => handleChange("last_name", e)}
                />
              </InputsWrapper>
            </Fieldset>
          </Form244H>
          <div className={`${FlexRowBetween} button-group`}>
            <MediumButton bg="var(--white-alt)" textcolor="var(--text-dark)" type="button" onClick={onClose}>
              Cancel
            </MediumButton>
            {isLoading ? (
              <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                type={selectedValue?.full_name === "" ? "button" : "submit"}
                disabled={selectedValue?.full_name === ""}
              >
                Send request
              </MediumButton>
            )}
          </div>
        </form>
      </ModalBodyWrapper>
      <Toaster position="top-center" visibleToasts={3} />
    </Modal>
  );
};

export default RequestVerificationModal;
