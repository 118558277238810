import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const CreateAssessment = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          201 Created
        </div>
      ),
      key: "201",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          201 OK Ext. Consent - Credit
        </div>
      ),
      key: "201-credit",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          201 OK Ext. Consent - Comprehensive
        </div>
      ),
      key: "201-comprehensive",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          422 Error
        </div>
      ),
      key: "422",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    "201": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "first_name": "John",
        "last_name": "Abdul",
        "middle_name": "Adewale",
        "user_country_code": "ng",
        "id_type": "international_passport",
        "id_number": "APP23242323",
        "email": "samuel+migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "reference": "ZDdV9yYlLHb_P97",
        "external_consent": true,
        "bvn": "22169027416"
      },
      response: {
        "message": "A  report on <strong>John Abdul</strong> has been requested. You will be notified once it is ready.",
        "data": {
          "id": 11,
          "user_country_code": "ng",
          "request_source": "api",
          "reference": "bDiTrWE64awpOyI",
          "first_name": "John",
          "last_name": "Abdul",
          "middle_name": "Adewale",
          "decision_criteria_id": null,
          "email": "samuel+migrant@trymaxim.com",
          "phone_number": "2347065514172",
          "status": "pending",
          "id_type": "international_passport",
          "id_number": "APP23242323",
          "idcard": {
            "id": 2,
            "country_code": "ng",
            "name": "International Passport",
            "key": "international_passport",
            "status": true,
            "created_at": "2024-01-09T09:57:07.000000Z",
            "updated_at": "2024-01-09T09:57:07.000000Z"
          },
          "consent": "http://staging.app.trymaxim.com/consent?hash=cmVmZXJlbmNlPWJEaVRyV0U2NGF3cE95SSZjb21wYW55X2lkPU5HLUMwMWhrZndlbnIxbXFicDJ5NTM1OW5jMmpkciZlbnY9c2FuZGJveA%3D%3D"
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">201 Created:</span> This indicates that the assessment has been successfully created, and the process requires external consent.
        </>
      ),
    },
    "201-credit": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "first_name": "John",
        "last_name": "Abdul",
        "middle_name": "Adewale",
        "user_country_code": "ng",
        "id_type": "international_passport",
        "id_number": "APP23242323",
        "email": "samuel+migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "reference": "ZDdV9yYlLHb_P97",
        "external_consent": true,
        "bvn": "22169027416"
      },
      response: {
        "message": "A  report on <strong>John Abdul</strong> has been requested. You will be notified once it is ready.",
        "data": {
          "id": 12,
          "user_country_code": "ng",
          "request_source": "api",
          "reference": "nehsw8npiXQM1to",
          "first_name": "John",
          "last_name": "Abdul",
          "middle_name": "Adewale",
          "decision_criteria_id": null,
          "email": "samuel+migrant@trymaxim.com",
          "phone_number": "2347065514172",
          "status": "consented",
          "id_type": "international_passport",
          "id_number": "APP23242323",
          "idcard": {
            "id": 2,
            "country_code": "ng",
            "name": "International Passport",
            "key": "international_passport",
            "status": true,
            "created_at": "2024-01-09T09:57:07.000000Z",
            "updated_at": "2024-01-09T09:57:07.000000Z"
          },
          "result": null
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">201 External Consent - Credit:</span> This indicates that the assessment has been successfully created, and the process requires external consent specifically for credit-related information.
        </>
      ),
    },
    "201-comprehensive": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "first_name": "John",
        "last_name": "Abdul",
        "middle_name": "Adewale",
        "user_country_code": "ng",
        "id_type": "international_passport",
        "id_number": "APP23242323",
        "email": "samuel+migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "reference": "ZDdV9yYlLHb_P97",
        "external_consent": true,
        "bvn": "22169027416"
      },
      response: {
        "message": "A  report on <strong>John Abdul</strong> has been requested. You will be notified once it is ready.",
        "data": {
          "id": 13,
          "user_country_code": "ng",
          "request_source": "api",
          "reference": "rhN5aYoIVEKG_pZ",
          "first_name": "John",
          "last_name": "Abdul",
          "middle_name": "Adewale",
          "decision_criteria_id": null,
          "email": "samuel+migrant@trymaxim.com",
          "phone_number": "2347065514172",
          "status": "pending",
          "id_type": "international_passport",
          "id_number": "APP23242323",
          "idcard": {
            "id": 2,
            "country_code": "ng",
            "name": "International Passport",
            "key": "international_passport",
            "status": true,
            "created_at": "2024-01-09T09:57:07.000000Z",
            "updated_at": "2024-01-09T09:57:07.000000Z"
          },
          "bank_auth": "?hash=cmVmZXJlbmNlPXJoTjVhWW9JVkVLR19wWiZjb21wYW55X2lkPU5HLUMwMWhrZndlbnIxbXFicDJ5NTM1OW5jMmpkciZlbnY9c2FuZGJveA%3D%3D"
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">201 External Consent - Comprehensive:</span> This indicates that the assessment has been successfully created for comprehensive external consent. It involves a thorough examination of various financial records and information beyond standard credit data.
        </>
      ),
    },
    422: {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "first_name": "John",
        "last_name": "Abdul",
        "middle_name": "Adewale",
        "user_country_code": "ng",
        "id_type": "international_passport",
        "id_number": "APP23242323",
        "email": "samuel+migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "reference": "ZDdV9yYlLHb_P97",
        "external_consent": true,
        "bvn": "22169027416"
      },
      response: {
        "message": "The consent field is required when external consent is true. (and 1 more error)",
        "errors": {
          "consent": [
            "The consent field is required when external consent is true."
          ],
          "bvn": [
            "The bvn field is required."
          ]
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">422 Error:</span> This indicates that the server has encountered validation errors while processing the request payload for creating an assessment.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Create an assessment"
      urlType="post"
      urlBg="bg-[#F4F1ED]"
      urlColor="warning"
      endpoint="/v1/assessments"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default CreateAssessment;
