import GetStartedSection from "components/GetStarted/GetStartedSection";
import MainLayout from "components/mainLayout";
import React from "react";

const GetStarted = () => {
  return (
    <MainLayout pageHeader="Get Started">
      <GetStartedSection />
    </MainLayout>
  );
};

export default GetStarted;
