import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const GetIdType = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    "200": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
        {
          key: "country-code",
          value: "ng",
          description: "",
        },
      ],
      response: {
        "message": "Identification fetched",
        "data": [
          {
            "name": "Driver's License",
            "key": "drivers_license",
            "country_code": "ng"
          },
          {
            "name": "International Passport",
            "key": "international_passport",
            "country_code": "ng"
          },
          {
            "name": "National Identity Card",
            "key": "national_identity",
            "country_code": "ng"
          },
          {
            "name": "Voters Card",
            "key": "voters_card",
            "country_code": "ng"
          }
        ]
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to retrieve information about supported ID card types was successful.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Get ID card type"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/assessments/20sUOfTINuNScVV"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default GetIdType;
