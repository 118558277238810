import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { Toaster, toast } from 'sonner';
import store from 'store';
import { MediumButton } from 'components/button';
import { Flex, FlexCol, SpaceBetweenW100 } from 'assets/styles/common-styles';
import { InputsWrapper } from 'components/formFields/formStyles';
import FloatingTextField from 'components/formFields/FloatingTextField';
import FloatingSelectField from 'components/formFields/FloatingSelectField';
import CustomModal from 'components/modals/CustomModal';
import CustomerRequestSuccessModal from 'components/modals/CustomerRequestSuccessModal';
import { DrawerStyle } from 'components/modals/modalStyles';
import Spinner from 'components/common/Spinner';
import { validateFormData } from 'utils/validateFormData';
import Toast from 'components/common/ToastNotification';
import { addCountryCode, capitalizeFirstLetter } from 'utils/common';
import Tab from 'components/common/Tab';
import { ReactComponent as DualTone } from 'assets/images/icons/dual-tone.svg';
import UploadCSV from 'components/customerReports/UploadCSV';

const CustomerReportRequestForm = ({
  isOpen,
  onClose,
  setIsCustomerReportRequestFormOpen,
}) => {
  const snap = useSnapshot(store.lookups);
  const countries = snap?.countries;
  const identities = snap?.identities;
  const identitiesLoading = snap?.identitiesLoading;
  const countriesLoading = snap?.countriesLoading;
  const [isRequestSuccessOpen, setIsRequestSuccessOpen] = useState(false);
  const [isRequestBulkSuccessOpen, setIsRequestBulkSuccessOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestResponse, setRequestResponse] = useState('');
  const [selectedValue, setSelectedValue] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    user_country_code: 'Select a country',
    id_type: '',
    id_number: '',
    email: '',
    phone_number: '',
    calling_code_phone_number: '',
    type: '',
  });
  const [bulkFile, setBulkFile] = useState(null);

  const tabs = [
    { label: 'single', urlParam: 'single' },
    { label: 'multiple', urlParam: 'multiple' },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  useEffect(() => {
    store.lookups.getCountries();
    store?.lookups.getIdentities();
  }, []);

  const handleChange = (name, e) => {
    setSelectedValue({ ...selectedValue, [name]: e.target.value });
  };

  const handleSelectChange = (name, e) => {
    setSelectedValue({ ...selectedValue, [name]: e.target.key });
  };

  useEffect(() => {
    const selectedCountry = countries.find(
      (country) => country.alpha_2_code === selectedValue?.user_country_code
    );
    if (selectedCountry) {
      setSelectedValue((prevState) => ({
        ...prevState,
        calling_code_phone_number: addCountryCode(
          selectedValue?.phone_number,
          selectedCountry?.calling_code
        ),
      }));
    }
  }, [
    countries,
    selectedValue?.phone_number,
    selectedValue?.user_country_code,
  ]);

  const handleRequestResponse = (data) => {
    setRequestResponse(data);
    setIsRequestSuccessOpen(true);
  };

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data = {
      first_name: selectedValue?.first_name,
      middle_name: selectedValue?.middle_name,
      last_name: selectedValue?.last_name,
      user_country_code: selectedValue?.user_country_code,
      id_type: selectedValue?.id_type,
      id_number: selectedValue?.id_number,
      email: selectedValue?.email,
      phone_number: selectedValue?.calling_code_phone_number,
      type: selectedValue?.type,
    };

    const validationRules = {
      first_name: [{ required: true }],
      last_name: [{ required: true }],
      user_country_code: [{ required: true }],
      id_type: [{ required: true }],
      id_number: [{ required: true }],
      email: [{ required: true }],
      phone_number: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={firstError.replace('_', ' ')}
        />
      ));
      setIsLoading(false);
      return;
    }

    try {
      const response = await store.customerReport.createReport(data);
      if (response && response?.data?.reference) {
        handleRequestResponse(response?.message);
        setIsRequestSuccessOpen(true);
        await store.customerReport.getReportRequestsWithCountryLimit();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType='error'
                text={errorMessage}
              />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={response?.data?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={'Something went wrong'}
        />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', bulkFile);

    try {
      const response = await store.customerReport.createBulkReport(formData);
      if (
        response &&
        response?.message === 'Assessments created successfully!'
      ) {
        setIsRequestBulkSuccessOpen(true);
        await store.customerReport.getReportRequestsWithCountryLimit();
      } else if (response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType='error'
                text={errorMessage}
              />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType='error'
            text={response?.data?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast
          toastType='error'
          text={'Something went wrong'}
        />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className={DrawerStyle}
    >
      <form
        className={`${FlexCol}`}
        onSubmit={handleSubmit}
      >
        <h3 className='heading-h2-type-2 card-header'>
          Consumer report request
        </h3>
        <Tab
          tabs={tabs.map((tab) => ({
            label: capitalizeFirstLetter(tab.label) + ' report',
            urlParam: tab.urlParam,
          }))}
          activeTabLabel={activeTab}
          handleTabClick={handleTabClick}
          className='webhook-tab'
          tabclass='w-1/2 text-center text-md-medium py-2.5 justify-center'
          isactiveclass='bg-white text-md-semibold rounded-xl text-dark'
        />
        {activeTab === 'single' && (
          <>
            <InputsWrapper className={SpaceBetweenW100}>
              <FloatingTextField
                label='First name'
                type='text'
                name='first_name'
                id='first_name'
                labelfor='first_name'
                value={selectedValue.first_name}
                onChange={(e) => handleChange('first_name', e)}
              />
              <FloatingTextField
                label='Middle name'
                type='text'
                name='middle_name'
                id='middle_name'
                labelfor='middle_name'
                value={selectedValue.middle_name}
                onChange={(e) => handleChange('middle_name', e)}
              />
            </InputsWrapper>
            <FloatingTextField
              label='Last name'
              type='text'
              name='last_name'
              id='last_name'
              labelfor='last_name'
              value={selectedValue.last_name}
              onChange={(e) => handleChange('last_name', e)}
            />
            <FloatingSelectField
              id='user_country_code'
              name='user_country_code'
              options={countries
                .filter((country) => country.enabled === true)
                .map((country) => ({
                  value: country.name,
                  key: country.alpha_2_code,
                }))}
              label='Nationality'
              value={selectedValue.user_country_code}
              onChange={(e) => handleSelectChange('user_country_code', e)}
              isLoading={countriesLoading}
              fullwidth='true'
              search
            />
            <FloatingSelectField
              id='id_type'
              name='id_type'
              options={identities.map((identity) => ({
                value: identity.name,
                key: identity.key,
              }))}
              label='ID type'
              value={selectedValue.id_type}
              onChange={(e) => handleSelectChange('id_type', e)}
              isLoading={identitiesLoading}
              fullwidth='true'
            />
            <FloatingTextField
              label='ID number'
              type='text'
              name='id_number'
              id='id_number'
              labelfor='id_number'
              value={selectedValue.id_number}
              onChange={(e) => handleChange('id_number', e)}
            />
            <FloatingTextField
              label="Consumer's email"
              type='email'
              name='email'
              id='email'
              labelfor='email'
              value={selectedValue.email}
              onChange={(e) => handleChange('email', e)}
            />
            <FloatingTextField
              label="Consumer's contact number"
              type='text'
              name='phone_number'
              id='phone_number'
              labelfor='phone_number'
              value={selectedValue.phone_number}
              onChange={(e) => handleChange('phone_number', e)}
            />
            {isLoading ? (
              <Spinner style={{ margin: '0.5rem auto' }} />
            ) : (
              <MediumButton
                bg='var(--accent-1)'
                border='var(--accent-1)'
                textcolor='var(--primary)'
                style={{ alignSelf: 'flex-end' }}
                type='submit'
              >
                Submit Request
              </MediumButton>
            )}
          </>
        )}
        {activeTab === 'multiple' && (
          <>
            <section className='upload-header'>
              <div className={`${Flex} country`}>
                <DualTone />
                <p className='text-base-semibold'>CSV Template</p>
              </div>
              <Link
                to='https://maxim-csv.s3.amazonaws.com/Bulk+Report+CSV+Example+-+Sheet1.csv'
                target='_blank'
              >
                <p className='text-md-semibold select'>Download</p>
              </Link>
            </section>
            <p className='text-base-semibold headings'>For multiple requests</p>
            <UploadCSV onFileUpload={(file) => setBulkFile(file)} />
            <div className='min-h-[28vh] flex flex-col justify-end'>
              {isLoading ? (
                <Spinner style={{ margin: '0.5rem 0 0 auto' }} />
              ) : (
                <MediumButton
                  bg='var(--accent-1)'
                  border='var(--accent-1)'
                  textcolor='var(--primary)'
                  style={{ alignSelf: 'flex-end' }}
                  // type="submit"
                  onClick={handleBulkSubmit}
                >
                  Submit Request
                </MediumButton>
              )}
            </div>
          </>
        )}
      </form>
      {isRequestSuccessOpen && (
        <CustomerRequestSuccessModal
          isOpen={isRequestSuccessOpen}
          onClose={() => setIsRequestSuccessOpen(false)}
          requestResponse={requestResponse}
          setIsCustomerReportRequestFormOpen={
            setIsCustomerReportRequestFormOpen
          }
        />
      )}
      {isRequestBulkSuccessOpen && (
        <CustomerRequestSuccessModal
          isOpen={isRequestBulkSuccessOpen}
          onClose={() => setIsRequestBulkSuccessOpen(false)}
          requestResponse={
            'A bulk request have been made for multiple customers. Result typically takes 1 - 24hrs.'
          }
          setIsCustomerReportRequestFormOpen={
            setIsCustomerReportRequestFormOpen
          }
        />
      )}
      <Toaster
        position='top-center'
        visibleToasts={2}
      />
    </CustomModal>
  );
};

export default CustomerReportRequestForm;
