import axiosInstance from "axiosInstance";
import { proxy } from "valtio";

const onboarding = proxy({
  currentStep: 0,
  checklist: [],
  checklistLoading: null,
  onboardingStage: [],
  onboardingStageLoading: false,

  async getChecklist() {
    this.checklistLoading = true;
    return axiosInstance
      .get("/onboarding/checklist")
      .then(({ data }) => {
        this.checklist = data.data;
        this.checklistLoading = false;
        return this.checklist;
      })
      .catch(({ response }) => {
        this.checklistLoading = false;
        return response;
      });
  },
  async getOnboardingStage() {
    this.onboardingStageLoading = true;
    return axiosInstance
      .get("/onboarding/stage")
      .then(({ data }) => {
        this.onboardingStage = data.data;
        this.onboardingStageLoading = false;
        return this.onboardingStage;
      })
      .catch(({ response }) => {
        this.onboardingStageLoading = false;
        return response;
      });
  },
  async postCompanyInfo(data) {
    return axiosInstance
      .post("/onboarding/company", data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postCompanyDirectorInfo(data) {
    return axiosInstance
      .post("/onboarding/company/director/profile", data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async postMemberKycToDirector(data) {
    return axiosInstance
      .post("/onboarding/company/director/send-onboard-link", data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
  async getKycUrl(data) {
    return axiosInstance
      .post("/onboarding/get-kyc-url", data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  },
});

export default onboarding;
