import React from "react";
import { Link } from "react-router-dom";
import Modal from "components/modals";
import { MediumButton } from "components/button";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { FlexCol, FlexColumnCenter } from "assets/styles/common-styles";
import { ReactComponent as Warning } from "assets/images/warning.svg";

const InactiveSubscriptionModal = ({ isOpen, onClose, text = "Consumer" }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper
        className={`request-verification-success ${FlexColumnCenter}`}
      >
        <div className={`${FlexCol} header`}>
          <Warning />
          <h2 className="modal-title mb-[-1rem]">No active subscription</h2>
        </div>
        <section className={`${FlexCol} info`}>
          <p className="text-base">
            You need an active subscription to run{" "}
            <span className="text-base-medium dark">{text} Report Checks.</span>{" "}
            Explore Maxim plans tailored for your optimal experience.
          </p>
        </section>
        <Link
          to="/plans/subscriptions"
          onClick={() => onClose()}
        >
          <MediumButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
          >
            See subscription plans
          </MediumButton>
        </Link>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default InactiveSubscriptionModal;
