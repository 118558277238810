import { useCallback, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import store from "store";
import {
  Card,
  MostRecent,
  MostRecentRequestHeader,
} from "assets/styles/customer-report-styles";
import { Flex, FlexColumnCenter } from "assets/styles/common-styles";
import Table from "components/table";
import Spinner from "components/common/Spinner";
import TextField from "components/formFields/TextField";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { getLocalStorageProperties } from "utils/user";

const Results = ({ onRowClick, setOpenLookupDrawer }) => {
  const loadRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [loadedData, setLoadedData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 1,
  });
  const perPage = 10;
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchCleared, setIsSearchCleared] = useState(false);
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  const handleRowClick = useCallback(
    (ref) => {
      onRowClick(ref);
    },
    [onRowClick]
  );

  const columns = [
    {
      Header: "COMPANY NAME",
      accessor: "company_name",
    },
    {
      Header: "RC NUMBER",
      accessor: "rc_number",
    },
    {
      Header: "REPORT",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "created_at",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const fetchMoreData = useCallback(async () => {
    setIsLoadingMore(true);
    try {
      const response = await store.commercialCheck.getCommercialChecks({
        perPage,
        page: pagination.currentPage + 1,
      });

      if (response?.data && response?.data?.length > 0) {
        const newData = response?.data?.map((dataCheck) => {
          return {
            company_name: dataCheck.company_name,
            rc_number: dataCheck.company_number,
            status: (
              <span className="status capitalize">
                <span
                  className={`status-ball ${
                    dataCheck?.status === "active" ||
                    dataCheck?.status === "completed"
                      ? "active"
                      : dataCheck?.status === "processing"
                      ? "pending"
                      : dataCheck?.status === "inactive" ||
                        dataCheck?.status === "pending"
                      ? "error"
                      : "error"
                  }`}
                ></span>
                {dataCheck?.status}
              </span>
            ),
            created_at: format(
              new Date(dataCheck?.created_at),
              "MMM dd yyyy, hh:mm aa"
            ),
            reference: dataCheck?.reference,
            action: (
              <span className="view-btn" onClick={() => handleRowClick()}>
                View
              </span>
            ),
          };
        });
        setLoadedData((prevData) => [...prevData, ...newData]);
        setPagination({
          perPage: response?.pagination?.per_page,
          currentPage: response?.pagination?.current_page,
          totalPages: response?.pagination?.total_pages,
        });
      } else {
        setIsLoadingMore(false);
      }
    } catch (error) {
      console.error("Error fetching more data:", error);
      setIsLoadingMore(false);
    } finally {
      setIsLoadingMore(false);
    }
  }, [handleRowClick, pagination.currentPage]);

  useEffect(() => {
    if (companyId) {
      const shouldFetchData = !loadRef.current || isSearchCleared;

      if (shouldFetchData) {
        fetchMoreData();
        loadRef.current = true;
        setIsSearchCleared(false);
      }
    }
  }, [companyId, fetchMoreData, isSearchCleared]);

  const handleLoadMore = () => {
    if (pagination.currentPage < pagination.totalPages) {
      fetchMoreData();
    }
  };

  const searchData = useCallback(
    async (searchText) => {
      setIsSearchLoading(true);
      try {
        const response = await store.commercialCheck.searchCommercialChecksFull(
          searchText
        );
        if (response?.data && response?.data?.length > 0) {
          const newData = response?.data?.map((dataCheck) => {
            return {
              company_name: dataCheck.company_name,
              rc_number: dataCheck.company_number,
              status: (
                <span className="status capitalize">
                  <span
                    className={`status-ball ${
                      dataCheck?.status === "active" ||
                      dataCheck?.status === "completed"
                        ? "active"
                        : dataCheck?.status === "processing"
                        ? "pending"
                        : dataCheck?.status === "inactive" ||
                          dataCheck?.status === "pending"
                        ? "error"
                        : "error"
                    }`}
                  ></span>
                  {dataCheck?.status}
                </span>
              ),
              created_at: format(
                new Date(dataCheck?.created_at),
                "MMM dd yyyy, hh:mm aa"
              ),
              reference: dataCheck?.reference,
              action: (
                <span className="view-btn" onClick={() => handleRowClick()}>
                  View
                </span>
              ),
            };
          });
          setLoadedData(newData);
          setPagination({
            perPage: response?.pagination?.per_page,
            currentPage: response?.pagination?.current_page,
            totalPages: response?.pagination?.total_pages,
          });
        } else {
          setLoadedData([]);
          setPagination({
            currentPage: 0,
            totalPages: 1,
          });
          setIsSearchLoading(false);
        }
      } catch (error) {
        console.error("Error fetching more data:", error);
        setIsSearchLoading(false);
      } finally {
        setIsSearchLoading(false);
      }
    },
    [handleRowClick]
  );

  const handleSearchInputChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    if (searchText.trim() !== "") {
      searchData(searchText);
    } else {
      setTimeout(() => {
        setLoadedData([]);
        setPagination({ currentPage: 0, totalPages: 1 });
        setIsSearchLoading(false);
        setIsSearchCleared(true);
      }, 500);
    }
  };

  return (
    <>
      <section className={MostRecent}>
        <div className={MostRecentRequestHeader}>
          <div className={`${Flex} gap-6 w-[60%]`}>
            <p className="text-base-semibold card-header headings whitespace-nowrap">
              Company results
            </p>

            {/* <SelectField
            name="filter"
            value="Sort by date"
            // onChange={selectedColumn => handleSort(selectedColumn)}
            onChange={(selectedColumn) => {
              const { key } = selectedColumn.target;
              handleSort(key);
            }}
            options={columns
              .filter((option) => option.accessor === "created_at")
              .map((option) => ({
                value: option.Header,
                key: option.accessor,
              }))}
            width="8rem"
            className="filter-sm"
            wrapperclass="dropdown-md"
          /> */}
          </div>
          <TextField
            type="text"
            name="search_names"
            placeholder="Search company names"
            value={searchText}
            onChange={handleSearchInputChange}
            iconLeft={<SearchIcon className="icon left-icon" />}
            className="text-indent"
            width="15.875rem"
            wrapperclass="input-sm"
          />
        </div>
        {isLoadingMore ? (
          <Spinner style={{ margin: "0.5rem auto" }} />
        ) : loadedData && loadedData?.length > 0 ? (
          <Table
            columns={columns}
            data={loadedData}
            showSearch={false}
            className="customer-request-table"
            onRowClick={handleRowClick}
            isLoading={isLoadingMore || isSearchLoading}
            onLoadMore={() => handleLoadMore()}
            showLoadMoreButton={pagination.currentPage < pagination.totalPages}
          />
        ) : (
          <Card>
            <div className={`${FlexColumnCenter} empty-state`}>
              <p className="text-base">
                Looks like a blank canvas! Get ready to fill this space with
                meaningful insights
              </p>
              <span
                className={`text-md-spaced ${
                  !!companyId ? "cursor-pointer" : "disabled"
                }`}
                onClick={
                  !!companyId ? () => setOpenLookupDrawer(true) : undefined
                }
              >
                START LOOKUP
              </span>
            </div>
          </Card>
        )}
      </section>
    </>
  );
};

export default Results;
