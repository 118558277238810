import React from 'react';
import { Drawer } from 'antd';

const BottomDrawer = ({ open, onClose, children }) => {
  return (
    <Drawer
      placement='bottom'
      onClose={onClose}
      open={open}
      height={'100%'}
      className='customise-plan-drawer'
      closeIcon={null}
    >
      {children}
    </Drawer>
  );
};

export default BottomDrawer;
