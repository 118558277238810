import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import store from "store";
import {
  LogoWrapper,
  NavItemsWrapper,
  SidebarWrapper,
} from "components/mainLayout/layoutStyles";
import { ReactComponent as Logo } from "assets/images/logo-white.svg";
// import { ReactComponent as DashboardIcon } from 'assets/images/icons/dashboard.svg';
// import { ReactComponent as WalletIcon } from 'assets/images/icons/wallet.svg';
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import { ReactComponent as GetStarted } from "assets/images/icons/get-started.svg";
// import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings.svg';
import { ReactComponent as ProfileCheckIcon } from "assets/images/icons/profile-check.svg";
import { ReactComponent as CheckBgIcon } from "assets/images/icons/checkBg.svg";
import { ReactComponent as PlansIcon } from "assets/images/icons/plans-icon.svg";
import { ReactComponent as APIIcon } from "assets/images/icons/api-icon.svg";
import { ReactComponent as BankIcon } from "assets/images/icons/bank.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down-alt.svg";
import { ReactComponent as Note } from "assets/images/icons/note.svg";
import {
  FlexColumnBetween,
  FlexCenter,
  Flex,
} from "assets/styles/common-styles";
import { getLocalStorageProperties } from "utils/user";
import { getFirstLetter, truncateString } from "utils/common";

const getStartedIcon = <GetStarted />;
const userIcon = <UserIcon />;
const bankIcon = <BankIcon />;
const profileCheckIcon = <ProfileCheckIcon />;
const checkBgIcon = <CheckBgIcon />;
const plansIcon = <PlansIcon />;
const apiIcon = <APIIcon />;
const noteIcon = <Note />;

const Sidebar = ({ isOnboarding }) => {
  const location = useLocation();
  const snap = useSnapshot(store.misc);
  const environment = snap?.environment;
  // const environmentLoading = snap?.environmentLoading;
  const userDetails = localStorage.getItem("user");
  const companyInfo = localStorage.getItem("companyInfo");
  const idToExtract = ["company_id", "maxim_id", "company"];
  const nameToExtract = ["id", "name"];
  const extractedUserDetails = getLocalStorageProperties(
    userDetails,
    idToExtract
  );
  const extractedCompanyName = getLocalStorageProperties(
    companyInfo,
    nameToExtract
  );
  const {
    maxim_id: userMaximId,
    company,
    company_id: companyId,
  } = extractedUserDetails;
  const { name: companyName } = extractedCompanyName;
  const [isDecisionEngineHovered, setIsDecisionEngineHovered] = useState(false);

  useEffect(() => {
    if (companyId) {
      store.misc.getEnvironment();
    }
  }, [companyId]);

  const [navItems, setNavItems] = useState([
    {
      label: "Overview",
      items: [
        {
          to: ["/get-started", "/get-started/"],
          icon: getStartedIcon,
          title: "Get Started",
        },
      ],
      open: true,
    },
    {
      label: "Credit Report",
      items: [
        {
          to: ["/consumer-reports", "/consumer-reports/"],
          icon: userIcon,
          title: "Consumer Check",
        },
        {
          to: ["/commercial-check", "/commercial-check/"],
          icon: bankIcon,
          title: "Commercial Check",
        },
      ],
      open: false,
    },
    {
      label: "KYC VERIFICATION",
      items: [
        {
          to: ["/user-id-verification"],
          icon: profileCheckIcon,
          title: "User ID Verification",
        },
      ],
      open: false,
    },
    {
      label: "Decision Engine",
      items: [
        {
          icon: checkBgIcon,
          title: "Decision Engine",
        },
      ],
      open: false,
    },
    {
      label: "Payment",
      items: [
        {
          to: ["/plans"],
          icon: plansIcon,
          title: "Plans and Billing",
        },
      ],
      open: false,
    },
    {
      label: "Developers",
      items: [
        {
          to: ["/api-keys", "/ip-whitelists", "/api-logs", "/webhooks"],
          icon: apiIcon,
          title: "APIs and Webhooks",
        },
        {
          to: ["/docs", "/docs/introduction"],
          icon: noteIcon,
          title: "Documentation",
        },
      ],
      open: false,
    },
  ]);

  useEffect(() => {
    const storedNavItems = JSON.parse(localStorage.getItem("navItems"));
    if (storedNavItems) {
      const addIcon = storedNavItems.map((navItem) => {
        return {
          ...navItem,
          items: navItem.items.map((item) => {
            return {
              ...item,
              icon:
                item.title === "Get Started"
                  ? getStartedIcon
                  : item.title === "Consumer Check"
                  ? userIcon
                  : item.title === "Commercial Check"
                  ? bankIcon
                  : item.title === "User ID Verification"
                  ? profileCheckIcon
                  : item.title === "Decision Engine"
                  ? checkBgIcon
                  : item.title === "Plans and Billing"
                  ? plansIcon
                  : item.title === "APIs and Webhooks"
                  ? apiIcon
                  : item.title === "Documentation"
                  ? noteIcon
                  : "",
            };
          }),
          open: navItem.items.some((subItem) =>
            Array.isArray(subItem.to)
              ? subItem.to.includes(location.pathname)
              : subItem.to === location.pathname
          ),
        };
      });
      setNavItems(addIcon);
    }
  }, [location]);

  const handleOpenNav = (menu) => {
    setNavItems((prevNavItems) => {
      const updatedNavItems = prevNavItems.map((item) => ({
        ...item,
        open: item.label === menu.label ? !item.open : false,
      }));
      localStorage.setItem("navItems", JSON.stringify(updatedNavItems));
      return updatedNavItems;
    });
  };

  return (
    <>
      <SidebarWrapper onClick={(e) => e.stopPropagation()}>
        <div className="top-nav">
          <LogoWrapper>
            {isOnboarding ? (
              <div className="dummy-logo" />
            ) : (
              <Link to="/consumer-reports">
                <div className={`brand-logo ${Flex}`}>
                  <span className={`brand-icon text-base-bold ${FlexCenter}`}>
                    {getFirstLetter(
                      company?.name || companyName || userMaximId
                    )}
                  </span>
                  <div>
                    <p className="text-md-semibold">
                      {truncateString(company?.name, 11) ||
                        truncateString(companyName, 11) ||
                        userMaximId}
                    </p>
                    {(company?.name || companyName) && (
                      <p className="text-sm-medium">{userMaximId}</p>
                    )}
                  </div>
                </div>
              </Link>
            )}
          </LogoWrapper>
          <NavItemsWrapper>
            <>
              {isOnboarding ? (
                <div className={`dummy-nav ${FlexColumnBetween}`}>
                  <div className="dummy-menu" />
                  <div className="dummy-menu" />
                </div>
              ) : (
                <>
                  {navItems.map((menu) => (
                    <div key={menu.label}>
                      {menu.label === "Payment" &&
                      (environment
                        ? environment?.environment === "sandbox"
                        : !companyId) ? null : (
                        <div>
                          <div
                            onClick={() => handleOpenNav(menu)}
                            className="flex items-center justify-between mt-[2.75rem] px-4 cursor-pointer"
                          >
                            <p className="font-bold text-neutral uppercase text-[10px] tracking-widest">
                              {menu.label}
                            </p>
                            <div className={menu.open ? "rotate-[180deg]" : ""}>
                              <ArrowDown />
                            </div>
                          </div>
                          {menu.open &&
                            menu.items.map((item) => (
                              <span
                                key={item.to + item.title}
                                className="nav-wrapper"
                                onMouseEnter={() =>
                                  setIsDecisionEngineHovered(
                                    item.title === "Decision Engine"
                                  )
                                }
                                onMouseLeave={() =>
                                  setIsDecisionEngineHovered(false)
                                }
                              >
                                {isDecisionEngineHovered &&
                                  item.title === "Decision Engine" && (
                                    <span className="coming-soon accent-1 text-xs-semibold">
                                      Coming soon
                                    </span>
                                  )}
                                <span
                                  className={`${
                                    Array.isArray(item.to) &&
                                    item.to.some((path) =>
                                      location.pathname.includes(path)
                                    )
                                  }`}
                                ></span>
                                <Link
                                  key={item.to}
                                  to={
                                    Array.isArray(item.to)
                                      ? item.to[0]
                                      : item.to
                                  }
                                  className={`nav-item mt-5 ${
                                    (Array.isArray(item.to) &&
                                      item.to.some((path) =>
                                        location.pathname.includes(path)
                                      )) ||
                                    location.pathname === item.to
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="icon">{item.icon}</span>
                                  <span className={`nav-item-title`}>
                                    {item.title}
                                  </span>
                                </Link>
                              </span>
                            ))}
                        </div>
                      )}
                    </div>
                  ))}

                  {environment
                    ? environment?.environment === "sandbox" &&
                      (location.pathname === "/plans" ||
                        location.pathname === "/plans/") && (
                        <Navigate to="/get-started" replace />
                      )
                    : (location.pathname === "/plans" ||
                        location.pathname === "/plans/") && (
                        <Navigate to="/get-started" replace />
                      )}
                </>
              )}
            </>
          </NavItemsWrapper>
        </div>
        <div className="bottom-nav">
          <p className="bottom-nax-text">Powered By</p>
          <Logo width="76px" alt="Maxim Logo" />
        </div>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
