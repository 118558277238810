import axios from "axios";
import { Navigate } from "react-router-dom";
import store from "store";
import { getLocalStorageProperties } from "utils/user";

const maximUser = localStorage.getItem("user");
const toExtract = ["dashboard_state", "company_id"];
const extractedUsernames = getLocalStorageProperties(maximUser, toExtract);
const { dashboard_state: dashboardState, company_id: companyId } =
  extractedUsernames;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;

// Add a request interceptor to add the bearer token, country code, company id to the headers
axiosInstance.interceptors.request.use(async (config) => {
  const token = await getTokenFromStore();
  const countryCode = await getCountryCodeFromStore();
  const plan = localStorage.getItem("plan");

  const currentPath = window.location.pathname;

  // Check if the current route includes "/auth"
  if (
    currentPath.includes("/auth") ||
    currentPath.includes("/select-country") ||
    currentPath.includes("/consent")
  ) {
    // Skip token check for routes under "/auth" and "/select-country"
    return config;
  }

  if (plan) {
    config.headers = {
      ...config.headers,
      plan: plan,
    };
  } else {
    config.headers = {
      ...config.headers,
      plan: "free",
    };
  }

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
    localStorage.setItem("token", token);
  } else {
    // If token is missing, redirect to login page
    return <Navigate to="/auth/login" />;
  }

  if (countryCode) {
    config.headers = {
      ...config.headers,
      "country-code": countryCode,
    };
    localStorage.setItem("country-code", countryCode);
  }

  if (companyId) {
    config.headers = {
      ...config.headers,
      "company-id": companyId,
    };
  }

  if (dashboardState === "sandbox") {
    config.headers = {
      ...config.headers,
      mode: dashboardState,
    };
  }

  return config;
});

async function getTokenFromStore() {
  const token = store.auth.token;
  if (!token) {
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (store.auth.processToken) {
          clearInterval(intervalId);
          resolve();
        }
      }, 100);
    });
  }
  return store.auth.token;
}

async function getCountryCodeFromStore() {
  const countryCode = store.auth.countryCode;
  if (!countryCode) {
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (store.auth.processCountryCode) {
          clearInterval(intervalId);
          resolve();
        }
      }, 100);
    });
  }
  return store.auth.countryCode;
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code === 401) {
      // Clear local storage
      localStorage.clear();
      window.location.reload();

      return <Navigate to="/auth/login" />;
    }
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Clear local storage
      localStorage.clear();
      window.location.reload();

      return <Navigate to="/auth/login" />;
    }
    return Promise.reject(error);
  }
);
