import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import store from "store";
import FloatingTextField from "components/formFields/FloatingTextField";
import { FullWidthButton } from "components/button";
import { VerifyEmailForm } from "assets/styles/authpage-styles";
import { validateFormData } from "utils/validateFormData";
import Toast from "components/common/ToastNotification";
import Spinner from "components/common/Spinner";

const VerifyAccount = ({ referenceData }) => {
  const navigate = useNavigate();
  const [newReferenceCode, setNewReferenceCode] = useState(null);
  const [selectedValue, setSelectedValue] = useState({
    code: "",
    recipient: referenceData?.email,
    reference: referenceData?.reference
  });
  const [countdown, setCountdown] = useState(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const handleCodeChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  if (!selectedValue.recipient) {
    return <Navigate to="/auth/login" />;
  }
  
  const handleResend = async () => {
    const storedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    const storedCountryCode = localStorage.getItem('selectedCountry');
    const newData = {
      country_code: storedCountry.alpha_2_code || storedCountryCode,
      email: referenceData?.email
    };
    try {
      const response = await store.auth.postResendEmailVerification(newData);
      if (response && response?.data?.reference) {
        toast.custom((t) => (
          <Toast toastType="success" text={response?.message} />
        ));
        setNewReferenceCode(response?.data?.reference);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });    

    const data =  {
      code: selectedValue.code,
      recipient: selectedValue.recipient,
      reference: newReferenceCode || selectedValue.reference,
    };

    const validationRules = {
      code: [{ required: true }, { maxLength: 6 }],
      recipient: [{ required: true }],
      reference: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    };

    try {
      const response = await store.auth.postEmailVerification(data);
      if (response && response?.message === "Login Successful") {
        const token = response?.data?.token;
        store.auth.storeToken(token);
        const code = response?.data?.country_code;
        store.auth.storeCountryCode(code);
        localStorage.setItem("token", JSON.stringify(response?.data?.token));
        localStorage.setItem("user", JSON.stringify(response?.data));
        navigate("/confirm");
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="container">
      <section className="verification-header">
        <h2 className="heading-h2">Account created successfully</h2>
        <p className="text-base">Enter the code sent to 
        <span className="sent-to-email"> {referenceData?.email}</span></p>
      </section>
      <VerifyEmailForm onSubmit={handleSubmit}>
        <FloatingTextField
          label="Enter 6 digits code"
          type="text"
          id="code"
          name="code"
          labelfor="code"
          value={selectedValue.code}
          onChange={(e) => handleCodeChange("code", e)}
          maxLength="6"
        />
        <p className="text-base resend">
          {countdown > 0
            ? `Resend code in ${countdown}${" "}seconds...`
            : (
              <span className="text-base-semibold resend-code" onClick={()=> handleResend()}>
                Resend code
              </span>
            )}
        </p>
        {isLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }}/>
        ) : (
          <FullWidthButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
            width="100%"
            type="submit"
          >
            Verify email
          </FullWidthButton>
        )}
      </VerifyEmailForm>
      <section className="base">
        <p className="text-base">Need help? <Link to="#">Contact Support</Link> or <Link to="/auth/login">Log in</Link></p>
      </section>
      <Toaster position="top-center" visibleToasts={3} />
    </section>
  );
}

export default VerifyAccount;
