import React from 'react';
import Modal from 'components/modals';
import { ModalBodyWrapper } from 'components/modals/modalStyles';
import { FlexColumnCenter } from 'assets/styles/common-styles';
import { ReactComponent as Envelope } from "assets/images/icons/envelope.svg";

const DirectorNotificationModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBodyWrapper className={`director-notification ${FlexColumnCenter}`}>
        <Envelope />
          <h2 className="modal-title">We'll be in touch</h2>
          <p className="text-base">Once completed, the outcome of your ID and liveliness verification will be sent to your email.</p>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default DirectorNotificationModal;
