import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import store from "store";
import {
  Card,
  CustomerReport,
  ReportWrapper,
} from "assets/styles/customer-report-styles";
import MainLayout from "components/mainLayout";
import { SmallButton } from "components/button";
import RequestedReports from "components/customerReports/RequestedReports";
import SmallCard from "components/common/SmallCard";
import DatapointsList from "components/customerReports/DatapointsList";
import CustomerReportRequestForm from "components/customerReports/CustomerReportRequestForm";
import { BoxTagStyle, Flex, FlexRowBetween } from "assets/styles/common-styles";
import { ReactComponent as CardIcon } from "assets/images/icons/card.svg";
import Spinner from "components/common/Spinner";
import EditDatapointsDrawer from "components/customerReports/EditDatapoints";
import { getLocalStorageProperties } from "utils/user";
import OnboardingCompliance from "components/dashboard/OnboardingCompliance";

const CustomerReports = () => {
  const loadRef = useRef(null);
  const navigate = useNavigate();
  const snap = useSnapshot(store.onboarding);
  const customerSnap = useSnapshot(store.customerReport);
  const onboardingStage = snap?.onboardingStage;
  const onboardingStageLoading = snap?.onboardingStageLoading;
  const completionPercentage = onboardingStage?.percentage + "%" || "0%";
  const datapointDetails = customerSnap?.datapointDetails;
  const datapointDetailsLoading = customerSnap?.datapointDetailsLoading;
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const subscriptionLoading = subscriptionSnapshot?.subscriptionLoading;
  const subscriptionData = subscriptionSnapshot?.subscriptionData;
  const miscSnapshot = useSnapshot(store.misc);
  const environment = miscSnapshot?.environment;
  const environmentLoading = miscSnapshot?.environmentLoading;
  const [isCustomerReportRequestFormOpen, setIsCustomerReportRequestFormOpen] =
    useState(false);
  const [isOpenList, setIsOpenList] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const maximUser = localStorage.getItem("user");
  const extractedValues = getLocalStorageProperties(maximUser, ["company_id"]);
  const { company_id: companyId } = extractedValues;

  useEffect(() => {
    if (companyId) {
      if (!loadRef.current) {
        store?.customerReport.getDatapointsDetails();
        store?.subscription.getSubscription();
        loadRef.current = true;
      }
    }
  }, [companyId]);

  useEffect(() => {
    store?.onboarding.getOnboardingStage();
  }, []);

  const handleViewClick = (reference) => {
    if (reference) {
      navigate(`/consumer-reports/${reference}`);
    }
  };

  return (
    <MainLayout pageHeader="Consumer Check">
      <ReportWrapper>
        <div className="card-row">
          <SmallCard
            cardTitle="Start consumer check"
            cardDesc="Get a comprehensive report on your customers by providing their name, country, international passport number, and email."
            btnText="Request consumer data"
            btnClick={() => setIsCustomerReportRequestFormOpen(true)}
            disabled={datapointDetails}
          />
          <Card className={`${CustomerReport} justify-between`}>
            <div className={FlexRowBetween}>
              <p className="text-base-semibold card-header line-height-normal">
                Data points
              </p>
              <p className={`text-sm-semibold ${Flex} gap-1`}>
                Based on your plan
                <CardIcon />
              </p>
            </div>
            {datapointDetailsLoading ? (
              <Spinner style={{ margin: "0.5rem auto" }} />
            ) : (
              <>
                <div className="flex items-end gap-1">
                  {datapointDetails ? (
                    <>
                      <span className="heading-h1 dark">
                        {datapointDetails?.companyDatapointsCount}
                      </span>
                      <span className="text-sm-bold-spaced">OF</span>
                      <span className="heading-h1 dark">
                        {datapointDetails?.totalDatapointsCount}
                      </span>
                      <span className="text-sm-bold-spaced">ACTIVE</span>
                    </>
                  ) : (
                    <span className="heading-h1 dark">0</span>
                  )}
                </div>
                <div className={FlexRowBetween}>
                  <SmallButton
                    bg="var(--white-alt)"
                    border="var(--white-alt)"
                    textcolor="var(--text-dark)"
                    onClick={() => setOpenDrawer(true)}
                    disabled={!datapointDetails}
                  >
                    Edit active data points
                  </SmallButton>
                  {datapointDetails && (
                    <div className={`${Flex} gap-2`}>
                      <span className="heading-h3 headings">
                        {datapointDetails?.plan}
                      </span>
                      <BoxTagStyle className="text-xs-semibold-spaced-2">
                        {datapointDetails?.frequency}
                      </BoxTagStyle>
                    </div>
                  )}
                </div>
              </>
            )}
          </Card>
        </div>
        <RequestedReports
          planDetails={datapointDetails}
          setCurrentPage={"summary"}
          onRowClick={(reference) => {
            handleViewClick(reference);
          }}
        />
      </ReportWrapper>
      {isCustomerReportRequestFormOpen && (
        <CustomerReportRequestForm
          isOpen={isCustomerReportRequestFormOpen}
          onClose={() => setIsCustomerReportRequestFormOpen(false)}
          setIsCustomerReportRequestFormOpen={
            setIsCustomerReportRequestFormOpen
          }
        />
      )}
      {isOpenList && (
        <DatapointsList
          isOpen={isOpenList}
          onClose={() => setIsOpenList(false)}
        />
      )}
      {openDrawer && (
        <EditDatapointsDrawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        />
      )}
      {onboardingStageLoading ? (
        ""
      ) : completionPercentage !== "50%" && completionPercentage !== "100%" ? (
        <OnboardingCompliance progressWidth={completionPercentage} />
      ) : subscriptionLoading || environmentLoading ? (
        ""
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length === 0 ? (
        <OnboardingCompliance isLive />
      ) : environment?.environment === "live" &&
        subscriptionData?.subscription_details &&
        Object.keys(subscriptionData?.subscription_details)?.length > 0 &&
        !subscriptionData?.subscription_details?.active ? (
        <OnboardingCompliance isNotSubscribed />
      ) : (
        ""
      )}
    </MainLayout>
  );
};

export default CustomerReports;
