import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  FlexCol,
  FlexColumnCenter,
  SpaceBetweenW100,
} from "assets/styles/common-styles";

import {
  Card,
  ReportOverviewWrapper,
  ReportReviewWrapper,
} from "assets/styles/customer-report-styles";
import { useSnapshot } from "valtio";
import store from "store";
import { useEffect } from "react";
import Spinner from "components/common/Spinner";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  removeUnderscores,
} from "utils/common";
import BackButton from "components/common/BackButton";
import PaymentHistory from "components/commercialCheck/PaymentHistory";
import ReportSidebar from "components/commercialCheck/ReportSidebar";
import ReportContent from "components/commercialCheck/ReportContent";
import ReportSummary from "components/commercialCheck/ReportSummary";
import { ReactComponent as Pending } from "assets/images/pending.svg";

const CommercialCheckResult = ({ reference }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(store.commercialCheck);
  const singleCommercialCheck = snap?.singleCommercialCheck?.data;
  const singleCommercialCheckLoading = snap?.singleCommercialCheckLoading;
  const [isOpenList, setIsOpenList] = useState(false);
  const [activeTab, setActiveTab] = useState("general_information");
  const [activeSubTab, setActiveSubTab] = useState(null);

  useEffect(() => {
    if (reference) {
      store.commercialCheck.getSingleCheck(reference);
    }
  }, [reference]);

  const goBack = () => {
    navigate(`/commercial-check`);
  };

  const tabsWithSubTabs = useMemo(() => {
    const orderOfTabs = [
      "general_information",
      "directors_information",
      "facility_performance",
      "credit_agreement",
    ];

    const tabsData = { mainTabs: orderOfTabs.slice() };

    if (singleCommercialCheck && singleCommercialCheck?.result) {
      const meta = singleCommercialCheck?.result;

      orderOfTabs.forEach((tab) => {
        const tabData = meta[tab];

        if (tabData && typeof tabData === "object") {
          // Check for sub-tabs
          const subTabs = Object.keys(tabData)?.filter((subTab) => {
            const subTabData = tabData[subTab];
            return subTabData && typeof subTabData === "object";
          });

          if (subTabs?.length > 0) {
            // Update values inside "credit_agreement"
            if (tab === "credit_agreement") {
              const subscriberNameCounts = {};

              tabsData[tab] = subTabs?.map((subTab) => {
                const subscriberName = meta[tab][subTab]?.subscriber_name;
                const lowerCaseSubscriberName = subscriberName?.toLowerCase();

                if (!subscriberNameCounts[lowerCaseSubscriberName]) {
                  // First occurrence, just use the name
                  subscriberNameCounts[lowerCaseSubscriberName] = 1;
                  return lowerCaseSubscriberName;
                } else {
                  // Increment the count and append to the name
                  const count = ++subscriberNameCounts[lowerCaseSubscriberName];
                  return `${lowerCaseSubscriberName}_${count}`;
                }
              });
            }
            // Update values inside "directors_information"
            else if (tab === "directors_information") {
              const nameCounts = {};

              tabsData[tab] = subTabs?.map((subTab, index) => {
                const firstName = meta[tab][subTab]?.first_name;
                const surname = meta[tab][subTab]?.surname;

                const fullName = `${firstName?.toLowerCase()} ${surname?.toLowerCase()}`;

                const lowerCaseFullName = fullName?.toLowerCase();

                if (!nameCounts[lowerCaseFullName]) {
                  // First occurrence, just use the name
                  nameCounts[lowerCaseFullName] = 1;
                  return lowerCaseFullName;
                } else {
                  // Increment the count and append to the name
                  const count = ++nameCounts[lowerCaseFullName];
                  return `${lowerCaseFullName}_${count}`;
                }
              });
            } else {
              tabsData[tab] = subTabs;
            }
          }
        }
      });
    }

    return tabsData;
  }, [singleCommercialCheck]);

  const handleTabClick = (tab) => {
    setActiveTab((prevTab) => {
      if (prevTab === tab) {
        return prevTab;
      }
      if (
        tabsWithSubTabs?.mainTabs?.includes(tab) &&
        tabsWithSubTabs[tab]?.length > 0
      ) {
        setActiveSubTab((prevSubTab) =>
          tabsWithSubTabs[tab].includes(prevSubTab)
            ? prevSubTab
            : tabsWithSubTabs[tab][0]
        );
      } else {
        setActiveSubTab(null);
      }
      return tab;
    });
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const subTabIndex = tabsWithSubTabs[activeTab]?.indexOf(activeSubTab);

  const renderTabContent = () => {
    const activeTabData = singleCommercialCheck?.result?.[activeTab];
    if (
      (activeTab === "general_information" && activeTabData) ||
      (activeTab === "facility_performance" && activeTabData)
    ) {
      return (
        <>
          {activeTabData &&
            Object.entries(activeTabData)?.map(([key, value]) => (
              <React.Fragment key={key}>
                {key !== "no_of_directors" &&
                key !== "web_address" &&
                typeof value !== "object" &&
                value !== null &&
                value !== "" ? (
                  <div className="detail-item" key={key}>
                    <p className="text-base">
                      {capitalizeFirstLetter(removeUnderscores(key))}
                    </p>
                    <p className="text-base-medium darker">
                      {capitalizeFirstLetter(removeUnderscores(value)) || value}
                    </p>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
        </>
      );
    } else {
      const activeSubTabIndex =
        tabsWithSubTabs[activeTab]?.indexOf(activeSubTab);

      if (
        tabsWithSubTabs[activeTab]?.includes(activeSubTab) &&
        activeSubTabIndex !== -1
      ) {
        return (
          <>
            {activeTabData &&
              activeTabData[activeSubTabIndex] &&
              Object.entries(activeTabData[activeSubTabIndex])?.map(
                ([key, value]) => (
                  <React.Fragment key={key}>
                    {key === "performance_status" ||
                    key === "last_updated" ||
                    key === "account_status" ||
                    key === "payment_history" ||
                    key === "id" ? null : value !== null && value !== "" ? (
                      <div className="detail-item" key={key}>
                        <p className="text-base">
                          {capitalizeFirstLetter(removeUnderscores(key))}
                        </p>
                        <p className="text-base-medium darker">
                          {capitalizeFirstLetter(removeUnderscores(value)) ||
                            value}
                        </p>
                      </div>
                    ) : (
                      <div className="detail-item" key={key}>
                        <p className="text-base">
                          {capitalizeFirstLetter(removeUnderscores(key))}
                        </p>
                        <p className="text-base-medium darker">Nil</p>
                      </div>
                    )}
                  </React.Fragment>
                )
              )}
          </>
        );
      }
    }
  };

  return (
    <>
      <section className={`${FlexCol} gap-5`}>
        <BackButton onClick={goBack} />
        <section className={`${Flex} ${SpaceBetweenW100}`}>
          <div className={`${Flex} gap-1`}>
            <span className="text-md-semibold text-dark">Commercial Check</span>
            <span className="text-md-semibold">/</span>
            <span className="text-md-semibold text-dark">Company result</span>
            <span className="text-md-semibold">/</span>
            {singleCommercialCheckLoading
              ? ""
              : singleCommercialCheck && (
                  <span className="text-md-medium">
                    {capitalizeWords(singleCommercialCheck?.company_name)}
                  </span>
                )}
          </div>
          {singleCommercialCheck?.reference && (
            <p className="text-md">
              Reference ID:
              <span className="text-md-medium darker">
                {singleCommercialCheck?.reference}
              </span>
            </p>
          )}
        </section>
      </section>

      <ReportSummary
        singleCommercialCheck={singleCommercialCheck}
        singleCommercialCheckLoading={singleCommercialCheckLoading}
      />

      {singleCommercialCheckLoading ? (
        <Spinner style={{ margin: "0.5rem auto" }} />
      ) : singleCommercialCheck &&
        (singleCommercialCheck?.status === "pending" ||
          singleCommercialCheck?.status === "processing") ? (
        <ReportOverviewWrapper>
          <ReportSidebar
            tabsWithSubTabs={tabsWithSubTabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            handleSubTabClick={handleSubTabClick}
            singleCommercialCheckLoading={singleCommercialCheckLoading}
            activeSubTab={activeSubTab}
            singleCommercialCheck={singleCommercialCheck}
          />
          <Card
            className={`${ReportReviewWrapper} ${FlexColumnCenter} empty-state min-h-[47vh]`}
          >
            <Pending />
            <p className="text-headings font-semibold my-3">Report Pending</p>
            <div className="w-[40%]">
              <p className="text-neutral text-center">
                Stay tuned while we fetch the details of your data requests.
              </p>
            </div>
          </Card>
        </ReportOverviewWrapper>
      ) : (
        <ReportOverviewWrapper>
          <ReportSidebar
            tabsWithSubTabs={tabsWithSubTabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            handleSubTabClick={handleSubTabClick}
            singleCommercialCheckLoading={singleCommercialCheckLoading}
            activeSubTab={activeSubTab}
            singleCommercialCheck={singleCommercialCheck}
          />
          <ReportContent
            singleCommercialCheck={singleCommercialCheck}
            tabsWithSubTabs={tabsWithSubTabs}
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            renderTabContent={renderTabContent}
            setIsOpenList={setIsOpenList}
            singleCommercialCheckLoading={singleCommercialCheckLoading}
            subTabIndex={subTabIndex}
          />
        </ReportOverviewWrapper>
      )}
      {isOpenList && (
        <PaymentHistory
          isOpen={isOpenList}
          onClose={() => setIsOpenList(false)}
          subscriber={
            singleCommercialCheck?.result?.credit_agreement[subTabIndex]
              ?.subscriber_name
          }
          details={
            singleCommercialCheck?.result?.credit_agreement[subTabIndex]
              ?.payment_history
          }
        />
      )}
    </>
  );
};

export default CommercialCheckResult;
