import store from "store";
import { parseUserInfo } from "utils/user";

const userValues = parseUserInfo("user");

export const useCountryCode = async () => {
  const countryCode = localStorage.getItem("country-code") || userValues?.countryCode;
  store.auth.storeCountryCode(countryCode);
  store.auth.processCountryCode = true;
  return;
};
