// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-container {
  margin-left: auto;
  margin-right: auto;
  width: 1366px;
  max-width: 100%;
}

/* PROGRESS BAR */

.plan-progress.ant-progress {
  height: 100%;
}

.plan-progress.ant-progress .ant-progress-outer {
  display: block;
}

.plan-progress.ant-progress .ant-progress-inner {
  border-radius: 4px !important;
}

.plan-progress.ant-progress .ant-progress-bg {
  border-radius: 0px !important;
}

/* DRAWER */

.customise-plan-drawer .ant-drawer-content-wrapper {
  border-top-right-radius: 100px !important;
}

.ant-drawer .ant-drawer-wrapper-body {
  background: #f3f6f8 !important;
}

.customise-plan-drawer .ant-drawer-body {
  background-color: white !important;
  border-top-right-radius: 50px !important;
  border-top-left-radius: 50px !important;
  padding: 40px 68px;
}

.ant-pagination .ant-pagination-item-active a {
  color: white;
}

.ant-pagination .ant-pagination-item-active a:hover {
  color: white;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #5e8b43;
  border: 1px solid #5e8b43;
}

.ant-pagination .ant-pagination-item-active:hover {
  border: 1px solid #5e8b43;
}

.ant-pagination .ant-pagination-item {
  min-width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 14px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Plans-Billing/plans-billing.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA,iBAAiB;;AAEjB;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA,WAAW;;AAEX;EACE,yCAAyC;AAC3C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".sub-container {\n  margin-left: auto;\n  margin-right: auto;\n  width: 1366px;\n  max-width: 100%;\n}\n\n/* PROGRESS BAR */\n\n.plan-progress.ant-progress {\n  height: 100%;\n}\n\n.plan-progress.ant-progress .ant-progress-outer {\n  display: block;\n}\n\n.plan-progress.ant-progress .ant-progress-inner {\n  border-radius: 4px !important;\n}\n\n.plan-progress.ant-progress .ant-progress-bg {\n  border-radius: 0px !important;\n}\n\n/* DRAWER */\n\n.customise-plan-drawer .ant-drawer-content-wrapper {\n  border-top-right-radius: 100px !important;\n}\n\n.ant-drawer .ant-drawer-wrapper-body {\n  background: #f3f6f8 !important;\n}\n\n.customise-plan-drawer .ant-drawer-body {\n  background-color: white !important;\n  border-top-right-radius: 50px !important;\n  border-top-left-radius: 50px !important;\n  padding: 40px 68px;\n}\n\n.ant-pagination .ant-pagination-item-active a {\n  color: white;\n}\n\n.ant-pagination .ant-pagination-item-active a:hover {\n  color: white;\n}\n\n.ant-pagination .ant-pagination-item-active {\n  background-color: #5e8b43;\n  border: 1px solid #5e8b43;\n}\n\n.ant-pagination .ant-pagination-item-active:hover {\n  border: 1px solid #5e8b43;\n}\n\n.ant-pagination .ant-pagination-item {\n  min-width: 20px;\n  height: 20px;\n  line-height: 18px;\n  font-size: 14px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
