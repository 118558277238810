import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import store from "store";
import { useNavigate, useParams } from "react-router-dom";
import { ReportWrapper } from "assets/styles/customer-report-styles";
import MainLayout from "components/mainLayout";
import ReportOverview from "components/customerReports/ReportOverview";
import { capitalizeFirstLetter } from "utils/common";

const CustomerReport = () => {
  const { reference_id } = useParams();
  const navigate = useNavigate();
  const snap = useSnapshot(store.customerReport);
  const reportRequestsWithPagination = snap?.reportRequestsWithPagination;
  const singleReportRequest = snap?.singleReportRequest;
  const [pagination, ] = useState({
    perPage: 20,
    page: 1,
  });

  useEffect(() => {
    store.customerReport.getReportRequestsWithPagination({ ...pagination });
  }, [pagination]);

  useEffect(() => {
    if (reference_id) {
      store.customerReport.getSingleReportRequest(reference_id);
    }
  }, [reference_id]);

  useEffect(() => {
    if (!Array.isArray(reportRequestsWithPagination)) {
      return;
    }

    const allData = reportRequestsWithPagination.reduce(
      (acc, page) => [...acc, ...page.data],
      []
    );

    const found = allData.some(item => item.reference === reference_id);

    if (!found) {
      navigate("/consumer-reports");
    }
  }, [reference_id, navigate, reportRequestsWithPagination]);

  return (
    <MainLayout
      pageHeader={
        singleReportRequest?.first_name &&
        singleReportRequest?.last_name &&
        `${capitalizeFirstLetter(singleReportRequest.first_name)} ${
          singleReportRequest.middle_name !== ""
            ? `${capitalizeFirstLetter(singleReportRequest.middle_name)} `
            : ""
        } ${capitalizeFirstLetter(singleReportRequest.last_name)}`
      }
    >
      <ReportWrapper>
        <ReportOverview reference={reference_id} />
      </ReportWrapper>
    </MainLayout>
  );
};

export default CustomerReport;
