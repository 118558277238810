import { proxy } from 'valtio';
import axiosInstance from 'axiosInstance';
import { parseUserInfo } from 'utils/user';

const userValues = parseUserInfo('user');

const lookups = proxy({
  businessCategories: [],
  businessCategoriesLoading: null,
  countries: [],
  countriesLoading: null,
  coverageCountries: [],
  coverageCountriesLoading: null,
  companyInfo: {},
  companyInfoLoading: null,
  companyDirectors: [],
  companyDirectorsLoading: null,
  reportTypes: null,
  reportTypesLoading: null,
  datapointsByCategory: {},
  datapointsByCategoryLoading: null,
  datapointsByClass: null,
  datapointsByClassLoading: false,
  criteriaOperators: {},
  criteriaOperatorsLoading: null,
  identities: [],
  identitiesLoading: null,
  // miniCompanyInfo: null,
  // miniCompanyInfoLoading: false,

  async getBusinessCategories() {
    this.businessCategoriesLoading = true;
    return axiosInstance
      .get('/lookups/business-categories')
      .then(({ data }) => {
        this.businessCategories = data.data;
        this.businessCategoriesLoading = false;
      })
      .catch(({ response }) => {
        this.businessCategoriesLoading = false;
        return response;
      });
  },

  async getCountries() {
    this.countriesLoading = true;
    return axiosInstance
      .get('/lookups/countries')
      .then(({ data }) => {
        this.countries = data.data;
        this.countriesLoading = false;
      })
      .catch(({ response }) => {
        this.countriesLoading = false;
        return response;
      });
  },

  async getCoverageCountries() {
    this.coverageCountriesLoading = true;
    return axiosInstance
      .get('/lookups/countries/coverage')
      .then(({ data }) => {
        this.coverageCountries = data.data;
        this.coverageCountriesLoading = false;
      })
      .catch(({ response }) => {
        this.coverageCountriesLoading = false;
        return response;
      });
  },

  async getCompanyInfo(companyId) {
    this.companyInfoLoading = true;
    return axiosInstance
      .get(
        `/lookups/companies/${companyId}?country_code=${
          localStorage.getItem('country-code') || userValues?.countryCode
        }`
      )
      .then(({ data }) => {
        this.companyInfo = data;
        this.companyInfoLoading = false;
        return this.companyInfo;
      })
      .catch(({ response }) => {
        this.companyInfoLoading = false;
        return response;
      });
  },

  // async getMiniCompanyInfo() {
  //   this.miniCompanyInfoLoading = true;
  //   return axiosInstance.get(`/lookups/companies/${userValues.companyId}?country_code=${localStorage.getItem("country-code") || userValues?.countryCode}`)
  //     .then(({data}) => {
  //       this.miniCompanyInfo = data;
  //       this.miniCompanyInfoLoading = false;
  //       return this.companyInfo;
  //     })
  //     .catch(({response}) => {
  //       this.miniCompanyInfoLoading = false;
  //       return response;
  //     });
  // },

  async getCompanyDirectors(companyId) {
    this.companyDirectorsLoading = true;
    return axiosInstance
      .get(
        `/lookups/companies/${companyId}/directors?country_code=${
          localStorage.getItem('country-code') || userValues?.countryCode
        }`
      )
      .then(({ data }) => {
        this.companyDirectors = data.data;
        this.companyDirectorsLoading = false;
        return this.companyDirectors;
      })
      .catch(({ response }) => {
        this.companyDirectorsLoading = false;
        return response;
      });
  },

  async getReportTypes() {
    this.reportTypesLoading = true;
    return axiosInstance
      .get('/lookups/reports/types')
      .then(({ data }) => {
        this.reportTypes = data.data;
        this.reportTypesLoading = false;
        return this.reportTypes;
      })
      .catch(({ response }) => {
        this.reportTypesLoading = false;
        return response;
      });
  },
  async getDataPointsByCategory(country) {
    this.datapointsByCategoryLoading = true;
    return axiosInstance
      .get(`/lookups/data-points?country_code=${country}&group_by=category`)
      .then(({ data }) => {
        this.datapointsByCategory = data.data;
        this.datapointsByCategoryLoading = false;
        return this.datapointsByCategory;
      })
      .catch(({ response }) => {
        this.datapointsByCategoryLoading = false;
        return response;
      });
  },
  async getDataPointsByClass(country) {
    this.datapointsByClassLoading = true;
    return axiosInstance
      .get(`/lookups/data-points?country_code=${country}&group_by=class`)
      .then(({ data }) => {
        this.datapointsByClass = data.data;
        this.datapointsByClassLoading = false;
        return this.datapointsByClass;
      })
      .catch(({ response }) => {
        this.datapointsByClassLoading = false;
        return response;
      });
  },
  async getDecisionOperators() {
    this.criteriaOperatorsLoading = true;
    return axiosInstance
      .get(`/lookups/criteria-operators`)
      .then(({ data }) => {
        this.criteriaOperators = data.data;
        this.criteriaOperatorsLoading = false;
        // return this.criteriaOperators;
      })
      .catch(({ response }) => {
        this.criteriaOperatorsLoading = false;
        return response;
      });
  },
  async getIdentities() {
    this.identitiesLoading = true;
    return axiosInstance
      .get(`/lookups/identities`)
      .then(({ data }) => {
        this.identities = data.data;
        this.identitiesLoading = false;
        return this.identities;
      })
      .catch(({ response }) => {
        this.identitiesLoading = false;
        return response;
      });
  },
});

export default lookups;
