import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from 'valtio';
import store from 'store';
import { FlexCol, FlexColumnBetween, FlexEndCenter, FlexRowBetween } from "assets/styles/common-styles";
import { CountrySelectWrapper } from "assets/styles/selectcountry-styles";
import { MediumButton } from "components/button";
import LaunchNotificationModal from "components/modals/LaunchNotificationModal";
import { ReactComponent as Bell } from "assets/images/icons/bell.svg";
import LaunchNotificationSubmissionSuccess from "components/modals/LaunchNotificationSubmissionSuccess";
import Spinner from "components/common/Spinner";

const SelectCountry = () => {
  const navigate = useNavigate();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const snap = useSnapshot(store.lookups);
  const coverageCountries = snap?.coverageCountries;
  const coverageCountriesLoading = snap?.coverageCountriesLoading;

  useEffect(() => {
    store.lookups.getCoverageCountries();
  }, []);

  const handleCountrySelect = (country) => {
    const selectedCountryData = {
      id: country.id,
      name: country.name,
      alpha_2_code: country.alpha_2_code,
      flag: country.flag,
      active_market: country.active_market,
      enabled: country.enabled,
      calling_code: country.calling_code,
    };
    setSelectedCountry(selectedCountryData);
    localStorage.setItem('selectedCountry', JSON.stringify(selectedCountryData));
  };  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCountry) {
      navigate("/auth/signup");
    }
  };

  return (
    <CountrySelectWrapper className={FlexColumnBetween}>
      <section className={`${FlexCol} header`}>
        <h2 className="heading-h2">Country of business incorporation</h2>
        <section className={`${FlexRowBetween} sub-heading`}>
          <p className="text-base-medium">Select the country your business was incorporated or registered. This action can not be undone. </p>
          <span>
            <p className="text-base-medium">Can't find the country you do business in? </p>
            <p className="text-base-medium">
              <span className="set-reminder text-base-semibold" onClick={() => setShowReminderModal(true)}><Bell />Set Reminder</span>{" "}to get notified when we launch in your country.</p>
          </span>
        </section>
        <form className="country-list-wrapper" onSubmit={handleSubmit} id="country_list">
          <p className="text-base-semibold title">Maxim markets</p>
          <div className="country-list">
            {coverageCountriesLoading ?
              <Spinner />
            : coverageCountries?.map((country) => (
              <div
                key={country?.name}
                className={`country ${selectedCountry?.name === country?.name ? 'selected' : ''} ${!country?.enabled ? 'disabled' : ''}`}
                onClick={() => country?.enabled && handleCountrySelect(country)}
              >
                <div className={`${selectedCountry?.name === country?.name ? 'country-card selected' : 'country-card'}`}>
                  <img src={country?.flag} className={`${selectedCountry?.name === country?.name ? 'selected' : ''}`} alt={`${country?.name} flag`} />
                </div>
                <p>{country?.name}</p>
              </div>
            ))}
          </div>
        </form>
      </section>
      <section className={FlexEndCenter}>
        <MediumButton
          bg="var(--accent-1)"
          border="var(--accent-1)"
          textcolor="var(--primary)"
          type="submit"
          disabled={!selectedCountry}
          form="country_list"
        >
          Continue
        </MediumButton>
      </section>
      {showReminderModal && (
        <LaunchNotificationModal
          isOpen={showReminderModal}
          onClose={() => setShowReminderModal(false)}
          setShowSubmissionModal={setShowSubmissionModal}
        />
      )}
      {showSubmissionModal && (
        <LaunchNotificationSubmissionSuccess
          isOpen={showSubmissionModal}
          onClose={() => setShowSubmissionModal(false)}
        />
      )}
    </CountrySelectWrapper>
  );
}

export default SelectCountry;