import { MediumButton } from "components/button";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";

const ReportAuthorizationFormModal = ({
  isOpen,
  onClose,
  termsData,
  agreeFn,
  disagreeFn,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className="sm:min-w-[927px] px-6 py-7"
    >
      <ModalBodyWrapper>
        <p className="font-secondary text-headings text-xl sm:text-3xl font-semibold mb-3 sm:mb-7">
          Report Authorization Form
        </p>

        <div className="border border-stroke rounded-xl p-6 mb-3 sm:mb-7 max-h-[200px] sm:max-h-[300px] overflow-y-scroll">
          <div dangerouslySetInnerHTML={{ __html: termsData?.content }} />
        </div>

        <div className="sm:flex justify-between">
          <div>
            <p className="text-sm mb-3">
              Signature:
              <span className="text-text-dark font-semibold text-base ml-2 border-b border-stroke">
                {termsData?.signature}
              </span>
            </p>
            <p className="text-sm mb-3">
              Date:
              <span className="text-text-dark font-semibold text-base ml-2 border-b border-stroke">
                {termsData?.date}
              </span>
            </p>
          </div>

          <div className="flex items-center space-x-8">
            <MediumButton
              bg="var(--white-alt)"
              border="var(--white-alt)"
              textcolor="var(--primary)"
              hovercolor="var(--white-alt)"
              onClick={() => {
                disagreeFn();
                onClose();
              }}
            >
              No, Decline
            </MediumButton>

            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              hovercolor="var(--accent-1)"
              onClick={() => {
                onClose();
                agreeFn();
              }}
            >
              Yes, I Agree
            </MediumButton>
          </div>
        </div>
      </ModalBodyWrapper>
    </Modal>
  );
};

export default ReportAuthorizationFormModal;
