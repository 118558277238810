import React from 'react';
import { styled } from 'goober';

const buttonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  font-size: 1.125rem;
  max-height: 3.75rem;
  width: 100%;
  max-width: var(--button-width);
  background: var(--button-bg-color) !important;
  color: var(--button-text-color);
  border: 1px solid var(--button-border);
  border-radius: var(--br);
  text-align: center;
  line-height: normal;
  outline: none;
  font-family: 'Inter',sans-serif;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:hover {
    background: var(--button-hover-color);
  }
`;

const StyledButton = styled('button')`
  ${buttonStyles}
  ${(props) => props.customstyles}
`;

const Button = React.memo(
  ({
    textcolor,
    bg,
    border,
    children,
    className = '',
    onClick,
    type = 'button',
    disabled,
    width,
    hovercolor,
    ...props
  }) => {
    const customStyles = `
    --button-bg-color: ${bg};
    --button-text-color: ${textcolor};
    --button-border: ${border || 'none'};
    --button-width: ${width || 'fit-content'};
    --button-hover-color: ${hovercolor || '#B1DE11'};
  `;

    const handleClick = (event) => {
      if (!disabled && onClick) {
        onClick(event);
      }
    };

    return (
      <StyledButton
        className={`${className} ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
        type={type}
        customstyles={customStyles}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

export default Button;
