import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import store from "store";
import { ReportWrapper } from "assets/styles/customer-report-styles";
import MainLayout from "components/mainLayout";
import { capitalizeFirstLetter } from "utils/common";
import CommercialCheckResult from "components/commercialCheck/SingleResult";

const CommercialCheckReport = () => {
  const { reference } = useParams();
  const navigate = useNavigate();
  const snap = useSnapshot(store.commercialCheck);
  const commercialChecks = snap?.commercialChecks;
  const singleCommercialCheck = snap?.singleCommercialCheck?.data;
  const singleCommercialCheckLoading = snap?.singleCommercialCheckLoading;
  const [pagination] = useState({
    perPage: 20,
    page: 1,
  });

  useEffect(() => {
    store.commercialCheck.getCommercialChecks({ ...pagination });

    if (reference) {
      store.commercialCheck.getSingleCheck(reference);
    }
  }, [pagination, reference]);

  useEffect(() => {
    if (!Array.isArray(commercialChecks)) {
      return;
    }

    const allData = commercialChecks.reduce(
      (acc, page) => [...acc, ...page.data],
      []
    );

    const found = allData.some(item => item.reference === reference);

    if (!found) {
      navigate("/commercial-check");
    }
  }, [reference, navigate, commercialChecks]);

  return (
    <MainLayout
      pageHeader={
        singleCommercialCheckLoading
          ? ""
          : singleCommercialCheck?.company_name &&
            capitalizeFirstLetter(singleCommercialCheck.company_name)
      }
    >
      <ReportWrapper>
        <CommercialCheckResult reference={reference} />
      </ReportWrapper>
    </MainLayout>
  );
};

export default CommercialCheckReport;
