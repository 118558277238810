import Table from "components/table";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import store from "store";
import { capitalizeFirstLetter, formatNumberWithCommas } from "utils/common";
import { useSnapshot } from "valtio";
import { Pagination } from "antd";
import { getLocalStorageProperties } from "utils/user";
import Toast from "components/common/ToastNotification";
import { toast } from "sonner";

const BillingHistory = () => {
  const subscriptionSnapshot = useSnapshot(store.subscription);
  const billingHistoryData = subscriptionSnapshot?.billingHistoryData;
  const billingHistoryLoading = subscriptionSnapshot?.billingHistoryLoading;
  const maximUser = localStorage.getItem("user");
  const toExtract = ["company_id"];
  const extractedCompanyId = getLocalStorageProperties(maximUser, toExtract);
  const { company_id } = extractedCompanyId;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    perPage: 5,
    page: 1,
  });

  useEffect(() => {
    if (company_id) {
      store?.subscription.getBillingHistory({ ...pagination });
    }
  }, [pagination, company_id]);

  const downloadReceipt = async (ref) => {
    try {
      const response = await store?.subscription.getGenerateReceipt(ref);
      if (response === undefined) {
      } else if (response && response?.data?.message === "Transaction not paid yet") {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message}
          />
        ));
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast
          toastType="error"
          text={"Something went wrong"}
        />
      ));
    }
  };

  useEffect(() => {
    if (billingHistoryData && billingHistoryData?.data?.length > 0) {
      const refinedData = billingHistoryData?.data?.map((billing) => {
        return {
          date: format(new Date(billing.createdAt), "dd MMM yy, hh:mm a"),
          amount: (
            <p className="font-medium text-neutral">
              {billing.currency} {formatNumberWithCommas(billing.amount)}
            </p>
          ),
          status: (
            <div className="flex items-center space-x-[6px]">
              <span
                className={`w-2 h-2 ${
                  billing.status === "Paid" ? "bg-success" : "bg-warning"
                } rounded-full`}
              />
              <p className="font-medium text-neutral">{billing.status}</p>
            </div>
          ),
          type: (
            <p className="text-base">
              {capitalizeFirstLetter(billing.planType)}
            </p>
          ),
          modeOfPayment: (
            <>
              {billing.type !== null ? (
                <p className="font-medium text-neutral">
                  <span className="capitalize">{billing.type} -</span>{" "}
                  <span className="lowercase">{billing?.metadata?.type}</span>{" "}
                  **** {billing?.metadata?.last_4digits}
                </p>
              ) : (
                <p className="font-medium">Not Available</p>
              )}
            </>
          ),
          action: (
            <p
              className="font-semibold cursor-pointer text-sm accent-2"
              onClick={() => {
                downloadReceipt(billing.reference);
              }}
            >
              Download PDF
            </p>
          ),
        };
      });
      setData(refinedData);
    }
  }, [billingHistoryData]);

  const columns = [
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "PLAN TYPE",
      accessor: "type",
    },
    {
      Header: "MODE OF PAYMENT",
      accessor: "modeOfPayment",
    },
    {
      Header: "ACTION",
      accessor: "action",
    },
  ];

  return (
    <div className="mt-[4.5rem]">
      <p className="text-dark text-base font-semibold mb-9">Billing history</p>
      <Table
        columns={columns}
        data={data}
        isLoading={billingHistoryLoading}
        className="webhook-table"
      />
      {billingHistoryData?.data?.length ? (
        <>
          <div className="h-[1px] w-full bg-stroke my-10" />
          <Pagination
            current={pagination.page}
            onChange={(page) => setPagination({ ...pagination, page })}
            total={billingHistoryData?.totalPages * pagination.perPage}
            pageSize={pagination.perPage}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default BillingHistory;
