import React, { useState } from "react";
import { Toaster, toast } from "sonner";
import store from "store";
import Toast from "components/common/ToastNotification";
import Modal from "components/modals";
import { ModalBodyWrapper } from "components/modals/modalStyles";
import { MediumButton } from "components/button";
import {
  FlexEndCenter,
  FlexJustifyCenter100,
} from "assets/styles/common-styles";
import { ReactComponent as DatabaseCheck } from "assets/images/icons/database-check.svg";
import Spinner from "components/common/Spinner";
import { parseUserInfo } from "utils/user";

const VerifyCompanySuccessModal = ({
  isOpen,
  companyInfoDetails,
  handleCloseVerifyCompanySuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userValues = parseUserInfo("user");
  const countryCode =
    localStorage.getItem("country-code") || userValues?.countryCode;

  const handleSaveCompanyInfo = async () => {
    setIsLoading(true);

    const data = {
      company_id: companyInfoDetails?.companyInfo?.id.trim(),
      business_category_id: companyInfoDetails?.business_category_id,
    };

    try {
      const response = await store.onboarding.postCompanyInfo(data);

      if (response && response?.data?.id) {
        const { id, name, company_number } = response?.data;
        const userData = JSON.parse(localStorage.getItem("user")) || {};
        // Update only the company_id field
        userData.company_id = String(id).trim();
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem(
          "companyInfo",
          JSON.stringify({ id: String(id).trim(), name, company_number })
        );
        handleCloseVerifyCompanySuccess();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(
          response?.errors || response?.data?.errors
        );
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach(
            (errorMessage) => {
              toast.custom((t) => (
                <Toast
                  key={`${key}-${errorMessage}`}
                  toastType="error"
                  text={errorMessage}
                />
              ));
            }
          );
        });
      } else {
        toast.custom((t) => (
          <Toast
            toastType="error"
            text={response?.data?.message || response?.message}
          />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalBodyWrapper className={`${FlexJustifyCenter100}`}>
        <section className="modal-header">
          <h2 className="modal-title">
            {countryCode === "gb"
              ? "Company house number verified successfully"
              : "Company verified successfully"}
          </h2>
        </section>
        <div className="company-verification-summary">
          <div className="company-name">
            <div>
              <h3 className="heading-h3-type-2">
                {companyInfoDetails?.companyInfo?.name}
              </h3>
              <span className="text-md-medium">
                {companyInfoDetails?.companyInfo?.number}
              </span>
            </div>
            <DatabaseCheck />
          </div>
          <div className="company-address">
            <p className="text-base title">Correspondence address</p>
            <p className="text-base info">
              {companyInfoDetails?.companyInfo?.address?.address_line_1 + ", "}
              {companyInfoDetails?.companyInfo?.address?.address_line_2 &&
                companyInfoDetails?.companyInfo?.address?.address_line_2 + ", "}
              {companyInfoDetails?.companyInfo?.address?.state}
            </p>
          </div>
          <div className="company-status">
            <div className="item">
              <p className="text-base title">Status</p>
              <p className="text-base info">
                {companyInfoDetails?.companyInfo?.status || ""}
              </p>
            </div>
            {/* <div className="item">
              <p className="text-base title">Type</p>
              <p className="text-base info">Loan entity</p>
            </div> */}
            <div className="item">
              <p className="text-base title">
                {countryCode === "gb" ? "Incorporated on" : "Registration date"}
              </p>
              <p className="text-base info">
                {companyInfoDetails?.companyInfo?.incorporation_date || ""}
              </p>
            </div>
          </div>
        </div>
        <div className={FlexEndCenter}>
          {isLoading ? (
            <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
          ) : (
            <MediumButton
              bg="var(--accent-1)"
              textcolor="var(--primary)"
              onClick={() => handleSaveCompanyInfo()}
            >
              Okay, got it
            </MediumButton>
          )}
        </div>
      </ModalBodyWrapper>
      <Toaster position="top-center" />
    </Modal>
  );
};

export default VerifyCompanySuccessModal;
