import { Flex, FlexCol } from "assets/styles/common-styles";
import {
  MainPageContent,
  MainPageTitle,
} from "assets/styles/docs-styles";
import DocsLayout from "components/api-docs/layout";
import SideContent from "components/api-docs/layout/SideContent";
import { ReactComponent as HeaderImg } from "assets/images/header-img.svg";

const ApiDocsEnvironment = () => {
  return (
    <DocsLayout>
      <MainPageTitle className={Flex}>
        <h2 className="heading-h1 headings">Environment</h2>
      </MainPageTitle>
      <MainPageContent>
        <section className={`${FlexCol} gap-8`}>
          <div>
            <p className="text-base-semibold text-dark mb-3">Sandbox</p>
            <p className="leading-[1.75rem]">
              The Sandbox environment serves as a secure and controlled testing
              environment for Maxim API integration. It is designed for
              developers, quality assurance teams, and businesses to thoroughly
              test their applications and interactions with Maxim's services
              without affecting live data or making actual decisions. In this environment, users  do not need to complete their onboarding and compliance process.
            </p>
          </div>
          <div className="border border-[var(--stroke)] py-2.5 px-3 rounded-[0.25rem]">
            <HeaderImg width="100%" height="100%" />
          </div>
          <div>
            <p className="text-base-semibold text-dark mb-3">Production</p>
            <p className="leading-[1.75rem]">
              The Live environment is the operational environment where Maxim's
              API services are used for real-world applications. It is the
              environment where actual decisions are made based on the data and
              criteria set up by businesses. In this environment, users will need to complete their onboarding and compliance process on Maxim.
            </p>
          </div>
        </section>
        <SideContent />
      </MainPageContent>
    </DocsLayout>
  );
};

export default ApiDocsEnvironment;
