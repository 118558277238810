import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Toaster, toast } from 'sonner';
import store from 'store';
import Toast from "components/common/ToastNotification";
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { Form244H } from 'components/formFields/formStyles';
import { ModalBodyWrapper, } from 'components/modals/modalStyles';
import FloatingSelectField from 'components/formFields/FloatingSelectField';
import { FlexColumnBetween, FlexRowBetween } from 'assets/styles/common-styles';
import { validateFormData } from 'utils/validateFormData';
import { getLocalStorageProperties, parseUserInfo } from 'utils/user';
import Spinner from 'components/common/Spinner';

const SelectDirectorModal = ({
    isOpen,
    onClose,
    handleDirectorInfoVerification,
  }) => {
  const snap = useSnapshot(store.lookups);
  const companyDirectors = snap?.companyDirectors;
  const companyDirectorsLoading = snap?.companyDirectorsLoading;
  const userValues = parseUserInfo("user");
  const companyInfo = localStorage.getItem('companyInfo');
  const propertiesToExtract = ['id', 'name',];
  const extractedProperties = getLocalStorageProperties(companyInfo, propertiesToExtract);
  const { id: companyId, name: companyName } = extractedProperties;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    full_name: "",
    first_name: "",
    last_name: "",
    is_director: "",
    phone_number: "",
    date_of_birth: "",
    company_id: companyId || userValues?.companyId,
    director_id: "",
    role: "",
    nationality: ""
  });

  useEffect(() => {
    if (companyId || userValues?.companyId) {
      store.lookups.getCompanyDirectors(companyId || userValues?.companyId);
    }
  }, [companyId, userValues?.companyId]);

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });

    const director = companyDirectors?.directors?.find(director => director.first_name + " " + director.last_name === value);
    if (director) {
      setSelectedValue(prevValue => ({
        ...prevValue,
        first_name: director.first_name,
        last_name: director.last_name,
        is_director: director.is_director,
        phone_number: director.mobile_number,
        director_id: director.id,
        role: director.officer_role,
        nationality: director.nationality
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data =  {
      first_name: selectedValue.first_name,
      last_name: selectedValue.last_name,
      is_director: selectedValue.is_director,
      phone_number: selectedValue.phone_number,
      company_id: selectedValue.company_id,
      director_id: selectedValue.director_id,
      role: selectedValue.role,
      nationality: selectedValue.nationality,
    };
    const validationRules = {
      first_name: [{ required: true }],
      last_name: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }

    handleDirectorInfoVerification(data);
    setIsLoading(false)
    onClose();
  };

  return (
    <Modal isOpen={isOpen} className="longer">
      <ModalBodyWrapper className="director-info">
        <div className="modal-header">
          <p className="text-base">{companyName || companyDirectors?.company?.name}</p>
          <h2 className="modal-title">Which director are you?</h2>
        </div>
        <form className={FlexColumnBetween} id="director_select" onSubmit={handleSubmit}>
          <Form244H>
            <FloatingSelectField
              id="full_name"
              name="full_name"
              labelfor="full_name"
              options={
                companyDirectors?.directors?.map((director, index) => ({
                  value: `${director?.first_name} ${director?.last_name}`,
                  key: `${director?.first_name}${director?.last_name}${index}`,
                }))
              }
              label="Select from company directors"
              value={selectedValue?.full_name}
              onChange={(e) => {
                handleChange("full_name", e);
              }}
              isLoading={companyDirectorsLoading}
            />
          </Form244H>
          <div className={`${FlexRowBetween} button-group`}>
            <MediumButton bg="var(--white-alt)" textcolor="var(--text-dark)" type="button" onClick={onClose}>
              Cancel
            </MediumButton>
            {isLoading ? (
              <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }} />
            ) : (
              <MediumButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                type={selectedValue?.full_name === "" ? "button" : "submit"}
                disabled={selectedValue?.full_name === ""}
              >
                Continue
              </MediumButton>
            )}
          </div>
        </form>
      </ModalBodyWrapper>
      <Toaster position="top-center" visibleToasts={3} />
    </Modal>
  );
};

export default SelectDirectorModal;
