import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "assets/images/icons/arrow-up.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { Select, CustomDropdown, CustomDropdownIcon, CustomOption, SelectFieldWrapper } from "components/formFields/formStyles";
import TextField from "components/formFields/TextField";
import Spinner from "components/common/Spinner";

const SelectField = ({
  label,
  options,
  value,
  onChange,
  className,
  width,
  search,
  minwidth,
  isDisabledSelect,
  wrapperclass,
  isLoading,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || "");
  const [searchText, setSearchText] = useState("");

  const customStyles = {
    "--selectfield-width": width,
    "--selectfield-minwidth": minwidth,
  };

  const handleOpen = () => {
    if (!isDisabledSelect) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (optionValue, optionKey) => {
    setCurrentValue(optionValue);
    setIsOpen(false);
    onChange({ target: { value: optionValue, key: optionKey } });
  };

  const filteredOptions = options?.filter(option =>
    typeof option.value === "string" ? option.value.toLowerCase().includes(searchText.toLowerCase())
    : option.value
  );

  const handleSearchInputChange = event => {
    setSearchText(event.target.value);
  };
  
  return (
    <SelectFieldWrapper  className={wrapperclass} style={customStyles}>
      <Select
        className={className}
        onClick={() => handleOpen()}
        {...props}
      >
        {currentValue || label}
        {isOpen ? (
          <CustomDropdownIcon><ArrowUp stroke="var(--text-neutral)" /></CustomDropdownIcon>
        ) : (
          <CustomDropdownIcon><ArrowDown stroke="var(--text-neutral)" /></CustomDropdownIcon>
        )}
      </Select>
      {isOpen && (
        <CustomDropdown id="custom-dropdown">
          {isLoading ? (
            <Spinner style={{margin: "1rem auto"}}/>
          ) : (
            <>
              {search && (
                <div className="search">
                  <TextField
                    type="search"
                    name="searchField"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    iconLeft={<SearchIcon className="icon left-icon" />}
                    className="text-indent"
                    width="420px"
                    iconTopSpacing="13px"
                  />
                </div>
              )}
              {filteredOptions?.map((option, key) => {
                return (
                <CustomOption
                  key={option.key}
                  selected={option.value === currentValue}
                  onClick={() => handleSelect(option.value, option.key)}
                  className={option.value === currentValue ? "selected" : ""}
                >
                  {option.value}
                </CustomOption>
              )})}
            </>
          )}
        </CustomDropdown>
      )}
    </SelectFieldWrapper>
  );
};

export default SelectField;
