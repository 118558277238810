import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const ListCommercialChecks = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    200: {
      queryData: [
        {
          key: "perPage",
          value: "10",
          description: "-",
        },
        {
          key: "q",
          value: "quick",
          description: "Search value if needed",
        },
      ],
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      response: {
        "message": "Reports fetched successfully",
        "data": [
          {
            "reference": "Pl5PDAzPVfWegmAfOzhh",
            "company_name": "QUICKTEK DIGITAL INNOVATIONS",
            "company_number": "2739770",
            "status": "pending",
            "created_at": "2024-01-22T14:24:56.000000Z"
          },
          {
            "reference": "JyPCAfmBvSJlN6Z0smNl",
            "company_name": "QUICKTEK DIGITAL INNOVATIONS",
            "company_number": "2739770",
            "status": "pending",
            "created_at": "2024-01-22T14:24:54.000000Z"
          },
          {
            "reference": "aA9qXQun46pv1595PQSq",
            "company_name": "QUICKTEK DIGITAL INNOVATIONS",
            "company_number": "2739770",
            "status": "pending",
            "created_at": "2024-01-22T14:24:34.000000Z"
          },
          {
            "reference": "vXiX5C7yiEE0Zed5IGK6",
            "company_name": "QUICKTEK DIGITAL INNOVATIONS",
            "company_number": "2739770",
            "status": "pending",
            "created_at": "2024-01-22T12:51:32.000000Z"
          }
        ],
        "pagination": {
          "total": 4,
          "per_page": 20,
          "current_page": 1,
          "total_pages": 1,
          "first_page": 1,
          "last_page": 1,
          "first_page_url": "http://localhost:8000/v1/commercial-reports?page=1",
          "last_page_url": "http://localhost:8000/v1/commercial-reports?page=1",
          "next_page_url": null,
          "prev_page_url": null,
          "path": "http://localhost:8000/v1/commercial-reports",
          "from": 1,
          "to": 4
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the request to retrieve a list of the commercial report was successful.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Commercial Report"
      pageDescription="Use this endpoint to check the CAC status and credit details of a registered Nigerian company or business organization."
      subtitle="List"
      urlType="get"
      urlBg="bg-[#EFF4ED]"
      urlColor="accent-2"
      endpoint="/v1/commercial-reports?perPage=20&q=quick"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default ListCommercialChecks;
