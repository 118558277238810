import { DashboardWrapper } from 'assets/styles/dashboard-styles';
import MainLayout from 'components/mainLayout';
import SettingsSection from 'components/settings/SettingsSection';

const SettingsPage = () => {
  return (
    <MainLayout pageHeader='API and Webhooks'>
      <DashboardWrapper>
        <SettingsSection />
      </DashboardWrapper>
    </MainLayout>
  );
};

export default SettingsPage;
