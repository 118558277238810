import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NetworkSvg from "assets/images/network.svg";
import { useSnapshot } from "valtio";
import store from "store";
import Table from "components/table";
import { format } from "date-fns";
import Spinner from "components/common/Spinner";
import { ReactComponent as ArrowLeftPlain } from "assets/images/icons/arrow-left-plain.svg";
import { Flex, StatusBall } from "assets/styles/common-styles";
import { Card } from "assets/styles/customer-report-styles";
import { capitalizeFirstLetter } from "utils/common";
import WebhookModal from "components/API-Webhooks/Webhook/WebhookModal";

const WebhookService = () => {
  const navigate = useNavigate();
  const { service } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const settingsSnapshot = useSnapshot(store.settings);
  const allWebhooksByService = settingsSnapshot?.allWebhooksByService;
  const allWebhooksByServiceLoading =
    settingsSnapshot?.allWebhooksByServiceLoading;
  const webhookTypes = settingsSnapshot?.webhookTypes;
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");

  useEffect(() => {
    store.settings.getWebhookTypes();
  }, []);

  useEffect(() => {
    store?.settings?.getAllWebhooksByService(service);
  }, [service]);

  useEffect(() => {
    if (allWebhooksByService?.length > 0) {
      const refinedData = allWebhooksByService?.map((webhook) => {
        return {
          reference: webhook.reference,
          code: webhook.status_code && (
            <div className={`${Flex}`}>
              <StatusBall
                className={`mr-[0.375rem] ${
                  webhook.status_code === "200" ? "active" : "pending"
                }`}
              ></StatusBall>
              {webhook.status_code}
            </div>
          ),
          attempt: webhook.retry_count,
          id: webhook.id,
          status: (
            <div className={`${Flex}`}>
              <StatusBall
                className={`mr-[0.375rem] ${
                  webhook.status === "success"
                    ? "active"
                    : webhook.status === "failed"
                    ? "error"
                    : "pending"
                }`}
              ></StatusBall>
              {capitalizeFirstLetter(webhook.status)}
            </div>
          ),
          createdAt: format(
            new Date(webhook.created_at),
            "MMM dd yyyy, hh:mm a"
          ),
        };
      });
      setData(refinedData);
    }
  }, [allWebhooksByService]);

  useEffect(() => {
    if (!Array.isArray(webhookTypes)) {
      return;
    }

    const validServices = webhookTypes?.map((item) => item.slug);
    if (validServices.length > 0 && !validServices.includes(service)) {
      navigate("/get-started");
    }
  }, [service, navigate, webhookTypes]);

  const handleRowClick = (row) => {
    const { id } = row;
    setRowId(id);
    setOpenModal(true);
  };

  const columns = [
    {
      Header: "REFERENCE ID",
      accessor: "reference",
    },
    {
      Header: "CODE",
      accessor: "code",
    },
    {
      Header: "ATTEMPT",
      accessor: "attempt",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "createdAt",
    },
  ];

  return (
    <section className="w-full">
      <Card className="min-h-[80%]">
        <div className="px-11 pt-8 pb-10">
          <div
            className={`${Flex} mb-5 gap-[0.625rem] cursor-pointer`}
            onClick={() => navigate("/webhooks")}
          >
            <ArrowLeftPlain stroke="var(--text-dark)" />
            <p className="text-base-semibold darker">Return to main table</p>
          </div>
          {allWebhooksByService?.length > 0 && (
            <div className="flex items-center justify-between mb-6">
              <div className="flex gap-6 items-center">
                <p className="text-maximgray-10 text-base">Service</p>
                <p className="font-semibold text-md-semibold text-maximblack-10">
                  {capitalizeFirstLetter(allWebhooksByService?.[0]?.service)}
                </p>
              </div>
              <div className="flex flex-col gap-2 leading-[20px] text-right">
                <p className="text-base">Target URL</p>
                <p className="darker text-md-semibold">
                  {allWebhooksByService?.[0]?.url}
                </p>
              </div>
            </div>
          )}
          {allWebhooksByServiceLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : allWebhooksByService && allWebhooksByService?.length > 0 ? (
            <Table
              columns={columns}
              data={data}
              className="webhook-table"
              onRowClick={(row) => handleRowClick(row)}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-[40vh]">
              <img src={NetworkSvg} alt="network" draggable={false} />
              <p className="text-base mt-6 text-center">
                Nothing to show at the moment
              </p>
            </div>
          )}
        </div>
      </Card>
      {openModal && (
        <WebhookModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          rowId={rowId}
        />
      )}
    </section>
  );
};

export default WebhookService;
