import { styled } from "goober";
import Button from "components/button/button";

export const LargeButton = styled(Button)`
  max-width: 21.25rem;
  padding: 1.1875rem 1.5rem;
  font-size: 1.125rem;
`;

export const MediumButton = styled(Button)`
  padding: 0.96875rem 1.5625rem;
  border-radius: var(--br-md);
  font-size: 1rem;
`;

export const SmallButton = styled(Button)`
  padding: 0.625rem 1.5rem;
  border-radius: var(--br-sm);
  font-size: 0.875rem;
  line-height: 20px;
`;

export const FullWidthButton = styled(LargeButton)`
  max-width: 100%;
`;