import { css, styled } from "goober";
import { mobile } from "globalStyles";

export const OnboardingChecklistWrapper = styled("div")`
  width: 100%;
  max-width: 35.625rem;
  margin: 7.5rem auto;
  strong {
    color: var(--text-dark);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  .add-teammates-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5625rem;
    .view-verified {
      color: var(--accent-2);
    }
    .add-teammates {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      p {
        color: var(--accent-2);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
    }
    .not-now {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.4px;
      color: var(--text-neutral);
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;

export const DashboardWrapper = styled("div")`
  display: flex;
  gap: 1.25rem;
  padding: 3rem 3.75rem 2rem 3.75rem;
  min-height: 91vh;
  ${mobile} {
    padding: 1rem;
    flex-wrap: wrap;
  }
`;

export const DashboardCard = styled("section")`
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--br-md);
  .card-header {
    color: var(--headings);
  }
`;

export const ReportReviewWrapper = css`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.375rem 2.0625rem 1.375rem;
  flex: 1;
  .empty-state {
    gap: 1.25rem;
  }
`;
