import axiosInstance from 'axiosInstance';
import { proxy } from 'valtio';

const reminders = proxy({
  reminders: [],
  remindersLoading: null,

  async getReminders() {
    this.remindersLoading = true;
    return axiosInstance.get('/notifications')
      .then(({data}) => {
        this.reminders = data.data;
        this.remindersLoading = false;
      })
      .catch(({response}) => {
        this.remindersLoading = false;
        return response;
      });
  },
  async postReminder(data) {
    return axiosInstance.post('/notifications', data)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response;
      });
  },
});

export default reminders;