import { styled } from "goober";
import { ReactComponent as SuccessIcon } from "assets/images/icons/check-bg.svg";
import { ReactComponent as ErrorIcon } from "assets/images/icons/close-bg-error.svg";
import { ReactComponent as WarningIcon } from "assets/images/icons/info-bg.svg";

const Toast = ({ toastType, className = "", text }) => {
  let icon;

  switch (toastType) {
    case "success":
      icon = <SuccessIcon />;
      break;
    case "error":
      icon = <ErrorIcon />;
      break;
    case "warning":
      icon = <WarningIcon />;
      break;
    case "info":
      break;
    default:
      // Handle default case
      break;
  }
  return (
    <ToastWrapper className={`${className} ${toastType}`}>
      {icon}
      <p className="text-md-medium">{text}</p>
    </ToastWrapper>
  );
};

export default Toast;

export const ToastWrapper = styled("section")`
  position: relative;
  width: 100%;
  min-width: 20.375rem;
  min-height: 4.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.5rem;
  color: var(--text-dark);
  border-radius: var(--br-sm);
  svg {
    min-width: 1.5rem;
    min-height: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  &.success {
    background: var(--bg);
    border-left: 8px solid var(--success);
  }
  &.error {
    background: #f7e9e8;
    border-left: 8px solid var(--error);
  }
  &.warning {
    background: #f7f7e8;
    border-left: 8px solid var(--warning);
  }
  &.info {
    background: #e0eff4;
    border-left: 8px solid var(--complimentary-1);
  }
`;
