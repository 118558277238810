import { Flex } from "assets/styles/common-styles";
import ApiReference from "components/api-docs/ApiReferenceContentLayout";

const UpdateConsentData = () => {
  const options = [
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball active"></div>
          200 OK
        </div>
      ),
      key: "200",
    },
    {
      value: (
        <div className={`${Flex} gap-2`}>
          <div className="status-ball error"></div>
          404 Error
        </div>
      ),
      key: "404",
    },
  ];

  const contentMap = {
    columns:[
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    "200": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "type": "Comprehensive",
        "first_name": "ola",
        "last_name": "jide",
        "middle_name": "samuel",
        "user_country_code": "ng",
        "id_type": "National ID Card",
        "id_number": "89458784",
        "email": "samuel+2migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "decision_criteria_id": "DCR01hd2wzp3h0gvaspggday3e42s"
      },
      response: {
        "message": "Assessment updated",
        "data": {
          "id": 13,
          "user_country_code": "ng",
          "request_source": "api",
          "reference": "rhN5aYoIVEKG_pZ",
          "first_name": "ola",
          "last_name": "jide",
          "middle_name": "samuel",
          "decision_criteria_id": "DCR01hd2wzp3h0gvaspggday3e42s",
          "email": "samuel+2migrant@trymaxim.com",
          "phone_number": "2347065514172",
          "status": "pending",
          "id_type": "National ID Card",
          "id_number": "89458784",
          "idcard": null
        }
      },
      description: (
        <>
          <span className="text-base-medium text-dark">200 OK:</span> This indicates that the update operation on the assessment was successful.
        </>
      ),
    },
    "404": {
      headerData: [
        {
          key: "api-key",
          value: "{{apiKey}}",
          description: "'Api key generated from the dashboard’",
        },
      ],
      request: {
        "type": "Comprehensive",
        "first_name": "ola",
        "last_name": "jide",
        "middle_name": "samuel",
        "user_country_code": "ng",
        "id_type": "National ID Card",
        "id_number": "89458784",
        "email": "samuel+2migrant@trymaxim.com",
        "phone_number": "2347065514172",
        "decision_criteria_id": "DCR01hd2wzp3h0gvaspggday3e42s"
      },
      response: {
        "message": "Assessment not found"
      },
      description: (
        <>
          <span className="text-base-medium text-dark">404 Error:</span> This indicates that the assessment to be updated was not found.
        </>
      ),
    },
  };

  return (
    <ApiReference
      pageTitle="Assesment"
      pageDescription="Use this endpoint to get access to the financial records of a customer to be sure that they are creditworthy."
      subtitle="Update an assessment"
      urlType="patch"
      urlBg="bg-[#F1EDF4]"
      urlColor="text-[#643797]"
      endpoint="/v1/assessments/20sUOfTINuNScVV"
      statusOptions={options}
      contentMap={contentMap}
    />
  );
};

export default UpdateConsentData;
