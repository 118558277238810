import { useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from "sonner";
import store from "store";
import { MediumButton, SmallButton } from "components/button";
import {
  Flex,
  FlexCenter,
  FlexCol,
  FlexColumnBetween,
  ShadowCard,
  SpaceBetweenW100,
} from "assets/styles/common-styles";
import FloatingTextField from "components/formFields/FloatingTextField";
import CustomModal from "components/modals/CustomModal";
import { DrawerStyle } from "components/modals/modalStyles";
import Spinner from "components/common/Spinner";
import { validateFormData } from "utils/validateFormData";
import Toast, { ToastWrapper } from "components/common/ToastNotification";
import { capitalizeFirstLetter } from "utils/common";
import Tab from "components/common/Tab";
import { ReactComponent as DualTone } from "assets/images/icons/dual-tone.svg";
import SuccessBadge from "assets/images/badge.svg";
import FailBadge from "assets/images/icons/fail.svg";
import UploadCSV from "components/customerReports/UploadCSV";
import { InputsWrapper } from "components/formFields/formStyles";

const InitiateLookup = ({ isOpen, onClose, setIsTopUpUnitsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSingleResult, setShowSingleResult] = useState(false);
  const [showBulkResult, setShowBulkResult] = useState(false);
  const [matchValue, setMatchValue] = useState(false);
  const [singleResult, setSingleResult] = useState({});
  const [bulkResult, setBulkResult] = useState("");
  const [showCautionMsg, setShowCautionMsg] = useState(null);
  const [selectedValue, setSelectedValue] = useState({
    bvn: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });
  const [bulkFile, setBulkFile] = useState(null);

  const tabs = [
    { label: "single", urlParam: "single" },
    { label: "multiple", urlParam: "multiple" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleChange = (name, e) => {
    setSelectedValue({ ...selectedValue, [name]: e.target.value });
  };

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const handleTopup = () => {
    onClose();
    setIsTopUpUnitsOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });

    const data = {
      bvn: selectedValue?.bvn,
      first_name: selectedValue?.first_name,
      middle_name: selectedValue?.middle_name,
      last_name: selectedValue?.last_name,
      email: selectedValue?.email,
      phone_number: selectedValue?.phone_number,
    };

    const validationRules = {
      bvn: [{ required: true }],
      first_name: [{ required: true }],
      last_name: [{ required: true }],
      email: [{ required: true }],
      phone_number: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }

    try {
      const response = await store.profileCheck.initiateProfileCheck(data);
      if (response && response?.data?.id) {
        setSingleResult(data);
        setMatchValue(response?.data?.match);
        setShowSingleResult(true);
        await store.profileCheck.getProfileChecks();
        await store.profileCheck.getUnits();
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType="error"
                text={errorMessage}
              />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", bulkFile);

    try {
      const response = await store.profileCheck.initiateBulkProfileChecks(
        formData
      );
      if (response && response?.message === "Bulk upload successful!") {
        setBulkResult(response?.data?.count);
        setShowBulkResult(true);
        await store.profileCheck.getProfileChecks();
        await store.profileCheck.getUnits();
      } else if (response?.data?.data?.key === "insufficient-unit") {
        setShowCautionMsg(true);
      } else if (response?.data?.errors) {
        const errorKeys = Object.keys(response?.data?.errors);
        errorKeys.forEach((key) => {
          response?.data?.errors?.[key].forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast
                key={`${key}-${errorMessage}`}
                toastType="error"
                text={errorMessage}
              />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={DrawerStyle}>
      {showSingleResult ? (
        <section className={FlexColumnBetween}>
          <h3 className="heading-h2-type-2 card-header headings">
            Single profile result
          </h3>
          <section>
            <div className="text-center mb-4">
              <h3 className="heading-h2-type-2 card-header headings mb-2">
                {singleResult?.first_name &&
                  singleResult?.first_name +
                    " " +
                    singleResult?.middle_name +
                    " " +
                    singleResult?.last_name}
              </h3>
              <p>
                <span className="text-dark text-base-medium">BVN </span>/{" "}
                {singleResult?.bvn}
              </p>
            </div>
            <ShadowCard
              className={`justify-center items-center min-h-[32.5rem] text-center px-[2.375rem] gap-10`}
            >
              <div className={FlexCol}>
                {matchValue ? (
                  <img
                    src={SuccessBadge}
                    alt="success-badge"
                    className="mb-5 h-[3.25rem] text-center"
                  />
                ) : (
                  <img
                    src={FailBadge}
                    alt="fail-badge"
                    className="mb-5 h-[3.25rem] text-center"
                  />
                )}
                <p className="mb-4">
                  Status -{" "}
                  <span className="text-dark text-base-medium">
                    {matchValue && matchValue ? "Match" : "No match"}
                  </span>
                </p>
              </div>
              <div className={`${FlexCol} gap-2`}>
                <p className="text-base">Email address</p>
                <p
                  className={`text-base-medium text-dark ${
                    !matchValue ? "blur-sm" : ""
                  }`}
                >
                  {singleResult?.email}
                </p>
              </div>
              <div className={`${FlexCol} gap-2`}>
                <p className="text-base">Phone</p>
                <p
                  className={`text-base-medium text-dark ${
                    !matchValue ? "blur-sm" : ""
                  }`}
                >
                  {singleResult?.phone_number}
                </p>
              </div>
              <div className={`${FlexCol} gap-2`}>
                <p className="text-base">Nationality</p>
                <p className="text-base-medium text-dark">Nigeria</p>
              </div>
            </ShadowCard>
          </section>
        </section>
      ) : showBulkResult ? (
        <section className={FlexColumnBetween}>
          <h3 className="heading-h2-type-2 card-header headings">
            Multiple profile results
          </h3>
          <section>
            <div className="text-center mb-4">
              <h3 className="heading-h2-type-2 card-header headings mb-2">
                Bulk upload successful
              </h3>
              <p>
                <span className="text-dark">{bulkResult}</span> entries detected
              </p>
            </div>
            <ShadowCard
              className={`justify-center items-center min-h-[32.5rem] text-center px-[2.375rem] gap-[2.75rem]`}
            >
              <div>
                <span className={FlexCenter}>
                  <img
                    src={SuccessBadge}
                    alt="success-badge"
                    className="mb-5 h-[3.25rem] text-center"
                  />
                  <img
                    src={FailBadge}
                    alt="fail-badge"
                    className="mb-5 h-[3.25rem] ml-[-6%] text-center"
                  />
                </span>
                <p>
                  Status -{" "}
                  <span className="text-dark text-base-medium">
                    All available on profile results table
                  </span>
                </p>
              </div>
              <p>
                A total of{" "}
                <span className="text-dark text-base-medium">
                  {bulkResult} entries
                </span>{" "}
                have been uploaded successfully. Go ahead and confirm their
                status on the profile results table
              </p>
              <SmallButton
                bg="var(--accent-1)"
                border="var(--accent-1)"
                textcolor="var(--primary)"
                onClick={() => onClose()}
              >
                Okay, got it
              </SmallButton>
            </ShadowCard>
          </section>
        </section>
      ) : (
        <form className={`${FlexCol} h-full`} onSubmit={handleSubmit}>
          <h3 className="heading-h2-type-2 card-header">
            Initiate User ID Verification
          </h3>
          <Tab
            tabs={tabs.map((tab) => ({
              label: capitalizeFirstLetter(tab.label) + " lookup",
              urlParam: tab.urlParam,
            }))}
            activeTabLabel={activeTab}
            handleTabClick={handleTabClick}
            className="webhook-tab"
            tabclass="w-1/2 text-center text-md-medium py-2.5 justify-center"
            isactiveclass="bg-white text-md-semibold rounded-xl text-dark"
          />
          {activeTab === "single" && (
            <>
              <FloatingTextField
                label="Bank Verification Number"
                type="number"
                name="bvn"
                id="bvn"
                labelfor="bvn"
                value={selectedValue.bvn}
                onChange={(e) => handleChange("bvn", e)}
              />
              <InputsWrapper className={SpaceBetweenW100}>
                <FloatingTextField
                  label="First name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  labelfor="first_name"
                  value={selectedValue.first_name}
                  onChange={(e) => handleChange("first_name", e)}
                />
                <FloatingTextField
                  label="Middle name"
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  labelfor="middle_name"
                  value={selectedValue.middle_name}
                  onChange={(e) => handleChange("middle_name", e)}
                />
              </InputsWrapper>
              <FloatingTextField
                label="Last name"
                type="text"
                name="last_name"
                id="last_name"
                labelfor="last_name"
                value={selectedValue.last_name}
                onChange={(e) => handleChange("last_name", e)}
              />
              <FloatingTextField
                label="Email address"
                type="email"
                name="email"
                id="email"
                labelfor="email"
                value={selectedValue.email}
                onChange={(e) => handleChange("email", e)}
              />
              <FloatingTextField
                label="Phone number"
                type="text"
                name="phone_number"
                id="phone_number"
                labelfor="phone_number"
                value={selectedValue.phone_number}
                onChange={(e) => handleChange("phone_number", e)}
              />
              <div className="mt-auto self-end">
                {isLoading ? (
                  <Spinner style={{ margin: "0.5rem 0 0 auto" }} />
                ) : (
                  <MediumButton
                    bg="var(--accent-1)"
                    border="var(--accent-1)"
                    textcolor="var(--primary)"
                    style={{ alignSelf: "flex-end" }}
                    type="submit"
                  >
                    Submit
                  </MediumButton>
                )}
              </div>
            </>
          )}
          {activeTab === "multiple" && (
            <>
              <section className="upload-header">
                <div className={`${Flex} country`}>
                  <DualTone />
                  <p className="text-base-semibold">CSV Template</p>
                </div>
                <Link
                  to="https://maxim-csv.s3.amazonaws.com/Bulk+lookup+csv+example+-+Sheet1.csv"
                  target="_blank"
                >
                  <p className="text-md-semibold select">Download</p>
                </Link>
              </section>
              <p className="text-base-semibold headings">
                For multiple requests
              </p>
              <UploadCSV onFileUpload={(file) => setBulkFile(file)} />
              {showCautionMsg && (
                <ToastWrapper className="warning !bg-[#FDFAF4] text-neutral mt-2">
                  <p className="text-md-medium">
                    The amount of data entries detected surpasses your available
                    unit for User ID Verification. You can click here to{" "}
                    <span
                      className="warning cursor-pointer"
                      onClick={() => handleTopup()}
                    >
                      Purchase more User ID Verification units
                    </span>
                  </p>
                </ToastWrapper>
              )}
              <div className="mt-auto self-end">
                {isLoading ? (
                  <Spinner style={{ margin: "0.5rem 0 0 auto" }} />
                ) : (
                  <MediumButton
                    bg="var(--accent-1)"
                    border="var(--accent-1)"
                    textcolor="var(--primary)"
                    style={{ alignSelf: "flex-end" }}
                    onClick={handleBulkSubmit}
                    disabled={showCautionMsg}
                  >
                    Submit
                  </MediumButton>
                )}
              </div>
            </>
          )}
        </form>
      )}
      <Toaster position="top-center" visibleToasts={2} />
    </CustomModal>
  );
};

export default InitiateLookup;
