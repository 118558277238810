import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Toaster, toast } from 'sonner';
import store from 'store';
import Toast from "components/common/ToastNotification";
import Modal from 'components/modals';
import { MediumButton } from 'components/button';
import { Form244H } from 'components/formFields/formStyles';
import { ModalBodyWrapper, } from 'components/modals/modalStyles';
import FloatingTextField from 'components/formFields/FloatingTextField';
import { FlexCenter, FlexCol, FlexColumnBetween, FlexRowBetween } from 'assets/styles/common-styles';
import { ReactComponent as UserCheck } from "assets/images/icons/user-check.svg";
import { validateFormData } from 'utils/validateFormData';
import { getLocalStorageProperties, parseUserInfo } from 'utils/user';
import Spinner from 'components/common/Spinner';

const DirectorInfoVerificationModal = ({
    isOpen,
    onClose,
    handleDirectorInfoVerificationSuccessModal,
    handleSwitchDirectorInfoVerification,
    directorInfoDetails,
  }) => {
  const snap = useSnapshot(store.lookups);
  const companyDirectors = snap?.companyDirectors;
  const userValues = parseUserInfo("user");
  const companyInfo = localStorage.getItem('companyInfo');
  const propertiesToExtract = ['id', 'name',];
  const extractedProperties = getLocalStorageProperties(companyInfo, propertiesToExtract);
  const { id: companyId, name: companyName } = extractedProperties;
  const [selectedValue, setSelectedValue] = useState({
    date_of_birth: "dd/mm/yyyy",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (companyId || userValues?.companyId) {
      store.lookups.getCompanyDirectors(companyId || userValues?.companyId);
    }
  }, [companyId, userValues?.companyId]);

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });    

    const data =  {
      first_name: directorInfoDetails?.first_name,
      last_name: directorInfoDetails?.last_name,
      is_director: directorInfoDetails?.is_director,
      phone_number: directorInfoDetails?.phone_number,
      date_of_birth: selectedValue.date_of_birth,
      company_id: directorInfoDetails?.company_id,
      director_id: directorInfoDetails?.director_id,
    };
    const validationRules = {
      first_name: [{ required: true }],
      last_name: [{ required: true }],
      is_director: [{ required: true }],
      phone_number: [{ required: true }],
      date_of_birth: [{ required: true }],
      company_id: [{ required: true }],
      director_id: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }

    try {
      const response = await store.onboarding.postCompanyDirectorInfo(data);
      if (response && response?.data?.id) {
        const data = {
          "user_id": directorInfoDetails?.director_id,
          "user_type": "director"
        };
        try {
          const response = await store.onboarding.getKycUrl(data);
          if (response && response?.data?.url) {
            const data = {
              "url": response?.data?.url,
              "qr_code": response?.data?.qr_code
            };
            handleDirectorInfoVerificationSuccessModal(data);
            onClose();
          } else if (response?.errors || response?.data?.errors) {
            const errorKeys = Object.keys(response?.errors || response?.data?.errors);
            errorKeys.forEach((key) => {
              (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
                toast.custom((t) => (
                  <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
                ));
              });
            });
          } else {
            toast.custom((t) => (
              <Toast toastType="error" text={response?.data?.message || response?.message} />
            ));
          }
        } catch (error) {
          toast.custom((t) => (
            <Toast toastType="error" text={"Something went wrong"} />
          ));
        }
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className="longer">
      <ModalBodyWrapper className={`${FlexColumnBetween} director-info`}>
        <section className="modal-header">
          <p className="text-base">{companyName || companyDirectors?.company?.name}</p>
          <h2 className="modal-title">Director detail confirmation</h2>
        </section>
        <form id="director_info" onSubmit={handleSubmit}>
          <Form244H>
            <section className={`${FlexCol} director-summary`}>
              <h3 className="heading-h3 headings">
                {directorInfoDetails?.last_name +" "+ directorInfoDetails?.first_name}
              </h3>
              <section className={FlexRowBetween}>
                <div>
                  <p className="text-base">Role</p>
                  <p className="text-base-medium">{directorInfoDetails?.role}</p>
                </div>
                <div>
                  <p className="text-base">Nationality</p>
                  <p className="text-base-medium">{directorInfoDetails?.nationality}</p>
                </div>
                <div className={`${FlexCenter} icon-bg`}>
                  <UserCheck />
                </div>
              </section>
            </section>
            <FloatingTextField
              type="date"
              label="Date of Birth"
              id="date_of_birth"
              name="date_of_birth"
              labelfor="date_of_birth"
              value={selectedValue.date_of_birth}
              onChange={(e) => handleChange("date_of_birth", e)}
              fullwidth="true"
            />
          </Form244H>
        </form>
        <div className={`${FlexRowBetween} button-group`}>
          <MediumButton
            bg="var(--white-alt)"
            textcolor="var(--text-dark)"
            type="button"
            onClick={handleSwitchDirectorInfoVerification}
          >
            Back
          </MediumButton>
          {isLoading ? (
            <Spinner style={{ margin: "0.5rem 0 0.5rem auto" }}/>
          ) : (
            <MediumButton
              bg="var(--accent-1)"
              border="var(--accent-1)"
              textcolor="var(--primary)"
              type={selectedValue?.date_of_birth === "" ? "button" : "submit"}
              disabled={selectedValue?.date_of_birth === ""}
              form="director_info"
            >
              Submit details
            </MediumButton>
          )}
        </div>
      </ModalBodyWrapper>
      <Toaster position="top-center" visibleToasts={5} />
    </Modal>
  );
};

export default DirectorInfoVerificationModal;
