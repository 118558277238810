import { useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import store from "store";
import FloatingTextField from "components/formFields/FloatingTextField";
import { FullWidthButton } from "components/button";
import { VerifyEmailForm } from "assets/styles/authpage-styles";
import { validateFormData } from "utils/validateFormData";
import Toast from "components/common/ToastNotification";
import Spinner from "components/common/Spinner";

const ForgotPassword = ({ onSendResetCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    email: ""
  });

  const handleChange = (name, e) => {
    const value = e.target.value;
    setSelectedValue({ ...selectedValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    setIsLoading(true);

    Object.keys(selectedValue).forEach((key) => {
      formData.append(key, selectedValue[key]?.value ?? selectedValue[key]);
    });    

    const data =  {
      email: selectedValue.email,
    };
    const validationRules = {
      email: [{ required: true }],
    };

    const errors = validateFormData(formData, validationRules);
    const errorMessages = Object.values(errors);
    if (errorMessages.length) {
      const firstError = errorMessages[0];
      toast.custom((t) => (
        <Toast toastType="error" text={firstError.replace("_", " ")} />
      ));
      setIsLoading(false);
      return;
    }
    
    try {
      const response = await store.auth.postRequestPasswordReset(data);
      if (response && response?.data?.reference !== "") {
        const newData = {
          email: data.email,
          reference: response?.data?.reference
        };
        toast.custom((t) => (
          <Toast toastType="success" text={response.message} />
        ));
        onSendResetCode(newData);
      } else if (response?.errors || response?.data?.errors) {
        const errorKeys = Object.keys(response?.errors || response?.data?.errors);
        errorKeys.forEach((key) => {
          (response?.errors?.[key] || response?.data?.errors?.[key]).forEach((errorMessage) => {
            toast.custom((t) => (
              <Toast key={`${key}-${errorMessage}`} toastType="error" text={errorMessage} />
            ));
          });
        });
      } else {
        toast.custom((t) => (
          <Toast toastType="error" text={response?.data?.message || response?.message} />
        ));
      }
    } catch (error) {
      toast.custom((t) => (
        <Toast toastType="error" text={"Something went wrong"} />
      ));
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <section className="container">
      <section className="header">
        <h2 className="heading-h2">Reset password</h2>
        <p className="text-base">Enter your email to reset password.</p>
      </section>
      <VerifyEmailForm onSubmit={handleSubmit}>
        <FloatingTextField
          label="Enter email"
          type="email"
          name="email"
          id="email"
          labelfor="email"
          value={selectedValue.email}
          onChange={(e) => handleChange("email", e)}
        />
          <p className="text-base resend">
          </p>
        {isLoading ? (
          <Spinner style={{ margin: "0.5rem auto" }}/>
        ) : (
          <FullWidthButton
            bg="var(--accent-1)"
            textcolor="var(--primary)"
            width="100%"
            type="submit"
          >
            Send reset code
          </FullWidthButton>
        )}
      </VerifyEmailForm>
      <section className="base">
        <p className="text-base">Need help? <Link to="#">Contact Support</Link> or <Link to="/auth/login">Log in</Link></p>
      </section>
      <Toaster position="top-center" visibleToasts={3} />
    </section>
  );
}

export default ForgotPassword;
